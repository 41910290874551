import React, { createContext, useContext } from 'react';
import { useAuth } from '../context/AuthContext'

const Notification = () => {
  const { notification } = useAuth();

  if (!notification) return null;

  // const notificationStyle = 
  // notification.type === "success"
  //   ? "bg-green-500 text-white" 
  //   : "bg-red-500 text-white";

  const notificationStyle =
    notification.type === "success"
      ? "bg-green-500 text-white"
      : notification.type === "error"
        ? "bg-red-500 text-white"
        : notification.type === "info"
          ? "bg-yellow-500 text-white"
          : "";


  return (
    <div className={`fixed top-5 right-5 p-4  shadow-md rounded-xl z-50 ${notificationStyle}`}>
      {notification.message}
    </div>
  );
};

export default Notification