import React from 'react';
import { TrashIcon, PlusIcon, DocumentAddIcon } from '@heroicons/react/outline';

const ActionPopup = ({ handleDeleteSection, openCreateGroupPopup, openCreateSubSectionPopup, section, closePopup }) => {
    return (
        <div className="fixed inset-0 flex items-center justify-center z-50 bg-black bg-opacity-50">
            <div className="bg-white rounded-lg p-8 w-full max-w-3xl mx-4">
                <h2 className="text-2xl font-bold mb-6">Choose an Action</h2>
                <div className="flex flex-col space-y-4">
                    <button
                        className="flex items-center space-x-3 p-4 bg-gray-100 text-gray-700 rounded-lg hover:bg-gray-200 transition"
                        onClick={(e) => {
                            e.stopPropagation();
                            const isConfirmed = window.confirm('Are you sure you want to delete this section?');
                            if (isConfirmed) {
                                handleDeleteSection(section.id);
                                closePopup();
                            }
                        }}
                    >
                        <TrashIcon className="w-6 h-6" />
                        <span className="text-lg">Delete Section</span>
                    </button>
                    <button
                        className="flex items-center space-x-3 p-4 bg-gray-100 text-gray-700 rounded-lg hover:bg-gray-200 transition"
                        onClick={(e) => {
                            e.stopPropagation();
                            openCreateGroupPopup(section, "section");
                            closePopup();
                        }}
                    >
                        <PlusIcon className="w-6 h-6" />
                        <span className="text-lg">Add Group</span>
                    </button>
                    <button
                        className="flex items-center space-x-3 p-4 bg-gray-100 text-gray-700 rounded-lg hover:bg-gray-200 transition"
                        onClick={(e) => {
                            e.stopPropagation();
                            openCreateSubSectionPopup(section);
                            closePopup();
                        }}
                    >
                        <DocumentAddIcon className="w-6 h-6" />
                        <span className="text-lg">Add Subsection</span>
                    </button>
                </div>
                <button
                    className="mt-6 bg-gray-500 text-white p-3 rounded-lg hover:bg-gray-600 transition"
                    onClick={closePopup}
                >
                    Close
                </button>
            </div>
        </div>
    );
};

export default ActionPopup;
