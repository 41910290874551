import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useAuth } from '../../context/AuthContext';
import { format } from 'date-fns';
import { pl } from 'date-fns/locale';
import {  notification } from 'antd';

const AssignAudit = ({ selectedUser, onCancel }) => {
    const [audits, setAudits] = useState([]);
    const [selectedAudits, setSelectedAudits] = useState([]);
    const backendUrl = process.env.REACT_APP_BACKEND_URL;
    const { authTokens } = useAuth();

    useEffect(() => {
        fetchAudits();
    }, []);

    const fetchAudits = async () => {
        try {
            const response = await axios.get(`${backendUrl}/audit-page/audits/`, {
                headers: {
                    Authorization: `Bearer ${authTokens?.access || ''}`,
                },
            });
            setAudits(response.data);

            // Inicjalizacja zaznaczonych audytów na podstawie przypisanych użytkowników
            const assignedAudits = response.data
                .filter(audit => audit.assigned_users.includes(selectedUser))
                .map(audit => audit.audit_id);
            setSelectedAudits(assignedAudits);
        } catch (error) {
            console.error('Error fetching audits:', error);
            notification.error({
                message: 'Wystąpił błąd',
                description: 'Nie udało się pobrać audytów.',
            });
        }
    };

    const handleAuditChange = (e) => {
        const auditId = parseInt(e.target.value);  // Zapewniamy, że auditId jest liczbą
        if (e.target.checked) {
            setSelectedAudits([...selectedAudits, auditId]);
        } else {
            setSelectedAudits(selectedAudits.filter((id) => id !== auditId));
        }
    };

    const handleSubmit = async () => {
        try {
            await axios.post(`${backendUrl}/audit-page/assign-audit/`, {
                user_id: selectedUser,
                audit_ids: selectedAudits,
            }, {
                headers: {
                    Authorization: `Bearer ${authTokens?.access || ''}`,
                },
            });
            alert('Audits assigned successfully');
            onCancel();
        } catch (error) {
            console.error('Error assigning audits:', error);
            notification.error({
                message: 'Wystąpił błąd',
                description: 'Nie udało się przypisać audytów.',
            });
        }
    };

    const formatDateRange = (startDate, endDate) => {
        if (!startDate || !endDate) return '';
        const start = new Date(startDate);
        const end = new Date(endDate);
        if (start.getMonth() === end.getMonth() && start.getFullYear() === end.getFullYear()) {
            return `${format(start, 'd', { locale: pl })}-${format(end, 'd.MM.yyyy', { locale: pl })}`;
        }
        return `${format(start, 'd.MM', { locale: pl })}-${format(end, 'd.MM.yyyy', { locale: pl })}`;
    };

    return (
        <div className="bg-white shadow-lg rounded-lg p-6  mx-auto">
            <h3 className="text-2xl font-bold text-gray-900 mb-6">Przypisz Audyty</h3>
            <div className="mb-6 space-y-4">
                {audits.map((audit) => (
                    <div key={audit.audit_id} className="flex items-center justify-between">
                        <label className="inline-flex items-center cursor-pointer">
                            <input
                                type="checkbox"
                                value={audit.audit_id}
                                checked={selectedAudits.includes(audit.audit_id)}
                                onChange={handleAuditChange}
                                className="form-checkbox h-5 w-5 text-indigo-600 transition duration-150 ease-in-out"
                            />
                            <span className="ml-3 text-gray-700">{audit.audit_title}</span>
                            <span className="text-sm text-gray-500 ml-4">
                                {formatDateRange(audit.start_date, audit.end_date)}
                            </span>
                        </label>

                    </div>
                ))}
            </div>
            <div className="flex justify-end space-x-4">
                <button
                    onClick={handleSubmit}
                    className="px-4 py-2 bg-indigo-600 text-white rounded-md hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 transition duration-150 ease-in-out"
                >
                    Przypisz Audyty
                </button>
                <button
                    onClick={onCancel}
                    className="px-4 py-2 bg-gray-200 text-gray-700 rounded-md hover:bg-gray-300 focus:outline-none focus:ring-2 focus:ring-gray-500 focus:ring-offset-2 transition duration-150 ease-in-out"
                >
                    Anuluj
                </button>
            </div>
        </div>
    );
};

export default AssignAudit;