import React, { useState, useEffect, useRef } from 'react';
import { ReactComponent as DashboardIcon } from '../../utils/dashboard/dashboard.svg';
import { ReactComponent as ScheduledIcon } from '../../utils/dashboard/scheduled.svg';
import { ReactComponent as SettingsIcon } from '../../utils/dashboard/settings.svg';
import { ReactComponent as LogoutIcon } from '../../utils/dashboard/logout.svg';
import { ReactComponent as CreateIcon } from '../../utils/dashboard/create-audit.svg';
import { ReactComponent as AdminIcon } from '../../utils/dashboard/admin-alt.svg';
import { ReactComponent as CalendarIcon } from '../../utils/dashboard/calendar.svg';
import { ReactComponent as QACheckIcon } from '../../utils/dashboard/completed.svg';
import { ReactComponent as SentIcon } from '../../utils/dashboard/completed.svg';
import GuestifyLogo from '../../utils/dashboard/guestify.png';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { MenuIcon, XIcon } from '@heroicons/react/solid';
import { useAuth } from '../../context/AuthContext';
import { FaTachometerAlt, FaCalendarAlt, FaCheckCircle, FaPaperPlane, FaCog, FaUserShield, FaSignOutAlt, FaPlusCircle } from 'react-icons/fa';

// Popups
import CreateAuditPopup from '../Popups/CreateAuditPopup';

const Nav = ({ currentPage, setCurrentPage, setIsSectionsView, setCreatePageNumber }) => {

    const [isCreateAuditPopupVisible, setIsCreateAuditPopupVisible] = useState(false);
    const [isOpen, setIsOpen] = useState(false);
    const { logout, userInfo } = useAuth();
    const popupRef = useRef(null);

    const navigate = useNavigate();
    const location = useLocation();

    const navigateToDashboard = () => {
        if (location.pathname !== '/home') {
            navigate('/home');
        }
    };

    const handleClick = () => {
        setIsOpen(!isOpen);
    };

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (popupRef.current && !popupRef.current.contains(event.target)) {
                setIsCreateAuditPopupVisible(false);
            }
        };

        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [popupRef]);

    useEffect(() => {
        if (location.pathname === '/home' && currentPage === 7) {
            setCurrentPage(1);
        }
    }, [location.pathname, setCurrentPage]);

    const getColor = (page) => {
        if (location.pathname === '/adminPanel') {
            return page === 7 ? 'bg-zinc-800 text-white' : 'hover:bg-gray-200';
        }
        if (location.pathname === '/calendar') {
            return page === 8 ? 'bg-zinc-800 text-white' : 'hover:bg-gray-200';
        }
        if (location.pathname === '/home') {
            return currentPage === page ? 'bg-zinc-800 text-white' : 'hover:bg-gray-200';
        }
        return 'hover:bg-gray-200';
    };

    const getFill = (page) => {
        if (location.pathname === '/adminPanel') {
            return page === 7 ? '#fff' : '#000';
        }
        if (location.pathname === '/calendar') {
            return page === 8 ? '#fff' : '#000';
        }
        if (location.pathname === '/home') {
            return currentPage === page ? '#fff' : '#000';
        }
        return '#000';
    };

    return (
        <>
            {isCreateAuditPopupVisible && <CreateAuditPopup popupRef={popupRef} setIsCreateAuditPopupVisible={setIsCreateAuditPopupVisible} setCreatePageNumber={setCreatePageNumber} />}
            <div className='hidden lg:block w-72 h-screen border-r border-black'>
                <div className='w-72 m-auto flex flex-col justify-between h-full pt-12'>
                    <div><Link to="/home"><img src={GuestifyLogo} className='w-40 m-auto' /> </Link></div>
                    <div className='align-center text-sm mt-6 w-40 mx-auto '>
                        <div
                            onClick={() => {
                                navigateToDashboard();
                                setCurrentPage(1);
                            }}
                            className={`${getColor(1)} rounded-lg py-2 px-2 w-40 flex items-center justify-center cursor-pointer text-center mb-2`}>
                            <DashboardIcon fill={getFill(1)} className='w-4 h-4' />
                            <h3 className='ml-3 text-base font-semibold w-24 text-start'>Dashboard</h3>
                        </div>

                        <div onClick={() => { navigateToDashboard(); setCurrentPage(2); }} className={`${getColor(2)} rounded-lg py-2 px-2 w-40 flex items-center justify-center cursor-pointer text-center mb-2`}>
                            <ScheduledIcon fill={getFill(2)} className='w-4 h-4 ' />
                            <h3 className='ml-3 text-base font-semibold w-24 text-start'>Scheduled</h3>
                        </div>

                        <div onClick={() => { navigateToDashboard(); setCurrentPage(4); }} className={`${getColor(4)} rounded-lg py-2 px-2 w-40 flex items-center justify-center cursor-pointer text-center mb-2`}>
                            <QACheckIcon fill={getFill(4)} className='w-4 h-4' />
                            <h3 className='ml-3 text-base font-semibold w-24 text-start'>QA Check</h3>
                        </div>
                        <div onClick={() => { navigateToDashboard(); setCurrentPage(5); }} className={`${getColor(5)} rounded-lg py-2 px-2 w-40 flex items-center justify-center cursor-pointer text-center mb-2`}>
                            <SentIcon fill={getFill(5)} className='w-4 h-4' />
                            <h3 className='ml-3 text-base font-semibold w-24 text-start'>Sent</h3>
                        </div>
                        <div onClick={() => { navigateToDashboard(); setCurrentPage(6); }} className={`${getColor(6)} rounded-lg py-2 px-2 w-40 flex items-center justify-center cursor-pointer text-center mb-2`}>
                            <SettingsIcon fill={getFill(6)} className='w-4 h-4' />
                            <h3 className='ml-3 text-base font-semibold w-24 text-start'>Settings</h3>
                        </div>
                        {userInfo.role === 'Admin' && (
                            <Link to="/adminPanel" onClick={() => setCurrentPage(7)} className={`${getColor(7)} rounded-lg py-2 px-2 w-40 flex items-center justify-center cursor-pointer text-center mb-2`}>
                                <AdminIcon fill={getFill(7)} className='w-4 h-4' />
                                <h3 className='ml-3 text-base font-semibold w-24 text-start'>Admin Panel</h3>
                            </Link>
                        )}
                        <Link to="/calendar" onClick={() => setCurrentPage(8)} className={`${getColor(8)} rounded-lg py-2 px-2 w-40 flex items-center justify-center cursor-pointer text-center mb-2`}>
                            <CalendarIcon fill={getFill(8)} className='w-4 h-4' />
                            <h3 className='ml-3 text-base font-semibold w-24 text-start'>Calendar</h3>
                        </Link>
                        <div onClick={() => { logout() }} className={`rounded-lg py-2 px-2 w-40 flex items-center justify-center cursor-pointer text-center mb-2 hover:bg-gray-200`}>
                            <LogoutIcon className='w-5 h-5 ' />
                            <h3 className='ml-2 text-base font-semibold w-24 text-start'>Logout</h3>
                        </div>
                    </div>

                    <div className='m-auto mb-10 text-center'>
                        <div onClick={() => setIsCreateAuditPopupVisible(true)} className='bg-zinc-800 rounded-lg py-2 px-2 w-40 flex items-center justify-center cursor-pointer text-center mb-2'>
                            <CreateIcon fill="#fff" className='w-4 h-4 ' />
                            <h3 className='text-white text-base ml-3 font-semibold'>Create Audit</h3>
                        </div>
                        <span className='text-xs'>ver. 3.1 / 250425</span>
                    </div>
                </div>
            </div>
            <button onClick={handleClick} className='lg:hidden block w-screen  h-12 pr-4'>
                {isOpen ? <XIcon className="h-8 w-8 ml-auto" /> : <MenuIcon className="h-8 w-8 ml-auto" />}
            </button>

            {/* MOBILE VIEW */}
            {isOpen && (
                <div className="fixed inset-0 z-50 bg-white overflow-auto">
                    <div className="flex flex-col h-full">
                        {/* Close Button */}
                        <div className="flex justify-end p-2">
                            <button onClick={handleClick}>
                                <XIcon className="h-6 w-6 text-gray-700" />
                            </button>
                        </div>
                        <div className="flex justify-center mb-4">
                            <img src={GuestifyLogo} className="w-32" alt="Guestify Logo" />
                        </div>
                        <div className='my-auto'>
                            {/* Logo */}


                            {/* Menu Items */}
                            <div className="flex flex-col items-center flex-grow px-4">
                                <div
                                    onClick={() => {
                                        navigateToDashboard();
                                        setCurrentPage(1);
                                        handleClick();
                                    }}
                                    className={`${getColor(
                                        1
                                    )} w-full max-w-sm rounded-lg py-2 px-3 mb-3 flex items-center justify-center cursor-pointer`}
                                >
                                    <h3 className="text-lg font-semibold">Dashboard</h3>
                                </div>

                                <div
                                    onClick={() => {
                                        navigateToDashboard();
                                        setCurrentPage(2);
                                        handleClick();
                                    }}
                                    className={`${getColor(
                                        2
                                    )} w-full max-w-sm rounded-lg py-2 px-3 mb-3 flex items-center justify-center cursor-pointer`}
                                >
                                    <h3 className="text-lg font-semibold">Scheduled</h3>
                                </div>

                                <div
                                    onClick={() => {
                                        navigateToDashboard();
                                        setCurrentPage(4);
                                        handleClick();
                                    }}
                                    className={`${getColor(
                                        4
                                    )} w-full max-w-sm rounded-lg py-2 px-3 mb-3 flex items-center justify-center cursor-pointer`}
                                >
                                    <h3 className="text-lg font-semibold">QA Check</h3>
                                </div>

                                <div
                                    onClick={() => {
                                        navigateToDashboard();
                                        setCurrentPage(5);
                                        handleClick();
                                    }}
                                    className={`${getColor(
                                        5
                                    )} w-full max-w-sm rounded-lg py-2 px-3 mb-3 flex items-center justify-center cursor-pointer`}
                                >
                                    <h3 className="text-lg font-semibold">Sent</h3>
                                </div>

                                <div
                                    onClick={() => {
                                        navigateToDashboard();
                                        setCurrentPage(6);
                                        handleClick();
                                    }}
                                    className={`${getColor(
                                        6
                                    )} w-full max-w-sm rounded-lg py-2 px-3 mb-3 flex items-center justify-center cursor-pointer`}
                                >
                                    <h3 className="text-lg font-semibold">Settings</h3>
                                </div>

                                {userInfo.role === 'Admin' && (
                                    <div
                                        onClick={() => {
                                            navigate('/adminPanel');
                                            setCurrentPage(7);
                                            handleClick();
                                        }}
                                        className={`${getColor(
                                            7
                                        )} w-full max-w-sm rounded-lg py-2 px-3 mb-3 flex items-center justify-center cursor-pointer`}
                                    >
                                        <h3 className="text-lg font-semibold">Admin Panel</h3>
                                    </div>
                                )}

                                <div
                                    onClick={() => {
                                        handleClick();
                                        setIsCreateAuditPopupVisible(true);
                                    }}
                                    className="w-full max-w-sm rounded-lg py-2 px-3 mb-3 flex items-center justify-center cursor-pointer bg-green-500 text-white"
                                >
                                    <h3 className="text-lg font-semibold">Create Audit</h3>
                                </div>

                                <div
                                    onClick={() => {
                                        logout();
                                    }}
                                    className="w-full max-w-sm rounded-lg py-2 px-3 mb-6 flex items-center justify-center cursor-pointer bg-red-500 text-white"
                                >
                                    <h3 className="text-lg font-semibold">Logout</h3>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            )}


        </>
    );
};

export default Nav;