import React from 'react';

const CommentPopup = ({
  currentComment,
  setCurrentComment,
  closeCommentPopup,
  saveComment,
  commentPopupRef
}) => {
  return (
    <div className="fixed inset-0 flex items-center justify-center z-50 bg-black bg-opacity-50">
      <div className="bg-white p-8 rounded-lg w-full max-w-3xl mx-4 shadow-lg" ref={commentPopupRef}>
        <h2 className="text-2xl font-bold mb-6 border-b border-gray-300 pb-2">
          Comment
        </h2>
        <textarea
          value={currentComment}
          onChange={(e) => setCurrentComment(e.target.value)}
          placeholder="Enter comment content..."
          className="border border-gray-300 p-3 w-full h-64 rounded-lg focus:outline-none focus:border-gray-500 font-semibold"
        />
      </div>
    </div>
  );
};

export default CommentPopup;
