import React from 'react';
import { ReactComponent as CreateIcon } from '../../utils/dashboard/create-audit.svg';
import { Link } from 'react-router-dom';

const CreateAuditPopup = ({ setIsCreateAuditPopupVisible, setCreatePageNumber, popupRef }) => {
    return (
        // Overlay
        <div className='fixed inset-0 z-40 flex items-center justify-center bg-black bg-opacity-50' onClick={() => setIsCreateAuditPopupVisible(false)}>
            {/* Popup Container */}
            <div className='bg-white rounded-lg shadow-lg max-w-4xl w-full p-6 m-4 z-50' ref={popupRef} onClick={e => e.stopPropagation()}>
                <div className='text-center mb-6'>
                    <h1 className='text-3xl font-bold text-gray-800'>New Audit</h1>
                </div>
                <div className='grid grid-cols-1 md:grid-cols-2 gap-4'>
                    <Link onClick={() => {setIsCreateAuditPopupVisible(false); setCreatePageNumber(1)}} to="/create/" className='bg-gray-800 rounded-md p-6 flex flex-col items-center justify-between hover:bg-gray-700 transition-colors'>
                    <div className='flex items-center'>
                        <h3 className='text-3xl font-semibold text-white'>Create</h3>
                        <CreateIcon fill="#fff" className='w-8 h-8 ml-4' />
                    </div>
                    <span className='text-white text-lg'>Create audit from scratch</span>
                </Link>
                <Link onClick={() => {setIsCreateAuditPopupVisible(false); setCreatePageNumber(2)}} to="/create/" className='bg-gray-800 rounded-md p-6 flex flex-col items-center justify-between hover:bg-gray-700 transition-colors'>
                    <div className='flex items-center'>
                        <h3 className='text-3xl font-semibold text-white'>Copy</h3>
                        <CreateIcon fill="#fff" className='w-8 h-8 ml-4' />
                    </div>
                    <span className='text-white text-lg'>Copy from previous audit</span>
                </Link>
            </div>
        </div>
        </div >
    );
};

export default CreateAuditPopup;
