import React, { useEffect, useRef } from 'react';

const CreateGroupPopup = ({ handleGroupTextChange, handleCreateGroup, handleCreateGroupInSubsection, setIsCreateGroupPopupVisible, createGroupPopupRef, isSubsection }) => {

  const textareaRef = useRef(null);

  const handleCreateGroupInternal = () => {
    if (isSubsection) {
      handleCreateGroupInSubsection();
    } else {
      handleCreateGroup();
    }
  };

  useEffect(() => {
    const handleKeyDown = (event) => {
      if (event.key === 'Enter' && !event.shiftKey) {
        event.preventDefault();
        handleCreateGroupInternal();
      }
    };

    const textareaElement = textareaRef.current;
    textareaElement.addEventListener('keydown', handleKeyDown);

    return () => {
      textareaElement.removeEventListener('keydown', handleKeyDown);
    };
  }, [handleCreateGroupInternal]);

  return (
    <div className="fixed inset-0 flex items-center justify-center z-50 bg-black bg-opacity-50">
      <div className="bg-white rounded-lg p-8 w-full max-w-3xl mx-4" ref={createGroupPopupRef}>
        <h2 className="text-2xl font-bold mb-6">Create a New Group</h2>
        <div className="mb-4">
          <label htmlFor="groupName" className="block text-gray-700 text-lg font-bold mb-2">
            Group Name:
          </label>
          <input
            id="groupName"
            ref={textareaRef}
            className="border border-gray-300 p-3 w-full rounded-lg focus:outline-none focus:border-gray-500 font-semibold"
            placeholder="Enter group name..."
            onChange={handleGroupTextChange}
          ></input>
        </div>
        <div className="flex justify-between items-center mt-6">
          <button
            onClick={() => setIsCreateGroupPopupVisible(false)}
            className="bg-gray-500 text-white px-4 py-2 rounded-lg hover:bg-gray-600 transition"
          >
            Close
          </button>
          <button
            onClick={handleCreateGroupInternal}
            className="bg-green-500 text-white px-4 py-2 rounded-lg hover:bg-green-600 transition"
          >
            Save
          </button>
        </div>
      </div>
    </div>
  );
};

export default CreateGroupPopup;
