import React, { useState, useEffect } from 'react';
import { useAuth } from '../context/AuthContext';
import ActivityLogs from '../components/AdminPanel/ActivityLogs';
import UserManagement from '../components/AdminPanel/UserManagement';
import Clients from '../components/AdminPanel/Clients';

import Nav from '../components/MainPage/Nav';
import Sidebar from '../components/MainPage/Sidebar';
import UserElement from '../components/UserElement';
import MainSearchbar from '../components/MainSearchbar';

const getDescription = (sectionName) => {
    const descriptions = {
        Users: 'Zarządzaj użytkownikami systemu',
        Clients: 'Przeglądaj i edytuj dane klientów',
        Analytics: 'Analizuj statystyki i raporty',
        Logs: 'Przeglądaj logi aktywności',
        Overview: 'Ogólny przegląd systemu',
        Settings: 'Konfiguruj ustawienia aplikacji',
    };
    return descriptions[sectionName] || 'Brak opisu';
};

const AdminPage = () => {
    const { authTokens, allAudits } = useAuth();
    const [selectedSection, setSelectedSection] = useState(null); // State to store the selected section

    const [userStats, setUserStats] = useState({ total: 0, admin: 0, manager: 0, auditor: 0 });
    const [auditStats, setAuditStats] = useState({ total: 0, completed: 0, inProgress: 0, planned: 0 });
    const [systemHealth, setSystemHealth] = useState({
        serverStatus: 'OK',
        lastBackup: '',
        errorRate: 0,
    });
    const sections = [
        { name: 'Users', component: UserManagement },
        { name: 'Clients', component: Clients },
        { name: 'Logs', component: ActivityLogs },
    ];

    useEffect(() => {
        const fetchData = async () => {
            try {
                // Replace with actual API call
                // Example data below
                setUserStats({ total: 100, admin: 5, manager: 15, auditor: 80 });
                setAuditStats({ total: 500, completed: 300, inProgress: 150, planned: 50 });
                setSystemHealth({ serverStatus: 'OK', lastBackup: '2023-05-02 23:00', errorRate: 0.5 });
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        };

        fetchData();
    }, []);

    const handleSectionClick = (section) => {
        setSelectedSection(section);
    };

    const {

        currentPage,
        setCurrentPage,
        createPageNumber,
        setCreatePageNumber,

    } = useAuth() || {};

    return (
        <div className="flex flex-col lg:flex-row max-h-screen overflow-hidden">
            <Nav
                currentPage={currentPage}
                setCurrentPage={setCurrentPage}
                createPageNumber={createPageNumber}
                setCreatePageNumber={setCreatePageNumber}
            />

            <div className="w-full p-4 sm:p-6 lg:p-8 sm:!pt-0 flex flex-col min-h-screen overflow-auto pb-12">
                {/* Header */}
                <div className="w-full pt-4 sm:pt-8 mb-4 sm:mb-8">
                    {/* <div className="flex flex-col sm:flex-row sm:justify-between items-start sm:items-center">
                        <div>
                            <h1 className="text-3xl sm:text-4xl font-bold text-gray-800 mb-2">Panel Administratora</h1>
                            <p className="text-lg sm:text-xl text-gray-600 mb-4 sm:mb-6">Zarządzaj systemem i użytkownikami</p>
                        </div>
                        <div className='flex mr-3'>
                            <div className='hidden lg:block w-80 mx-12 relative'>
                                <MainSearchbar allAudits={allAudits} />
                            </div>
                            <UserElement />
                        </div>

                    </div> */}
                    <div className='w-full flex  justify-between mb-4'>
                        {/* Sekcja tytułu i powitania */}
                        <div className='flex flex-col'>
                            <h1 className="text-3xl sm:text-4xl font-bold text-gray-800 mb-2">Panel Administratora</h1>
                            <p className="text-lg sm:text-xl text-gray-600 mb-4 sm:mb-6">Zarządzaj systemem i użytkownikami</p>

                        </div>

                        {/* Sekcja wyszukiwania */}
                        <div className='flex mr-3'>
                            <div className='hidden lg:block w-80 mx-12 relative'>
                                <MainSearchbar allAudits={allAudits} />
                            </div>
                            <UserElement />
                        </div>

                    </div>


                    {/* Statistics */}
                    {!selectedSection && (
                        <div className="grid grid-cols-1 md:grid-cols-3 gap-4 sm:gap-6 mb-4 sm:mb-8">
                            <div className="bg-white p-4 rounded-lg shadow">
                                <h2 className="text-lg font-semibold mb-2">Użytkownicy</h2>
                                <p>Łącznie: {userStats.total}</p>
                                <p>Admini: {userStats.admin}</p>
                                <p>Managerowie: {userStats.manager}</p>
                                <p>Audytorzy: {userStats.auditor}</p>
                            </div>
                            <div className="bg-white p-4 rounded-lg shadow">
                                <h2 className="text-lg font-semibold mb-2">Audyty</h2>
                                <p>Łącznie: {auditStats.total}</p>
                                <p>Zakończone: {auditStats.completed}</p>
                                <p>W toku: {auditStats.inProgress}</p>
                                <p>Zaplanowane: {auditStats.planned}</p>
                            </div>
                            <div className="bg-white p-4 rounded-lg shadow">
                                <h2 className="text-lg font-semibold mb-2">Stan systemu</h2>
                                <p>
                                    Status:{' '}
                                    <span className={systemHealth.serverStatus === 'OK' ? 'text-green-500' : 'text-red-500'}>
                                        {systemHealth.serverStatus}
                                    </span>
                                </p>
                                <p>Ostatnia kopia zapasowa: {systemHealth.lastBackup}</p>
                                <p>Wskaźnik błędów: {systemHealth.errorRate}%</p>
                            </div>
                        </div>
                    )}
                </div>

                {/* Main Content */}
                {!selectedSection ? (
                    <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-4 sm:gap-6 my-auto w-full">
                        {sections.map((section, index) => (
                            <div
                                key={index}
                                onClick={() => handleSectionClick(section)}
                                className="cursor-pointer p-6 bg-gradient-to-br from-gray-800 to-gray-700 shadow-lg rounded-lg flex flex-col items-center justify-center text-center transition duration-300 transform hover:scale-105 hover:shadow-xl h-40 sm:h-48"
                            >
                                <h3 className="text-xl sm:text-2xl font-bold text-white mb-2">{section.name}</h3>
                                <p className="text-gray-300 text-sm">{getDescription(section.name)}</p>
                            </div>
                        ))}
                    </div>
                ) : (
                    <div className="flex flex-col">
                        <button
                            onClick={() => setSelectedSection(null)}
                            className="mb-4 px-4 py-2 bg-gray-500 text-white rounded hover:bg-gray-600 w-max"
                        >
                            ← Powrót do Panelu
                        </button>
                        {selectedSection.component === UserManagement ? (
                            <UserManagement />
                        ) : selectedSection.component === ActivityLogs ? (
                            <ActivityLogs />
                        ) : selectedSection.component === Clients ? (
                            <Clients />
                        ) : (
                            <div>Wybrana sekcja: {selectedSection.name}</div>
                        )}
                    </div>
                )}
            </div>

            {/* Optional Sidebar for larger screens */}
            {/* Uncomment if needed */}
            {/* <div className="hidden lg:flex">
        <Sidebar
          scheduledAudits={scheduledAudits}
          completedAudits={completedAudits}
          currentAudit={currentAudit}
          setCurrentAudit={setCurrentAudit}
          setCurrentPage={setCurrentPage}
        />
      </div> */}
        </div>
    );
};

export default AdminPage;
