import React, { useState, useEffect, useRef } from 'react';
import { useAuth } from '../../context/AuthContext';
import axios from 'axios';
import { Link } from 'react-router-dom';
import { PDFDownloadLink } from '@react-pdf/renderer';
import Spinner from '../Spinner';
import { useLocation, useNavigate } from 'react-router-dom';
import SignaturePad from 'react-signature-canvas';
import AuditEditPopup from '../Popups/AuditEdit';
import AuditorPage from '../../pages/AuditorPage';
import { notification } from 'antd';

import ActionPlanNew from '../Pdfs/ActionPlan';
import TestPdf from '../Pdfs/FullRaport';
import { XIcon } from '@heroicons/react/solid'; // Upewnij się, że masz zainstalowany pakiet @heroicons/react

import MuseumIcon from '../../utils/dashboard/museum.svg';
import TheaterIcon from '../../utils/dashboard/theater.svg';
import HotelIcon from '../../utils/dashboard/hotel.svg';
import { ReactComponent as ChartHistogramIcon } from '../../utils/dashboard/chart-histogram.svg';
import { ReactComponent as SignatureIcon } from '../../utils/dashboard/signature.svg';
import { ReactComponent as AuditIcon } from '../../utils/svgs/audit.svg';
import { ReactComponent as PencilIcon } from '../../utils/svgs/pencil.svg';
import { ReactComponent as SettingsIcon } from '../../utils/dashboard/settings.svg';

const PreviewPopup = ({ audit, setAudit, PreviewPopupRef, setIsPreviewPopupVisible }) => {
    const [shouldGenerateReport, setShouldGenerateReport] = useState(false);
    const [shouldGenerateActionPlan, setShouldGenerateActionPlan] = useState(false);
    const backendUrl = process.env.REACT_APP_BACKEND_URL

    const [sections, setSections] = useState([]);
    const [auditTitle, setAuditTitle] = useState('');
    const [EndDate, setEndDate] = useState('');
    const [CoveragePhoto, setCoveragePhoto] = useState('');

    const [selectedAnswers, setSelectedAnswers] = useState({});

    const navigate = useNavigate();
    const location = useLocation();
    const { authTokens, userInfo } = useAuth();
    const auditorPageRef = useRef();

    const totalQuestions = audit.all_qs
    const yesAnswers = audit.yes_count
    const noAnswers = audit.no_count
    const naAnswers = audit.na_count
    const missedQs = audit.open_qs
    const answeredQuestions = totalQuestions - missedQs
    const progressPercentage = totalQuestions > 0 ? ((answeredQuestions / totalQuestions) * 100).toFixed(2) : 0;
    const scorePercentage = (answeredQuestions - naAnswers) > 0 ? ((yesAnswers / (answeredQuestions - naAnswers)) * 100).toFixed(2) : 0;

    const [isGeneratingReport, setIsGeneratingReport] = useState(false);
    const [isGeneratingActionPlan, setIsGeneratingActionPlan] = useState(false);
    const [reportGenerated, setReportGenerated] = useState(false);
    const [actionPlanGenerated, setActionPlanGenerated] = useState(false);

    let Icon;
    if (audit.object_type === 'Hotel') {
        Icon = HotelIcon;
    } else if (audit.object_type === 'Museum') {
        Icon = MuseumIcon;
    } else if (audit.object_type === 'Theater') {
        Icon = TheaterIcon;
    }

    const [isAuditorPage, setIsAuditorPage] = useState(false);
    const [isAuditEdit, setIsAuditEdit] = useState(false);
    const [highlights, setHighlights] = useState('');
    const [auditorName, setAuditorName] = useState('');
    const [signatureData, setSignatureData] = useState(null);
    const [signatureKey, setSignatureKey] = useState(0); // Inicjalizacja klucza
    const [summary, setSummary] = useState(null);
    const sigPadRef = useRef(null);


    const handleGenerateReport = () => {
        if (isGeneratingReport || shouldGenerateReport) return; // Zakończ, jeśli już generuje lub wygenerowano
        setIsGeneratingReport(true); // Ustaw flagę generowania

        fetch(`${backendUrl}/audit-page/audits/${audit.audit_id}/`, {
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${authTokens?.access || ''}`,
            },
        })
            .then((response) => response.ok ? response.json() : Promise.reject(response))
            .then((data) => {
                setSections(data.sections || []);
                setAuditTitle(data.audit_title || '');
                setEndDate(data.end_date || '');
                setCoveragePhoto(data.cover_photo || '');

                const updatedAnswers = {};
                data.sections.forEach((section) => {
                    section.groups.forEach((group) => {
                        group.questions.forEach((question) => {
                            if (question.answer) {
                                updatedAnswers[question.id] = { answer: question.answer };
                            }
                        });
                    });
                });
                setSelectedAnswers(updatedAnswers);
                setShouldGenerateReport(true);
                setIsGeneratingReport(false); // Zresetuj flagę po zakończeniu
            })
            .catch((error) => {
                console.error('Error:', error);
                setIsGeneratingReport(false); // Zresetuj flagę w przypadku błędu
            });
    };

    const handleGenerateActionPlan = () => {
        if (isGeneratingActionPlan || shouldGenerateActionPlan) return; // Zakończ, jeśli już generuje lub wygenerowano
        setIsGeneratingActionPlan(true); // Ustaw flagę generowania
        fetch(`${backendUrl}/audit-page/audits/${audit.audit_id}/`, {
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${authTokens?.access || ''}`,
            },
        })
            .then((response) => response.ok ? response.json() : Promise.reject(response))
            .then((data) => {
                setSections(data.sections || []);
                setAuditTitle(data.audit_title || '');
                setEndDate(data.end_date || '');
                setCoveragePhoto(data.cover_photo || '');

                const updatedAnswers = {};
                data.sections.forEach((section) => {
                    section.groups.forEach((group) => {
                        group.questions.forEach((question) => {
                            if (question.answer) {
                                updatedAnswers[question.id] = { answer: question.answer };
                            }
                        });
                    });
                });
                setSelectedAnswers(updatedAnswers);
                setShouldGenerateActionPlan(true);
                setIsGeneratingActionPlan(false); // Zresetuj flagę po zakończeniu
            })
            .catch((error) => {
                console.error('Error:', error);
                setIsGeneratingActionPlan(false); // Zresetuj flagę w przypadku błędu
            });
    };



    const clearSignature = () => {
        setSignatureData(null);
        sigPadRef.current.clear();
        setSignatureKey((prevKey) => prevKey + 1);
    };

    const getSignatureImage = () => {
        if (sigPadRef.current) {
            try {
                const canvas = sigPadRef.current.getCanvas();
                return canvas.toDataURL('image/png');
            } catch (error) {
                console.error("Error while getting signature image:", error);
                return null;
            }
        }
        return null;
    };

    const saveAuditorSummary = async () => {
        // Przechowaj pierwotne dane
        const originalSignature = signatureData;

        // Sprawdzanie, czy dane się zmieniły
        const hasChanges = () => {
            // Sprawdzenie, czy komentarze lub imię zostały zmienione
            const textChanged = highlights !== summary?.auditor_comments || auditorName !== summary?.auditor_name;

            // Sprawdzenie, czy podpis został wyczyszczony lub zmieniony
            const signatureChanged = signatureData !== originalSignature && (sigPadRef.current && !sigPadRef.current.isEmpty());

            return textChanged || signatureChanged;
        };

        if (!hasChanges()) {
            // notification.info({
            //     message: 'Brak zmian',
            //     description: 'Nie wprowadzono żadnych zmian w podsumowaniu.',
            // });
            return;
        }

        const signatureImage = getSignatureImage();
        const formData = new FormData();

        formData.append('audit', audit.audit_id);
        formData.append('auditor_comments', highlights);
        formData.append('auditor_name', auditorName);

        if (signatureImage) {
            try {
                const response = await fetch(signatureImage);
                const blob = await response.blob();
                formData.append('signature', blob, 'signature.png');
            } catch (error) {
                console.error("Error while fetching signature image:", error);
            }
        }

        try {
            const response = await fetch(`${backendUrl}/audit-page/auditor-summary/create/`, {
                method: 'POST',
                headers: {
                    'Authorization': `Bearer ${authTokens?.access}`
                },
                body: formData
            });

            if (!response.ok) {
                throw new Error('Network response was not ok');
            }

            console.log("Auditor Summary saved successfully");
            notification.success({
                message: 'Poprawnie zapisano podsumowanie!',
                description: 'Podsumowanie zostało poprawnie zapisane.',
            });

        } catch (error) {
            console.error("Error saving Auditor Summary:", error);
            notification.error({
                message: 'Wystąpił błąd',
                description: 'Nie udało się zapisać podsumowania',
            });
        }
    };


    useEffect(() => {
        axios
            .get(
                `${backendUrl}/audit-page/auditor-summary/${audit.audit_id}`, {
                headers: {
                    Authorization: `Bearer ${authTokens?.access || ''}`,
                },
            })
            .then(response => {
                setSummary(response.data);
                setHighlights(response.data.auditor_comments);
                setAuditorName(response.data.auditor_name);
                let url = response.data?.signature ? `${response.data.signature}` : null;
                setSignatureData(url);
            })
            .catch(error => {
                console.error("Brak Auditor Summary lub błąd podczas ładowania");
            });
    }, [isAuditorPage, setIsAuditorPage]);

    useEffect(() => {
        try {
            if (signatureData && sigPadRef.current) {
                sigPadRef.current.clear();
                sigPadRef.current.fromDataURL(signatureData);
            }
        } catch (error) {
            console.error("Error while setting signature data:", error);
        }
    }, [isAuditorPage, signatureData, setIsAuditorPage]);

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (
                auditorPageRef.current &&
                !auditorPageRef.current.contains(event.target)
            ) {
                saveAuditorSummary();
                setAuditorName("");
                setHighlights("");
                setIsAuditorPage(false);
            }

            if (
                PreviewPopupRef.current &&
                !PreviewPopupRef.current.contains(event.target)
            ) {
                setIsPreviewPopupVisible(false);
            }
        };

        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [isAuditorPage, auditorName, highlights]);

    const sortedSections = sections.slice().sort((a, b) => a.id - b.id);

    useEffect(() => {
        if (shouldGenerateReport) {
            setReportGenerated(false); // Resetuj flagę generowania przed rozpoczęciem
        }
    }, [shouldGenerateReport]);

    // useEffect(() => {
    //     const handleClickOutside = (event) => {
    //       
    //     };

    //     document.addEventListener('mousedown', handleClickOutside);
    //     return () => {
    //         document.removeEventListener('mousedown', handleClickOutside);
    //     };
    // }, [setIsPreviewPopupVisible]);


    useEffect(() => {
        if (shouldGenerateActionPlan) {
            setActionPlanGenerated(false); // Resetuj flagę generowania przed rozpoczęciem
        }
    }, [shouldGenerateActionPlan]);



    return (
        <>
            {isAuditorPage ? (
                <AuditorPage
                    highlights={highlights}
                    setHighlights={setHighlights}
                    auditorName={auditorName}
                    setAuditorName={setAuditorName}
                    clearSignature={clearSignature}
                    SignaturePad={SignaturePad}
                    signatureKey={signatureKey}
                    sigPadRef={sigPadRef}
                    saveAuditorSummary={saveAuditorSummary}
                    setIsAuditorPage={setIsAuditorPage}
                    auditorPageRef={auditorPageRef}
                />
            ) : (isAuditEdit ? (
                <AuditEditPopup
                    auditData={audit}
                    setAuditData={setAudit}
                    setIsAuditEdit={setIsAuditEdit}
                    setIsPreviewPopupVisible={setIsPreviewPopupVisible}
                />
            ) : (
                // <div className='bg-white p-5 w-max absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 rounded-lg sm:shadow-lg   border-gray-100'>

                <div className='fixed top-1/2 h-screen sm:h-max left-1/2 lg:w-1/2 w-full max-w-4xl  transform -translate-x-1/2 -translate-y-1/2 z-10 pt-20 py-6 px-3 lg:p-6  rounded-lg sm:shadow-lg   bg-white ' ref={PreviewPopupRef}
                    onClick={(e) => e.stopPropagation()}>
                    <button onClick={() => setIsPreviewPopupVisible(false)} className='w-full '><XIcon className="h-8 w-8 ml-auto" /></button>
                    <div className='text-center items-center '>
                        <img src={Icon} className='w-12 h-12 m-auto' />
                        <h1 className='text-xl font-bold mt-4'>{audit.audit_title}</h1>
                        <p className='text-gray-400 text-sm font-semibold'>{audit.city}, {audit.street} </p>
                    </div>
                    <div className='grid sm:grid-cols-3 grid-cols-2 gap-4 lg:mx-auto  mt-6  '>
                        <div className='bg-gray-200/60 py-2 px-4 rounded mx-auto w-full'>
                            <div className='flex'>
                                <p className='text-2xl lg:text-4xl font-bold'>{missedQs}</p>
                                <ChartHistogramIcon fill="#000" className='w-5 h-5 lg:w-6 lg:h-6 ml-auto lg:mr-2 mt-2' />
                            </div>
                            <p className='text-xs lg:text-sm lg:pt-0 text-black font-semibold sm:pl-1'>open questions</p>
                        </div>
                        <div className='bg-gray-200/60 py-2 px-4 rounded mx-auto w-full '>
                            <div className='flex'>
                                <p className='text-2xl lg:text-4xl font-bold'>{totalQuestions}</p>
                                <ChartHistogramIcon fill="#000" className='w-5 h-5 lg:w-6 lg:h-6 ml-auto lg:mr-2 mt-2' />
                            </div>
                            <p className='text-xs lg:text-sm lg:pt-0 text-black font-semibold sm:pl-1'>all questions</p>
                        </div>
                        <div className='bg-gray-200/60 py-2 px-4 rounded mx-auto w-full'>
                            <div className='flex'>
                                <p className='text-2xl lg:text-4xl font-bold'>{scorePercentage}%</p>
                                <ChartHistogramIcon fill="#000" className='w-5 h-5 lg:w-6 lg:h-6 ml-auto lg:mr-2 mt-2' />
                            </div>
                            <p className='text-xs lg:text-sm lg:pt-0 text-black font-semibold sm:pl-1'>HA score</p>
                        </div>
                        <Link to={`/audit/${audit.audit_id}?filter=yes`} className='bg-gradient-to-r text-start from-lime-400 to-lime-200 py-2 px-4 rounded mx-auto w-full'>
                            <div className='flex'>
                                <p className='text-2xl lg:text-4xl font-bold'>{yesAnswers}</p>
                                <ChartHistogramIcon fill="#000" className='w-5 h-5 lg:w-6 lg:h-6 ml-auto lg:mr-2 mt-2' />
                            </div>
                            <p className='text-xs lg:text-sm lg:pt-0 text-black font-semibold sm:pl-1'>YES Answers</p>
                        </Link>
                        <Link to={`/audit/${audit.audit_id}?filter=no`} className='bg-gradient-to-r  text-start from-red-400 to-red-300 py-2 px-4 rounded mx-auto w-full '>
                            <div className='flex'>
                                <p className='text-2xl lg:text-4xl font-bold'>{noAnswers}</p>
                                <ChartHistogramIcon fill="#000" className='w-5 h-5 lg:w-6 lg:h-6 ml-auto lg:mr-2 mt-2' />
                            </div>
                            <p className='text-xs lg:text-sm lg:pt-0 text-black font-semibold sm:pl-1'>NO Answers</p>
                        </Link>
                        <Link to={`/audit/${audit.audit_id}?filter=na`} className='bg-gradient-to-r  text-start from-yellow-400/80 to-yellow-200 py-2 px-4 rounded mx-auto w-full '>
                            <div className='flex'>
                                <p className='text-2xl  lg:text-4xl font-bold'>{naAnswers}</p>
                                <ChartHistogramIcon fill="#000" className='w-5 h-5 lg:w-6 lg:h-6 ml-auto lg:mr-2 mt-2' />
                            </div>
                            <p className='text-xs lg:text-sm lg:pt-0 text-black font-semibold sm:pl-1'>N/A Answers</p>
                        </Link>
                    </div>
                    <div className='flex justify-between mt-6 sm:mt-12 '>
                        {(userInfo.role === 'Admin' || userInfo.role === 'Moderator') && (

                            <div className='hidden sm:flex'>
                                {!shouldGenerateReport ? (
                                    <button
                                        onClick={handleGenerateReport}
                                        className='bg-gray-200 font-semibold py-2 px-4 rounded-lg flex items-center lg:mr-4 mr-1'>
                                        <AuditIcon className="w-5 lg:w-4 mr-2" />
                                        <h3 className='text-sm lg:text-base'>Raport</h3>
                                    </button>
                                ) : (
                                    <PDFDownloadLink
                                        document={<TestPdf auditTitle={auditTitle} AuditDate={EndDate} sections={sortedSections} FullCoverPhoto={CoveragePhoto} summary={summary} />}
                                        fileName="Full raport.pdf"
                                        onClick={() => {
                                            setReportGenerated(false); // Resetuj flagę po kliknięciu
                                            setShouldGenerateReport(false);
                                        }}
                                    >
                                        {({ blob, url, loading, error }) =>
                                            loading ? <button className='bg-gray-200 font-semibold py-2 px-4 rounded-lg flex items-center mr-4'>
                                                <Spinner height={20} width={20} />
                                                <h3 className='ml-2'>Full raport</h3>
                                            </button> : <button
                                                onClick={(e) => {
                                                    e.preventDefault();
                                                    const newBlob = new Blob([blob], { type: "application/pdf" });
                                                    const data = window.URL.createObjectURL(newBlob);
                                                    window.open(data, '_blank');
                                                }}
                                                className='bg-gray-200 font-semibold py-2 px-4 rounded-lg flex items-center mr-4'>Podgląd Raportu</button>
                                        }
                                    </PDFDownloadLink>
                                )}
                                {!shouldGenerateActionPlan ? (
                                    <button
                                        onClick={handleGenerateActionPlan}
                                        className='bg-gray-200 font-semibold py-2 px-3 lg:px-4 w-32 lg:w-max rounded-lg flex items-center lg:mr-4 mr-1'>
                                        <AuditIcon className="w-5 lg:w-4 mr-2" />
                                        <h3 className='text-sm lg:text-base'>Action plan</h3>
                                    </button>
                                ) : (
                                    <PDFDownloadLink
                                        document={<ActionPlanNew sections={sortedSections} auditTitle={auditTitle} AuditDate={EndDate} FullCoverPhoto={CoveragePhoto} summary={summary} />}
                                        fileName="action-plan.pdf"
                                        onClick={() => {
                                            setActionPlanGenerated(false); // Resetuj flagę po kliknięciu
                                            setShouldGenerateActionPlan(false);
                                        }}
                                    >
                                        {({ blob, url, loading, error }) =>
                                            loading ? <button className='bg-gray-200 font-semibold py-2 px-4 rounded-lg flex items-center mr-4'>
                                                <Spinner height={20} width={20} />
                                                <h3 className='ml-2'>Action plan</h3>
                                            </button> : <button
                                                onClick={(e) => {
                                                    e.preventDefault();
                                                    const newBlob = new Blob([blob], { type: "application/pdf" });
                                                    const data = window.URL.createObjectURL(newBlob);
                                                    window.open(data, '_blank');
                                                }}
                                                className='bg-gray-200 font-semibold py-2 px-4 rounded-lg flex items-center mr-4'>
                                                <AuditIcon className="w-4 mr-2" />
                                                <h3>Podgląd Action Plan</h3>
                                            </button>
                                        }
                                    </PDFDownloadLink>
                                )}

                            </div>
                        )}
                        <div className='flex flex-wrap justify-center gap-2'>
                            <button
                                className='bg-gray-200 font-semibold h-10 py-2 px-4 rounded-lg flex items-center'
                                onClick={() => {
                                    setIsAuditorPage(true);
                                }}
                            >
                                <SignatureIcon className="w-4 h-4" />
                            </button>

                            {(userInfo.role === 'Admin' || userInfo.role === 'Moderator') && (
                                <button
                                    className='bg-gray-200 font-semibold h-10 py-2 px-4 rounded-lg flex items-center'
                                    onClick={() => {
                                        setIsAuditEdit(true);
                                    }}
                                >
                                    <SettingsIcon className="w-4 h-4" />
                                </button>
                            )}


                            <Link to={`/audit/${audit.audit_id}`}>
                                <button
                                    className='bg-gray-200 font-semibold h-10 py-2 px-4 rounded-lg flex items-center'
                                    onClick={() => {
                                        setIsPreviewPopupVisible(false);
                                    }}
                                >
                                    <PencilIcon className="w-4 h-4 mr-2" />
                                    <h3 className='text-sm lg:text-base'> Edit</h3>
                                </button>
                            </Link>
                        </div>

                    </div>
                </div>

            ))}
        </>
    );
};

export default PreviewPopup;
