import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes, useLocation, Navigate } from 'react-router-dom';
import LoginPage from './pages/LoginPage';
import { AuthProvider, useAuth } from './context/AuthContext';
import Notification from './components/Notification';
import MainPage from './pages/MainPage';
import CreateAuditPage from './pages/CreateAuditPage';
import AuditPage from './pages/AuditPage';
import AdminPage from './pages/AdminPage';
import ScrollToTop from './components/ScrollToTop ';
import ConnectionStatus from './components/ConnectionStatus';
import PasswordResetRequest from './components/Auth/PasswordResetRequest';
import PasswordResetConfirm from './components/Auth/PasswordResetConfirm';
import CalendarPage from './pages/CalendarPage';

function App() {
  
  return (
    <Router>
      <ScrollToTop />
      <AuthProvider>
        <Notification />
        <ConnectionStatus />
        <Routes>
          {/* Trasy publiczne */}
          <Route path="/login" element={<LoginPage />} />
          <Route path="/reset-password" element={<PasswordResetRequest />} />
          <Route path="/reset-password/:uidb64/:token" element={<PasswordResetConfirm />} />

          {/* Trasy chronione */}
          <Route path="/home" element={<ProtectedRoute><MainPage /></ProtectedRoute>} />
          <Route path="/create" element={<ProtectedRoute><CreateAuditPage /></ProtectedRoute>} />
          <Route path="/audit/:auditId" element={<ProtectedRoute><AuditPage /></ProtectedRoute>} />
          <Route path="/adminPanel" element={<ProtectedRoute><AdminPage /></ProtectedRoute>} />
          <Route path="/calendar" element={<ProtectedRoute><CalendarPage /></ProtectedRoute>} />

          {/* Przekierowanie dla nieznanych tras */}
          <Route path="*" element={<Navigate to="/login" replace />} />
        </Routes>
      </AuthProvider>
    </Router>
  );
}

// Ochrona tras dla zalogowanych użytkowników
function ProtectedRoute({ children }) {
  const { authTokens } = useAuth();
  const location = useLocation();
  if (!authTokens) {
    return <Navigate to="/login" state={{ from: location }} replace />;
  }

  return children;
}

export default App;
