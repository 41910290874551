import React, { createContext, useState, useContext, useEffect, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { jwtDecode } from 'jwt-decode';

const AuthContext = createContext(null);

export const useAuth = () => useContext(AuthContext);

export const AuthProvider = ({ children }) => {
    const [scheduledAudits, setScheduledAudits] = useState([]);
    const [inProgressAudits, setInProgressAudits] = useState([]);
    const [toCheckAudits, setToCheckAudits] = useState([]);
    const [toSendAudits, setToSendAudits] = useState([]);
    const [sentAudits, setSentAudits] = useState([]);

    const [currentPage, setCurrentPage] = useState(1);
    const [createPageNumber, setCreatePageNumber] = useState(1);
    const [currentAudit, setCurrentAudit] = useState(0);
    const [allAudits, setAllAudits] = useState([]);
    const [userInfo, setUserInfo] = useState([]);
    const [notification, setNotification] = useState(null);
    const [authTokens, setAuthTokens] = useState(() => {
        return localStorage.getItem('authTokens') ? JSON.parse(localStorage.getItem('authTokens')) : null;
    });
    const [user, setUser] = useState(() => {
        const tokens = localStorage.getItem('authTokens');
        const tokenObj = tokens ? JSON.parse(tokens) : null;
        return tokenObj ? jwtDecode(tokenObj.access) : null;
    });

    const navigate = useNavigate();
    const backendUrl = process.env.REACT_APP_BACKEND_URL;

    const logout = useCallback(() => {
        setAuthTokens(null);
        setUser(null);
        localStorage.removeItem('authTokens');
        navigate('/login');
    }, [navigate]);

    const updateUserProfile = (updatedData) => {
        setUserInfo(prevInfo => ({
            ...prevInfo,
            ...updatedData
        }));
    };

    const isTokenExpired = (token) => {
        const decodedToken = jwtDecode(token);
        const currentTime = new Date().getTime();
        const expirationTime = decodedToken.exp * 1000; // Konwersja na milisekundy
        return expirationTime - currentTime <= 1 * 1000; // Sprawdzanie, czy token wygaśnie w ciągu następnej minuty
    };

    const refreshToken = useCallback(async () => {
        if (!authTokens || !authTokens.refresh || isTokenExpired(authTokens.refresh)) {
            logout();
            return;
        }

        try {
            const response = await fetch(`${backendUrl}/audit-page/token/refresh/`, {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({ refresh: authTokens.refresh })
            });

            if (!response.ok) {
                throw new Error('Failed to refresh token');
            }

            const data = await response.json();
            setAuthTokens(data);
            localStorage.setItem('authTokens', JSON.stringify(data));
            setUser(jwtDecode(data.access));
        } catch (error) {
            logout();
        }
    }, [authTokens, backendUrl, logout]);

    useEffect(() => {
        if (authTokens) {
            if (isTokenExpired(authTokens.access) && !isTokenExpired(authTokens.refresh)) {
                refreshToken();  // Odśwież token, jeśli token dostępu wygasł, ale token odświeżania jest nadal ważny
            } else if (isTokenExpired(authTokens.access) && isTokenExpired(authTokens.refresh)) {
                logout();  // Wyloguj użytkownika, jeśli oba tokeny wygasły
            }
        }
    }, [authTokens, refreshToken, logout]);

    useEffect(() => {
        const interval = setInterval(() => {
            if (authTokens && isTokenExpired(authTokens.access)) {
                refreshToken();
            }
        }, 59 * 1000); // Odśwież token co 59 minut

        return () => clearInterval(interval);
    }, [authTokens, refreshToken]);

    useEffect(() => {
        const fetchUserInfo = async () => {
            if (!authTokens?.access || isTokenExpired(authTokens.access)) {
                // Tylko zalogowani użytkownicy z ważnym tokenem powinni wywoływać fetchUserInfo
                return;
            }

            try {
                const userInfoResponse = await fetch(`${backendUrl}/audit-page/user-info/`, {
                    headers: {
                        'Authorization': `Bearer ${authTokens.access}`,
                    },
                });

                if (!userInfoResponse.ok) {
                    throw new Error('Błąd podczas pobierania informacji o użytkowniku');
                }

                const userInfo = await userInfoResponse.json();
                setUserInfo(userInfo);
            } catch (error) {
                console.error('Error fetching user info:', error);
                showNotification('Nie udało się pobrać informacji o użytkowniku', 'warning');
            }
        };

        // Tylko wywołuj fetchUserInfo, gdy użytkownik jest zalogowany
        if (authTokens && !isTokenExpired(authTokens.access)) {
            fetchUserInfo();
        }

    }, [authTokens, backendUrl]);


    const login = async (username, password) => {
        try {
            const response = await fetch(`${backendUrl}/audit-page/token/`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ username, password }),
            });

            if (!response.ok) {
                const errorData = await response.json();
                throw new Error(errorData.detail || 'Logowanie nieudane');
            }

            const data = await response.json();
            setAuthTokens(data);
            localStorage.setItem('authTokens', JSON.stringify(data));
            setUser(jwtDecode(data.access));

            // Po zaktualizowaniu tokenu wywołaj fetchUserInfo
            await fetchUserInfo(data.access);

            // Po udanym pobraniu danych nawiguj do /audits
            navigate('/home');

        } catch (error) {
            console.error('Błąd logowania:', error);
            showNotification(error.message, 'error');
            throw error;
        }
    };



    const fetchUserInfo = async (accessToken) => {
        try {
            const userInfoResponse = await fetch(`${backendUrl}/audit-page/user-info/`, {
                headers: {
                    'Authorization': `Bearer ${accessToken}`
                }
            });
            if (!userInfoResponse.ok) {
                throw new Error('Błąd podczas pobierania informacji o użytkowniku');
            }

            const userInfo = await userInfoResponse.json();
            setUser(prevState => ({
                ...prevState,
                ...userInfo,
            }));
            setUserInfo(userInfo);
        } catch (error) {
            console.error('Error fetching user info:', error);
            showNotification('Nie udało się pobrać informacji o użytkowniku', 'warning');
        }
    };



    const showNotification = (message, type = "success") => {
        setNotification({ message, type });
        setTimeout(() => {
            setNotification(null);
        }, 3000);
    };

    return (
        <AuthContext.Provider value={{
            authTokens, login, logout, user, userInfo, notification,
            showNotification, inProgressAudits, setInProgressAudits,
            scheduledAudits, setScheduledAudits, currentPage, toCheckAudits, setToCheckAudits,
            toSendAudits, setToSendAudits, sentAudits, setSentAudits,
            setCurrentPage, createPageNumber, setCreatePageNumber, currentAudit,
            setCurrentAudit, allAudits, setAllAudits, updateUserProfile
        }}>
            {children}
        </AuthContext.Provider>
    );
};