import React from 'react';

const AuditorPage = ({ highlights, setHighlights, auditorName, setAuditorName, clearSignature, SignaturePad, signatureKey, sigPadRef, saveAuditorSummary, setIsAuditorPage, auditorPageRef }) => {
  return (
    <div className="fixed top-1/2 left-1/2 w-full max-w-lg sm:max-w-2xl transform -translate-x-1/2 -translate-y-1/2 z-50 bg-white py-6 px-4 sm:px-6 rounded-lg shadow-md border border-gray-200" ref={auditorPageRef}>
      <label className="block text-lg font-semibold mb-2 text-gray-800" htmlFor="summary">Highlights / Summary</label>
      <textarea
        value={highlights}
        onChange={(e) => setHighlights(e.target.value)}
        placeholder="Highlights..."
        maxLength={10000}
        className="w-full h-32 p-3 border border-gray-300 focus:border-gray-500 rounded-lg mb-4 resize-none focus:outline-none transition"
        id="summary"
      />

      <label className="block text-lg font-semibold mb-2 text-gray-800" htmlFor="auditor">Auditor</label>
      <input
        type="text"
        value={auditorName}
        onChange={(e) => setAuditorName(e.target.value)}
        placeholder="Name and Surname"
        className="w-full p-3 border border-gray-300 focus:border-gray-500 rounded-lg mb-4 focus:outline-none transition"
        id="auditor"
      />

      <div className="signature-pad-container mb-4">
        <div className="flex justify-between items-center mb-2">
          <h2 className="text-lg font-semibold text-gray-800">Signature</h2>
          <button
            onClick={clearSignature}
            className="border border-gray-300 text-gray-700 font-medium py-1 px-4 rounded-md hover:bg-gray-100 transition"
          >
            Clear
          </button>
        </div>
        <SignaturePad
          key={signatureKey}
          ref={sigPadRef}
          canvasProps={{
            className: "signature-canvas w-full h-32 bg-white border border-gray-300 rounded-lg"
          }}
        />
      </div>

      {/* <div className="flex justify-end pt-4">
        <button
          onClick={saveAuditorSummary}
          className="transition duration-150 ease-in-out bg-green-600 hover:bg-green-700 text-white font-medium py-2 px-6 rounded-md"
        >
          Save
        </button>
      </div> */}
    </div>
  );
};

export default AuditorPage;
