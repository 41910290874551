import React, { useState, useEffect } from 'react';
import axios from 'axios';

const PasswordResetRequest = () => {
    const [email, setEmail] = useState('');
    const [message, setMessage] = useState('');
    const [messageType, setMessageType] = useState(''); // 'success' lub 'error'
    const [csrfToken, setCsrfToken] = useState('');
    const [isLoading, setIsLoading] = useState(false); // Stan ładowania
    const backendUrl = process.env.REACT_APP_BACKEND_URL;

    // Pobierz token CSRF z Django
    useEffect(() => {
        const fetchCsrfToken = async () => {
            try {
                const response = await axios.get(`${backendUrl}/audit-page/get-csrf-token/`, {
                    withCredentials: true,
                });
                const csrfTokenFromResponse = response.headers['x-csrftoken'];
                setCsrfToken(csrfTokenFromResponse);
            } catch (error) {
                console.error('Error fetching CSRF token:', error);
            }
        };
        fetchCsrfToken();
    }, [backendUrl]);

    const handleSubmit = async (e) => {
        e.preventDefault();
        setIsLoading(true); // Rozpocznij ładowanie
        setMessage('');
        setMessageType('');
        try {
            await axios.post(
                `${backendUrl}/audit-page/password-reset-request/`,
                { email },
                {
                    headers: {
                        'X-CSRFToken': csrfToken,
                    },
                    withCredentials: true,
                }
            );
            setMessage('Email został wysłany. Sprawdź swoją skrzynkę odbiorczą.');
            setMessageType('success');
            setIsLoading(false); // Zakończ ładowanie
        } catch (error) {
            setMessage('Błąd podczas wysyłania emaila z resetem hasła. Spróbuj ponownie.');
            setMessageType('error');
            console.error(error);
            setIsLoading(false); // Zakończ ładowanie
        }
    };

    return (
        <div className="min-h-screen bg-gray-100 flex items-center justify-center p-4">
            <div className="bg-white shadow-md rounded-lg w-full max-w-md p-6">
                {messageType !== 'success' ? (
                    <>
                        <h2 className="text-2xl font-bold mb-2">Zapomniałeś hasła?</h2>
                        <p className="text-gray-600 mb-4">
                            Wpisz swój adres email, a wyślemy Ci link do zresetowania hasła.
                        </p>
                        {message && (
                            <p
                                className={`text-sm mb-4 ${messageType === 'success' ? 'text-green-600' : 'text-red-600'
                                    }`}
                            >
                                {message}
                            </p>
                        )}
                        <form onSubmit={handleSubmit} className="space-y-4">
                            <div>
                                <label htmlFor="email" className="block text-sm font-medium text-gray-700">
                                    Email
                                </label>
                                <input
                                    id="email"
                                    type="email"
                                    placeholder="Wpisz swój adres email"
                                    value={email}
                                    onChange={(e) => setEmail(e.target.value)}
                                    required
                                    className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-black focus:border-black"
                                    disabled={isLoading}
                                />
                            </div>
                            <button
                                type="submit"
                                className="w-full py-2 px-4 bg-black text-white rounded-md hover:bg-gray-800 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-black"
                                disabled={isLoading}
                            >
                                {isLoading ? 'Wysyłanie...' : 'Wyślij link do resetowania'}
                            </button>
                        </form>
                    </>
                ) : (
                    <div className="text-center">
                        <svg
                            className="mx-auto h-16 w-16 text-green-500 mb-4"
                            fill="none"
                            stroke="currentColor"
                            viewBox="0 0 48 48"
                            aria-hidden="true"
                        >
                            <path
                                d="M12 24l8 8 16-16"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                strokeWidth="4"
                            />
                        </svg>
                        <p className="text-lg font-medium text-green-600">
                            Sprawdź swoją skrzynkę odbiorczą!
                        </p>
                        <p className="text-gray-600">
                            Email z linkiem do resetowania hasła został wysłany.
                        </p>
                    </div>
                )}
            </div>
        </div>
    );
};

export default PasswordResetRequest;
