import React, { useState, useRef, useEffect } from 'react';
import PreviewPopup from './PreviewPopup';
import MuseumIcon from '../../utils/dashboard/museum.svg';
import TheaterIcon from '../../utils/dashboard/theater.svg';
import HotelIcon from '../../utils/dashboard/hotel.svg';
import { ReactComponent as PreviewIcon } from '../../utils/dashboard/chart-histogram.svg';
import { Link } from 'react-router-dom';
import { Select, notification } from 'antd';

const { Option } = Select;

const useWindowSize = () => {
    const [windowSize, setWindowSize] = useState({
        width: undefined,
        height: undefined,
    });

    useEffect(() => {
        function handleResize() {
            setWindowSize({
                width: window.innerWidth,
                height: window.innerHeight,
            });
        }

        window.addEventListener("resize", handleResize);
        handleResize();

        return () => window.removeEventListener("resize", handleResize);
    }, []);

    return windowSize;
}

const AuditComponent = ({ audits, setCurrentAudit, handleChange, handleSubmit, status, auditType, isMobileView, sortDescending }) => {
    const [isPreviewPopupVisible, setIsPreviewPopupVisible] = useState(false);
    const [audit, setAudit] = useState(null);
    const PreviewPopupRef = useRef();
    const { width } = useWindowSize();

    const openPreviewPopup = (audit) => {
        setAudit(audit);
        setCurrentAudit(audit.audit_id);
        setIsPreviewPopupVisible(true);
    };

    const handleSelectClick = (event) => {
        if (event.target.tagName.toLowerCase() === 'select') {
            return;
        }
        const auditString = event.currentTarget.getAttribute('data-audit');
        const audit = JSON.parse(auditString);
        openPreviewPopup(audit);
    };

    const renderSelectOptions = () => {
        const options = [
            { value: 'Scheduled', label: 'Scheduled' },
            { value: 'In Progress', label: 'In Progress' },
            { value: 'To Check', label: 'To Check' },
            { value: 'To Send', label: 'To Send' },
            { value: 'Sent', label: 'Sent' }
        ];

        return options.map(option => (
            <Option key={option.value} value={option.value}>
                {option.label}
            </Option>
        ));
    };

    const getColor = (status) => {
        switch (status) {
            case 'Scheduled': return 'blue-400';
            case 'In Progress': return 'yellow-400';
            case 'To Check': return 'orange-400';
            case 'To Send': return 'purple-400';
            case 'Sent': return 'green-400';
            default: return 'gray-400';
        }
    };
   
    return (
        <>
            {isPreviewPopupVisible && (
                <PreviewPopup
                    audit={audit}
                    setAudit={setAudit}
                    PreviewPopupRef={PreviewPopupRef}
                    setIsPreviewPopupVisible={setIsPreviewPopupVisible}
                />
            )}


            <div className={isMobileView ? 'grid gap-4' : ''} style={isMobileView ? { gridTemplateColumns: 'repeat(auto-fill, minmax(350px, 1fr))' } : {}}>

                {audits ? (

                    audits.sort((a, b) => {
                        const dateA = new Date(a.start_date || a.end_date);
                        const dateB = new Date(b.start_date || b.end_date);

                        // Sortowanie zależne od stanu sortDescending
                        return sortDescending ? dateB - dateA : dateA - dateB;
                    })

                        .map(audit => {
                            const totalQuestions = audit.all_qs;
                            const yesAnswers = audit.yes_count;
                            const noAnswers = audit.no_count;
                            const naAnswers = audit.na_count;
                            const missedQs = audit.open_qs;
                            const answeredQuestions = totalQuestions - missedQs;
                            const progressPercentage = totalQuestions > 0 ? ((answeredQuestions / totalQuestions) * 100).toFixed(2) : 0;
                            const scorePercentage = (answeredQuestions - naAnswers) > 0 ? ((yesAnswers / (answeredQuestions - naAnswers)) * 100).toFixed(2) : 0;


                            let Icon;
                            switch (audit.object_type) {
                                case 'Hotel':
                                    Icon = HotelIcon;
                                    break;
                                case 'Museum':
                                    Icon = MuseumIcon;
                                    break;
                                case 'Theater':
                                    Icon = TheaterIcon;
                                    break;
                                default:
                                    Icon = null;
                            }

                            return (
                                <React.Fragment key={audit.audit_id}>
                                 
                                    {(width >= 640 && !isMobileView) ? (
                                        <Link to={`/audit/${audit.audit_id}`}>
                                            <div className='flex justify-between items-center font-semibold text-sm w-full border border-gray-300  rounded-md py-2 mt-3'>
                                                <div className='flex items-center w-1/3 lg:w-2/6 pl-4'>
                                                    {Icon && <img src={Icon} className='w-6 h-6' />}
                                                    <p className='ml-5 lg:text-base truncate'>{audit.audit_title}</p>
                                                </div>

                                                <p className='w-1/3 lg:w-1/6 text-center lg:text-base'>
                                                    {audit.start_date && audit.end_date ? (
                                                        `${new Date(audit.start_date).toLocaleDateString('pl-PL', { day: '2-digit', month: '2-digit', year: '2-digit' })}`
                                                    ) : (
                                                        'Brak daty'
                                                    )}
                                                </p>
                                                <p className='hidden xl:block w-1/6 text-center lg:text-base'>{progressPercentage}%</p>
                                                <p className='hidden xl:block w-1/6 text-center lg:text-base truncate'>{`${yesAnswers}/${answeredQuestions - naAnswers} (${scorePercentage}%)`}</p>
                                                <p className='w-1/6 xl:w-1/12 lg:text-base text-center cursor-pointer' onClick={(e) => { e.preventDefault(); e.stopPropagation(); handleSelectClick(e); }} data-audit={JSON.stringify(audit)}>
                                                    <PreviewIcon fill="#9ca3af" className="lg:w-5 lg:h-5 w-4 h-4 m-auto" />
                                                </p>
                                                <div className='w-1/6 xl:w-1/6 text-center flex items-center justify-center' onClick={(e) => { e.preventDefault(); e.stopPropagation() }}>
                                                    <Select
                                                        defaultValue={status[audit.audit_id] || auditType}
                                                        style={{ width: 120 }}
                                                        onChange={(value) => {
                                                            handleChange({ target: { value } }, audit.audit_id);
                                                            handleSubmit(audit.audit_id, value);
                                                        }}
                                                    >
                                                        {renderSelectOptions()}
                                                    </Select>
                                                </div>
                                            </div>
                                        </Link>
                                    ) : (
                                        <Link to={`/audit/${audit.audit_id}`}>
                                            <div className='bg-gray-100 rounded-md overflow-hidden mb-4'>
                                                <div className='relative'>
                                                    {/* Obrazek wypełniający całe okienko */}
                                                    <img src={audit.cover_photo} className='w-full h-60 object-cover' alt="Audit Cover" />

                                                    {/* Nazwa obiektu na półprzezroczystym tle */}
                                                    <div className='absolute bottom-0 left-0 w-full bg-white bg-opacity-75 text-gray-800 text-center font-semibold py-1'>
                                                        {audit.audit_title}
                                                    </div>

                                                    {/* Przycisk Preview */}
                                                    <button
                                                        className='absolute bottom-12 right-2 bg-white bg-opacity-75 text-gray-700 text-xs px-2 py-1 rounded'
                                                        onClick={(e) => {
                                                            e.preventDefault();
                                                            e.stopPropagation();
                                                            handleSelectClick(e);
                                                        }}
                                                        data-audit={JSON.stringify(audit)}
                                                    >
                                                        Preview
                                                    </button>
                                                </div>

                                                {/* Pasek postępu */}
                                                <div className='relative w-full h-1 bg-gray-300'>
                                                    <div
                                                        className={`absolute top-0 left-0 h-1 ${progressPercentage == 100
                                                            ? 'bg-green-500'
                                                            : progressPercentage > 75
                                                                ? 'bg-orange-500'
                                                                : 'bg-black'
                                                            }`}
                                                        style={{ width: `${progressPercentage}%` }}
                                                    ></div>
                                                </div>
                                            </div>
                                        </Link>


                                    )}
                                </React.Fragment>
                            );
                        })
                ) : (
                    <h2 className="text-center">Brak audytów</h2>
                )}
            </div>
        </>
    );
};

export default AuditComponent;
