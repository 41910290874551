import React, { useState, useEffect } from 'react';
import { OpenAI } from "openai";

const CommentResponsePopup = ({
    currentComment,
    setCurrentComment,
    currentAiSolution,
    setCurrentAiSolution,
    saveComment,
    saveAiSolution,
    closeCommentResponsePopup,
    commentResponsePopupRef,
    currentQuestionNumber,
}) => {
    const [isLoading, setIsLoading] = useState(false);
    const [prompt, setPrompt] = useState("");
    const [isPromptInitialized, setIsPromptInitialized] = useState(false);

    // Nowy promptPrefix jako wiadomość systemowa
    const promptSystemMessage = {
        role: "system",
        content: "Jesteś ekspertem w zakresie audytu hotelowego, specjalizującym się w identyfikowaniu i rozwiązywaniu problemów związanych z obsługą gości i bezpieczeństwem. Twoim zadaniem jest rozwinięcie przedstawionych problemów w formie zwięzłych, konkretnych rekomendacji dla menadżerów hotelu. Dla każdego problemu, który opisze użytkownik, najpierw określ potencjalne ryzyka lub negatywne skutki wynikające z tego problemu, szczególnie te dotyczące doświadczeń gości oraz ich bezpieczeństwa. Następnie, zaproponuj proste, ale skuteczne rozwiązanie, które może zostać wdrożone przez zespół hotelowy. W swoich rekomendacjach używaj przyjaznego i konstruktywnego tonu. Zwracaj się do menadżerów hotelu jako 'Państwo'. Unikaj słów takich jak 'należy' lub 'natychmiast'. Zamiast tego używaj 'sugerujemy', 'zalecamy' lub 'zespół może wdrożyć'. Każda odpowiedź powinna składać się z dwóch części: najpierw opis problemu i jego potencjalnych skutków, a następnie propozycja rozwiązania. Przykład: Dla problemu opisanego jako 'Brak tabliczki opisującej danie oraz alergeny w bufecie restauracji', odpowiedź powinna być sformułowana tak: 'Brak tabliczek opisujących dania oraz alergeny w bufecie restauracji może prowadzić do nieporozumień i stwarzać ryzyko dla gości z alergiami pokarmowymi. Sugerujemy umieszczenie czytelnych i estetycznych tabliczek przy każdym daniu, informujących o jego nazwie oraz zawartych alergenach. To proste rozwiązanie pomoże gościom dokonywać świadomych wyborów i zwiększy ich poczucie bezpieczeństwa podczas korzystania z bufetu.' Odpowiedzi powinny być krótkie, zwięzłe i skupione na konkretnych działaniach, które można łatwo wdrożyć."
    };

    useEffect(() => {
        const savedPrompt = localStorage.getItem(`prompt_${currentQuestionNumber}`);
        if (savedPrompt) {
            setPrompt(savedPrompt);
        } else {
            setPrompt(currentComment);
        }
        setIsPromptInitialized(true);
    }, [currentComment, currentQuestionNumber]);

    useEffect(() => {
        if (isPromptInitialized) {
            localStorage.setItem(`prompt_${currentQuestionNumber}`, prompt);
        }
    }, [prompt, isPromptInitialized, currentQuestionNumber]);

    const handlePromptChange = (e) => {
        setPrompt(e.target.value);
    };

    const generateAiSolution = async () => {
        setIsLoading(true);
        const openai = new OpenAI({
            apiKey: process.env.REACT_APP_OPENAI_API_KEY,
            dangerouslyAllowBrowser: true
        });

        const chatCompletion = await openai.chat.completions.create({
            messages: [
                promptSystemMessage, // Dodanie wiadomości systemowej
                { role: 'user', content: `${prompt}` }
            ],
            model: 'gpt-4o',
        });

        let response = chatCompletion.choices[0].message.content;

        // Usunięcie nowej linii między akapitami
        response = response.replace(/\n+/g, ' ');
        // Przypisanie całej odpowiedzi do aiResponse
        setCurrentAiSolution(response);

        setIsLoading(false);
    };

    return (
        <div className="fixed inset-0 bg-gray-600 bg-opacity-50 overflow-y-auto h-full w-full flex justify-center items-center z-50">
            <div className="bg-white p-5 rounded-lg shadow-lg w-full lg:w-1/3" ref={commentResponsePopupRef}>
                <h2 className="mb-4 font-bold border-b-2 border-gray-400 py-2 text-lg">Comment:</h2>
                <textarea
                    value={currentComment}
                    onChange={(e) => setCurrentComment(e.target.value)}
                    placeholder="Wpisz treść komentarza..."
                    className="p-2 w-full h-32 border-2 focus:outline-none border-dashed border-gray-500 font-semibold mb-4"
                />
                <h2 className="mb-4 font-bold border-b-2 border-gray-400 py-2 text-lg">AI Solution:</h2>
                <textarea
                    value={currentAiSolution}
                    onChange={(e) => setCurrentAiSolution(e.target.value)}
                    placeholder="Wpisz treść odpowiedzi AI..."
                    className="p-2 w-full h-32 border-2 focus:outline-none border-dashed border-gray-500 font-semibold mb-4"
                />
                <h2 className="mb-4 font-bold border-b-2 border-gray-400 py-2 text-lg">Prompt:</h2>
                <textarea
                    value={prompt}
                    onChange={handlePromptChange}
                    placeholder="Wpisz prompt..."
                    className="p-2 w-full h-10 border-2 focus:outline-none border-dashed border-gray-500 font-semibold mb-4"
                />
                <button
                    onClick={generateAiSolution}
                    disabled={isLoading}
                    className="transition duration-150 ease-in-out bg-gray-500 hover:bg-green-600 my-2 px-2 w-36 h-10 rounded text-white"
                >
                    {isLoading ? 'Ładowanie...' : 'Generate Solution'}
                </button>
            </div>
        </div>
    );
};

export default CommentResponsePopup;
