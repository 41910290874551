// SearchBar.js
import React, { useState, useEffect, useRef } from 'react';
import PreviewPopup from './MainPage/PreviewPopup';

const SearchBar = ({ allAudits }) => {
    const [searchTerm, setSearchTerm] = useState('');
    const [searchResults, setSearchResults] = useState([]);
    const [isFocused, setIsFocused] = useState(false);
    const [selectedAudit, setSelectedAudit] = useState(null);
    const [isPreviewPopupVisible, setIsPreviewPopupVisible] = useState(false);
    const PreviewPopupRef = useRef();

    useEffect(() => {
        if (searchTerm === '') {
            setSearchResults([]);
        } else {
            const results = allAudits.filter(audit =>
                audit.audit_title.toLowerCase().includes(searchTerm.toLowerCase()) ||
                formatDate(audit.start_date).includes(searchTerm.toLowerCase())
            );
            setSearchResults(results);
        }
    }, [searchTerm, allAudits]);

    const formatDate = (dateString) => {
        const date = new Date(dateString);
        const day = String(date.getDate()).padStart(2, '0');
        const month = String(date.getMonth() + 1).padStart(2, '0');
        const year = date.getFullYear().toString().slice(-2);
        return `${day}/${month}/${year}`;
    };

    const handleSelectClick = (audit) => {
        setSelectedAudit(audit);
        setIsPreviewPopupVisible(true);
    };

    return (
        <div className='relative w-80'>
            <input
                className={`p-3 h-10 text-base font-normal focus:outline-none transition-shadow border border-gray-300 focus:border-gray-400 w-full ${isFocused ? 'rounded-t-md' : 'rounded-md'}`}
                placeholder="Search here..."
                value={searchTerm}
                onChange={e => setSearchTerm(e.target.value)}
                onFocus={() => setIsFocused(true)}
                onBlur={() => {
                    setTimeout(() => setIsFocused(false), 100);
                }}
            />
            {isFocused && (
                <div className='absolute top-full left-0 w-full bg-white shadow-lg border border-gray-400 border-t-0 rounded-b-md z-10'>
                    {searchResults.length > 0 ? (
                        searchResults.map((audit, index) => (
                            <div
                                className={`cursor-pointer py-2 px-4 text-gray-800 hover:bg-gray-100 transition-colors ${index === searchResults.length - 1 ? 'rounded-b-md' : 'border-b'}`}
                                key={audit.id}
                                onClick={() => handleSelectClick(audit)}
                            >
                                {audit.audit_title} - {formatDate(audit.start_date)}
                            </div>
                        ))
                    ) : (
                        <div className='py-2 px-4 text-gray-500 rounded-b-md'>No results found</div>
                    )}
                </div>
            )}

            {isPreviewPopupVisible && selectedAudit && (
                <PreviewPopup
                    audit={selectedAudit}
                    PreviewPopupRef={PreviewPopupRef}
                    setIsPreviewPopupVisible={setIsPreviewPopupVisible}
                />
            )}
        </div>
    );
};

export default SearchBar;
