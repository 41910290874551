import { Document, Page, Text, View, Image, Link as PdfLink } from '@react-pdf/renderer';
import React from 'react';
import styles from './PdfStyles';
import backgroundImage from '../../utils/pdfs/raport-bg.png';
import diamond from '../../utils/pdfs/diamond-certification.png';
import platinum from '../../utils/pdfs/platinum-certification.png';
import gold from '../../utils/pdfs/gold-certification.png';
import ceoSignature from '../../utils/pdfs/signature.png';
import Logo from '../../utils/pdfs/hotels-audit-logo.png';

function formatDate(dateString) {
    const date = new Date(dateString);
    const day = date.getDate().toString().padStart(2, '0');
    const month = (date.getMonth() + 1).toString().padStart(2, '0');
    const year = date.getFullYear();
    return `${day}/${month}/${year}`;
}

const AuditorSummaryPDF = React.memo(({ summary, AuditDate }) => {
    const decoder = new TextDecoder('utf-8');
    const bytes = new Uint8Array([0xC2, 0xA9, 0xEF, 0xB8, 0x8F]);
    const symbol = decoder.decode(bytes);

    if (!summary) return (
        <Page size="A4">
            <Text>Brak podsumowania</Text>
        </Page>
    );

    return (
        <Page size="A4" style={{ fontFamily: 'Open Sans', fontSize: 12, paddingTop: 30, paddingBottom: 30 }}>
            <View style={{ width: '85%', marginLeft: 'auto', marginRight: 'auto' }}>
                <Text style={{ fontSize: 24, textAlign: 'center', fontWeight: 'bold' }}>Summary</Text>
                {summary && (
                    <View style={{ marginTop: 25, width: '100%' }}>
                        {summary.auditor_comments && (
                            <Text style={{ textAlign: 'justify', fontSize: 13 }}>
                                {summary.auditor_comments}
                            </Text>
                        )}
                    </View>
                )}
                <View style={{ marginTop: 25, marginBottom: 20 }}>
                    <Text style={{ fontSize: 16, fontWeight: 'bold', marginBottom: 10 }}>Signature:</Text>
                    <View style={{ borderWidth: 1, borderColor: '#000', marginBottom: 10, padding: 2, width: 200 }}>
                        {summary.signature && <Image src={summary.signature} />}
                    </View>
                    <Text>Auditor: {summary.auditor_name}</Text>
                    <Text>Data: {formatDate(AuditDate)}</Text>
                </View>
            </View>

            <View fixed style={styles.mainfooter}>
                <View style={styles.footerLinkText}>
                    <Text>www.hotelsaudit.com</Text></View>
                <View style={styles.footerText}>
                    <Text> This document contains confidential information, do not share it with anyone outside the organization. Hotels Audit {symbol} 2024. </Text>
                </View>
                <View>
                    <Text
                        style={[styles.pageNumber]}
                        render={({ pageNumber, totalPages }) => (
                            `${pageNumber} / ${totalPages}`
                        )}
                    />
                </View>
            </View>
        </Page>
    );
});

const TestPdf = ({ auditTitle, AuditDate, sections, FullCoverPhoto, summary }) => {
    let totalAuditQuestions = 0;
    let auditCorrectAnswersCount = 0;
    let auditNaAnswersCount = 0;
    let auditNoAnswersCount = 0;

    const processGroups = (groups) => {
        groups.forEach(group => {
            group.questions.forEach(question => {
                if (question.answer !== null && question.answer !== "") {
                    totalAuditQuestions += 1;
                    if (question.answer === "yes") {
                        auditCorrectAnswersCount += 1;
                    }
                    if (question.answer === "na") {
                        auditNaAnswersCount += 1;
                    }
                    if (question.answer === "no") {
                        auditNoAnswersCount += 1;
                    }
                }
            });
        });
    };

    sections.forEach(section => {
        processGroups(section.groups);

        if (section.subSections && section.subSections.length > 0) {
            section.subSections.forEach(subSection => {
                processGroups(subSection.groups);
            });
        }
    });

    const auditPercentageScore = totalAuditQuestions > 0 ? (auditCorrectAnswersCount / (totalAuditQuestions - auditNaAnswersCount)) * 100 : 0;


    let certificateImageSrc;
    if (auditPercentageScore >= 95) {
        certificateImageSrc = diamond;
    } else if (auditPercentageScore >= 90) {
        certificateImageSrc = platinum;
    } else if (auditPercentageScore >= 85) {
        certificateImageSrc = gold;
    }

    const decoder = new TextDecoder('utf-8');
    const bytes = new Uint8Array([0xC2, 0xA9, 0xEF, 0xB8, 0x8F]);
    const symbol = decoder.decode(bytes);


    // Obliczanie procentowego wyniku
    // const calculateSectionScores = (sections) => {
    //     const scores = {};

    //     sections.forEach(section => {
    //         let sectionCorrectAnswersCount = 0;
    //         let sectionTotalAnsweredQuestions = 0;
    //         let sectionNaAnswersCount = 0;

    //         const processGroups = (groups) => {
    //             groups.forEach(group => {
    //                 group.questions.forEach(question => {
    //                     if (question.answer !== null) {
    //                         sectionTotalAnsweredQuestions += 1;
    //                         if (question.answer === "yes") {
    //                             sectionCorrectAnswersCount += 1;
    //                         }
    //                         if (question.answer === "na") {
    //                             sectionNaAnswersCount += 1;
    //                         }
    //                     }
    //                 });
    //             });
    //         };

    //         processGroups(section.groups);

    //         if (section.subSections && section.subSections.length > 0) {
    //             section.subSections.forEach(subSection => {
    //                 processGroups(subSection.groups);
    //             });
    //         }

    //         const score = sectionTotalAnsweredQuestions > 0 ? (sectionCorrectAnswersCount / (sectionTotalAnsweredQuestions - sectionNaAnswersCount)) * 100 : 0;
    //         scores[section.name] = score;
    //     });

    //     return scores;
    // };
    const calculateNoAnswersCount = (sections) => {
        const noAnswersCount = {};

        sections.forEach(section => {
            let sectionNoAnswersCount = 0;

            const processGroups = (groups) => {
                groups.forEach(group => {
                    group.questions.forEach(question => {
                        if (question.answer === "no") {
                            sectionNoAnswersCount += 1;
                        }
                    });
                });
            };

            processGroups(section.groups);

            if (section.subSections && section.subSections.length > 0) {
                section.subSections.forEach(subSection => {
                    processGroups(subSection.groups);
                });
            }

            noAnswersCount[section.name] = sectionNoAnswersCount;
        });

        return noAnswersCount;
    };

    // const sectionScores = calculateSectionScores(sections);
    const noAnswersCount = calculateNoAnswersCount(sections);
    const maxNoAnswers = Math.max(...Object.values(noAnswersCount));
    const steps = 4; // Stała liczba kroków (5 - 1, bo zaczynamy od zera)
    const stepValue = Math.ceil(maxNoAnswers / steps); // Obliczamy wartość kroku

    let globalQuestionNumber = 0;
    const calculateFontSize = (title) => {
        const titleLength = title.length;
        let fontSize = 48;

        if (titleLength >= 18 && titleLength < 24) {
            fontSize = 36;
        } else if (titleLength >= 24 && titleLength < 28) {
            fontSize = 32;
        } else if (titleLength >= 28) {
            fontSize = 28;
        }

        return fontSize;
    };

    const fontSize = calculateFontSize(auditTitle);

    return (
        <Document>
            <Page size="A4" style={styles.page}>
                <View style={styles.title}>
                    <Text>HOTELS AUDIT</Text>
                </View>

                <View style={styles.certification}>
                    <Text>CERTIFICATION</Text>
                </View>
                {FullCoverPhoto &&
                    <View style={styles.imageContainer}>
                        <Image style={styles.image} src={FullCoverPhoto} />
                    </View>
                }
                <View style={styles.hotelName}>
                    <Text style={{ fontSize: fontSize, whiteSpace: 'nowrap' }}>{auditTitle}</Text>
                </View>

                <View style={styles.scoreContainer}>
                    <View style={styles.scoreItem}>
                        <Text style={styles.scoreText}>Ilość opracowanych sugestii</Text>
                        <Text style={styles.scoreValue}>{auditNoAnswersCount}</Text>
                    </View>
                    <View style={styles.scoreItem}>
                        <Text style={styles.scoreText}>Ilość sprawdzonych czynników</Text>
                        <Text style={styles.scoreValue}>{totalAuditQuestions}</Text>
                    </View>
                </View>


                {/* <View style={{ flexDirection: 'row', alignItems: 'center', width: 160 }}>
                    <View style={styles.ha_container}>
                        <View style={[styles.ha_chart, { width: `${auditPercentageScore}%` }]}></View>
                    </View>
                    <Text style={styles.ha_percentage}>{auditPercentageScore.toFixed(2)}%</Text>
                </View> */}

                <View style={{ position: 'absolute', bottom: 70 }}>
                    {ceoSignature && <Image style={{ width: 55, height: 55 }} src={ceoSignature} />}
                </View>

                <View style={styles.signature}>
                    <Text>SIGNATURE</Text>
                </View>

                <View style={styles.auditDate}>
                    <Text>Audit date</Text>
                    <Text style={styles.auditDateValue}>{formatDate(AuditDate)}</Text>
                </View>
                <Text style={styles.footer}>WWW.HOTELSAUDIT.COM</Text>

                <Image style={styles.backgroundImage} src={backgroundImage} />

            </Page>
            <Page size="A4" style={styles.page}>
                <View fixed style={{ justifyContent: 'space-between', flexDirection: 'row', width: '100%', paddingTop: 20, height: 40 }}>
                    <Image style={[styles.logo, { marginLeft: 45 }]} src={Logo} />
                    <Text style={{ fontSize: 12, paddingRight: 40, fontWeight: '600', letterSpacing: 1 }}>Hotels Audit</Text>
                </View>

                <View style={styles.box}>
                    <Text style={styles.statsTitle}>Statystyki</Text>
                    <Text style={styles.startSubtitle}>Podsumowanie wszystkich sekcji</Text>
                    <Text style={styles.startSubSubtitle}>Ilość wykrytych problemów i opracowanych sugetii</Text>

                    <View style={styles.bars}>
                        <View style={styles.scaleContainer}>
                            {[...Array(steps + 1)].map((_, index) => (
                                <View
                                    key={index}
                                    style={[
                                        styles.scaleLine,
                                        { left: `${(index / steps) * 100}%` },
                                    ]}
                                />
                            ))}
                        </View>
                        {Object.entries(noAnswersCount).map(([section, count], index) => (
                            <View key={index} style={{ flexDirection: 'row', alignItems: 'center', marginBottom: 5, width: '100%', justifyContent: 'center' }}>
                                <Text style={[styles.statsLabel, { width: '20%', textAlign: 'right', marginRight: 10 }]}>{section}</Text>
                                <View style={[styles.statsBarContainer, { flex: 1, width: '70%' }]}>
                                    <View style={[styles.mainStartsBar, { width: `${(count / maxNoAnswers) * 100}%` }]}>
                                        <Text style={styles.mainStatsBarText}>{count}</Text>
                                    </View>
                                </View>
                            </View>
                        ))}
                        <View style={styles.scaleLabelContainer}>
                            {[...Array(steps + 1)].map((_, index) => (
                                <Text key={index} style={styles.scaleLabel}>{index * stepValue}</Text>
                            ))}
                        </View>
                    </View>

                    <View style={{ alignItems: 'center', marginTop: 40 }}>
                        <Text style={{ fontSize: 10, textAlign: 'justify' }}>Instrukcja korzystania z raportu: niektóre odpowiedzi zawierają załączone zdjęcia z badania w obiekcie. Aby otworzyć takie zdjęcie w pełnej rozdzielczości proszę nacisnąć na nie prawym przyciskiem myszy i wybrać opcję otworzenia go w przeglądarce.</Text>
                    </View>
                </View>

                <View fixed style={styles.mainfooter}>
                    <View style={styles.footerLinkText}>
                        <Text>www.hotelsaudit.com</Text></View>
                    <View style={styles.footerText}>
                        <Text> This document contains confidential information, do not share it with anyone outside the organization. Hotels Audit {symbol} 2024. </Text>
                    </View>
                    <View>
                        <Text
                            style={[styles.pageNumber]}
                            render={({ pageNumber, totalPages }) => (
                                `${pageNumber} / ${totalPages}`
                            )}
                        />
                    </View>
                </View>
            </Page>

            {sections.map((section, sectionIndex) => {
                let totalQuestions = 0;
                let totalYesAnswers = 0;
                let naAnswersCount = 0;
                let totalNoCount = 0;

                const processGroups = (groups) => {
                    groups.forEach(group => {
                        group.questions.forEach(question => {
                            if (question.answer !== null) {
                                totalQuestions += 1;
                                if (question.answer === 'yes') {
                                    totalYesAnswers += 1;
                                }
                                if (question.answer === 'na') {
                                    naAnswersCount += 1;
                                }
                                if (question.answer === 'no') {
                                    totalNoCount += 1;
                                }
                            }
                        });
                    });
                };

                const sortGroupsById = (groups) => {
                    return groups.slice().sort((a, b) => a.id - b.id);
                };

                const sortQuestionsById = (questions) => {
                    return questions.slice().sort((a, b) => a.id - b.id);
                };

                // Przetwarzanie grup w sekcji głównej
                processGroups(section.groups);

                // Przetwarzanie grup w podsekcjach
                if (section.subSections && section.subSections.length > 0) {
                    section.subSections.forEach(subSection => {
                        processGroups(subSection.groups);
                    });
                }

                const yesPercentage = totalQuestions > 0 ? ((totalYesAnswers / (totalQuestions - naAnswersCount)) * 100).toFixed(2) : 0;

                return (
                    <Page key={sectionIndex} size="A4" style={[styles.page, { paddingBottom: 40 }, { paddingTop: 10 }]}>
                        <View style={styles.box}>
                            <View style={styles.sectionHeader}>
                                <Image style={styles.logo} src={Logo} />
                                <Text style={[styles.sectionName, { fontSize: section.name.length > 28 ? 12.5 : 13.5 }]}>
                                    {section.name} | {totalNoCount} {totalNoCount === 1 ? 'suggestion' : 'suggestions'}
                                </Text>
                                <Text style={styles.hotelsAuditText}>Hotels Audit</Text>
                            </View>

                            {/* Najpierw grupy w sekcji głównej */}
                            {sortGroupsById(section.groups).map((group, groupIndex) => (
                                <>
                                    <Text wrap={false} style={{ marginTop: 15, fontSize: 13.5, marginBottom: 10, fontWeight: 'bold', textAlign: 'center', borderBottom: '2px dashed #ccc', borderTop: '2px dashed #ccc', paddingBottom: 10, paddingTop: 10 }}>{group.name}</Text>
                                    <View key={group.name} style={{ break: 'before' }}>
                                        {sortQuestionsById(group.questions).map((question, questionIndex) => {
                                            globalQuestionNumber += 1;
                                            return (
                                                <QuestionSection
                                                    key={questionIndex}
                                                    questionNumber={globalQuestionNumber}
                                                    question={question.question}
                                                    answer={question.answer}
                                                    note={question.note}
                                                    aiResponse={question.ai_solution}
                                                    photoSrc={question.photos ? question.photos.map(photo => photo.url) : []}
                                                />
                                            );
                                        })}
                                    </View>
                                </>
                            ))}

                            {/* Następnie podsekcje */}
                            {section.subSections && section.subSections.length > 0 && (
                                section.subSections.map((subSection, subSectionIndex) => (
                                    <View key={subSectionIndex}>
                                        <View style={styles.SubSectionHeader}>
                                            <Text style={[styles.sectionName, { textAlign: 'center' }]}>{subSection.name}</Text>
                                        </View>

                                        {sortGroupsById(subSection.groups).map((group, groupIndex) => (
                                            <>
                                                <Text wrap={false} style={{ marginTop: 15, fontSize: 13.5, marginBottom: 10, fontWeight: 'bold', textAlign: 'center', borderBottom: '2px dashed #ccc', borderTop: '2px dashed #ccc', paddingBottom: 10, paddingTop: 10 }}>{group.name}</Text>
                                                <View key={group.name} style={{ break: 'before' }}>
                                                    {sortQuestionsById(group.questions).map((question, questionIndex) => {
                                                        globalQuestionNumber += 1;
                                                        return (
                                                            <QuestionSection
                                                                key={questionIndex}
                                                                questionNumber={globalQuestionNumber}
                                                                question={question.question}
                                                                answer={question.answer}
                                                                note={question.note}
                                                                aiResponse={question.ai_solution}
                                                                photoSrc={question.photos ? question.photos.map(photo => photo.url) : []}
                                                            />
                                                        );
                                                    })}
                                                </View>
                                            </>
                                        ))}
                                    </View>
                                ))
                            )}
                        </View>

                        <View fixed style={styles.mainfooter}>
                            <View style={styles.footerLinkText}>
                                <Text>www.hotelsaudit.com</Text></View>
                            <View style={styles.footerText}>
                                <Text> This document contains confidential information, do not share it with anyone outside the organization. Hotels Audit {symbol}  2024. </Text>
                            </View>
                            <View>
                                <Text
                                    style={[styles.pageNumber]}
                                    render={({ pageNumber, totalPages }) => (
                                        `${pageNumber} / ${totalPages}`
                                    )}
                                />
                            </View>
                        </View>
                    </Page>
                );
            })}



            <AuditorSummaryPDF summary={summary} AuditDate={AuditDate} />
        </Document>
    );
};

const QuestionSection = ({ question, answer, note, aiResponse, photoSrc, questionNumber }) => {
    const answerColor = answer === 'yes' ? '#40e14b' : answer === 'no' ? '#ff8484' : 'gray';
    const shouldDisplayAIResponse = (answer || 'yes').toLowerCase() === 'no';

    return (
        <View wrap={false}>
            <View style={styles.errorQuestionContainer} break>
                <View style={photoSrc ? styles.errorQuestionContentWithPhoto : styles.errorQuestionContent}>
                    <View style={styles.questionAndAnswerContainer}>
                        <View style={[styles.answerIndicator, { borderColor: answerColor }]}>
                            <Text style={[styles.answerText, { color: answerColor }]}> {answer}</Text>
                        </View>
                        <Text style={styles.questionText}>{questionNumber}. {question}</Text>
                    </View>
                    {/* {note && (
                        <View style={{ paddingTop: 10, paddingBottom: 5, marginLeft: 70 }}>
                            <View style={styles.noteText}>
                                <Text style={{ fontWeight: 'bold' }}>COMMENT:</Text> <Text>{note}</Text>
                            </View>
                        </View>
                    )} */}
                    {aiResponse && (
                        <View style={{ marginLeft: 70, paddingTop: 5, paddingBottom: 10 }}>
                            <View style={styles.noteText}>
                                <Text style={{ fontWeight: 'bold' }}>RESPONSE:</Text> <Text>{aiResponse}</Text>
                            </View>
                        </View>
                    )}

                    {photoSrc && (
                        <View style={styles.photoContainer}>
                            {photoSrc && photoSrc.length > 0 && photoSrc.map((src, index) => (
                                <PdfLink src={src} key={index}><Image style={styles.photo} src={src} /></PdfLink>
                            ))}
                        </View>
                    )}
                </View>
            </View>
        </View>
    );
};


export default TestPdf;
