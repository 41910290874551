import React, { useEffect, useState } from 'react'
import { useAuth } from '../context/AuthContext';

import Nav from '../components/MainPage/Nav'
import Sidebar from '../components/MainPage/Sidebar'
import NewAudit from '../components/CreateAuditPage/NewAudit';
import CopyAudit from '../components/CreateAuditPage/CopyAudit';
import { notification } from 'antd';


const CreateAuditPage = () => {
    // const [scheduledAudits, setScheduledAudits] = useState([]);
    const { authTokens, toCheckAudits, setToCheckAudits, toSendAudits, setToSendAudits, sentAudits, setSentAudits } = useAuth();
    const backendUrl = process.env.REACT_APP_BACKEND_URL;
    // const [createPageNumber, setCreatePageNumber] = useState(1);

    const {
        scheduledAudits,
        setScheduledAudits,
        currentPage,
        setCurrentPage,
        createPageNumber,
        setCreatePageNumber,
        currentAudit,
        setCurrentAudit,
        allAudits,
        setAllAudits,
        completedAudits,
        setCompletedAudits,
        inProgressAudits,
        setInProgressAudits,
    } = useAuth() || {};

    const [questiongroupId, setQuestiongroupId] = useState(null);
    const [questions, setQuestions] = useState([]);

    const [selectedCategory, setSelectedCategory] = useState();
    const [createObjectType, setCreateObjectType] = useState('');

    const [selectedGroups, setSelectedGroups] = useState({});
    const [selectedSubSections, setSelectedSubSections] = useState({});

    const [auditTitle, setAuditTitle] = useState('');
    const [collapsedSections, setCollapsedSections] = useState({})
    const [users, setUsers] = useState([]);
    const [selectedUsers, setSelectedUsers] = useState(null);
    const [startDate, setStartDate] = useState('');
    const [endDate, setEndDate] = useState('');
    const [city, setCity] = useState('');
    const [street, setStreet] = useState('');

    const [coverPhoto, setCoverPhoto] = useState(null);

    const [sectionCounts, setSectionCounts] = useState({});

    // Get audit data from backend
    const [questionGroups, setQuestionGroups] = useState({});
    const [questionGroupsUnique, setQuestionGroupsUnique] = useState({});

    const fetchData = async (url, headers) => {
        const response = await fetch(url, { headers });
        if (!response.ok) {
            throw new Error('Błąd sieci lub serwera');
        }
        return response.json();
    };

    const fetchUsers = async () => {
        const headers = {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${authTokens?.access}`
        };

        try {
            const usersData = await fetchData(`${backendUrl}/audit-page/users/`, headers);
            setUsers(usersData);
        } catch (error) {
            console.error('Error fetching users:', error);
            notification.error({
                message: 'Wystąpił błąd',
                description: 'Nie udało się pobrać danych użytkowników.',
            });
        }
    };
    useEffect(() => {
        fetchUsers();
    }, [authTokens, backendUrl]);


    useEffect(() => {
        if (!scheduledAudits.length && !inProgressAudits.length && !toCheckAudits.length && !toSendAudits.length && !sentAudits.length) {

            fetch(`${backendUrl}/audit-page/audits/`, {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + (authTokens?.access || '')
                }
            })
                .then(response => response.json())
                .then(data => {
                    if (Array.isArray(data)) {
                        const scheduledAudits = data.filter(audit => audit.status === "Scheduled");
                        const inProgressAudits = data.filter(audit => audit.status === "In Progress");
                        const toCheckAudits = data.filter(audit => audit.status === "To Check");
                        const toSendAudits = data.filter(audit => audit.status === "To Send");
                        const sentAudits = data.filter(audit => audit.status === "Sent");
                        console.log(sentAudits)
                        setScheduledAudits(scheduledAudits);
                        setInProgressAudits(inProgressAudits);
                        setToCheckAudits(toCheckAudits);
                        setToSendAudits(toSendAudits);
                        setSentAudits(sentAudits);
                        setAllAudits(data);
                        console.log(data)
                    } else {
                        console.error('Oczekiwano tablicy, ale otrzymano:', data);
                    }
                })
                .catch(error => {
                    console.error('Błąd:', error);
                    setScheduledAudits([]);
                    setInProgressAudits([]);
                    setToCheckAudits([]);
                    setToSendAudits([]);
                    setSentAudits([]);
                    setAllAudits([]);
                });
        }
    }, [authTokens?.access, backendUrl, setScheduledAudits, inProgressAudits.length, scheduledAudits.length, setAllAudits, setInProgressAudits]);


    const toggleSectionCollapse = (section) => {
        setCollapsedSections(prev => ({
            ...prev,
            [section]: !prev[section]
        }));
    };





    const buildSectionsDataFromSelectedGroups = (selectedGroups) => {
        const sectionsMap = {};

        Object.keys(selectedGroups).forEach(key => {
            if (selectedGroups[key]) {
                const [sectionId, subSectionId, groupId] = key.split('_').map(id => id === 'null' ? null : parseInt(id));

                if (!sectionsMap[sectionId]) {
                    sectionsMap[sectionId] = {
                        id: sectionId,
                        name: `Section ${sectionId}`,  // Placeholder for section name
                        sub_sections: [],
                        groups: []
                    };
                }

                if (!subSectionId) {
                    sectionsMap[sectionId].groups.push({ id: groupId, name: `Group ${groupId}`, questions: [] });
                } else {
                    let subSection = sectionsMap[sectionId].sub_sections.find(sub => sub.id === subSectionId);
                    if (!subSection) {
                        subSection = { id: subSectionId, name: `SubSection ${subSectionId}`, groups: [] };
                        sectionsMap[sectionId].sub_sections.push(subSection);
                    }
                    subSection.groups.push({ id: groupId, name: `Group ${groupId}`, questions: [] });
                }
            }
        });

        return Object.values(sectionsMap);
    };

    console.log('buildSections')
    console.log(buildSectionsDataFromSelectedGroups(selectedGroups))

    const handleSubmit = async (event, unique, objectType) => {
        event.preventDefault();

        const auditData = {
            title: auditTitle,
            uniqueTitle: selectedCategory,
            start_date: startDate,
            end_date: endDate,
            user_ids: selectedUsers,
            object_type: objectType,
            is_unique: unique,
            question_group_id: questiongroupId,
            sections: buildSectionsDataFromSelectedGroups(selectedGroups),
            city: unique ? undefined : city,
            street: unique ? undefined : street,
            cover_photo: coverPhoto // Dodajemy plik bez konwersji
        };

        console.log("Audit Data before sending:", auditData);

        await sendAuditData(auditData);
    };

    const sendAuditData = async (auditData) => {
        try {
            console.log("Sending audit data to server:", auditData);

            const formData = new FormData();
            for (const key in auditData) {
                if (auditData[key] !== undefined && auditData[key] !== null) {
                    if (key === 'cover_photo') {
                        formData.append(key, auditData[key]); // Dodajemy plik
                    } else if (Array.isArray(auditData[key]) || typeof auditData[key] === 'object') {
                        formData.append(key, JSON.stringify(auditData[key])); // Serializujemy obiekty i tablice
                    } else {
                        formData.append(key, auditData[key]);
                    }
                }
            }

            const response = await fetch(`${backendUrl}/audit-page/create-audit/`, {
                method: 'POST',
                headers: {
                    'Authorization': `Bearer ${authTokens?.access}`
                },
                body: formData
            });

            if (!response.ok) {
                const errorData = await response.json();
                console.error("Error response from server:", errorData);
                throw new Error('Network or server error');
            }

            const data = await response.json();

            const newScheduledAudits = [...scheduledAudits, data];
            const newAllAudits = [...allAudits, data];

            setScheduledAudits(newScheduledAudits);
            setAllAudits(newAllAudits);

            resetFormState();
            notification.success({
                message: 'Audit utworzony pomyślnie',
                description: 'Audyt został poprawnie utworzony.',
            });
        } catch (error) {
            console.error('Error:', error);
            notification.error({
                message: 'Wystąpił błąd',
                description: 'Nie udało się utworzyć audytu.',
            });
        }
    };

    function resetFormState() {
        setSelectedGroups({});
        setAuditTitle('');
        setSelectedUsers([]);
        setStartDate('');
        setEndDate('');
        setCity('');
        setStreet('');
        setCoverPhoto(null);
    }

    return (
        <div className='lg:flex max-h-screen overflow-auto'>
            <Nav currentPage={currentPage} setCurrentPage={setCurrentPage} createPageNumber={createPageNumber} setCreatePageNumber={setCreatePageNumber} />

            {createPageNumber === 1 &&
                <NewAudit auditTitle={auditTitle} setAuditTitle={setAuditTitle} city={city} setCity={setCity} street={street} setStreet={setStreet} startDate={startDate} setStartDate={setStartDate} endDate={endDate} setEndDate={setEndDate} coverPhoto={coverPhoto} setCoverPhoto={setCoverPhoto} questionGroups={questionGroups} selectedCategory={selectedCategory} setSelectedCategory={setSelectedCategory} handleSubmit={handleSubmit} questions={questions} selectedGroups={selectedGroups} setSelectedGroups={setSelectedGroups} collapsedSections={collapsedSections} setCollapsedSections={setCollapsedSections} toggleSectionCollapse={toggleSectionCollapse} users={users} selectedUsers={selectedUsers} setSelectedUsers={setSelectedUsers} sectionCounts={sectionCounts} setSectionCounts={setSectionCounts} setCreateObjectType={setCreateObjectType} setCurrentAudit={setCurrentAudit} allAudits={allAudits} setCreatePageNumber={setCreatePageNumber} selectedSubSections={selectedSubSections} setSelectedSubSections={setSelectedSubSections} />
            }
            {createPageNumber === 2 &&
                <CopyAudit auditTitle={auditTitle} setAuditTitle={setAuditTitle} setCity={setCity} street={street} setStreet={setStreet} startDate={startDate} setStartDate={setStartDate} endDate={endDate} setEndDate={setEndDate} coverPhoto={coverPhoto} setCoverPhoto={setCoverPhoto} questionGroups={questionGroups} selectedCategory={selectedCategory} setSelectedCategory={setSelectedCategory} handleSubmit={handleSubmit} questions={questions} selectedGroups={selectedGroups} setSelectedGroups={setSelectedGroups} collapsedSections={collapsedSections} setCollapsedSections={setCollapsedSections} toggleSectionCollapse={toggleSectionCollapse} users={users} selectedUsers={selectedUsers} setSelectedUsers={setSelectedUsers} sectionCounts={sectionCounts} setSectionCounts={setSectionCounts} questionGroupsUnique={questionGroupsUnique} setCreateObjectType={setCreateObjectType} setQuestiongroupId={setQuestiongroupId} setCurrentAudit={setCurrentAudit} setCreatePageNumber={setCreatePageNumber} allAudits={allAudits} />
            }
            {/* <div className='hidden lg:flex'>

                <Sidebar scheduledAudits={scheduledAudits} currentAudit={currentAudit} setCurrentAudit={setCurrentAudit} setCurrentPage={setCurrentPage} />
            </div> */}
        </div>
    )
}

export default CreateAuditPage