import React, { useState, useEffect, useRef } from 'react';
import { FiSearch } from 'react-icons/fi'; // Importujemy ikonę lupy

function QuestionSearchBar({ sections, onSelect, expandedSection }) {
    const [searchTerm, setSearchTerm] = useState('');
    const [searchResults, setSearchResults] = useState([]);
    const [isFocused, setIsFocused] = useState(false);
    const selectedQuestionRef = useRef(null); // Ref do wybranego pytania
    const scrollContainerRef = useRef(null); // Ref do kontenera przewijania
    const [isExpanded, setIsExpanded] = useState(false); // Dodajemy stan dla rozwinięcia pola wyszukiwania
    const searchContainerRef = useRef(null); // Ref do kontenera pola wyszukiwania

    useEffect(() => {
        // Funkcja obsługująca kliknięcie poza polem wyszukiwania
        const handleClickOutside = (event) => {
            if (searchContainerRef.current && !searchContainerRef.current.contains(event.target)) {
                setTimeout(() => setIsExpanded(false), 100); // Zwijamy pole wyszukiwania z opóźnieniem
            }
        };

        // Dodajemy nasłuchiwanie na zdarzenie kliknięcia na cały dokument
        document.addEventListener("mousedown", handleClickOutside);

        // Czyszczenie nasłuchiwania po odmontowaniu komponentu
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, []);

    useEffect(() => {
        if (selectedQuestionRef.current) {
            selectedQuestionRef.current.scrollIntoView({ behavior: 'smooth', block: 'center' });
        }
    }, [selectedQuestionRef.current]); // Dodajemy selectedQuestionRef.current jako zależność

    const handleSelectClick = (event, question, sectionId, groupId) => {
        event.stopPropagation();
        onSelect(question, sectionId, groupId); // Przekazujemy dodatkowo informacje o sekcji i grupie

        selectedQuestionRef.current = question; // Aktualizujemy ref do wybranego pytania
    };


    const handleSearchChange = (e) => {
        setSearchTerm(e.target.value);
    };

    useEffect(() => {
        if (searchTerm === '') {
            setSearchResults([]);
        } else {
            let filteredQuestions = [];
            if (expandedSection) {
                const section = sections.find(section => section.id === expandedSection);
                if (section) {
                    filteredQuestions = section.groups.reduce((acc, group) => {
                        return acc.concat(group.questions.map(question => ({
                            ...question,
                            group: group.name, // Dodajemy nazwę grupy do pytania
                            sectionId: section.id, // Dodajemy id sekcji do pytania
                            groupId: group.id // Dodajemy id grupy do pytania
                        })));
                    }, []);
                }
            } else {
                const allQuestions = sections.reduce((accumulator, currentSection) => {
                    return accumulator.concat(currentSection.groups.reduce((acc, group) => {
                        return acc.concat(group.questions.map(question => ({
                            ...question,
                            group: group.name, // Dodajemy nazwę grupy do pytania
                            sectionId: currentSection.id, // Dodajemy id sekcji do pytania
                            groupId: group.id // Dodajemy id grupy do pytania
                        })));
                    }, []));
                }, []);
                filteredQuestions = allQuestions;
            }

            const filteredResults = filteredQuestions.filter(question =>
                question.question.toLowerCase().includes(searchTerm.toLowerCase())
            );

            setSearchResults(filteredResults);
        }
    }, [searchTerm, sections, expandedSection]);


    return (
        <div className='w-1/2 lg:w-1/6 relative z-10 ml-4' ref={searchContainerRef}>
        <div className={`flex items-center  ${isExpanded ? 'w-full' : 'w-10'} transition-width  duration-200 ease-in-out`}>

            {isExpanded && (
                <input
                    autoFocus
                    className='p-2 h-10 rounded-r font-semibold text-base focus:outline-none border-gray-300 flex-grow  '
                    placeholder='Search here...'
                    value={searchTerm}
                    onChange={handleSearchChange}
                    onFocus={() => setIsFocused(true)}
                    onBlur={() => {
                        setTimeout(() => setIsFocused(false), 100);
                    }}
                />
            )}
            <FiSearch className='w-6 h-6 my-2' onClick={() => setIsExpanded(true)} />
        </div>
        {isFocused && isExpanded && (
            <div className='absolute top-full left-0 w-full bg-white shadow-lg border border-gray-300 rounded-b-md'>
                <div ref={scrollContainerRef} className='overflow-auto sm:max-h-screen'>
                    {searchResults.length > 0 ? (
                        searchResults.map(question => (
                            <div
                                className='cursor-pointer py-2 px-4 text-black hover:bg-gray-200 border-b'
                                key={question.id}
                                ref={question.id === selectedQuestionRef.current?.id ? selectedQuestionRef : null}
                                onClick={(e) => handleSelectClick(e, question, question.sectionId, question.groupId)}
                            >
                                {expandedSection ? `${question.group} - ${question.question}` : `${question.group} - ${question.question}`}
                            </div>
                        ))
                    ) : (
                        <div className='py-2 px-4 text-gray-500'>No results found</div>
                    )}
                </div>
            </div>
        )}
    </div>
    );
}

export default QuestionSearchBar;
