import React, { useState, useEffect } from 'react';
import axios from 'axios';
import Select from 'react-select';
import { useAuth } from '../../context/AuthContext';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { Line } from 'react-chartjs-2';
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend,
    TimeScale,
} from 'chart.js';
import 'chartjs-adapter-date-fns';
import { pl } from 'date-fns/locale';
import {
    startOfDay,
    endOfDay,
    eachDayOfInterval,
    eachHourOfInterval,
    format,
    parseISO,
    isWithinInterval,
    differenceInDays,
} from 'date-fns';
import { notification } from 'antd';

ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend,
    TimeScale
);

const ActivityLogs = () => {
    const [logs, setLogs] = useState([]);
    const [filteredLogs, setFilteredLogs] = useState([]);
    const [isFilterOpen, setIsFilterOpen] = useState(false);
    const [selectedLog, setSelectedLog] = useState(null); // Stan do przechowywania wybranego logu
    const [filters, setFilters] = useState({
        user: [],
        actionType: [],
        targetType: [],
        fieldName: [],
    });
    const [userOptions, setUserOptions] = useState([]);
    const [actionTypeOptions, setActionTypeOptions] = useState([]);
    const [targetTypeOptions, setTargetTypeOptions] = useState([]);
    const [fieldNameOptions, setFieldNameOptions] = useState([]);
    const [chartData, setChartData] = useState(null);
    const [startDate, setStartDate] = useState(startOfDay(new Date()));
    const [endDate, setEndDate] = useState(endOfDay(new Date()));
    const [totalLogs, setTotalLogs] = useState(0);

    const { authTokens } = useAuth();
    const backendUrl = process.env.REACT_APP_BACKEND_URL;

    useEffect(() => {
        fetchLogs();
    }, [startDate, endDate]);

    useEffect(() => {
        applyFilters();
    }, [logs, filters]);

    useEffect(() => {
        if (filteredLogs.length > 0) {
            generateChartData();
        }
    }, [filteredLogs, startDate, endDate]);

    const fetchLogs = async () => {
        try {
            const response = await axios.get(`${backendUrl}/audit-page/activity-logs/`, {
                headers: {
                    Authorization: `Bearer ${authTokens?.access || ''}`,
                },
                params: {
                    start_date: startDate.toISOString(),
                    end_date: endDate.toISOString(),
                },
            });
            setLogs(response.data);
            setFilteredLogs(response.data);
            generateFilterOptions(response.data);
        } catch (error) {
            console.error('Error fetching logs:', error);
            notification.error({
                message: 'Wystąpił błąd',
                description: 'Nie udało się pobrać logów.',
            });
        }
    };

    const generateFilterOptions = (logsData) => {
        const users = [...new Set(logsData.map((log) => log.user))];
        const actionTypes = [...new Set(logsData.map((log) => log.action_type))];
        const targetTypes = [...new Set(logsData.map((log) => log.target_type))];
        const fieldNames = [
            ...new Set(logsData.map((log) => log.field_name).filter((name) => name !== null)),
        ];

        setUserOptions(users.map((user) => ({ label: user, value: user })));
        setActionTypeOptions(actionTypes.map((type) => ({ label: type, value: type })));
        setTargetTypeOptions(targetTypes.map((type) => ({ label: type, value: type })));
        setFieldNameOptions(fieldNames.map((name) => ({ label: name, value: name })));
    };

    const toggleFilterModal = () => {
        setIsFilterOpen(!isFilterOpen);
    };

    const handleFilterChange = (selectedOptions, actionMeta) => {
        setFilters((prevFilters) => ({
            ...prevFilters,
            [actionMeta.name]: selectedOptions ? selectedOptions.map((option) => option.value) : [],
        }));
    };

    const clearFilters = () => {
        setFilters({
            user: [],
            actionType: [],
            targetType: [],
            fieldName: [],
        });
        setFilteredLogs(logs); // Reset filtered logs to all logs
    };

    const applyFilters = () => {
        let filtered = logs;

        if (filters.user.length > 0) {
            filtered = filtered.filter((log) => filters.user.includes(log.user));
        }
        if (filters.actionType.length > 0) {
            filtered = filtered.filter((log) => filters.actionType.includes(log.action_type));
        }
        if (filters.targetType.length > 0) {
            filtered = filtered.filter((log) => filters.targetType.includes(log.target_type));
        }
        if (filters.fieldName.length > 0) {
            filtered = filtered.filter((log) => filters.fieldName.includes(log.field_name));
        }

        setFilteredLogs(filtered);
    };

    const selectLog = (log) => {
        setSelectedLog(log);
    };

    const clearSelectedLog = () => {
        setSelectedLog(null);
    };

    const formatTimestamp = (timestamp) => {
        const date = new Date(timestamp);
        const hours = date.getHours().toString().padStart(2, '0');
        const minutes = date.getMinutes().toString().padStart(2, '0');
        const day = date.getDate().toString().padStart(2, '0');
        const month = (date.getMonth() + 1).toString().padStart(2, '0');
        const year = date.getFullYear();
        return `${hours}:${minutes} - ${day}/${month}/${year}`;
    };

    const generateChartData = () => {
        const start = startOfDay(startDate);
        const end = endOfDay(endDate);
        const daysDifference = differenceInDays(end, start);

        let data;
        let unit;

        if (daysDifference > 0) {
            // Dane dzienne
            const days = eachDayOfInterval({ start, end });
            data = days.map((day) => {
                const count = filteredLogs.filter((log) => {
                    const logDate = parseISO(log.timestamp);
                    return isWithinInterval(logDate, { start: startOfDay(day), end: endOfDay(day) });
                }).length;
                return { x: day, y: count };
            });
            unit = 'day';
        } else {
            // Dane godzinowe
            const hours = eachHourOfInterval({ start, end });
            data = hours.map((hour) => {
                const count = filteredLogs.filter((log) => {
                    const logDate = parseISO(log.timestamp);
                    return isWithinInterval(logDate, { start: hour, end: new Date(hour.getTime() + 3599999) });
                }).length;
                return { x: hour, y: count };
            });
            unit = 'hour';
        }

        setChartData({
            labels: data.map((d) => d.x),
            datasets: [
                {
                    label: 'Liczba logów',
                    data: data.map((d) => ({ x: d.x, y: d.y })),
                    borderColor: 'rgb(75, 192, 192)',
                    tension: 0.1,
                },
            ],
        });

        setTotalLogs(data.reduce((sum, d) => sum + d.y, 0));

        return unit;
    };

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (isFilterOpen && !event.target.closest('.filter-modal')) {
                setIsFilterOpen(false);
                applyFilters();
            }
        };

        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [isFilterOpen, filters]);

    return (
        <div className="sm:p-8">
            <h1 className="text-2xl font-bold mb-4">Activity Logs</h1>

            {/* Wykres aktywności */}
            <div className="mb-8 bg-white shadow-md rounded-lg p-4">
                <h2 className="text-xl font-semibold mb-4">Wykres Aktywności</h2>
                <div className="flex flex-wrap items-start">
                    <div className="w-full md:w-1/3 mb-4 md:mb-0">
                        <div className="mb-4">
                            <label className="block text-sm font-medium text-gray-700">Data początkowa</label>
                            <DatePicker
                                selected={startDate}
                                onChange={(date) => setStartDate(date)}
                                selectsStart
                                startDate={startDate}
                                endDate={endDate}
                                className="mt-1 block w-full rounded-md border-gray-300 shadow-sm"
                            />
                        </div>
                        <div className="mb-4">
                            <label className="block text-sm font-medium text-gray-700">Data końcowa</label>
                            <DatePicker
                                selected={endDate}
                                onChange={(date) => setEndDate(date)}
                                selectsEnd
                                startDate={startDate}
                                endDate={endDate}
                                minDate={startDate}
                                className="mt-1 block w-full rounded-md border-gray-300 shadow-sm"
                            />
                        </div>
                        <div className="mb-4">
                            <label className="block text-sm font-medium text-gray-700">Użytkownik</label>
                            <Select
                                isMulti
                                name="user"
                                options={userOptions}
                                className="basic-multi-select"
                                classNamePrefix="select"
                                value={userOptions.filter((option) => filters.user.includes(option.value))}
                                onChange={(selectedOptions, actionMeta) => handleFilterChange(selectedOptions, { name: 'user' })}
                            />
                        </div>
                    </div>
                    <div className="w-full md:w-2/3">
                        {chartData && (
                            <div className="h-64">
                                <Line
                                    data={chartData}
                                    options={{
                                        responsive: true,
                                        maintainAspectRatio: false,
                                        scales: {
                                            x: {
                                                type: 'time',
                                                time: {
                                                    unit: differenceInDays(endDate, startDate) > 0 ? 'day' : 'hour',
                                                    displayFormats: {
                                                        hour: 'HH:mm',
                                                        day: 'dd.MM',
                                                    },
                                                },
                                                adapters: {
                                                    date: {
                                                        locale: pl,
                                                    },
                                                },
                                                title: {
                                                    display: true,
                                                    text: differenceInDays(endDate, startDate) > 0 ? 'Data' : 'Godzina',
                                                },
                                            },
                                            y: {
                                                beginAtZero: true,
                                                title: {
                                                    display: true,
                                                    text: 'Liczba logów',
                                                },
                                            },
                                        },
                                    }}
                                />
                            </div>
                        )}
                        <p className="mt-2 text-center">Całkowita liczba logów: {totalLogs}</p>
                    </div>
                </div>
            </div>

            {!selectedLog ? (
                <>
                    <button
                        onClick={toggleFilterModal}
                        className="mb-4 px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-600"
                    >
                        Filter Logs
                    </button>

                    {isFilterOpen && (
                        <div className="fixed inset-0 flex items-center justify-center bg-gray-500 bg-opacity-50 z-50">
                            <div className="bg-white p-6 rounded shadow-lg w-full max-w-md mx-auto filter-modal">
                                <h2 className="text-xl font-semibold mb-4">Filter Logs</h2>

                                <div className="mb-4">
                                    <label className="block text-sm font-medium text-gray-700">Action Type</label>
                                    <Select
                                        isMulti
                                        name="actionType"
                                        options={actionTypeOptions}
                                        className="basic-multi-select"
                                        classNamePrefix="select"
                                        value={actionTypeOptions.filter((option) => filters.actionType.includes(option.value))}
                                        onChange={handleFilterChange}
                                    />
                                </div>

                                <div className="mb-4">
                                    <label className="block text-sm font-medium text-gray-700">Target Type</label>
                                    <Select
                                        isMulti
                                        name="targetType"
                                        options={targetTypeOptions}
                                        className="basic-multi-select"
                                        classNamePrefix="select"
                                        value={targetTypeOptions.filter((option) => filters.targetType.includes(option.value))}
                                        onChange={handleFilterChange}
                                    />
                                </div>

                                <div className="mb-4">
                                    <label className="block text-sm font-medium text-gray-700">Field Name</label>
                                    <Select
                                        isMulti
                                        name="fieldName"
                                        options={fieldNameOptions}
                                        className="basic-multi-select"
                                        classNamePrefix="select"
                                        value={fieldNameOptions.filter((option) => filters.fieldName.includes(option.value))}
                                        onChange={handleFilterChange}
                                    />
                                </div>

                                <div className="flex justify-between">
                                    <button
                                        onClick={clearFilters}
                                        className="px-4 py-2 bg-red-500 text-white rounded hover:bg-red-600"
                                    >
                                        Clear All
                                    </button>
                                    <button
                                        onClick={() => setIsFilterOpen(false)}
                                        className="px-4 py-2 bg-gray-500 text-white rounded hover:bg-gray-600"
                                    >
                                        Close
                                    </button>
                                </div>
                            </div>
                        </div>
                    )}

                    <div className="overflow-y-auto max-h-[calc(96vh*2)] bg-white shadow-md rounded-lg p-4">
                        {/* Dla dużych ekranów zachowaj układ tabeli */}
                        <div className="hidden md:block">
                            <table className="min-w-full table-auto">
                                <thead className="sticky top-0 bg-white z-10">
                                    <tr>
                                        <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                            Użytkownik
                                        </th>
                                        <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                            Audyt
                                        </th>
                                        <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                            Typ akcji
                                        </th>
                                        <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                            Szczegóły
                                        </th>
                                        <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                            Data i czas
                                        </th>
                                    </tr>
                                </thead>
                                <tbody className="bg-white divide-y divide-gray-200">
                                    {filteredLogs.map((log, index) => (
                                        <tr key={index} className="cursor-pointer hover:bg-gray-50" onClick={() => selectLog(log)}>
                                            <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                                                {log.user || 'Nieznany użytkownik'}
                                            </td>
                                            <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                                                {log.audit || 'Brak audytu'}
                                            </td>
                                            <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                                                {log.action_type}
                                            </td>
                                            <td className="px-6 py-4 text-sm text-gray-500">
                                                <div>
                                                    {log.field_name && <strong>{log.field_name}: </strong>}
                                                    {log.description}
                                                </div>
                                                {log.section_name && <div className="text-xs text-gray-400">Sekcja: {log.section_name}</div>}
                                                {log.group_name && <div className="text-xs text-gray-400">Grupa: {log.group_name}</div>}
                                                {log.question_text && (
                                                    <div className="text-xs text-gray-400 mt-1">
                                                        Pytanie: {log.question_text.length > 50 ? `${log.question_text.substring(0, 50)}...` : log.question_text}
                                                    </div>
                                                )}
                                            </td>
                                            <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                                                {formatTimestamp(log.timestamp)}
                                            </td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        </div>
                        {/* Dla małych ekranów użyj układu kart */}
                        <div className="md:hidden">
                            {filteredLogs.map((log, index) => (
                                <div key={index} className="border-b border-gray-200 py-4 cursor-pointer" onClick={() => selectLog(log)}>
                                    <div className="flex justify-between">
                                        <div className="text-sm font-medium text-gray-800">
                                            {log.user || 'Nieznany użytkownik'}
                                        </div>
                                        <div className="text-sm text-gray-500">
                                            {formatTimestamp(log.timestamp)}
                                        </div>
                                    </div>
                                    <div className="text-sm text-gray-500">
                                        {log.action_type} - {log.audit || 'Brak audytu'}
                                    </div>
                                    <div className="text-sm text-gray-600">{log.description}</div>
                                </div>
                            ))}
                        </div>
                    </div>
                </>
            ) : (
                <div className="bg-white shadow-md rounded-lg p-6">
                    <button
                        onClick={clearSelectedLog}
                        className="mb-6 px-4 py-2 bg-gray-500 text-white rounded hover:bg-gray-600"
                    >
                        ← Powrót do logów
                    </button>
                    <h2 className="text-2xl font-bold mb-6">Szczegóły Logu</h2>
                    <div className="grid grid-cols-1 sm:grid-cols-2 gap-x-6 gap-y-4">
                        <div>
                            <p className="text-gray-600 font-semibold">Użytkownik</p>
                            <p className="text-gray-800">{selectedLog.user || 'Nieznany użytkownik'}</p>
                        </div>
                        <div>
                            <p className="text-gray-600 font-semibold">Audyt</p>
                            <p className="text-gray-800">{selectedLog.audit || 'Brak audytu'}</p>
                        </div>
                        <div>
                            <p className="text-gray-600 font-semibold">Typ akcji</p>
                            <p className="text-gray-800">{selectedLog.action_type}</p>
                        </div>
                        <div>
                            <p className="text-gray-600 font-semibold">Typ celu</p>
                            <p className="text-gray-800">{selectedLog.target_type}</p>
                        </div>
                        <div>
                            <p className="text-gray-600 font-semibold">Nazwa pola</p>
                            <p className="text-gray-800">{selectedLog.field_name || 'Brak'}</p>
                        </div>
                        <div>
                            <p className="text-gray-600 font-semibold">Stara wartość</p>
                            <p className="text-gray-800">{selectedLog.old_value || 'Brak'}</p>
                        </div>
                        <div>
                            <p className="text-gray-600 font-semibold">Nowa wartość</p>
                            <p className="text-gray-800">{selectedLog.new_value || 'Brak'}</p>
                        </div>
                        <div>
                            <p className="text-gray-600 font-semibold">Data i czas</p>
                            <p className="text-gray-800">{formatTimestamp(selectedLog.timestamp)}</p>
                        </div>
                        <div className="sm:col-span-2">
                            <p className="text-gray-600 font-semibold">Opis</p>
                            <p className="text-gray-800">{selectedLog.description || 'Brak'}</p>
                        </div>
                        {selectedLog.section_name && (
                            <div>
                                <p className="text-gray-600 font-semibold">Sekcja</p>
                                <p className="text-gray-800">{selectedLog.section_name}</p>
                            </div>
                        )}
                        {selectedLog.subsection_name && (
                            <div>
                                <p className="text-gray-600 font-semibold">Podsekcja</p>
                                <p className="text-gray-800">{selectedLog.subsection_name}</p>
                            </div>
                        )}
                        {selectedLog.group_name && (
                            <div>
                                <p className="text-gray-600 font-semibold">Grupa</p>
                                <p className="text-gray-800">{selectedLog.group_name}</p>
                            </div>
                        )}
                        {selectedLog.question_text && (
                            <div className="sm:col-span-2">
                                <p className="text-gray-600 font-semibold">Treść pytania</p>
                                <p className="text-gray-800">{selectedLog.question_text}</p>
                            </div>
                        )}
                    </div>
                </div>
            )}
        </div>
    );
};

export default ActivityLogs;
