import React, { useState, useEffect, useRef } from 'react';
import { useAuth } from '../../context/AuthContext';

// Icons
import { ReactComponent as ChartHistogramIcon } from '../../utils/dashboard/chart-histogram.svg';
import photoSvg from '../../utils/svgs/photo-upload.svg';

import PreviewPopup from '../MainPage/PreviewPopup';
import UserElement from '../UserElement';
import MainSearchbar from '../MainSearchbar';

const NewAudit = ({
    auditTitle,
    setAuditTitle,
    city,
    setCity,
    street,
    setStreet,
    startDate,
    setStartDate,
    endDate,
    setEndDate,
    coverPhoto,
    setCoverPhoto,
    questionGroups,
    selectedCategory,
    setSelectedCategory,
    selectedGroups,
    setSelectedGroups,
    setSelectedUsers,
    handleSubmit,
    setCollapsedSections,
    users,
    selectedUsers,
    sectionCounts,
    setSectionCounts,
    setCreateObjectType,
    setCurrentAudit,
    allAudits,
}) => {
    const [audit, setAudit] = useState(null);
    const [isPreviewPopupVisible, setIsPreviewPopupVisible] = useState(false);
    const [isUserListVisible, setIsUserListVisible] = useState(false);

    const [showScrollbar, setShowScrollbar] = useState(false);
    const lastScrollPos = useRef(null);
    const [expandedSections, setExpandedSections] = useState({});
    const [expandedSubSections, setExpandedSubSections] = useState({});
    const [expandedGroups, setExpandedGroups] = useState({});
    const [selectAll, setSelectAll] = useState(false);
    const [isEditMode, setIsEditMode] = useState(false);
    const PreviewPopupRef = useRef();

    const [showNewQuestionInput, setShowNewQuestionInput] = useState({});
    const [newQuestions, setNewQuestions] = useState({});
    const [editing, setEditing] = useState({});

    const [newSectionName, setNewSectionName] = useState('');
    const [showNewSectionInput, setShowNewSectionInput] = useState(false);
    const [newSubSectionName, setNewSubSectionName] = useState({});
    const [showNewSubSectionInput, setShowNewSubSectionInput] = useState({});
    const [newGroupName, setNewGroupName] = useState({});
    const [showNewGroupInput, setShowNewGroupInput] = useState({});


    const [selectedObjectType, setSelectedObjectType] = useState(null);

    const backendUrl = process.env.REACT_APP_BACKEND_URL;
    const { authTokens } = useAuth();

    const formatDate = (dateString) => {
        const date = new Date(dateString);
        const day = String(date.getDate()).padStart(2, '0');
        const month = String(date.getMonth() + 1).padStart(2, '0');
        const year = date.getFullYear().toString().slice(-2);
        return `${day}/${month}/${year}`;
    };
    const [auditData, setAuditData] = useState({});

    useEffect(() => {
        const fetchAuditData = async () => {
            try {
                const response = await fetch(`${backendUrl}/audit-page/get-base-questions/`, {
                    method: 'GET',
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${authTokens.access}`
                    }
                });
                const data = await response.json();
                setAuditData(data.audit_object_types); // Zmiana tutaj, aby ustawić dane bezpośrednio
            } catch (error) {
                console.error('Error fetching audit data:', error);
            }
        };
        fetchAuditData();
    }, [backendUrl, authTokens]);

    console.log("auditData")
    console.log(auditData)
    useEffect(() => {
        let scrollTimeout;

        const hideScrollbarFunc = () => {
            setShowScrollbar(false);
        };

        const showScrollbarFunc = () => {
            setShowScrollbar(true);
            clearTimeout(scrollTimeout);
            scrollTimeout = setTimeout(hideScrollbarFunc, 3000);
        };

        const handleScroll = () => {
            if (lastScrollPos.current !== window.scrollY) {
                showScrollbarFunc();
                lastScrollPos.current = window.scrollY;
            }
        };

        const handleMouseMove = () => {
            showScrollbarFunc();
        };

        const handleTouchStart = () => {
            showScrollbarFunc();
        };

        window.addEventListener('scroll', handleScroll);
        window.addEventListener('mousemove', handleMouseMove);
        window.addEventListener('touchstart', handleTouchStart);

        return () => {
            window.removeEventListener('scroll', handleScroll);
            window.removeEventListener('mousemove', handleMouseMove);
            window.removeEventListener('touchstart', handleTouchStart);
            clearTimeout(scrollTimeout);
        };
    }, []);

    const handleGroupChange = (sectionId, subSectionId, groupId) => {
        const key = `${sectionId}_${subSectionId}_${groupId}`;
        setSelectedGroups(prev => {
            const updated = { ...prev, [key]: !prev[key] };
            console.log('Selected Groups:', updated);
            return updated;
        });
    };






    const handleSubSectionSelectionChange = (sectionId, subSectionId) => {
        const subSection = selectedObjectType.sections
            .find(section => section.id === sectionId).sub_sections
            .find(subSection => subSection.id === subSectionId);

        const isEveryGroupSelected = subSection.groups.every(group => selectedGroups[`${sectionId}_${subSectionId}_${group.id}`]);

        const updatedSelectedGroups = { ...selectedGroups };

        subSection.groups.forEach(group => {
            updatedSelectedGroups[`${sectionId}_${subSectionId}_${group.id}`] = !isEveryGroupSelected;
        });

        setSelectedGroups(updatedSelectedGroups);
        console.log('Selected Groups:', updatedSelectedGroups);
    };

    const handleSectionSelectionChange = (sectionId) => {
        const section = selectedObjectType.sections.find(section => section.id === sectionId);

        const isEveryGroupSelected = section.groups.every(group => selectedGroups[`${sectionId}_null_${group.id}`])
            && section.sub_sections.every(subSection => subSection.groups.every(group => selectedGroups[`${sectionId}_${subSection.id}_${group.id}`]));

        const updatedSelectedGroups = { ...selectedGroups };

        section.groups.forEach(group => {
            updatedSelectedGroups[`${sectionId}_null_${group.id}`] = !isEveryGroupSelected;
        });

        section.sub_sections.forEach(subSection => {
            subSection.groups.forEach(group => {
                updatedSelectedGroups[`${sectionId}_${subSection.id}_${group.id}`] = !isEveryGroupSelected;
            });
        });

        setSelectedGroups(updatedSelectedGroups);
        console.log('Selected Groups:', updatedSelectedGroups);
    };




    const increaseSectionCount = (section, event) => {
        event.preventDefault();
        setSectionCounts(prevCounts => ({
            ...prevCounts,
            [section]: (prevCounts[section] || 1) + 1
        }));
    };

    const decreaseSectionCount = (section, event) => {
        event.preventDefault();
        setSectionCounts(prevCounts => ({
            ...prevCounts,
            [section]: Math.max((prevCounts[section] || 1) - 1, 1)
        }));
    };

    const toggleSectionCollapse = (sectionName) => {
        setExpandedSections(prevState => ({ ...prevState, [sectionName]: !prevState[sectionName] }));
    };

    const toggleGroupCollapse = (sectionName, subSectionName, groupName) => {
        const key = `${sectionName}_${subSectionName}_${groupName}`;
        setExpandedGroups(prevState => ({ ...prevState, [key]: !prevState[key] }));
    };

    const toggleSubSectionCollapse = (sectionName, subSectionName) => {
        const key = `${sectionName}_${subSectionName}`;
        setExpandedSubSections(prevState => ({ ...prevState, [key]: !prevState[key] }));
    };

    const getFill = (groupName) => {
        return groupName === selectedObjectType?.name ? '#fff' : '#000';
    };

    const handleUserSelection = (userId) => {
        setSelectedUsers((prevSelectedUsers) => {
            const userIndex = prevSelectedUsers.indexOf(userId);
            if (userIndex !== -1) {
                return [...prevSelectedUsers.slice(0, userIndex), ...prevSelectedUsers.slice(userIndex + 1)];
            } else {
                return [...prevSelectedUsers, userId];
            }
        });
    };

    useEffect(() => {
        if (users.length > 0 && !Array.isArray(selectedUsers)) {
            setSelectedUsers([users[0].id]);
        }
    }, [users]);

    const handleSelectAllSections = () => {
        if (selectAll) {
            setSelectedGroups({});
            setSelectAll(false);
        } else {
            const selectedAll = {};
            if (selectedObjectType) {
                selectedObjectType.sections.forEach(section => {
                    section.sub_sections.forEach(subSection => {
                        subSection.groups.forEach(group => {
                            selectedAll[`${section.id}_${subSection.id}_${group.id}`] = true;
                        });
                    });
                    section.groups.forEach(group => {
                        selectedAll[`${section.id}_null_${group.id}`] = true;
                    });
                });
            }
            setSelectedGroups(selectedAll);
            setSelectAll(true);
        }
    };




    const [searchTerm, setSearchTerm] = useState('');
    const [searchResults, setSearchResults] = useState([]);
    const [isFocused, setIsFocused] = useState(false);

    useEffect(() => {
        if (searchTerm === '') {
            setSearchResults([]);
        } else {
            const results = allAudits.filter(audit =>
                audit.audit_title.toLowerCase().includes(searchTerm.toLowerCase()) ||
                formatDate(audit.start_date).includes(searchTerm.toLowerCase())
            );
            setSearchResults(results);
        }
    }, [searchTerm, allAudits]);

    const openPreviewPopup = (audit) => {
        setCurrentAudit(audit.audit_id);
        setAudit(audit);
        setIsPreviewPopupVisible(true);
    };

    const handleSelectClick = (event, audit) => {
        event.stopPropagation();

        if (event.target.tagName.toLowerCase() === 'select') {
            return;
        }

        openPreviewPopup(audit);
    };

    const handleAddQuestion = (section, subSection, group) => {
        const subSectionName = subSection ? subSection.name : 'Brak podsekcji';
        const groupKey = `${section.name}_${subSectionName}_${group.id}`;

        console.log('handleAddQuestion - group:', group); // Debugging
        console.log('handleAddQuestion - section:', section); // Debugging
        console.log('handleAddQuestion - subSection:', subSection); // Debugging

        setShowNewQuestionInput(prev => ({
            ...prev,
            [groupKey]: true
        }));
    };




    const handleNewQuestionChange = (section, subSection, group, value) => {
        setNewQuestions(prev => ({
            ...prev,
            [`${section}_${subSection}_${group}`]: value
        }));
    };

    const handleSaveQuestion = async (section, subSection, group) => {
        const subSectionName = subSection ? subSection.name : 'Brak podsekcji';
        const groupKey = `${section.name}_${subSectionName}_${group.id}`;
        const newQuestion = newQuestions[groupKey];

        if (newQuestion) {
            try {
                const response = await fetch(`${backendUrl}/audit-page/create-audit-question/`, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${authTokens.access}`
                    },
                    body: JSON.stringify({
                        group_id: group.id,
                        question_text: newQuestion
                    })
                });

                const data = await response.json();

                // Update the state with the new question
                setAuditData(prevAuditData => {
                    return prevAuditData.map(auditObjectType => {
                        return {
                            ...auditObjectType,
                            sections: auditObjectType.sections.map(sec => {
                                if (sec.id === section.id) {
                                    return {
                                        ...sec,
                                        groups: sec.groups.map(grp => {
                                            if (grp.id === group.id) {
                                                return {
                                                    ...grp,
                                                    questions: [...grp.questions, { id: data.question_id, text: data.question_text }]
                                                };
                                            }
                                            return grp;
                                        }),
                                        sub_sections: sec.sub_sections.map(subSec => {
                                            if (subSec.id === subSection?.id) {
                                                return {
                                                    ...subSec,
                                                    groups: subSec.groups.map(grp => {
                                                        if (grp.id === group.id) {
                                                            return {
                                                                ...grp,
                                                                questions: [...grp.questions, { id: data.question_id, text: data.question_text }]
                                                            };
                                                        }
                                                        return grp;
                                                    })
                                                };
                                            }
                                            return subSec;
                                        })
                                    };
                                }
                                return sec;
                            })
                        };
                    });
                });

                // Update the selectedObjectType state with the new question
                setSelectedObjectType(prevSelectedObjectType => {
                    return {
                        ...prevSelectedObjectType,
                        sections: prevSelectedObjectType.sections.map(sec => {
                            if (sec.id === section.id) {
                                return {
                                    ...sec,
                                    groups: sec.groups.map(grp => {
                                        if (grp.id === group.id) {
                                            return {
                                                ...grp,
                                                questions: [...grp.questions, { id: data.question_id, text: data.question_text }]
                                            };
                                        }
                                        return grp;
                                    }),
                                    sub_sections: sec.sub_sections.map(subSec => {
                                        if (subSec.id === subSection?.id) {
                                            return {
                                                ...subSec,
                                                groups: subSec.groups.map(grp => {
                                                    if (grp.id === group.id) {
                                                        return {
                                                            ...grp,
                                                            questions: [...grp.questions, { id: data.question_id, text: data.question_text }]
                                                        };
                                                    }
                                                    return grp;
                                                })
                                            };
                                        }
                                        return subSec;
                                    })
                                };
                            }
                            return sec;
                        })
                    };
                });

                // Clear the input and hide it
                setNewQuestions(prev => ({
                    ...prev,
                    [groupKey]: ''
                }));
                setShowNewQuestionInput(prev => ({
                    ...prev,
                    [groupKey]: false
                }));
            } catch (error) {
                console.error('Error saving question:', error);
            }
        }
    };









    const handleEditChange = (key, value) => {
        setEditing(prev => ({
            ...prev,
            [key]: value
        }));

        const [type, id] = key.split('_');
        console.log(`handleEditChange: type=${type}, id=${id}, value=${value}`);

        // Aktualizacja selectedObjectType bezpośrednio w handleEditChange
        setSelectedObjectType(prevSelectedObjectType => {
            return {
                ...prevSelectedObjectType,
                sections: prevSelectedObjectType.sections.map(sec => {
                    if (type === 'section' && sec.id === parseInt(id, 10)) {
                        return { ...sec, name: value };
                    }
                    if (type === 'subSection') {
                        return {
                            ...sec,
                            sub_sections: sec.sub_sections.map(subSec => {
                                if (subSec.id === parseInt(id, 10)) {
                                    return { ...subSec, name: value };
                                }
                                return subSec;
                            })
                        };
                    }
                    if (type === 'group') {
                        return {
                            ...sec,
                            groups: sec.groups.map(grp => {
                                if (grp.id === parseInt(id, 10)) {
                                    return { ...grp, name: value };
                                }
                                return grp;
                            }),
                            sub_sections: sec.sub_sections.map(subSec => {
                                return {
                                    ...subSec,
                                    groups: subSec.groups.map(grp => {
                                        if (grp.id === parseInt(id, 10)) {
                                            return { ...grp, name: value };
                                        }
                                        return grp;
                                    })
                                };
                            })
                        };
                    }
                    if (type === 'question') {
                        return {
                            ...sec,
                            groups: sec.groups.map(grp => {
                                return {
                                    ...grp,
                                    questions: grp.questions.map(q => {
                                        if (q.id === parseInt(id, 10)) {
                                            return { ...q, text: value };
                                        }
                                        return q;
                                    })
                                };
                            }),
                            sub_sections: sec.sub_sections.map(subSec => {
                                return {
                                    ...subSec,
                                    groups: subSec.groups.map(grp => {
                                        return {
                                            ...grp,
                                            questions: grp.questions.map(q => {
                                                if (q.id === parseInt(id, 10)) {
                                                    return { ...q, text: value };
                                                }
                                                return q;
                                            })
                                        };
                                    })
                                };
                            })
                        };
                    }
                    return sec;
                })
            };
        });
    };





    // const handleBlur = (key, originalValue) => {
    //     const [type, id] = key.split('_');
    //     handleEdit(type, id, editing[key] || originalValue);
    //     setEditing(prev => {
    //         const updated = { ...prev };
    //         delete updated[key];
    //         return updated;
    //     });
    // };
    const handleBlur = (key, originalValue) => {
        const currentValue = editing[key]?.trim();

        if (currentValue === '') {
            // Jeśli aktualna wartość jest pusta, usuwamy pytanie
            const [type, id] = key.split('_');
            handleDelete(type, parseInt(id, 10));
        } else {
            // W przeciwnym razie, zapisujemy zmiany
            const [type, id] = key.split('_');
            handleEdit(type, parseInt(id, 10), currentValue || originalValue);
        }

        // Usunięcie elementu z `editing` po zakończeniu edycji
        setEditing((prevEditing) => {
            const newEditing = { ...prevEditing };
            delete newEditing[key];
            return newEditing;
        });
    };

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (!Object.keys(editing).length) return;

            const inputKeys = Object.keys(editing);
            inputKeys.forEach((key) => {
                const element = document.getElementById(key);
                if (element && !element.contains(event.target)) {
                    handleBlur(key, element.getAttribute('data-original-value'));
                }
            });
        };

        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [editing]);



    const handleDelete = async (type, id) => {
        let url = '';
        switch (type) {
            case 'question':
                url = `${backendUrl}/audit-page/delete-audit-question/${id}/`;
                break;
            case 'group':
                url = `${backendUrl}/audit-page/delete-base-group/${id}/`;
                break;
            case 'sub_section':
                url = `${backendUrl}/audit-page/delete-base-subsection/${id}/`;
                break;
            case 'section':
                url = `${backendUrl}/audit-page/delete-base-section/${id}/`;
                break;
            default:
                return;
        }

        try {
            const response = await fetch(url, {
                method: 'DELETE',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${authTokens.access}`
                }
            });

            if (response.ok) {
                // Update the state with the element removed
                setAuditData(prevAuditData => {
                    return prevAuditData.map(auditObjectType => {
                        switch (type) {
                            case 'question':
                                return {
                                    ...auditObjectType,
                                    sections: auditObjectType.sections.map(sec => ({
                                        ...sec,
                                        groups: sec.groups.map(grp => ({
                                            ...grp,
                                            questions: grp.questions.filter(q => q.id !== id)
                                        })),
                                        sub_sections: sec.sub_sections.map(subSec => ({
                                            ...subSec,
                                            groups: subSec.groups.map(grp => ({
                                                ...grp,
                                                questions: grp.questions.filter(q => q.id !== id)
                                            }))
                                        }))
                                    }))
                                };
                            case 'group':
                                return {
                                    ...auditObjectType,
                                    sections: auditObjectType.sections.map(sec => ({
                                        ...sec,
                                        groups: sec.groups.filter(grp => grp.id !== id),
                                        sub_sections: sec.sub_sections.map(subSec => ({
                                            ...subSec,
                                            groups: subSec.groups.filter(grp => grp.id !== id)
                                        }))
                                    }))
                                };
                            case 'sub_section':
                                return {
                                    ...auditObjectType,
                                    sections: auditObjectType.sections.map(sec => ({
                                        ...sec,
                                        sub_sections: sec.sub_sections.filter(subSec => subSec.id !== id)
                                    }))
                                };
                            case 'section':
                                return {
                                    ...auditObjectType,
                                    sections: auditObjectType.sections.filter(sec => sec.id !== id)
                                };
                            default:
                                return auditObjectType;
                        }
                    });
                });

                // Update the selectedObjectType state with the element removed
                setSelectedObjectType(prevSelectedObjectType => {
                    switch (type) {
                        case 'question':
                            return {
                                ...prevSelectedObjectType,
                                sections: prevSelectedObjectType.sections.map(sec => ({
                                    ...sec,
                                    groups: sec.groups.map(grp => ({
                                        ...grp,
                                        questions: grp.questions.filter(q => q.id !== id)
                                    })),
                                    sub_sections: sec.sub_sections.map(subSec => ({
                                        ...subSec,
                                        groups: subSec.groups.map(grp => ({
                                            ...grp,
                                            questions: grp.questions.filter(q => q.id !== id)
                                        }))
                                    }))
                                }))
                            };
                        case 'group':
                            return {
                                ...prevSelectedObjectType,
                                sections: prevSelectedObjectType.sections.map(sec => ({
                                    ...sec,
                                    groups: sec.groups.filter(grp => grp.id !== id),
                                    sub_sections: sec.sub_sections.map(subSec => ({
                                        ...subSec,
                                        groups: subSec.groups.filter(grp => grp.id !== id)
                                    }))
                                }))
                            };
                        case 'sub_section':
                            return {
                                ...prevSelectedObjectType,
                                sections: prevSelectedObjectType.sections.map(sec => ({
                                    ...sec,
                                    sub_sections: sec.sub_sections.filter(subSec => subSec.id !== id)
                                }))
                            };
                        case 'section':
                            return {
                                ...prevSelectedObjectType,
                                sections: prevSelectedObjectType.sections.filter(sec => sec.id !== id)
                            };
                        default:
                            return prevSelectedObjectType;
                    }
                });

                // Update the editing state to remove the deleted question
                setEditing(prevEditing => {
                    const newEditing = { ...prevEditing };
                    delete newEditing[`question_${id}`];
                    return newEditing;
                });
                console.log(`Deleted ${type} with id: ${id}`);
            } else {
                console.error(`Failed to delete ${type}`);
            }
        } catch (error) {
            console.error(`Error deleting ${type}:`, error);
        }
    };


    const handleAdd = (type, parentKey) => {
        let url = '';
        let data = {};
        let sectionId = '';
        let subSectionId = '';

        if (type === 'section') {
            url = `${backendUrl}/audit-page/create-base-section/`;
            data = { audit_object_type_id: selectedObjectType.id };
        } else if (type === 'subSection') {
            url = `${backendUrl}/audit-page/create-base-subsection/`;
            data = { section_id: parentKey };
        } else if (type === 'group') {
            if (String(parentKey).includes('_')) {
                [sectionId, subSectionId] = String(parentKey).split('_');
                url = `${backendUrl}/audit-page/create-base-group/`;
                data = { section_id: sectionId, sub_section_id: subSectionId };
            } else {
                sectionId = parentKey;
                url = `${backendUrl}/audit-page/create-base-group/`;
                data = { section_id: sectionId };
            }
        }

        fetch(url, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${authTokens.access}`
            },
            body: JSON.stringify(data)
        })
            .then(response => response.json())
            .then(data => {
                if (type === 'section') {
                    setSelectedObjectType(prevSelectedObjectType => ({
                        ...prevSelectedObjectType,
                        sections: [...prevSelectedObjectType.sections, { name: data.section, id: data.section_id, groups: [], sub_sections: [] }]
                    }));
                    setAuditData(prevAuditData => prevAuditData.map(auditObjectType => {
                        if (auditObjectType.id === selectedObjectType.id) {
                            return {
                                ...auditObjectType,
                                sections: [...auditObjectType.sections, { name: data.section, id: data.section_id, groups: [], sub_sections: [] }]
                            };
                        }
                        return auditObjectType;
                    }));
                } else if (type === 'subSection') {
                    setSelectedObjectType(prevSelectedObjectType => ({
                        ...prevSelectedObjectType,
                        sections: prevSelectedObjectType.sections.map(sec => {
                            if (sec.id === parseInt(parentKey, 10)) {
                                return {
                                    ...sec,
                                    sub_sections: [...sec.sub_sections, { name: data.sub_section, id: data.sub_section_id, groups: [] }]
                                };
                            }
                            return sec;
                        })
                    }));
                    setAuditData(prevAuditData => prevAuditData.map(auditObjectType => ({
                        ...auditObjectType,
                        sections: auditObjectType.sections.map(sec => {
                            if (sec.id === parseInt(parentKey, 10)) {
                                return {
                                    ...sec,
                                    sub_sections: [...sec.sub_sections, { name: data.sub_section, id: data.sub_section_id, groups: [] }]
                                };
                            }
                            return sec;
                        })
                    })));
                } else if (type === 'group') {
                    if (subSectionId) {
                        setSelectedObjectType(prevSelectedObjectType => ({
                            ...prevSelectedObjectType,
                            sections: prevSelectedObjectType.sections.map(sec => {
                                if (sec.id === parseInt(sectionId, 10)) {
                                    return {
                                        ...sec,
                                        sub_sections: sec.sub_sections.map(subSec => {
                                            if (subSec.id === parseInt(subSectionId, 10)) {
                                                return {
                                                    ...subSec,
                                                    groups: [...subSec.groups, { name: data.group, id: data.group_id, questions: [] }]
                                                };
                                            }
                                            return subSec;
                                        })
                                    };
                                }
                                return sec;
                            })
                        }));
                        setAuditData(prevAuditData => prevAuditData.map(auditObjectType => ({
                            ...auditObjectType,
                            sections: auditObjectType.sections.map(sec => {
                                if (sec.id === parseInt(sectionId, 10)) {
                                    return {
                                        ...sec,
                                        sub_sections: sec.sub_sections.map(subSec => {
                                            if (subSec.id === parseInt(subSectionId, 10)) {
                                                return {
                                                    ...subSec,
                                                    groups: [...subSec.groups, { name: data.group, id: data.group_id, questions: [] }]
                                                };
                                            }
                                            return subSec;
                                        })
                                    };
                                }
                                return sec;
                            })
                        })));
                    } else {
                        setSelectedObjectType(prevSelectedObjectType => ({
                            ...prevSelectedObjectType,
                            sections: prevSelectedObjectType.sections.map(sec => {
                                if (sec.id === parseInt(sectionId, 10)) {
                                    return {
                                        ...sec,
                                        groups: [...sec.groups, { name: data.group, id: data.group_id, questions: [] }]
                                    };
                                }
                                return sec;
                            })
                        }));
                        setAuditData(prevAuditData => prevAuditData.map(auditObjectType => ({
                            ...auditObjectType,
                            sections: auditObjectType.sections.map(sec => {
                                if (sec.id === parseInt(sectionId, 10)) {
                                    return {
                                        ...sec,
                                        groups: [...sec.groups, { name: data.group, id: data.group_id, questions: [] }]
                                    };
                                }
                                return sec;
                            })
                        })));
                    }
                }
            })
            .catch(error => console.error('Error:', error));
    };






    const handleSaveSection = () => {
        if (newSectionName) {
            // Logic for saving a new section (e.g., sending to the backend)
            console.log(`Saving new section: ${newSectionName}`);

            // Reset state
            setNewSectionName('');
            setShowNewSectionInput(false);
        }
    };

    const handleSaveSubSection = (section) => {
        if (newSubSectionName[section]) {
            console.log(`Saving new subSection in ${section}: ${newSubSectionName[section]}`);

            setNewSubSectionName(prev => ({
                ...prev,
                [section]: ''
            }));
            setShowNewSubSectionInput(prev => ({
                ...prev,
                [section]: false
            }));
        }
    };

    const handleSaveGroup = (section, subSection) => {
        const key = `${section}_${subSection}`;
        if (newGroupName[key]) {
            console.log(`Saving new group in ${section} - ${subSection}: ${newGroupName[key]}`);

            setNewGroupName(prev => ({
                ...prev,
                [key]: ''
            }));
            setShowNewGroupInput(prev => ({
                ...prev,
                [key]: false
            }));
        }
    };

    const getTotalQuestions = (sections) => {
        let totalQuestions = 0;
        sections.forEach(section => {
            section.groups.forEach(group => {
                totalQuestions += group.questions.length;
            });
            section.sub_sections.forEach(subSection => {
                subSection.groups.forEach(group => {
                    totalQuestions += group.questions.length;
                });
            });
        });
        return totalQuestions;
    };


    const handleEdit = async (type, id, newName) => {
        console.log('handleEdit called with', type, id, newName);
        let url = '';
        let payload = {};

        switch (type) {
            case 'section':
                url = `${backendUrl}/audit-page/update-base-section/${id}/`;
                payload = { name: newName };
                break;
            case 'subSection':
                url = `${backendUrl}/audit-page/update-base-subsection/${id}/`;
                payload = { name: newName };
                break;
            case 'group':
                url = `${backendUrl}/audit-page/update-base-group/${id}/`;
                payload = { name: newName };
                break;
            case 'question':
                url = `${backendUrl}/audit-page/update-audit-question/${id}/`;
                payload = { question_text: newName };
                break;
            default:
                console.error('Unknown edit type:', type);
                return;
        }

        try {
            const response = await fetch(url, {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${authTokens.access}`
                },
                body: JSON.stringify(payload)
            });

            if (response.ok) {
                const data = await response.json();
                console.log('API response data:', data);

                // Upewnijmy się, że aktualizujemy stan bezpośrednio
                setAuditData(prevAuditData => {
                    console.log('Updating auditData state');
                    return prevAuditData.map(auditObjectType => {
                        return {
                            ...auditObjectType,
                            sections: auditObjectType.sections.map(sec => {
                                if (type === 'section' && sec.id === id) {
                                    return { ...sec, name: newName };
                                }
                                if (type === 'subSection') {
                                    return {
                                        ...sec,
                                        sub_sections: sec.sub_sections.map(subSec => {
                                            if (subSec.id === id) {
                                                return { ...subSec, name: newName };
                                            }
                                            return subSec;
                                        })
                                    };
                                }
                                if (type === 'group') {
                                    return {
                                        ...sec,
                                        groups: sec.groups.map(grp => {
                                            if (grp.id === id) {
                                                return { ...grp, name: newName };
                                            }
                                            return grp;
                                        }),
                                        sub_sections: sec.sub_sections.map(subSec => {
                                            return {
                                                ...subSec,
                                                groups: subSec.groups.map(grp => {
                                                    if (grp.id === id) {
                                                        return { ...grp, name: newName };
                                                    }
                                                    return grp;
                                                })
                                            };
                                        })
                                    };
                                }
                                if (type === 'question') {
                                    return {
                                        ...sec,
                                        groups: sec.groups.map(grp => {
                                            return {
                                                ...grp,
                                                questions: grp.questions.map(q => {
                                                    if (q.id === id) {
                                                        return { ...q, text: newName };
                                                    }
                                                    return q;
                                                })
                                            };
                                        }),
                                        sub_sections: sec.sub_sections.map(subSec => {
                                            return {
                                                ...subSec,
                                                groups: subSec.groups.map(grp => {
                                                    return {
                                                        ...grp,
                                                        questions: grp.questions.map(q => {
                                                            if (q.id === id) {
                                                                return { ...q, text: newName };
                                                            }
                                                            return q;
                                                        })
                                                    };
                                                })
                                            };
                                        })
                                    };
                                }
                                return sec;
                            })
                        };
                    });
                });

                setSelectedObjectType(prevSelectedObjectType => {
                    console.log('Updating selectedObjectType state');
                    return {
                        ...prevSelectedObjectType,
                        sections: prevSelectedObjectType.sections.map(sec => {
                            if (type === 'section' && sec.id === id) {
                                return { ...sec, name: newName };
                            }
                            if (type === 'subSection') {
                                return {
                                    ...sec,
                                    sub_sections: sec.sub_sections.map(subSec => {
                                        if (subSec.id === id) {
                                            return { ...subSec, name: newName };
                                        }
                                        return subSec;
                                    })
                                };
                            }
                            if (type === 'group') {
                                return {
                                    ...sec,
                                    groups: sec.groups.map(grp => {
                                        if (grp.id === id) {
                                            return { ...grp, name: newName };
                                        }
                                        return grp;
                                    }),
                                    sub_sections: sec.sub_sections.map(subSec => {
                                        return {
                                            ...subSec,
                                            groups: subSec.groups.map(grp => {
                                                if (grp.id === id) {
                                                    return { ...grp, name: newName };
                                                }
                                                return grp;
                                            })
                                        };
                                    })
                                };
                            }
                            if (type === 'question') {
                                return {
                                    ...sec,
                                    groups: sec.groups.map(grp => {
                                        return {
                                            ...grp,
                                            questions: grp.questions.map(q => {
                                                if (q.id === id) {
                                                    return { ...q, text: newName };
                                                }
                                                return q;
                                            })
                                        };
                                    }),
                                    sub_sections: sec.sub_sections.map(subSec => {
                                        return {
                                            ...subSec,
                                            groups: subSec.groups.map(grp => {
                                                return {
                                                    ...grp,
                                                    questions: grp.questions.map(q => {
                                                        if (q.id === id) {
                                                            return { ...q, text: newName };
                                                        }
                                                        return q;
                                                    })
                                                };
                                            })
                                        };
                                    })
                                };
                            }
                            return sec;
                        })
                    };
                });
            }
        } catch (error) {
            console.error('Error editing', type, ':', error);
        }
    };


    return (
        <>
            <div className={`lg:w-full w-full px-2 lg:ml-6 pt-8 overflow-auto lg:max-h-screen  ${showScrollbar ? '' : 'hide-scrollbar'}`} id="scrollContainer">
                {isPreviewPopupVisible && (
                    <PreviewPopup
                        audit={audit}
                        PreviewPopupRef={PreviewPopupRef}
                        setIsPreviewPopupVisible={setIsPreviewPopupVisible}
                    />
                )}
                <div className="w-full flex items-center justify-between">
                    <h1 className="text-4xl font-bold text-black">New audit</h1>
                    <div className='flex mr-3'>
                        <div className='hidden lg:block w-80 mx-12 relative'>
                            <MainSearchbar allAudits={allAudits}/>
                        </div>
                        <UserElement />
                    </div>
                </div>


                <p className="text-2xl font-semibold text-gray-400 mt-6 mb-4">Choose place type:</p>

                <div className="flex flex-wrap gap-4 mt-2 ">
                    {auditData && auditData.length > 0 ? (
                        auditData.map((auditObjectType) => (
                            <div
                                key={auditObjectType.id}
                                onClick={(event) => {
                                    event.preventDefault();
                                    setSelectedObjectType(auditObjectType);
                                }}
                                className={`w-full sm:w-64 rounded-md py-2 px-4 cursor-pointer font-semibold ${selectedObjectType && auditObjectType.name === selectedObjectType.name ? 'bg-zinc-800 text-white ' : 'bg-gray-200 text-black'}`}
                            >
                                <h2 className="text-4xl flex items-center ">{auditObjectType.name} <ChartHistogramIcon fill={getFill(auditObjectType.name)} className="w-6 h-6 ml-auto mr-3 mt-2" /></h2>
                                <p className="text-lg mt-2">{getTotalQuestions(auditObjectType.sections)} questions</p>
                            </div>
                        ))
                    ) : (
                        <p className="text-lg">No audit object types available.</p>
                    )}
                </div>

                {!isEditMode && (
                    <div className='max-w-4xl'>
                        <h3 className="mt-12 text-2xl font-semibold text-gray-800">Add General Info:</h3>

                        {/* Pierwszy rząd: Nazwa, Miasto, Ulica */}
                        <div className="flex flex-col gap-4 mt-4 sm:flex-row">
                            {/* Nazwa */}
                            <input
                                className="w-full sm:w-1/3 p-2 h-10 font-medium border border-gray-300  placeholder-gray-500 bg-white rounded-lg shadow-sm  focus:outline-none focus:border-gray-400 hover:border-gray-400 transition duration-200"
                                type='text'
                                name='auditTitle'
                                id='auditTitle'
                                value={auditTitle}
                                onChange={(e) => setAuditTitle(e.target.value)}
                                placeholder="Place name"
                            />
                            {/* Miasto */}
                            <input
                                className="w-full sm:w-1/3 p-2 h-10 font-medium border border-gray-300 placeholder-gray-500 bg-white rounded-lg shadow-sm focus:outline-none focus:border-gray-400 hover:border-gray-400 transition duration-200"
                                type='text'
                                name='city'
                                id='city'
                                value={city}
                                onChange={(e) => setCity(e.target.value)}
                                placeholder="Place city"
                            />
                            {/* Ulica */}
                            <input
                                className="w-full sm:w-1/3 p-2 h-10 font-medium border border-gray-300 placeholder-gray-500 bg-white rounded-lg shadow-sm focus:outline-none focus:border-gray-400 hover:border-gray-400 transition duration-200"
                                type='text'
                                name='street'
                                id='street'
                                value={street}
                                onChange={(e) => setStreet(e.target.value)}
                                placeholder="Place street"
                            />
                        </div>

                        {/* Drugi rząd: Start date, End date, Wybór użytkownika + Zdjęcie */}
                        <div className="flex flex-col gap-4 mt-4 sm:flex-row">
                            {/* Start Date */}
                            <input
                                className="w-full sm:w-1/3 p-2 h-10 font-medium border border-gray-300 bg-white rounded-lg shadow-sm focus:outline-none focus:border-gray-400  placeholder-gray-500 hover:border-gray-400 transition duration-200"
                                type="text"
                                name="startDate"
                                id="startDate"
                                value={startDate}
                                onFocus={(e) => (e.target.type = 'date')}
                                onBlur={(e) => (e.target.type = 'text')}
                                onChange={(e) => setStartDate(e.target.value)}
                                placeholder="Start date"
                            />
                            {/* End Date */}
                            <input
                                className="w-full sm:w-1/3 p-2 h-10 font-medium border border-gray-300 bg-white rounded-lg shadow-sm focus:outline-none focus:border-gray-400 hover:border-gray-400 transition duration-200 placeholder-gray-500"
                                type="text"
                                name="endDate"
                                id="endDate"
                                value={endDate}
                                onFocus={(e) => (e.target.type = 'date')}
                                onBlur={(e) => (e.target.type = 'text')}
                                onChange={(e) => setEndDate(e.target.value)}
                                placeholder="End date"
                            />
                            {/* Wybór użytkownika i Zdjęcie */}
                            <div className="w-full sm:w-1/3 flex gap-4 items-center">
                                {/* Wybór użytkownika */}
                                <div className="w-5/6 relative">
                                    <div
                                        className="w-full p-2 h-10 font-medium border border-gray-300 bg-white text-gray-600 rounded-lg shadow-sm focus:outline-none cursor-pointer hover:border-gray-400 transition duration-200 "
                                        onClick={() => setIsUserListVisible(!isUserListVisible)}
                                    >
                                        Select users
                                    </div>
                                    {isUserListVisible && (
                                        <div className="absolute z-10 w-full bg-white border border-gray-300 rounded-lg mt-1 shadow-lg max-h-40 overflow-y-auto">
                                            {users.map(user => (
                                                <div key={user.id} className="flex items-center p-2">
                                                    <input
                                                        type="checkbox"
                                                        id={`user-${user.id}`}
                                                        checked={selectedUsers.includes(user.id)}
                                                        onChange={() => handleUserSelection(user.id)}
                                                        className="w-4 h-4 text-blue-600 border-gray-300 focus:ring-blue-500"
                                                    />
                                                    <label htmlFor={`user-${user.id}`} className="ml-2 text-sm text-gray-700">
                                                        {user.username}
                                                    </label>
                                                </div>
                                            ))}
                                        </div>
                                    )}
                                </div>
                                {/* Dodawanie zdjęcia */}
                                <div className="w-1/6 flex items-center justify-center">
                                    <div className={`flex items-center justify-center w-10 h-10 rounded-lg border  transition duration-200 ${coverPhoto ? 'border-green-400' : 'border-gray-300 hover:border-gray-400'}`} >
                                        <label htmlFor="coverPhoto" className="flex items-center justify-center w-full h-full">
                                            <img src={photoSvg} className="w-6 h-6 cursor-pointer" alt="Upload" />
                                        </label>
                                        <input
                                            type="file"
                                            name="coverPhoto"
                                            id="coverPhoto"
                                            onChange={(e) => setCoverPhoto(e.target.files[0])}
                                            className="hidden"
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>


                )}

                <div className='w-full mt-32 sm:mt-6'>
                    <label className="inline-flex items-center cursor-pointer mb-12">
                        <input
                            type="checkbox"
                            checked={isEditMode}
                            onChange={() => setIsEditMode(!isEditMode)}
                            className="sr-only peer"
                        />
                        <div className="relative w-11 h-6 ml-2 bg-gray-200 rounded-full peer dark:bg-gray-700 peer-checked:after:translate-x-full rtl:peer-checked:after:-translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:start-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-gray-600 peer-checked:bg-green-600"></div>
                        <span className="ms-3 text-sm font-medium text-gray-900 dark:text-black">Edit Mode</span>
                    </label>

                    <h3 className='text-2xl font-semibold text-gray-400'>
                        {!isEditMode ? 'Choose sections:' : 'Edit Sections'}
                    </h3>
                    {!isEditMode && (
                        <button onClick={handleSelectAllSections} className='text-gray-500 hover:text-gray-700 font-medium w-max'>
                            {selectAll ? 'Deselect All' : 'Select All'}
                        </button>
                    )}




                    <div className='flex justify-between mt-4'>
                        <p className='mt-1 text-xl font-semibold text-gray-400 w-2/6'>Section Name</p>
                        {(!isEditMode && <p className='mt-1 text-xl font-semibold text-center text-gray-400 lg:w-1/6'>Sections number</p>)}

                    </div>


                    {selectedObjectType && selectedObjectType.sections.map((section) => (
                        <div key={section.id} className='my-2'>
                            <div
                                className='flex items-center bg-slate-200 sm:p-3 p-2 rounded-xl cursor-pointer'
                                onClick={(e) => { if (!e.target.closest('.no-toggle')) toggleSectionCollapse(section.name) }}
                            >
                                <div className='flex w-full'>
                                    {!isEditMode && (
                                        <input
                                            type='checkbox'
                                            id={`section-${section.id}`}
                                            checked={
                                                section.sub_sections.every(subSection =>
                                                    subSection.groups.every(group =>
                                                        selectedGroups[`${section.id}_${subSection.id}_${group.id}`]
                                                    )
                                                ) && section.groups.every(group =>
                                                    selectedGroups[`${section.id}_null_${group.id}`]
                                                )
                                            }
                                            onChange={() => handleSectionSelectionChange(section.id)}
                                            onClick={(event) => event.stopPropagation()}
                                            className='w-6 h-6 text-blue-500 rounded border-gray-300 focus:ring-blue-500'
                                        />
                                    )}
                                    <h3 className=' text-base font-medium w-full text-gray-700 ml-2 truncate self-center'>
                                        {isEditMode ? (
                                            editing[`section_${section.id}`] !== undefined ? (
                                                <input
                                                    type="text"
                                                    value={editing[`section_${section.id}`]}
                                                    onChange={(e) => handleEditChange(`section_${section.id}`, e.target.value)}
                                                    onBlur={() => {
                                                        handleEdit('section', section.id, editing[`section_${section.id}`] || section.name);
                                                        setEditing((prevEditing) => ({
                                                            ...prevEditing,
                                                            [`section_${section.id}`]: undefined,
                                                        }));
                                                    }}
                                                    onClick={(e) => e.stopPropagation()}
                                                    onKeyDown={(e) => {
                                                        if (e.key === 'Enter') {
                                                            handleEdit('section', section.id, editing[`section_${section.id}`] || section.name);
                                                            setEditing((prevEditing) => ({
                                                                ...prevEditing,
                                                                [`section_${section.id}`]: undefined,
                                                            }));
                                                        }
                                                    }}
                                                    className="border border-gray-300 rounded px-2 py-1 w-full"
                                                    autoFocus
                                                    id={`section_${section.id}`}
                                                    data-original-value={section.name}
                                                />
                                            ) : (
                                                <span onClick={(e) => { e.stopPropagation(); handleEditChange(`section_${section.id}`, section.name); }}>{`${section.name}`}</span>
                                            )
                                        ) : (
                                            <span>{`${section.name}`}</span>
                                        )}




                                    </h3>

                                    {isEditMode && (
                                        <>
                                            <button
                                                onClick={(e) => {
                                                    e.stopPropagation();
                                                    if (window.confirm('Are you sure you want to delete this section?')) {
                                                        handleDelete('section', section.id);
                                                    }
                                                }}
                                                className='ml-2 text-red-500 hover:text-red-700 lg:w-max w-full'
                                            >
                                                Delete
                                            </button>

                                        </>
                                    )}
                                </div>
                                {!isEditMode &&
                                    <div className='sm:text-lg text-base flex items-center justify-center text-center font-bold w-1/6'>
                                        <button onClick={(event) => { event.stopPropagation(); increaseSectionCount(section.name, event); }}>+</button>
                                        <p className='mr-2'>{`${sectionCounts[section.name] || 1}`}</p>
                                        <button onClick={(event) => { event.stopPropagation(); decreaseSectionCount(section.name, event); }}>-</button>
                                    </div>
                                }

                            </div>
                            {expandedSections[section.name] && (
                                <div className='ml-4'>
                                    {/* Rendering groups directly in the section */}
                                    {section.groups.map(group => (
                                        <div key={group.id} className='ml-4'>
                                            <div className='flex items-center bg-gray-100 sm:p-3 p-2 rounded-xl my-2 cursor-pointer' onClick={(e) => { if (!e.target.closest('.no-toggle')) toggleGroupCollapse(section.name, 'Brak podsekcji', group.name) }}>
                                                <div className='flex w-full'>
                                                    {!isEditMode && (
                                                        <input
                                                            type='checkbox'
                                                            id={`group-${section.id}-null-${group.id}`}
                                                            checked={!!selectedGroups[`${section.id}_null_${group.id}`]}
                                                            onChange={(event) => {
                                                                event.stopPropagation();
                                                                handleGroupChange(section.id, 'null', group.id);
                                                            }}
                                                            onClick={(event) => event.stopPropagation()}
                                                            className='w-6 h-6 text-blue-500 rounded border-gray-300 focus:ring-blue-500'
                                                        />



                                                    )}
                                                    <label htmlFor={`group-${section.name}-Brak podsekcji-${group.name}`} className='ml-2 text-md text-gray-600 w-full'>
                                                        {isEditMode ? (
                                                            editing[`group_${group.id}`] !== undefined ? (
                                                                <input
                                                                    type="text"
                                                                    value={editing[`group_${group.id}`]}
                                                                    onChange={(e) => handleEditChange(`group_${group.id}`, e.target.value)}
                                                                    onBlur={() => {
                                                                        handleEdit('group', group.id, editing[`group_${group.id}`] || group.name);
                                                                        setEditing((prevEditing) => ({
                                                                            ...prevEditing,
                                                                            [`group_${group.id}`]: undefined,
                                                                        }));
                                                                    }}
                                                                    onClick={(e) => e.stopPropagation()}
                                                                    onKeyDown={(e) => {
                                                                        if (e.key === 'Enter') {
                                                                            handleEdit('group', group.id, editing[`group_${group.id}`] || group.name);
                                                                            setEditing((prevEditing) => ({
                                                                                ...prevEditing,
                                                                                [`group_${group.id}`]: undefined,
                                                                            }));
                                                                        }
                                                                    }}
                                                                    className="border border-gray-300 rounded px-2 py-1 w-full"
                                                                    autoFocus
                                                                    id={`group_${group.id}`}
                                                                    data-original-value={group.name}
                                                                />
                                                            ) : (
                                                                <span onClick={(e) => { e.stopPropagation(); handleEditChange(`group_${group.id}`, group.name); }}>{`${group.name}`}</span>
                                                            )
                                                        ) : (
                                                            <span>{`${group.name}`}</span>
                                                        )}


                                                    </label>

                                                    {isEditMode && (
                                                        <button
                                                            onClick={(e) => {
                                                                e.stopPropagation();
                                                                if (window.confirm('Are you sure you want to delete this group?')) {
                                                                    handleDelete('group', group.id);
                                                                }
                                                            }}
                                                            className='ml-2 text-red-500 hover:text-red-700 no-toggle'
                                                        >
                                                            Delete
                                                        </button>
                                                    )}
                                                </div>
                                            </div>

                                            {expandedGroups[`${section.name}_Brak podsekcji_${group.name}`] && (
                                                <div className='ml-8 my-2'>
                                                    {group.questions.map((question, index) => (
                                                        <div key={question.id || `temp-${index}`} className='flex items-center'>
                                                            <p className='text-md text-gray-600 my-2 w-full'>
                                                                {isEditMode ? (
                                                                    editing[`question_${question.id}`] !== undefined ? (
                                                                        <input
                                                                            type="text"
                                                                            value={editing[`question_${question.id}`]}
                                                                            onChange={(e) => {
                                                                                const value = e.target.value;
                                                                                if (value !== editing[`question_${question.id}`]) {
                                                                                    handleEditChange(`question_${question.id}`, value);
                                                                                }
                                                                            }}
                                                                            onBlur={() => {
                                                                                const currentValue = editing[`question_${question.id}`]?.trim();
                                                                                console.log("currentValue:", currentValue); // Debugging output
                                                                                if (currentValue === '') {
                                                                                    handleDelete('question', question.id);
                                                                                } else {
                                                                                    handleEdit('question', question.id, currentValue);
                                                                                }
                                                                                setEditing((prevEditing) => ({
                                                                                    ...prevEditing,
                                                                                    [`question_${question.id}`]: undefined,
                                                                                }));
                                                                            }}
                                                                            onClick={(e) => e.stopPropagation()}
                                                                            onKeyDown={(e) => {
                                                                                if (e.key === 'Enter') {
                                                                                    const currentValue = editing[`question_${question.id}`]?.trim();
                                                                                    if (currentValue === '') {
                                                                                        handleDelete('question', question.id);
                                                                                    } else {
                                                                                        handleEdit('question', question.id, currentValue);
                                                                                    }
                                                                                    setEditing((prevEditing) => ({
                                                                                        ...prevEditing,
                                                                                        [`question_${question.id}`]: undefined,
                                                                                    }));
                                                                                    e.preventDefault(); // Zapobiega dodawaniu nowej linii w polu tekstowym
                                                                                }
                                                                            }}
                                                                            className="border border-gray-300 rounded px-2 py-1 w-full"
                                                                            autoFocus
                                                                            id={`question_${question.id}`}
                                                                            data-original-value={question.text}
                                                                        />


                                                                    ) : (
                                                                        <span onClick={(e) => { e.stopPropagation(); handleEditChange(`question_${question.id}`, question.text); }}>
                                                                            {`${index + 1}. ${question.text}`}
                                                                        </span>
                                                                    )
                                                                ) : (
                                                                    <span>{`${index + 1}. ${question.text}`}</span>
                                                                )}
                                                            </p>
                                                        </div>
                                                    ))}



                                                    {isEditMode && (
                                                        showNewQuestionInput[`${section.name}_Brak podsekcji_${group.id}`] ? (
                                                            <div className='flex items-center'>
                                                                <input
                                                                    type="text"
                                                                    value={newQuestions[`${section.name}_Brak podsekcji_${group.id}`] || ''}
                                                                    onChange={(e) => setNewQuestions(prev => ({
                                                                        ...prev,
                                                                        [`${section.name}_Brak podsekcji_${group.id}`]: e.target.value
                                                                    }))}
                                                                    onBlur={() => handleSaveQuestion(section, null, group)}
                                                                    onKeyDown={(e) => {
                                                                        if (e.key === 'Enter') {
                                                                            handleSaveQuestion(section, null, group);
                                                                        }
                                                                    }}
                                                                    className="border border-gray-300 rounded px-2 py-1 w-full"
                                                                    autoFocus
                                                                />
                                                            </div>
                                                        ) : (
                                                            <button
                                                                onClick={(e) => { e.stopPropagation(); handleAddQuestion(section, null, group); }}
                                                                className='text-gray-500 hover:text-gray-700 font-medium mt-2 no-toggle'
                                                            >
                                                                + Add Question
                                                            </button>
                                                        )
                                                    )}




                                                </div>
                                            )}
                                        </div>
                                    ))}



                                    {/* Rendering subsections and their groups */}
                                    {section.sub_sections.map(subSection => (
                                        <div key={subSection.id} className='ml-4'>
                                            <div className='flex items-center bg-gray-200 sm:p-3 p-2 rounded-xl my-2 cursor-pointer' onClick={(e) => { if (!e.target.closest('.no-toggle')) toggleSubSectionCollapse(section.name, subSection.name) }}>
                                                <div className='flex w-full'>
                                                    {!isEditMode && (
                                                        <input
                                                            type='checkbox'
                                                            id={`subSection-${section.id}-${subSection.id}`}
                                                            checked={subSection.groups.every(group => selectedGroups[`${section.id}_${subSection.id}_${group.id}`])}
                                                            onChange={() => handleSubSectionSelectionChange(section.id, subSection.id)}
                                                            onClick={(event) => event.stopPropagation()}
                                                            className='w-6 h-6 text-blue-500 rounded border-gray-300 focus:ring-blue-500'
                                                        />
                                                    )}
                                                    <h4 className='sm:text-md text-base font-medium w-full text-gray-700 ml-2 truncate'>
                                                        {isEditMode ? (
                                                            editing[`subSection_${subSection.id}`] !== undefined ? (
                                                                <input
                                                                    type="text"
                                                                    value={editing[`subSection_${subSection.id}`]}
                                                                    onChange={(e) => handleEditChange(`subSection_${subSection.id}`, e.target.value)}
                                                                    onBlur={() => {
                                                                        const currentValue = editing[`subSection_${subSection.id}`]?.trim();
                                                                        if (currentValue !== '') {
                                                                            handleEdit('subSection', subSection.id, currentValue);
                                                                        }
                                                                        setEditing((prevEditing) => ({
                                                                            ...prevEditing,
                                                                            [`subSection_${subSection.id}`]: undefined,
                                                                        }));
                                                                    }}
                                                                    onClick={(e) => e.stopPropagation()}
                                                                    onKeyDown={(e) => {
                                                                        if (e.key === 'Enter') {
                                                                            const currentValue = editing[`subSection_${subSection.id}`]?.trim();
                                                                            if (currentValue !== '') {
                                                                                handleEdit('subSection', subSection.id, currentValue);
                                                                            }
                                                                            setEditing((prevEditing) => ({
                                                                                ...prevEditing,
                                                                                [`subSection_${subSection.id}`]: undefined,
                                                                            }));
                                                                            e.preventDefault(); // Zapobiega dodawaniu nowej linii w polu tekstowym
                                                                        }
                                                                    }}
                                                                    className="border border-gray-300 rounded px-2 py-1 w-full"
                                                                    autoFocus
                                                                    id={`subSection_${subSection.id}`}
                                                                    data-original-value={subSection.name}
                                                                />
                                                            ) : (
                                                                <span onClick={(e) => { e.stopPropagation(); handleEditChange(`subSection_${subSection.id}`, subSection.name); }}>{`${subSection.name}`}</span>
                                                            )
                                                        ) : (
                                                            <span>{`${subSection.name}`}</span>
                                                        )}


                                                    </h4>

                                                    {isEditMode && (
                                                        <>
                                                            <button
                                                                onClick={(e) => {
                                                                    e.stopPropagation();
                                                                    if (window.confirm('Are you sure you want to delete this subsection?')) {
                                                                        handleDelete('sub_section', subSection.id);
                                                                    }
                                                                }}
                                                                className='ml-2 text-red-500 hover:text-red-700 w-max'
                                                            >
                                                                Delete
                                                            </button>
                                                        </>
                                                    )}
                                                </div>
                                            </div>

                                            {expandedSubSections[`${section.name}_${subSection.name}`] && (
                                                <div className='ml-4'>
                                                    {subSection.groups.map(group => (
                                                        <div key={group.id} className='ml-4'>
                                                            <div className='flex items-center bg-gray-100 sm:p-3 p-2 rounded-xl my-2 cursor-pointer' onClick={(e) => { if (!e.target.closest('.no-toggle')) toggleGroupCollapse(section.name, subSection.name, group.name) }}>
                                                                <div className='flex w-full'>
                                                                    {!isEditMode && (
                                                                        <input
                                                                            type='checkbox'
                                                                            id={`subSection-${section.id}-${subSection.id}`}
                                                                            checked={subSection.groups.every(group => selectedGroups[`${section.id}_${subSection.id}_${group.id}`])}
                                                                            onChange={() => handleSubSectionSelectionChange(section.id, subSection.id)}
                                                                            onClick={(event) => event.stopPropagation()}
                                                                            className='w-6 h-6 text-blue-500 rounded border-gray-300 focus:ring-blue-500'
                                                                        />
                                                                    )}
                                                                    <label htmlFor={`${section.name}_${subSection.name}_${group.name}`} className='ml-2 text-md text-gray-600 w-full'>
                                                                        {isEditMode ? (
                                                                            editing[`group_${group.id}`] !== undefined ? (
                                                                                <input
                                                                                    type="text"
                                                                                    value={editing[`group_${group.id}`]}
                                                                                    onChange={(e) => handleEditChange(`group_${group.id}`, e.target.value)}
                                                                                    onBlur={() => {
                                                                                        const currentValue = editing[`group_${group.id}`]?.trim();
                                                                                        if (currentValue !== '') {
                                                                                            handleEdit('group', group.id, currentValue);
                                                                                        }
                                                                                        setEditing((prevEditing) => ({
                                                                                            ...prevEditing,
                                                                                            [`group_${group.id}`]: undefined,
                                                                                        }));
                                                                                    }}
                                                                                    onClick={(e) => e.stopPropagation()}
                                                                                    onKeyDown={(e) => {
                                                                                        if (e.key === 'Enter') {
                                                                                            const currentValue = editing[`group_${group.id}`]?.trim();
                                                                                            if (currentValue !== '') {
                                                                                                handleEdit('group', group.id, currentValue);
                                                                                            }
                                                                                            setEditing((prevEditing) => ({
                                                                                                ...prevEditing,
                                                                                                [`group_${group.id}`]: undefined,
                                                                                            }));
                                                                                            e.preventDefault(); // Zapobiega dodawaniu nowej linii w polu tekstowym
                                                                                        }
                                                                                    }}
                                                                                    className="border border-gray-300 rounded px-2 py-1 w-full"
                                                                                    autoFocus
                                                                                    id={`group_${group.id}`}
                                                                                    data-original-value={group.name}
                                                                                />

                                                                            ) : (
                                                                                <span onClick={(e) => { e.stopPropagation(); handleEditChange(`group_${group.id}`, group.name); }}>{`Group: ${group.name}`}</span>
                                                                            )
                                                                        ) : (
                                                                            <span>{`${group.name}`}</span>
                                                                        )}
                                                                    </label>
                                                                    {isEditMode && (
                                                                        <button
                                                                            onClick={(e) => {
                                                                                e.stopPropagation();
                                                                                if (window.confirm('Are you sure you want to delete this group?')) {
                                                                                    handleDelete('group', group.id);
                                                                                }
                                                                            }}
                                                                            className='ml-2 text-red-500 hover:text-red-700 no-toggle'
                                                                        >
                                                                            Delete
                                                                        </button>
                                                                    )}
                                                                </div>
                                                            </div>

                                                            {expandedGroups[`${section.name}_${subSection.name}_${group.name}`] && (
                                                                <div className='ml-8 my-2 '>
                                                                    {group.questions.map((question, index) => (
                                                                        <div key={question.id} className='flex items-center'>
                                                                            <p className='text-md text-gray-600 w-full'>
                                                                                {isEditMode ? (
                                                                                    editing[`question_${question.id}`] !== undefined ? (
                                                                                        <input
                                                                                            type="text"
                                                                                            value={editing[`question_${question.id}`]}
                                                                                            onChange={(e) => handleEditChange(`question_${question.id}`, e.target.value)}
                                                                                            onBlur={() => {
                                                                                                const currentValue = editing[`question_${question.id}`]?.trim();
                                                                                                if (currentValue === '') {
                                                                                                    handleDelete('question', question.id);  // Usunięcie pytania, jeśli jest puste
                                                                                                } else {
                                                                                                    handleEdit('question', question.id, currentValue);
                                                                                                }
                                                                                                setEditing((prevEditing) => ({
                                                                                                    ...prevEditing,
                                                                                                    [`question_${question.id}`]: undefined,
                                                                                                }));
                                                                                            }}
                                                                                            onClick={(e) => e.stopPropagation()}
                                                                                            onKeyDown={(e) => {
                                                                                                if (e.key === 'Enter') {
                                                                                                    const currentValue = editing[`question_${question.id}`]?.trim();
                                                                                                    if (currentValue === '') {
                                                                                                        handleDelete('question', question.id);  // Usunięcie pytania, jeśli jest puste
                                                                                                    } else {
                                                                                                        handleEdit('question', question.id, currentValue);
                                                                                                    }
                                                                                                    setEditing((prevEditing) => ({
                                                                                                        ...prevEditing,
                                                                                                        [`question_${question.id}`]: undefined,
                                                                                                    }));
                                                                                                    e.preventDefault(); // Zapobiega dodawaniu nowej linii w polu tekstowym
                                                                                                }
                                                                                            }}
                                                                                            className="border border-gray-300 rounded px-2 py-1 w-full"
                                                                                            autoFocus
                                                                                            id={`question_${question.id}`}
                                                                                            data-original-value={question.text}
                                                                                        />

                                                                                    ) : (
                                                                                        <span onClick={(e) => { e.stopPropagation(); handleEditChange(`question_${question.id}`, question.text); }}>{`${index + 1}. ${question.text}`}</span>
                                                                                    )
                                                                                ) : (
                                                                                    <span>{`${index + 1}. ${question.text}`}</span>
                                                                                )}
                                                                            </p>
                                                                        </div>
                                                                    ))}

                                                                    {isEditMode && (
                                                                        showNewQuestionInput[`${section.name}_${subSection.name}_${group.id}`] ? (
                                                                            <div className='flex items-center'>
                                                                                <input
                                                                                    type="text"
                                                                                    value={newQuestions[`${section.name}_${subSection.name}_${group.id}`] || ''}
                                                                                    onChange={(e) => setNewQuestions(prev => ({
                                                                                        ...prev,
                                                                                        [`${section.name}_${subSection.name}_${group.id}`]: e.target.value
                                                                                    }))}
                                                                                    onBlur={() => handleSaveQuestion(section, subSection, group)}
                                                                                    onKeyDown={(e) => {
                                                                                        if (e.key === 'Enter') {
                                                                                            handleSaveQuestion(section, subSection, group);
                                                                                        }
                                                                                    }}
                                                                                    className="border border-gray-300 rounded px-2 py-1 w-full"
                                                                                    autoFocus
                                                                                />
                                                                            </div>
                                                                        ) : (
                                                                            <button
                                                                                onClick={(e) => { e.stopPropagation(); handleAddQuestion(section, subSection, group); }}
                                                                                className='text-gray-500 hover:text-gray-700 font-medium mt-2 no-toggle'
                                                                            >
                                                                                + Add Question
                                                                            </button>
                                                                        )
                                                                    )}
                                                                </div>
                                                            )}
                                                        </div>
                                                    ))}
                                                    {isEditMode &&
                                                        <button onClick={(e) => { e.stopPropagation(); handleAdd('group', `${section.id}_${subSection.id}`); }} className='text-gray-500 hover:text-gray-700 mt-2 ml-6'>Add Group</button>
                                                    }
                                                </div>
                                            )}


                                            {showNewSubSectionInput[section.name] && (
                                                <div className='flex items-center'>
                                                    <input
                                                        type="text"
                                                        value={newSubSectionName[section.name] || ''}
                                                        onChange={(e) => setNewSubSectionName(prev => ({ ...prev, [section.name]: e.target.value }))}
                                                        onBlur={() => handleSaveSubSection(section.name)}
                                                        className="border border-gray-300 rounded px-2 py-1 w-full"
                                                        autoFocus
                                                    />
                                                </div>
                                            )}

                                        </div>
                                    ))}
                                    {showNewGroupInput[`${section.name}_Brak podsekcji`] && (
                                        <div className='flex items-center'>
                                            <input
                                                type="text"
                                                value={newGroupName[`${section.name}_Brak podsekcji_`] || ''}
                                                onChange={(e) => setNewGroupName(prev => ({ ...prev, [`${section.name}_Brak podsekcji`]: e.target.value }))}
                                                onBlur={() => handleSaveGroup(section.name, 'Brak podsekcji')}
                                                className="border border-gray-300 rounded px-2 py-1 w-full"
                                                autoFocus
                                            />
                                        </div>
                                    )}
                                    {isEditMode &&
                                        <div className='ml-6 border-t-2 border-dashed my-4 pt-2'>
                                            <button onClick={(e) => { e.stopPropagation(); handleAdd('subSection', section.id); }} className='text-gray-500 hover:text-gray-800 mt-2 mr-6'>Add SubSection</button>
                                            <button onClick={(e) => { e.stopPropagation(); handleAdd('group', section.id); }} className='text-gray-500 hover:text-gray-800 mt-2'>Add Group to Section</button>
                                        </div>
                                    }
                                </div>
                            )}

                        </div>

                    ))}


                    {isEditMode && (
                        <div className='w-36 mt-6 mb-24 sm:mb-6 mx-auto'>
                            {showNewSectionInput ? (
                                <div className='flex items-center'>
                                    <input
                                        type="text"
                                        value={newSectionName}
                                        onChange={(e) => setNewSectionName(e.target.value)}
                                        onBlur={handleSaveSection}
                                        className="border border-gray-300 rounded px-2 py-1 w-full"
                                        autoFocus
                                    />
                                </div>
                            ) : (
                                <button
                                    onClick={(e) => { e.stopPropagation(); handleAdd('section'); }}
                                    className='py-2 px-4 bg-gray-500 text-white text-lg font-semibold rounded hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-blue-300 no-toggle'
                                >
                                    + Add Section
                                </button>
                            )}
                        </div>
                    )}

                    <button
                        onClick={(event) => handleSubmit(event, false, selectedObjectType.name)}
                        type='button'
                        className={`w-36 mt-6 mb-24 sm:mb-6 mx-auto py-2 px-4 bg-zinc-800 text-white text-lg font-semibold rounded hover:bg-zinc-600 focus:outline-none focus:ring-2 focus:ring-blue-300 ${isEditMode ? 'hidden' : ''}`}
                    >
                        Create Audit
                    </button>
                </div>
            </div>
        </>
    );
}

export default NewAudit;
