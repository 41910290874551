import React from 'react';
import { FaClock, FaCheckCircle, FaHourglassHalf, FaPaperPlane, FaEnvelope } from 'react-icons/fa';

const statusStyles = {
    'Scheduled': {
        color: 'bg-blue-100 text-blue-800',
        icon: <FaClock />,
    },
    'In Progress': {
        color: 'bg-orange-100 text-orange-800',
        icon: <FaHourglassHalf />,
    },
    'To Check': {
        color: 'bg-yellow-100 text-yellow-800',
        icon: <FaCheckCircle />,
    },
    'To Send': {
        color: 'bg-teal-100 text-teal-800',
        icon: <FaPaperPlane />,
    },
    'Sent': {
        color: 'bg-green-100 text-green-800',
        icon: <FaEnvelope />,
    },
};

const Event = ({ event, events = [] }) => {
    const eventStart = new Date(event.start).getTime();
    const eventEnd = new Date(event.end).getTime();

    // Sprawdzenie, czy jakiekolwiek inne wydarzenie pokrywa się z obecnym wydarzeniem w całym jego przedziale czasowym
    const overlappingEvents = events.filter(e => {
        const eStart = new Date(e.start).getTime();
        const eEnd = new Date(e.end).getTime();

        // Sprawdzamy, czy inne wydarzenie pokrywa się w jakimkolwiek dniu z obecnym wydarzeniem
        return (
            (eStart <= eventEnd && eEnd >= eventStart) &&
            e !== event // Upewniamy się, że to nie jest to samo wydarzenie
        );
    });

    const status = statusStyles[event.status] || {};

    // Liczba nakładających się wydarzeń (łącznie z bieżącym)
    const maxOverlaps = overlappingEvents.length + 1;

    // Jeśli występuje jakiekolwiek nakładanie w zakresie dni, ustawiamy mniejszą wysokość
    let heightStyle;
    if (maxOverlaps > 1) {
        // Jeśli jest więcej niż 3, zmniejszamy jeszcze bardziej
        heightStyle = {
            height: maxOverlaps > 4 ? `calc(100% / ${maxOverlaps * 1.5})` : `calc(100% / ${maxOverlaps})`,
            margin: 0,
            padding: '0.3em',
        };
    } else {
        // Jeśli nie ma nakładania się na inne wydarzenia, używamy pełnego rozmiaru
        heightStyle = {
            padding: '1em',
        };
    }

    return (
        <div
            className={`flex items-center rounded-lg shadow hover:shadow-md transition-shadow duration-300 cursor-pointer ${status.color} bg-opacity-80`}
            style={heightStyle}
        >
            <div className="mr-2 text-lg">
                {status.icon}
            </div>
            <div className="flex-1">
                <div className={`font-semibold ${maxOverlaps > 1 ? 'text-sm' : 'text-lg'}`}>{event.audit_title}</div>
                {maxOverlaps === 1 && (
                    <div className="text-sm">
                        {event.status} &middot; {event.start.toLocaleDateString()}
                    </div>
                )}
            </div>
        </div>
    );
};

export default Event;
