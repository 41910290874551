import React, { useState } from 'react';
import { useAuth } from '../../context/AuthContext';
import photoSvg from '../../utils/svgs/photo-upload.svg'
import { notification } from 'antd';

import axios from 'axios';
const AuditEditPopup = ({ auditData, setAuditData, setIsAuditEdit, setIsPreviewPopupVisible }) => {
    const [auditTitle, setAuditTitle] = useState(auditData.audit_title);
    const [auditId] = useState(auditData.audit_id);
    const [city, setCity] = useState(auditData.city);
    const [street, setStreet] = useState(auditData.street);
    const [startDate, setStartDate] = useState(formatDate(auditData.start_date));
    const [endDate, setEndDate] = useState(formatDate(auditData.end_date));
    const [coverPhoto] = useState(auditData.cover_photo);
    const [coverPhotoFile, setCoverPhotoFile] = useState(null);
    const [previewCoverPhoto, setPreviewCoverPhoto] = useState(auditData.cover_photo);
    const [showModal, setShowModal] = useState(false);
    const backendUrl = process.env.REACT_APP_BACKEND_URL
    const { authTokens } = useAuth()
    const [isDeleteConfirmationOpen, setIsDeleteConfirmationOpen] = useState(false);
    const [deleteConfirmationText, setDeleteConfirmationText] = useState('');
    const deleteConfirmationPhrase = 'DELETE';





    const {
        scheduledAudits,
        setScheduledAudits,
        toCheckAudits,
        setToCheckAudits,
        toSendAudits,
        setToSendAudits,
        sentAudits,
        setSentAudits,
        inProgressAudits,
        setInProgressAudits,
        setAllAudits
    } = useAuth();

    // Function to format date string into YYYY-MM-DD
    function formatDate(dateString) {
        const date = new Date(dateString);
        const year = date.getFullYear();
        let month = (date.getMonth() + 1).toString().padStart(2, '0');
        let day = date.getDate().toString().padStart(2, '0');
        return `${year}-${month}-${day}`;
    }

    // Function to handle changes in start date
    const handleStartDateChange = (e) => {
        setStartDate(e.target.value);
    };

    // Function to handle changes in end date
    const handleEndDateChange = (e) => {
        setEndDate(e.target.value);
    };

    // Function to handle cover photo change
    const handleCoverPhotoChange = (e) => {
        const file = e.target.files[0];
        if (file) {
            setCoverPhotoFile(file);
            setPreviewCoverPhoto(URL.createObjectURL(file));
        }
    };

    // Function to handle cover photo click
    const handleCoverPhotoClick = () => {
        setShowModal(true);
    };

    // Function to close the modal
    const closeModal = () => {
        setShowModal(false);
    };
    // Function to handle click outside the image (to close modal)
    const handleOutsideClick = (e) => {
        if (e.target.classList.contains('audit-modal')) {
            closeModal();
        }
        if (e.target.id === 'modal-background') {
            closeModal();
        }
    };

    const handleBackgroundClick = (e) => {
        if (e.target.id === 'popup-background') {
            setIsAuditEdit(false);
            saveAuditData()
        }
    };
    // Compare audit data
    const hasChanges = () => {
        return (
            auditTitle !== auditData.audit_title ||
            city !== auditData.city ||
            street !== auditData.street ||
            startDate !== formatDate(auditData.start_date) ||
            endDate !== formatDate(auditData.end_date) ||
            coverPhotoFile !== null
        );
    };

    const saveAuditData = async () => {
        if (!hasChanges()) {
            // notification.info({
            //     message: 'Brak zmian',
            //     description: 'Nie wprowadzono żadnych zmian w danych audytu.',
            // });
            return;
        }
        try {
            const formData = new FormData();
            formData.append('audit_id', auditData.audit_id);
            formData.append('audit_title', auditTitle);
            formData.append('city', city);
            formData.append('street', street);
            formData.append('start_date', startDate);
            formData.append('end_date', endDate);
            if (coverPhotoFile) {
                formData.append('cover_photo', coverPhotoFile, coverPhotoFile.name);
            }

            const response = await axios.post(`${backendUrl}/audit-page/edit-audit/`, formData, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                    Authorization: `Bearer ${authTokens?.access || ''}`,
                },
            });

            if (response.status === 200) {
                const updatedAuditData = {
                    ...auditData,
                    ...response.data.audit, // Load all updated data from the response, including the photo URL
                };
                // Update the state of audits depending on their status
                const updateAuditsList = (audits) => audits.map(audit =>
                    audit.audit_id === updatedAuditData.audit_id ? updatedAuditData : audit
                );

                switch (updatedAuditData.status) {
                    case "In Progress":
                        setInProgressAudits(updateAuditsList(inProgressAudits));
                        break;
                    case "Scheduled":
                        setScheduledAudits(updateAuditsList(scheduledAudits));
                        break;
                    case "To Check":
                        setToCheckAudits(updateAuditsList(toCheckAudits));
                        break;
                    case "To Send":
                        setToSendAudits(updateAuditsList(toSendAudits));
                        break;
                    case "Sent":
                        setSentAudits(updateAuditsList(sentAudits));
                        break;
                    default:
                        break;
                }
                setAuditData(updatedAuditData); // Update the audit data in the local state of the component
                notification.success({
                    message: 'Saved',
                    description: 'The audit data has been saved successfully',
                });
                setIsAuditEdit(false); // Close the edit popup
            } else {
                throw new Error('Failed to update audit data');
            }
        } catch (error) {
            console.error('Error saving audit data:', error);
            notification.error({
                message: 'Error',
                description: 'The audit data was not saved!',
            });
        }
    };

    const handleDeleteAudit = () => {
        setIsDeleteConfirmationOpen(true);

    };

    const confirmDeleteAudit = () => {
        axios.delete(`${backendUrl}/audit-page/delete-audit/`, {
            data: { audit: auditId },
            headers: { Authorization: `Bearer ${authTokens?.access || ''}` },
        })
            .then(response => {
                // Update state after deletion
                setAllAudits(prevAudits => prevAudits.filter(audit => audit.audit_id !== auditId));
                setScheduledAudits(prevAudits => prevAudits.filter(audit => audit.audit_id !== auditId));
                setToCheckAudits(prevAudits => prevAudits.filter(audit => audit.audit_id !== auditId));
                setToSendAudits(prevAudits => prevAudits.filter(audit => audit.audit_id !== auditId));
                setSentAudits(prevAudits => prevAudits.filter(audit => audit.audit_id !== auditId));
                setInProgressAudits(prevAudits => prevAudits.filter(audit => audit.audit_id !== auditId));
                setIsAuditEdit(false);
                setIsPreviewPopupVisible(false);
                setIsDeleteConfirmationOpen(false);
                notification.success({
                    message: 'Deleted',
                    description: 'The audit has been successfully deleted.',
                });
                console.log(`Successfully deleted audit: ${auditId}`);
            })
            .catch(error => {
                console.error('Error deleting audit:', error);
            });
    };

    return (
        <>
            <div id="popup-background" className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-50" onClick={handleBackgroundClick}>
                <div className="bg-white p-4 sm:p-6 md:p-8 rounded-lg shadow-lg w-full max-w-lg sm:max-w-2xl md:max-w-3xl mx-4" onClick={(e) => e.stopPropagation()}>
                    <h2 className="text-xl sm:text-2xl font-bold mb-4 sm:mb-6">Edit Audit</h2>
                    <div className="grid grid-cols-1 gap-4 sm:grid-cols-2">
                        <div className="sm:mb-2">
                            <label htmlFor="auditTitle" className="block text-sm sm:text-md mb-1 font-medium text-gray-700">Audit Title</label>
                            <input
                                type="text"
                                value={auditTitle}
                                onChange={(e) => setAuditTitle(e.target.value)}
                                className="block w-full border border-gray-300 hover:border-gray-500 focus:border-gray-500 outline-0 rounded-md p-2"
                                id="auditTitle"
                            />
                        </div>
                        <div className="sm:mb-2">
                            <label htmlFor="city" className="block text-sm sm:text-md mb-1 font-medium text-gray-700">City</label>
                            <input
                                type="text"
                                value={city}
                                onChange={(e) => setCity(e.target.value)}
                                className="block w-full border border-gray-300 hover:border-gray-500 focus:border-gray-500 outline-0 rounded-md p-2"
                                id="city"
                            />
                        </div>
                        <div className="sm:mb-2">
                            <label htmlFor="street" className="block text-sm sm:text-md mb-1 font-medium text-gray-700">Street</label>
                            <input
                                type="text"
                                value={street}
                                onChange={(e) => setStreet(e.target.value)}
                                className="block w-full border border-gray-300 hover:border-gray-500 focus:border-gray-500 outline-0 rounded-md p-2"
                                id="street"
                            />
                        </div>
                        <div className="sm:mb-2">
                            <label htmlFor="startDate" className="block text-sm sm:text-md mb-1 font-medium text-gray-700">Start Date</label>
                            <input
                                type="date"
                                value={startDate}
                                onChange={handleStartDateChange}
                                className="block w-full border border-gray-300 hover:border-gray-500 focus:border-gray-500 outline-0 rounded-md p-2"
                                id="startDate"
                            />
                        </div>
                        <div className="sm:mb-2">
                            <label htmlFor="endDate" className="block text-sm sm:text-md mb-1 font-medium text-gray-700">End Date</label>
                            <input
                                type="date"
                                value={endDate}
                                onChange={handleEndDateChange}
                                className="block w-full border border-gray-300 hover:border-gray-500 focus:border-gray-500 outline-0 rounded-md p-2"
                                id="endDate"
                            />
                        </div>
                        <div className="flex items-center mt-5">
                            <div className={`flex items-center justify-center w-10 h-10 sm:w-11 sm:h-11 rounded-lg border ${coverPhoto ? 'border-green-400' : 'border-gray-300'}`}>
                                <label htmlFor="coverPhoto" className="flex items-center justify-center w-full h-full cursor-pointer">
                                    <img src={photoSvg} className="w-5 h-5 sm:w-6 sm:h-6" alt="Add photo" />
                                </label>
                                <input
                                    type="file"
                                    name="coverPhoto"
                                    id="coverPhoto"
                                    onChange={handleCoverPhotoChange}
                                    className="hidden"
                                />
                            </div>
                            {/* Thumbnail only on small screens */}
                            {previewCoverPhoto && (
                                <div className="ml-2 flex items-center justify-center w-10 h-10 rounded-lg cursor-pointer sm:hidden" onClick={handleCoverPhotoClick}>
                                    <img
                                        src={`http://localhost:8000/${previewCoverPhoto}`}
                                        alt="Photo preview"
                                        className="w-full h-full object-cover rounded-md"
                                    />
                                </div>
                            )}

                            <button
                                className="ml-auto px-4 sm:px-6 py-2 bg-red-500 text-white rounded-lg hover:bg-red-600 transition"
                                onClick={() => setIsDeleteConfirmationOpen(true)}
                            >
                                Delete Audit
                            </button>

                            {/* Large photo only on larger screens */}

                        </div>
                        {previewCoverPhoto && (
                            <div className=" justify-center col-span-1 sm:col-span-2 mt-4 hidden sm:flex">
                                <img
                                    src={`http://localhost:8000/${previewCoverPhoto}`}
                                    alt="Photo preview"
                                    className="w-48 h-48 object-cover rounded-lg shadow-md"
                                    onClick={handleCoverPhotoClick}
                                />
                            </div>
                        )}
                    </div>

                </div>

            </div >

            {showModal && (
                <div id="modal-background" className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-75 z-50" onClick={handleOutsideClick}>
                    <img src={`http://localhost:8000/${previewCoverPhoto}`} alt="Photo preview" className="max-w-full max-h-full rounded-lg shadow-lg" />
                </div>
            )
            }

            {
                isDeleteConfirmationOpen && (
                    <div className="fixed inset-0 flex items-center justify-center bg-opacity-75 bg-gray-700 z-50">
                        <div className="bg-white p-4 sm:p-6 rounded shadow-md text-center mx-4 sm:mx-0">
                            <h2 className="text-lg sm:text-xl font-bold mb-2 sm:mb-4">Confirm deletion</h2>
                            <p className="text-sm sm:text-base">To confirm deletion, enter <strong>{deleteConfirmationPhrase}</strong> below:</p>
                            <input
                                type="text"
                                value={deleteConfirmationText}
                                onChange={(e) => setDeleteConfirmationText(e.target.value)}
                                className="border p-2 mt-4 w-full rounded"
                            />
                            <div className="flex justify-end mt-4 space-x-2">
                                <button
                                    className="bg-red-500 text-white py-2 px-4 sm:px-6 rounded hover:bg-red-600 transition"
                                    disabled={deleteConfirmationText !== deleteConfirmationPhrase}
                                    onClick={confirmDeleteAudit}
                                >
                                    Confirm
                                </button>
                                <button
                                    className="bg-gray-300 py-2 px-4 sm:px-6 rounded hover:bg-gray-400 transition"
                                    onClick={() => setIsDeleteConfirmationOpen(false)}
                                >
                                    Cancel
                                </button>
                            </div>
                        </div>
                    </div>
                )
            }
        </>

    );
};

export default AuditEditPopup;
