import React, { useState } from 'react';
import { useAuth } from '../context/AuthContext';
import { Link } from 'react-router-dom';  // Importujemy Link

const Login = () => {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);
  const { login } = useAuth();

  const handleSubmit = async (event) => {
    event.preventDefault();
    setError('');
    setLoading(true);
    setSuccess(false);
    try {
      await login(username, password);
      setLoading(false);
      setSuccess(true);
      setTimeout(() => {
        setSuccess(false);
      }, 2000); // Wyświetla sukces przez 2 sekundy
    } catch {
      setError('Niepoprawny login lub hasło!');
      setLoading(false);
    }
  };

  return (
    <div className='fixed inset-0 overflow-y-auto h-full w-full flex justify-center items-center z-50 bg-zinc-800'>
      <div className={`w-96 bg-white shadow-lg p-5 py-8 rounded transform transition-all duration-300 ease-in-out ${success ? 'scale-105' : ''}`}>
        <form onSubmit={handleSubmit} className='flex flex-col justify-between h-full'>
          <h2 className={`text-center text-3xl mb-6 font-semibold tracking-wider text-gray-900 transition-transform ${error ? 'shake' : ''}`}>
            Login
          </h2>
          <div className='mb-4 mt-4'>
            <input
              className={`transition-all text-lg appearance-none border-b-2 font-semibold border-gray-200 w-full py-2 text-gray-700 leading-tight focus:outline-none focus:shadow-outline focus:border-zinc-800 ${error ? 'border-red-400' : ''}`}
              id='username'
              type='text'
              value={username}
              placeholder='Username'
              onChange={(e) => setUsername(e.target.value)}
              required
            />
          </div>
          <div className='mb-6 mt-6'>
            <input
              className={`transition-all text-lg appearance-none border-b-2 font-semibold border-gray-200 w-full py-2 text-gray-700 leading-tight focus:outline-none focus:shadow-outline focus:border-zinc-800 ${error ? 'border-red-400' : ''}`}
              id='password'
              type='password'
              placeholder='Password'
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              required
            />
          </div>
          <div className="mb-4 text-sm text-right text-gray-600">
            <Link to="/reset-password" className="text-gray-600 hover:text-gray-400 hover:underline">
              Zapomniałeś hasła?
            </Link>
          </div>
          {error && (
            <div className='mb-4 font-semibold text-red-400 animate-pulse'>
              {error}
            </div>
          )}
          {success && (
            <div className='mb-4 font-semibold text-green-400'>
              Logowanie zakończone sukcesem!
            </div>
          )}
          <div className='flex items-center justify-between mt-4'>
            <button
              className={`transition-colors duration-300 ease-in-out bg-gray-900 hover:bg-gray-700 text-white font-bold ml-auto py-2 px-4 rounded focus:outline-none focus:shadow-outline ${loading ? 'cursor-wait opacity-75' : ''}`}
              type='submit'
              disabled={loading}
            >
              {loading ? 'Logowanie...' : 'Log in'}
            </button>
          </div>

        </form>
      </div>
    </div>
  );
};

export default Login;
