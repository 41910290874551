import React, { useEffect, useState, useRef } from 'react';

import { useAuth } from '../context/AuthContext';

import SectionList from '../components/MainPage/SectionList'; // Adjusted path
import Nav from '../components/MainPage/Nav'; // Adjusted path

// Popups
import PreviewPopup from '../components/MainPage/PreviewPopup' // Adjusted path

const AuditPage = () => {

    const { authTokens } = useAuth()
    const backendUrl = process.env.REACT_APP_BACKEND_URL
    const [isPreviewPopupVisible, setIsPreviewPopupVisible] = useState(false);
    const [audit, setAudit] = useState(null);
    const PreviewPopupRef = useRef();

    const {
        scheduledAudits,
        setScheduledAudits,
        inProgressAudits,
        setInProgressAudits,
        toCheckAudits,
        setToCheckAudits,
        toSendAudits,
        setToSendAudits,
        sentAudits,
        setSentAudits,
        currentPage,
        setCurrentPage,
        setCreatePageNumber,
        currentAudit,
        setCurrentAudit,
        setAllAudits,

    } = useAuth() || {};

    useEffect(() => {
        if (!scheduledAudits.length && !inProgressAudits.length && !toCheckAudits.length && !toSendAudits.length && !sentAudits.length) {

            fetch(`${backendUrl}/audit-page/audits/`, {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + (authTokens?.access || '')
                }
            })
                .then(response => response.json())
                .then(data => {
                    if (Array.isArray(data)) {
                        const scheduledAudits = data.filter(audit => audit.status === "Scheduled");
                        const inProgressAudits = data.filter(audit => audit.status === "In Progress");
                        const toCheckAudits = data.filter(audit => audit.status === "To Check");
                        const toSendAudits = data.filter(audit => audit.status === "To Send");
                        const sentAudits = data.filter(audit => audit.status === "Sent");
                        console.log(sentAudits)
                        setScheduledAudits(scheduledAudits);
                        setInProgressAudits(inProgressAudits);
                        setToCheckAudits(toCheckAudits);
                        setToSendAudits(toSendAudits);
                        setSentAudits(sentAudits);
                        setAllAudits(data);
                        console.log(data)
                    } else {
                        console.error('Oczekiwano tablicy, ale otrzymano:', data);
                    }
                })
                .catch(error => {
                    console.error('Błąd:', error);
                    setScheduledAudits([]);
                    setInProgressAudits([]);
                    setToCheckAudits([]);
                    setToSendAudits([]);
                    setSentAudits([]);
                    setAllAudits([]);
                });
        }
    }, [authTokens?.access, backendUrl, setScheduledAudits, inProgressAudits.length, scheduledAudits.length, setAllAudits, setInProgressAudits]);

    // Renderowanie pojedynczej sekcji
    return (
        <div className="lg:flex " style={{ overflow: 'auto' }}>
            {isPreviewPopupVisible && (
                <PreviewPopup
                    audit={audit}
                    setAudit={setAudit}
                    PreviewPopupRef={PreviewPopupRef}
                    setIsPreviewPopupVisible={setIsPreviewPopupVisible}
                />
            )}
            
            <Nav currentPage={currentPage} setCurrentPage={setCurrentPage} setCreatePageNumber={setCreatePageNumber} />
            <div className={`lg:w-full w-full px-2 lg:pl-6 sm:pt-4  sm:pb-16 pb-16 sm:max-h-screen  `} id="scrollContainer">
                <SectionList setAudit={setAudit} setIsPreviewPopupVisible={setIsPreviewPopupVisible} />
            </div>
            <div className='hidden lg:flex'>
                {/* <Sidebar scheduledAudits={scheduledAudits} sentAudits={sentAudits} currentAudit={currentAudit} setCurrentAudit={setCurrentAudit} setCurrentPage={setCurrentPage} /> */}
            </div>
        </div>
    );

};

export default AuditPage;
