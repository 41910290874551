import React from 'react';

const CreateSectionPopup = ({ handleSectionTextChange, handleCreateSection, setIsCreateSectionPopupVisible, createSectionPopupRef }) => {
  return (
    <div className="fixed inset-0 flex items-center justify-center z-50 bg-black bg-opacity-50">
      <div className="bg-white p-8 rounded-lg w-full max-w-3xl mx-4 shadow-lg" ref={createSectionPopupRef}>
        <h2 className="text-2xl font-bold mb-6">New Section</h2>
        <div className="mb-4">
          <label
            htmlFor="sectionName"
            className="block text-gray-700 text-lg font-bold mb-2"
          >
            Section Name:
          </label>
          <input
            id="sectionName"
            type="text"
            className="border border-gray-300 p-3 w-full rounded-lg focus:outline-none focus:border-gray-500 font-semibold"
            placeholder="Enter section name..."
            onChange={handleSectionTextChange}
          ></input>
        </div>
        <div className="flex justify-between items-center mt-6">
          <button
            onClick={() => setIsCreateSectionPopupVisible(false)}
            className="bg-gray-500 text-white px-4 py-2 rounded-lg hover:bg-gray-600 transition"
          >
            Close
          </button>
          <button
            onClick={handleCreateSection}
            className="bg-green-500 text-white px-4 py-2 rounded-lg hover:bg-green-600 transition"
          >
            Save
          </button>
        </div>
      </div>
    </div>
  );
};

export default CreateSectionPopup;
