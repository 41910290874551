import React, { useState, useEffect } from 'react';
import { FaWifi,  FaExclamationTriangle } from 'react-icons/fa';
import { PiWifiSlash } from "react-icons/pi";

const ConnectionStatus = () => {
    const [connectionStatus, setConnectionStatus] = useState(navigator.onLine ? 'online' : 'offline');
    const [showStatus, setShowStatus] = useState(false);

    useEffect(() => {
        const handleConnectionChange = () => {
            if (navigator.onLine) {
                setConnectionStatus('online');
                setShowStatus(true);
                setTimeout(() => setShowStatus(false), 1000); // Wyświetlaj komunikat przez 3 sekundy
            } else {
                setConnectionStatus('offline');
                setShowStatus(true);
            }
        };

        window.addEventListener('online', handleConnectionChange);
        window.addEventListener('offline', handleConnectionChange);

        // Początkowe sprawdzenie połączenia
        handleConnectionChange();

        return () => {
            window.removeEventListener('online', handleConnectionChange);
            window.removeEventListener('offline', handleConnectionChange);
        };
    }, []);

    if (!showStatus) return null;

    const getStatusMessage = () => {
        switch (connectionStatus) {
            case 'online':
                return 'Połączono z internetem';
            case 'unstable':
                return 'Niestabilne połączenie z internetem';
            case 'offline':
                return 'Brak połączenia z internetem';
            default:
                return '';
        }
    };

    const getStatusIcon = () => {
        switch (connectionStatus) {
            case 'online':
                return <FaWifi className="inline mr-2" />;
            case 'unstable':
                return <FaExclamationTriangle className="inline mr-2" />;
            case 'offline':
                return <PiWifiSlash className="inline mr-2" />;
            default:
                return null;
        }
    };

    const statusColor = connectionStatus === 'online' ? 'bg-green-500' : connectionStatus === 'unstable' ? 'bg-yellow-500' : 'bg-red-500';

    return (
        <div
            className={`fixed bottom-0 left-0 w-full p-2 text-center transition-transform text-white ${statusColor} transition-transform duration-500 ${showStatus ? 'translate-y-0' : 'translate-y-full'}`}
            style={{ zIndex: 1000 }}
        >
            {getStatusIcon()}
            {getStatusMessage()}
        </div>
    );
};

export default ConnectionStatus;
