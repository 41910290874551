import { StyleSheet, Font } from '@react-pdf/renderer';

import Regular from '../../fonts/OpenSans-Regular.ttf'
import Semibold from '../../fonts/OpenSans-SemiBold.ttf'
import Bold from '../../fonts/OpenSans-Bold.ttf'
import Extrabold from '../../fonts/OpenSans-ExtraBold.ttf'

Font.register({
  family: "Open Sans",
  fonts: [
    { src: Regular },
    { src: Semibold, fontWeight: 600 },
    { src: Bold, fontWeight: 700 },
    { src: Extrabold, fontWeight: 800 },

  ]
});


const styles = StyleSheet.create({
  page: {
    flexDirection: 'column',
    alignItems: 'center',
    fontFamily: 'Open Sans',
  },
  backgroundImage: {
    position: 'absolute',
    height: '100%',
    width: '100%',
    zIndex: -1,
    top: 0,
    left: 0,
  },
  title: {
    textAlign: 'center',
    marginTop: 30,
    fontSize: 32,
    fontWeight: 600,
    letterSpacing: 5,
  },
  certification: {
    textAlign: 'center',
    fontSize: 10,
    marginTop: 0,
    letterSpacing: 5,
  },
  imageContainer: {
    marginTop: 28,
    paddingVertical: 8,
    alignItems: 'center',
    width: 520, // szerokość w pikselach
    height: 320,
    borderRadius: 10,
    overflow: 'hidden',
  },
  image: {
    width: '100%',
    height: '100%',
    borderRadius: 10,
    resizeMode: 'contain',
  },
  hotelName: {
    textAlign: 'center',
    // fontSize: 48,
    marginTop: 16,
    fontWeight: 700,
  },
  scoreContainer: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    marginTop: 40,
  },
  scoreItem: {
    width: '45%',
    alignItems: 'center',
  },
  scoreText: {
    textAlign: 'center',
    fontSize: 14,
    fontWeight: 700,
  },
  scoreValue: {
    textAlign: 'center',
    fontSize: 36,
    fontWeight: 700,
  },

  ha_container: {
    width: '100',
    height: 20,
    backgroundColor: '#666',
    borderRadius: 10,
    overflow: 'hidden',
    justifyContent: 'center',
    alignItems: 'center',
    position: 'relative',
  },
  ha_chart: {
    height: '100%',
    backgroundColor: '#000',
    borderRadius: 10,
    position: 'absolute',
    left: 0,
    top: 0,
  },
  ha_percentage: {
    color: '#000',
    fontSize: 18,
    fontWeight: 800,
    fontWeight: 700,
    paddingLeft: 10,
  },
  certificateContainer: {
    marginTop: 8,
    position: 'absolute',
    left: 10,
    bottom: 25,
    textAlign: 'center',
  },
  cerificateImage: {
    width: 100,
    height: 100,
    zIndex: 100,
    paddingLeft: 10,
  },
  certificateText: {
    fontSize: 10,
    width: 140,
    fontWeight: 700,
  },

  signature: {
    borderTopWidth: 1,
    borderTopColor: '#000',
    width: '20%',
    marginHorizontal: 'auto',
    paddingTop: 5,
    fontSize: 12,
    textAlign: 'center',
    position: 'absolute',
    bottom: 50,
  },
  auditDate: {
    position: 'absolute',
    bottom: 20,
    right: 20,
    textAlign: 'center',
    fontSize: 10,
    fontWeight: '700'
  },
  auditDateValue: {
    fontSize: 20,
    fontWeight: 600,
    bottom: 2,
    right: 25,
  },
  footer: {
    position: 'absolute',
    bottom: 16,
    left: 0,
    right: 0,
    textAlign: 'center',
    fontSize: 12,
  },
  mainfooter: {
    width: '100%',
    height: 40,
    borderTopWidth: 2,
    borderTopColor: '#000',
    flexDirection: 'row',
    alignItems: 'center',
    position: 'absolute',
    bottom: 0,
    display: 'flex',
    justifyContent: 'space-between',

  },
  footerText: {
    fontSize: 8,
    width: '54%',
    textAlign: 'center',
    alignContent: 'center',
    marginRight: 35,
    fontFamily: 'Open Sans'
  },
  footerLinkText: {
    fontSize: 8,
    fontWeight: '600',
    marginLeft: 15,
  },
  pageNumber: {
    fontSize: 8,
    fontWeight: '600',
    marginRight: 15,
  },

  // PAGE 2 
  statsTitle: {
    fontSize: 48,
    textAlign: 'center',
    fontWeight: '700',
    marginTop: 10,
    marginBottom: 30,
  },
  startSubtitle: {
    fontSize: 18,
    textAlign: 'center',
    fontWeight: '600',
  },
  startSubSubtitle: {
    fontSize: 16,
    textAlign: 'center',
    fontWeight: '600',
    marginTop: 10,
  },
  box: {
    width: "90%",
    marginLeft: 'auto',
    marginRight: 'auto',
  },

  statsContainer: {
    flexDirection: 'row',
    width: '100%',
  },
  labelsSection: {
    width: '20%',
  },
  label: {
    marginBottom: 14,
    height: 30,
    justifyContent: 'center',
    alignItems: 'center',
    textAlign: 'center',
    paddingRight: 2,
    fontSize: '12',
  },
  statsLabel: {
    height: 20,
    alignItems: 'center',
    fontSize: '9',
  },
  labelText: {
    color: 'black',
    fontSize: 8,
  },
  bars: {
    width: '100%',
    flexDirection: 'column',
    position: 'relative',
    marginTop: 30,
  },
  barContainer: {
    alignItems: 'flex-start',
    marginBottom: 14,
    textAlign: 'right',
    width: '76%',
    justifyContent: 'center',
  },
  statsBarContainer: {
    flexDirection: 'row',
    alignItems: 'center',
    marginBottom: 2,
    flex: 1,
    position: 'relative', // Dodane dla kontenera paska

  },
  bar: {
    height: 30,
    backgroundColor: '#bbb',
    borderTopRightRadius: '25%',
    borderBottomRightRadius: '25%',
    marginTop: 0,
    marginBottom: 0,

  },
  statsBar: {
    height: 20,
    backgroundColor: '#bbb',
    borderTopRightRadius: '25%',
    borderBottomRightRadius: '25%',
    marginTop: 0,
    marginBottom: 0,
    position: 'relative', // Dodane dla kontenera paska
  },
  mainStartsBar: {
    height: 20,
    backgroundColor: '#bbb',
    borderTopRightRadius: '25%',
    borderBottomRightRadius: '25%',
    marginTop: 0,
    marginBottom: 0,
    position: 'relative', // Dodane dla kontenera paska
    justifyContent: 'center',

  },
  barText: {
    fontSize: 16,
    fontWeight: 'bold',
    paddingRight: 10,
    color: 'black',
    justifyContent: 'center',
  },
  statsBarText: {
    fontSize: 14,
    fontWeight: 'bold',
    paddingLeft: 6,
    color: 'black',
  },
  mainStatsBarText: {
    fontSize: 12,
    fontWeight: 'bold',
    paddingLeft: 6,
    color: 'black',
    textAlign: 'right',
    right: 7, // Pozycjonuje tekst na końcu paska
  },


  scaleContainer: {
    position: 'absolute',
    left: 117,
    top: 0,
    width: '78%',
    height: '100%',
  },
  scaleLine: {
    position: 'absolute',
    top: 0,
    left: 0,
    width: 1,
    height: '100%',
    backgroundColor: '#ccc',
  },
  scaleLabelContainer: {
    position: 'absolute',
    width: '78%',
    left: 116,
    bottom: -25,
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  scaleLabel: {
    fontSize: 10,
    textAlign: 'center',
  },
  secondSection: {
    fontSize: 6,
  },
  sectionTitle: {
    textAlign: 'center',
    fontSize: 16,
    fontWeight: 'bold',
    paddingTop: 15,
    paddingBottom: 15,
  },

  // PAGE 3
  questionContentWithPhoto: {
    flex: 1,
    paddingLeft: 10,
  },
  questionContent: {
    flexDirection: 'column',
  },
  contentContainer: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'flex-start',
    justifyContent: 'flex-start',
  },
  question: {
    fontSize: 14,
    fontWeight: 'bold',
    flex: 1,
    paddingRight: 10,
  },
  questionText: {
    width: '100%',
    fontWeight: 'bold',
    paddingLeft: 15,
    fontSize: 11,

  },
  questionAndAnswerContainer: {
    flexDirection: 'row',
    width: '100%',
    // alignItems: 'center',
    // alignContent: 'center',
  },
  answerIndicator: {
    width: 60,
    height: 20,
    justifyContent: 'center',
    alignItems: 'center',
    border: '1px solid #444'
  },
  answerText: {
    fontSize: 12,
    paddingRight: 3,
    color: 'white',
    fontWeight: 'bold',
    textTransform: 'uppercase',
  },
  noteSection: {
    marginTop: 4,
    backgroundColor: '#f0f0f0',
    borderRadius: 4,
  },
  noteText: {
    fontSize: 10,
    color: '#000',
  },

  // PAGE 4
  errorQuestionContainer: {
    flexDirection: 'row',
    alignItems: 'flex-start',
    marginTop: 5,
    marginTop: 5,
    // borderTop: '2px solid #444'
  },
  // photo: {
  //   width: 108,
  //   height: 80,
  //   marginTop: 10,
  //   marginBottom: 10,
  //   marginRight: 10,
  //   backgroundColor: '#e0e0e0',
  //   borderRadius: 5,
  // },
  // photoContainer: {
  //   display: 'flex',
  //   flexDirection: 'row',
  //   alignItems: 'flex-start',
  //   width: '80%',
  //   marginLeft: 70
  // },
  photoContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'flex-start',
    flexWrap: 'wrap', // This ensures the photos wrap to the next line if needed
    width: '80%',
    marginLeft: 70,
  },
  photo: {
    width: 108,
    height: 80,
    marginTop: 10,
    marginBottom: 10,
    marginRight: 10,
    backgroundColor: '#e0e0e0',
    borderRadius: 5,
  },
  errorQuestionContentWithPhoto: {
    flex: 1,
  },
  errorQuestionCheckbox: {
    marginRight: 5,
  },
  errorQuestionName: {
    fontWeight: 'bold',
    fontSize: 15.2,
  },
  aiResponse: {
    marginTop: 10,
    fontSize: 10,
  },
  actionPlanTitle: {
    marginTop: 10,
    marginBottom: 4,
    fontWeight: 'bold',
    fontSize: 11.5,
  },
  checkboxContainer: {
    flexDirection: 'row',
    alignItems: 'center',
    marginBottom: 5,
  },
  mainCheckboxContainer: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  topContainer: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'flex-start',
    marginBottom: 10,
    marginTop: 10,
    borderTop: '2px solid #444',
    paddingTop: 10

  },
  firstPhotoContainer: {
    width: '35%',
    paddingRight: 5,
  },
  firstPhoto: {
    width: '100%',
    height: '200',
    resizeMode: 'cover',
    borderRadius: 30,
  },
  contentContainer: {
    width: '65%',
    paddingLeft: 5,
  },
  fullWidth: {
    width: '100%',
  },
  checkbox: {
    width: 10,
    height: 10,
    borderWidth: 1,
    borderColor: '#000',
    marginRight: 8,
  },
  mainCheckbox: {
    width: 16,
    height: 16,
    paddingTop: 2,
    borderWidth: 2,
    borderColor: '#000',
    marginRight: 8,
  },
  sectionHeader: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    backgroundColor: '#ccc',
    borderRadius: 20,
    marginTop: 10,
    paddingVertical: 10,
    paddingHorizontal: 20,
    width: '100%'
  },
  ActionPlanSectionHeader: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    borderBottom: '1px dotted #000',
    borderTop: '1px dotted #000',
    marginTop: 10,
    paddingVertical: 10,
    paddingHorizontal: 2,
    width: '100%'
  },
  SubSectionHeader: {
    alignItems: 'center',
    textAlign: 'center',
    borderBottom: '1px solid #ccc',
    borderTop: '1px solid #ccc',
    marginTop: 10,
    paddingVertical: 10,
    paddingHorizontal: 2,
    width: '100%',
    fontSize: 16,
    fontWeight: 'bold'
  },
  logo: {
    width: 40,
    marginRight: 10,
    height: 20,
  },
  sectionName: {
    fontWeight: 'bold',
    fontSize: 16.5,
    textAlign: 'center',
  },
  hotelsAuditText: {
    fontSize: 12,
    textAlign: 'right',
  },
});

export default styles;