import React, { useEffect, useState, useRef } from 'react';
import { useParams, useLocation, Link } from 'react-router-dom';
import { PlusCircleIcon } from '@heroicons/react/outline';
import { notification } from 'antd';

import { OpenAI } from "openai";
import Papa from 'papaparse';
import axios from 'axios';

import { useAuth } from '../../context/AuthContext';
import Spinner from '../Spinner';
import QuestionSearchBar from './QuestionSearchBar';

// SVG imports
import { ReactComponent as TrashIcon } from '../../utils/svgs/trash-icon.svg';
import { ReactComponent as CopyIcon } from '../../utils/svgs/copy.svg';
import { ReactComponent as CreateSubIcon } from '../../utils/svgs/subsection.svg';
import { ReactComponent as ChartHistogramIcon } from '../../utils/dashboard/chart-histogram.svg';
import { ReactComponent as ChartPieIcon } from '../../utils/dashboard/chart-pie.svg';
import photoSvg from '../../utils/svgs/photo-upload.svg';
import commentSvg from '../../utils/svgs/comment.svg';
import aiSolutionSvg from '../../utils/svgs/ai-solution.svg';
import arrowLeft from '../../utils/dashboard/arrow-left.svg';
import infoSvg from '../../utils/dashboard/info.svg';

// Popup imports
import CommentPopup from '../Popups/CommentPopup';

import CreateGroupPopup from '../Popups/CreateGroupPopup';
import CreateSectionPopup from '../Popups/CreateSectionPopup';
import CommentResponsePopup from '../Popups/CommentResponsePopup';
import SectionActionPopup from '../Popups/SectionActionPopup';
import CreateSubSectionPopup from '../Popups/CreateSubSectionPopup';

import UserElement from '../UserElement';


// Helper functions
const saveToLocalStorage = (key, value) => {
  localStorage.setItem(key, JSON.stringify(value));
};

const getFromLocalStorage = (key, defaultValue) => {
  const storedValue = localStorage.getItem(key);
  return storedValue ? JSON.parse(storedValue) : defaultValue;
};

const SectionList = ({ setAudit, setIsPreviewPopupVisible }) => {
  const { authTokens, allAudits, setCurrentAudit, currentAudit, userInfo } = useAuth();
  const backendUrl = process.env.REACT_APP_BACKEND_URL;
  let { auditId } = useParams();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const filterSections = queryParams.get('filter');

  // Main data states
  const [sections, setSections] = useState([]);
  const [selectedAnswers, setSelectedAnswers] = useState({});
  const [isLoading, setIsLoading] = useState(true);
  const [isEditMode, setIsEditMode] = useState(false);

  // UI states
  const [expandedSubSection, setExpandedSubSection] = useState(getFromLocalStorage(`${auditId}_expandedSubSection`, null));
  const [expandedSection, setExpandedSection] = useState(getFromLocalStorage(`${auditId}_expandedSection`, null));
  const [expandedGroup, setExpandedGroup] = useState(getFromLocalStorage(`${auditId}_expandedGroup`, null));
  const [expandedInfo, setExpandedInfo] = useState(null);

  // Audit info states
  const [CoveragePhoto, setCoveragePhoto] = useState('');
  const [auditTitle, setAuditTitle] = useState('');
  const [auditCity, setAuditCity] = useState('');
  const [auditStreet, setAuditStreet] = useState('');
  const [StartDate, setStartDate] = useState('');
  const [EndDate, setEndDate] = useState('');

  // Question data states
  const [currentQuestionNumber, setCurrentQuestionNumber] = useState(null);
  const [comments, setComments] = useState({});
  const [currentComment, setCurrentComment] = useState('');
  const [editingCommentId, setEditingCommentId] = useState(null);
  const [aiSolutions, setAiSolutions] = useState({});
  const [editingAiSolutionId, setEditingAiSolutionId] = useState(null);
  const [currentAiSolution, setCurrentAiSolution] = useState('');
  const [selectedPhotos, setSelectedPhotos] = useState(null);
  const [photos, setPhotos] = useState(new Map());
  const [fullSizePhoto, setFullSizePhoto] = useState(null);
  const [editableQuestion, setEditableQuestion] = useState({ id: null, question: '' });
  const [editingQuestionId, setEditingQuestionId] = useState(null);
  const [currentQuestionText, setCurrentQuestionText] = useState('');

  // Section editing states
  const [currentQuestionSection, setcurrentQuestionSection] = useState(null);
  const [isEditingSection, setIsEditingSection] = useState(false);
  const [editingSectionId, setEditingSectionId] = useState(null);
  const [editingSectionName, setEditingSectionName] = useState('');
  const [newSectionName, setNewSectionName] = useState('');
  const [isEditingSubSection, setIsEditingSubSection] = useState(false);
  const [editingSubSectionId, setEditingSubSectionId] = useState(null);
  const [editingSubSectionName, setEditingSubSectionName] = useState('');
  const [newSubSectionName, setNewSubSectionName] = useState('');

  // Group editing states
  const [currentQuestionGroup, setcurrentQuestionGroup] = useState(null);
  const [isEditingGroup, setIsEditingGroup] = useState(false);
  const [editingGroupId, setEditingGroupId] = useState(null);
  const [editingGroupName, setEditingGroupName] = useState('');
  const [newGroupName, setNewGroupName] = useState('');

  // Subsection states
  const [isSubsection, setIsSubsection] = useState(false);
  const [currentSubsection, setCurrentSubsection] = useState(null);

  // Popup visibility states
  const [isCommentPopupVisible, setIsCommentPopupVisible] = useState(false);
  const [isCommentResponsePopupVisible, setIsCommentResponsePopupVisible] = useState(false);
  const [isEditPopupVisible, setIsEditPopupVisible] = useState(false);
  const [isCreateGroupPopupVisible, setIsCreateGroupPopupVisible] = useState(false);
  const [isCreateSectionPopupVisible, setIsCreateSectionPopupVisible] = useState(false);
  const [isCreateSubSectionPopupVisible, setIsCreateSubSectionPopupVisible] = useState(false);
  const [isSectionActionPopupVisible, setIsSectionActionPopupVisible] = useState(false);

  // Other states
  const [currentSection, setCurrentSection] = useState(null);
  const [generatingQuestionId, setGeneratingQuestionId] = useState(null);

  // Refs
  const commentPopupRef = useRef();
  const aiSolutionPopupRef = useRef();
  const commentResponsePopupRef = useRef();
  const fullSizePhotoRef = useRef();
  const createQuestionPopupRef = useRef();
  const createGroupPopupRef = useRef();
  const createSectionPopupRef = useRef();
  const editQuestionPopupRef = useRef();

  const currentPage = 2;

  // Funkcje do obslugi danych 
  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);
      try {
        const response = await fetch(`${backendUrl}/audit-page/audits/${auditId}/`, {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${authTokens?.access || ''}`,
          },
        });

        if (!response.ok) throw new Error('Network response was not ok.');

        const data = await response.json();
        console.log('DATA FROM API: ', data);

        const processedSections = processSections(data.sections, filterSections);

        // Update states with processed data
        setSections(processedSections);
        setCoveragePhoto(data.cover_photo || '');
        setAuditTitle(data.audit_title || '');
        setAuditCity(data.audit_city || '');
        setAuditStreet(data.audit_street || '');
        setStartDate(data.start_date || '');
        setEndDate(data.end_date || '');
        handleOfflineResponses();
        handleOfflineComments();
        updateUIStates(processedSections);

        // Load saved answers from localStorage
        let savedAnswers = JSON.parse(localStorage.getItem(`selectedAnswers_${auditId}`)) || {};

        // If there are no saved answers in localStorage, load from API data
        const answersFromAPI = {};
        data.sections.forEach(section => {
          section.groups.forEach(group => {
            group.questions.forEach(question => {
              if (question.answer) {
                answersFromAPI[question.id] = { answer: question.answer };
              }
            });
          });
          if (section.subSections) {
            section.subSections.forEach(subSection => {
              subSection.groups.forEach(group => {
                group.questions.forEach(question => {
                  if (question.answer) {
                    answersFromAPI[question.id] = { answer: question.answer };
                  }
                });
              });
            });
          }
        });
        // Merge answers from localStorage with answers from API
        const mergedAnswers = { ...answersFromAPI, ...savedAnswers };

        // Save merged answers to localStorage and update state
        localStorage.setItem(`selectedAnswers_${auditId}`, JSON.stringify(mergedAnswers));
        setSelectedAnswers(mergedAnswers);
      } catch (error) {
        console.error('Error:', error);
      } finally {
        setIsLoading(false);
      }
    };

    fetchData();
  }, [backendUrl, auditId, filterSections]);

  // Save selectedAnswers to localStorage whenever it changes
  useEffect(() => {
    localStorage.setItem(`selectedAnswers_${auditId}`, JSON.stringify(selectedAnswers));
  }, [selectedAnswers, auditId]);


  const processSections = (sections, filter) => {
    if (!filter) return sections;

    return sections.map(section => {
      // Process groups within the section
      const filteredGroups = section.groups.filter(group =>
        group.questions.some(question => question?.answer === filter)
      ).map(group => ({
        ...group,
        questions: group.questions.filter(question => question?.answer === filter)
      }));

      // Process subsections within the section
      const filteredSubSections = section.subSections ? section.subSections.filter(subSection =>
        subSection.groups.some(group =>
          group.questions.some(question => question?.answer === filter)
        )
      ).map(subSection => ({
        ...subSection,
        groups: subSection.groups.map(group => ({
          ...group,
          questions: group.questions.filter(question => question?.answer === filter)
        })).filter(group => group.questions.length > 0)
      })) : [];

      return {
        ...section,
        groups: filteredGroups.filter(group => group.questions.length > 0),
        subSections: filteredSubSections.filter(subSection => subSection.groups.length > 0)
      };
    }).filter(section => section.groups.length > 0 || section.subSections.length > 0);
  };


  // Funkcja do pobierania identyfikatora serwera na podstawie lokalnego identyfikatora
  const getServerId = (localId) => {
    let idMapping = JSON.parse(localStorage.getItem('idMapping')) || {};
    return idMapping[localId] || localId;
  };

  const handleOfflineResponses = () => {
    const offlineResponses = JSON.parse(localStorage.getItem('offlineResponses')) || [];
    offlineResponses.forEach(response => {
      saveResponse(response.question, response.answer);
    });
    localStorage.removeItem('offlineResponses');
  };

  const handleOfflineComments = () => {
    const offlineComments = JSON.parse(localStorage.getItem('comments')) || [];
    Object.keys(offlineComments).forEach(questionId => {
      const comment = offlineComments[questionId];
      saveComment(questionId, comment.note);
    });
    localStorage.removeItem('comments');
  };

  const updateUIStates = (sections) => {
    const initialSectionsState = {};
    const initialGroupsState = {};
    const updatedAnswers = {};
    const updatedComments = {};
    const updatedAiSolutions = {};
    let updatedPhotos = new Map();

    const processGroup = (sectionId, group) => {
      initialGroupsState[`${sectionId}_${group.id}`] = true;
      group.questions.forEach((question) => {
        if (question.answer) updatedAnswers[question.id] = { answer: question.answer };
        if (question.note) updatedComments[question.id] = { note: question.note };
        if (question.photos) updatedPhotos.set(question.id, question.photos);
        if (question.ai_solution) updatedAiSolutions[question.id] = { ai_solution: question.ai_solution };
      });
    };

    const processSubSections = (sectionId, subSections) => {
      subSections.forEach((subSection) => {
        subSection.groups.forEach((group) => {
          processGroup(sectionId, group);
        });
        if (subSection.subSections) {
          processSubSections(sectionId, subSection.subSections);
        }
      });
    };

    sections.forEach((section) => {
      initialSectionsState[section.id] = true;
      section.groups.forEach((group) => {
        processGroup(section.id, group);
      });
      if (section.subSections) {
        processSubSections(section.id, section.subSections);
      }
    });

    setSelectedAnswers(updatedAnswers);
    setComments(updatedComments);
    setAiSolutions(updatedAiSolutions);
    setPhotos(updatedPhotos);
  };

  const saveResponse = async (questionId, answer) => {
    console.log(`Saving response for questionId: ${questionId}, answer: ${answer}`);

    // Zamiana lokalnego ID na serwerowe (jeśli to konieczne)
    let serverQuestionId = questionId;
    if (String(questionId).startsWith("local-")) {
      const realId = getServerId(questionId);
      if (realId) {
        serverQuestionId = realId;
      }
    }

    const data = {
      audit: auditId,
      question: serverQuestionId,
      answer: answer,
    };

    const handleResponse = async (res) => {
      if (res.ok) {
        console.log('Odpowiedź zapisana pomyślnie');
        setSelectedAnswers((prevAnswers) => ({
          ...prevAnswers,
          [questionId]: { answer: answer },
        }));

        // Pobierz offlineResponses z localStorage
        let offlineResponses = JSON.parse(localStorage.getItem('offlineResponses')) || [];

        // Usuwanie lokalnych odpowiedzi po pomyślnym zapisie
        const updatedResponses = offlineResponses.filter(res => res.question !== questionId);
        localStorage.setItem('offlineResponses', JSON.stringify(updatedResponses));

        // notification.success({
        //   message: 'Zapisano',
        //   description: 'Odpowiedź zapisana pomyślnie',
        // });
      } else {
        console.error('Wystąpił błąd podczas zapisywania odpowiedzi');
        saveResponseOffline(questionId, answer); // Zapisz lokalnie
      }
    };

    // Sprawdzenie stanu połączenia internetowego
    console.log(`Navigator online status: ${navigator.onLine}`);
    if (navigator.onLine) {
      try {
        const res = await fetch(`${backendUrl}/audit-page/audit-response/`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${authTokens?.access || ''}`,
          },
          body: JSON.stringify(data),
        });
        console.log('Server response:', res);
        handleResponse(res);
      } catch (error) {
        console.error('Wystąpił błąd podczas zapisywania odpowiedzi', error);
        saveResponseOffline(questionId, answer); // Zapisz lokalnie
      }
    } else {
      notification.info({
        message: 'Zapisano offline',
        description: 'Odpowiedź zapisana lokalnie',
      });
      saveResponseOffline(questionId, answer); // Zapisz lokalnie
    }
  };

  const saveResponseOffline = (questionId, answer) => {
    // Aktualizowanie offlineResponses w localStorage
    let offlineResponses = JSON.parse(localStorage.getItem('offlineResponses')) || [];
    const existingResponseIndex = offlineResponses.findIndex(res => res.question === questionId);

    if (existingResponseIndex !== -1) {
      offlineResponses[existingResponseIndex].answer = answer; // Aktualizuj odpowiedź
    } else {
      offlineResponses.push({ audit: auditId, question: questionId, answer: answer }); // Dodaj nową odpowiedź
    }

    localStorage.setItem('offlineResponses', JSON.stringify(offlineResponses));
    console.log('Zapisano odpowiedź lokalnie:', offlineResponses);
    setSelectedAnswers((prevAnswers) => ({
      ...prevAnswers,
      [questionId]: { answer: answer },
    }));
  };


  const uploadOfflineResponses = async () => {
    let offlineResponses = JSON.parse(localStorage.getItem('offlineResponses')) || [];

    if (offlineResponses.length === 0) {
      console.log('No offline responses to upload.');
      return;
    }

    // Iteracja przez wszystkie lokalne odpowiedzi
    for (let response of offlineResponses) {
      const { audit, question, answer } = response;

      const data = {
        audit: auditId,
        question: getServerId(question), // Zamieniamy lokalne ID na serwerowe (jeśli to konieczne)
        answer,
      };

      try {
        const res = await fetch(`${backendUrl}/audit-page/audit-response/`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${authTokens?.access || ''}`,
          },
          body: JSON.stringify(data),
        });

        if (res.ok) {
          console.log(`Odpowiedź dla pytania ${question} przesłana pomyślnie.`);
          let serverQuestionId = getServerId(question) || question; // Użyj ID serwera lub lokalnego

          // Pobierz bieżący stan i zaktualizuj odpowiedzi
          setSelectedAnswers((prevAnswers) => {
            return {
              ...prevAnswers,
              [serverQuestionId]: { answer: answer },
            };
          });
          console.log(selectedAnswers)

          console.log("update dla pytania: ", serverQuestionId);

          // Usuń zsynchronizowaną odpowiedź z localStorage
          offlineResponses = offlineResponses.filter(
            (r) => r.question !== question || r.audit !== audit
          );
          localStorage.setItem('offlineResponses', JSON.stringify(offlineResponses));

        } else {
          console.error(`Błąd podczas synchronizacji odpowiedzi dla pytania ${question}.`);
        }
      } catch (error) {
        console.error(`Wystąpił błąd podczas synchronizacji odpowiedzi dla pytania ${question}:`, error);
      }
    }

    notification.success({
      message: 'Synchronizacja udana',
      description: 'Odpowiedzi zostały zsynchronizowane pomyślnie.',
    });
  };





  // Funkcja obsługująca dodawanie komentarzy do pytań
  const saveComment = async (questionId, note) => {
    if (note == comments[questionId]?.note) {
      return
    }
    console.log(`Saving comment for questionId: ${questionId}, note: ${note}`);
    const serverQuestionId = getServerId(questionId); // Pobieramy rzeczywiste ID z serwera
    const data = {
      audit: auditId,
      question: serverQuestionId,
      note: note, // Przesyłamy aktualny komentarz
    };

    const handleResponse = (res) => {
      if (res.ok) {
        console.log('Komentarz zapisany pomyślnie');
        setComments((prevComments) => ({
          ...prevComments,
          [questionId]: { note: note },
        }));
        closeCommentPopup(); // Zamknij popup po zapisaniu komentarza
        notification.success({
          message: 'Zapisano',
          description: 'Komentarz zapisany pomyślnie',
        });
      } else {
        console.error('Wystąpił błąd podczas zapisywania komentarza');
        const updatedComments = { ...comments, [questionId]: { note: note } };
        localStorage.setItem('comments', JSON.stringify(updatedComments));
        setComments(updatedComments); // Aktualizuj lokalny stan komentarzy
        closeCommentPopup(); // Zamknij popup po zapisaniu komentarza
      }
    };

    // Sprawdź stan połączenia internetowego
    console.log(`Navigator online status: ${navigator.onLine}`);
    if (navigator.onLine) {
      try {
        const res = await fetch(`${backendUrl}/audit-page/audit-response/`, {  // Upewnij się, że URL jest poprawny
          method: 'PATCH',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${authTokens?.access || ''}`,
          },
          body: JSON.stringify(data),
        });
        console.log('Server response:', res);
        handleResponse(res);



        // Po pomyślnym zapisaniu komentarza, generuj odpowiedź AI
        // Sprawdź, czy to pierwsze dodanie komentarza

        // const existingComment = comments[questionId]?.note;
        // if (!existingComment || existingComment.trim() === '') {
        //   // Generuj odpowiedź AI tylko jeśli to pierwsze dodanie komentarza
        //   await generateAndSaveAiSolution(note, questionId);
        // }



      } catch (error) {
        console.error('Wystąpił błąd podczas zapisywania komentarza', error);
        closeCommentPopup(); // Zamknij popup po zapisaniu komentarza
      }
    } else {
      notification.info({
        message: 'Zapisano offline',
        description: 'Zapisano komentarz lokalnie',
      });

      const updatedComments = { ...comments, [questionId]: { note: note } };
      const existingComments = JSON.parse(localStorage.getItem('comments')) || {};
      existingComments[questionId] = { note: note };
      localStorage.setItem('comments', JSON.stringify(existingComments));
      console.log(`Updated comments:`, existingComments);
      setComments(updatedComments); // Aktualizuj lokalny stan komentarzy
      closeCommentPopup(); // Zamknij popup po zapisaniu komentarza
    }
  };


  // Funkcja generująca odpowiedź AI i zapisująca ją automatycznie
  const generateAndSaveAiSolution = async (comment, questionId, questionText) => {
    if (!comment) {
      notification.error({
        message: 'Błąd',
        description: 'Brak komentarza',
      });
      return;
    }
    let questionAnswer = selectedAnswers[questionId]?.answer
    console.log(questionText, questionAnswer)
    setGeneratingQuestionId(questionId); // Ustaw stan na questionId przed rozpoczęciem generowania

    const openai = new OpenAI({
      apiKey: process.env.REACT_APP_OPENAI_API_KEY,
      dangerouslyAllowBrowser: true
    });

    // const promptSystemMessage = {
    //   role: "system",
    //   // content: "Jesteś ekspertem w zakresie audytu hotelowego, specjalizującym się w identyfikowaniu i rozwiązywaniu problemów związanych z obsługą gości i bezpieczeństwem. Twoim zadaniem jest rozwinięcie przedstawionych problemów w formie zwięzłych, konkretnych rekomendacji dla menadżerów hotelu. Dla każdego problemu, który opisze użytkownik, najpierw określ potencjalne ryzyka lub negatywne skutki wynikające z tego problemu, szczególnie te dotyczące doświadczeń gości oraz ich bezpieczeństwa. Następnie, zaproponuj proste, ale skuteczne rozwiązanie, które może zostać wdrożone przez zespół hotelowy. W swoich rekomendacjach używaj przyjaznego i konstruktywnego tonu. Zwracaj się do menadżerów hotelu jako 'Państwo'. Unikaj słów takich jak 'należy' lub 'natychmiast'. Zamiast tego używaj 'sugerujemy', 'zalecamy' lub 'zespół może wdrożyć'. Każda odpowiedź powinna składać się z dwóch części: najpierw opis problemu i jego potencjalnych skutków, a następnie propozycja rozwiązania. Proszę, aby odpowiedzi były zapisane w jednym akapicie, bez nowych linii między sekcjami."
    //   content: "Jesteś ekspertem w zakresie audytu hotelowego, specjalizującym się w identyfikowaniu i rozwiązywaniu problemów związanych z obsługą gości, bezpieczeństwem i Guest Experience. Twoim zadaniem jest rozwinięcie przedstawionych problemów w formie zwięzłych, konkretnych rekomendacji dla menadżerów hotelu. Dla każdego problemu, który opisze użytkownik, najpierw opisz zwięźle jeszcze raz jak wygląda dany problem. Następnie, zaproponuj proste, ale skuteczne rozwiązanie, które może zostać wdrożone przez zespół hotelowy. W swoich rekomendacjach używaj przyjaznego i konstruktywnego tonu. Zwracaj się do menadżerów hotelu jako 'Państwo'. Unikaj słów takich jak 'należy' lub 'natychmiast'. Zamiast tego używaj 'sugerujemy', 'zalecamy' lub 'zespół może wdrożyć'. Każda odpowiedź powinna składać się z dwóch części: najpierw opis problemu i jego potencjalnych skutków, a następnie propozycja rozwiązania. Proszę, aby odpowiedzi były zapisane w jednym akapicie, bez nowych linii między sekcjami i jako ciągły tekst bez rozdzielania wizualnie na opis problemu itp. Tekst ma być bardzo zwięzły nie rozpisuj się zbyt bardzo. NIE rodzielać tekstu na np. 'Problem:' i 'Sugestia:', wszystko ma być jednym ciągłym tekstem"
    // };
    //     const promptSystemMessage = {
    //       role: "system",
    //       content: `"Tworzę raport z audytu w hotelu. Przedstawię tutaj problemy, które się pojawiły, i napisz mi sugestie rozwiązań problemu według poniższego schematu:

    // 1. Zaczynam od opisu problemu, zaczynając od słowa 'Problem:', po czym krótko przedstawiam, co jest nie tak.
    // 2. Następnie formułuję 'Sugestia rozwiązania:' i podaję, co można zrobić, aby rozwiązać problem.

    // Każda odpowiedź powinna być zgodna z ustalonym wzorem. Problemy i sugestie rozwiązania muszą być zwięzłe, konkretne i napisane w stylu, który wypracowaliśmy podczas testów — np. 'Warto...', 'Można rozważyć...' itd. Odpowiedzi muszą być spójne z oczekiwanym formatem, który podałeś podczas testowania.

    // A oto przykłądy promptów i idealnych oczekiwanych od Ciebie odpowiedzi:
    // 'Jest tylko jedna osoba do obsługi całej sali restauracji, która całkowicie nie wyrabia i podeszła do nas z kartą po dłużczym czasie oczekiwania
    // Problem:
    // W restauracji obsługą całej sali zajmuje się tylko jedna osoba, co powoduje opóźnienia w obsłudze gości. Goście muszą długo czekać, zanim otrzymają menu, co negatywnie wpływa na ich wrażenia i zadowolenie z usług.

    // Sugestia rozwiązania:
    // Warto rozważyć zwiększenie liczby personelu podczas godzin szczytu, aby usprawnić obsługę sali i skrócić czas oczekiwania na podejście do stolika oraz otrzymanie karty. Alternatywnie, można zadbać o lepszą organizację pracy, np. poprzez szybkie powitanie gości i przekazanie menu tuż po ich przyjściu, nawet jeśli pełna obsługa zostanie wykonana później.
    // '
    // 'Personel w żaden sposób nie usadzą gości w restauracji, nie wskazuje miejsca ani nie podprowadzą do stolika. Gdy wybrałem sobie miejsce, w którym chciałem usiąść zostałem przesadzony bliżej wejścia, gdyż przygotowywana była strefa obiadokolacji. 

    // Pierwsze wrażenie dramatyczne, personel całkowicie niezainteresowany, zero uśmiechu, przywitania, czegokolwiek. 
    // Problem:
    // Personel w restauracji nie pomaga gościom przy wyborze miejsca, nie wskazuje stolika ani nie podprowadza do niego. Goście muszą sami szukać miejsc, co może być niewygodne. Dodatkowo, po samodzielnym wybraniu miejsca, goście są przesadzani bliżej wejścia z powodu przygotowywania strefy na obiadokolację. Brak powitania, uśmiechu i zaangażowania ze strony personelu negatywnie wpływa na pierwsze wrażenie.

    // Sugestia rozwiązania:
    // Warto zachęcić personel do bardziej aktywnej obsługi, polegającej na witaniu gości przy wejściu oraz proponowaniu odpowiedniego stolika, zamiast pozwalać gościom na samodzielne szukanie miejsc. Jeśli konieczne jest przesadzenie gości, powinno to odbywać się w uprzejmy sposób, z odpowiednim wyjaśnieniem sytuacji. Ważnym elementem jest także zadbanie o uśmiech i miłe przywitanie, które pozytywnie wpływają na pierwsze wrażenie.
    // '
    // 'Brak upsellingu ze strony personelu, nikt nie chodzi i nie pyta, czy gość sobie czegoś dodatkowo życzy do picia np z baru. Takie rozwiązanie stosują z powodzeniem inne hotele i dzięki temu nawet podczas śniadania sprzedają dodatkowe produkty z baru

    // Problem:
    // Brak upsellingu ze strony personelu – nikt nie pyta gości podczas śniadania, czy życzą sobie coś dodatkowego do picia, np. z baru. Inne hotele z powodzeniem stosują takie podejście, co pozwala im zwiększyć sprzedaż dodatkowych produktów, nawet podczas śniadania.

    // Sugestia rozwiązania:
    // Warto rozważyć zachęcenie personelu do aktywnego oferowania gościom dodatkowych napojów lub produktów z baru podczas śniadania. Może to być prosta forma upsellingu, polegająca na regularnym pytaniu, czy gość chciałby zamówić coś dodatkowego, np. świeżo wyciskany sok, kawę specjalną czy drink bezalkoholowy.
    // '
    // "`
    //     };

    // const newPrompt = `
    //       Tworzę raport z audytu w hotelu. Przedstawiam problemy, które się pojawiły, i chciałbym, abyś zaproponował rozwiązania, przestrzegając poniższych zasad:
    //       - Opisz problem jeszcze raz zwięźle.
    //       - Zaproponuj proste i praktyczne rozwiązanie.
    //       - Pisz w formie sugestii, nie używaj trybu rozkazującego ani nie odwołuj się do formalnych działań (np. szkoleń).
    //       - Nie zaczynaj zdania od fraz typu: 'Problem polega na tym, że...' lub 'Sugerowane rozwiązanie polega na...'.
    //       ${comment}
    //       `

    const getExpandedNames = (sections, expandedSectionId, expandedSubSectionId, expandedGroupId) => {
      let expandedSectionName = '';
      let expandedSubSectionName = '';
      let expandedGroupName = '';

      // Znajdź nazwę rozwiniętej sekcji
      const expandedSection = sections.find(section => section.id === expandedSectionId);
      if (expandedSection) {
        expandedSectionName = expandedSection.name;

        // Sprawdź, czy sekcja zawiera podsekcje i czy podsekcja jest rozwinięta
        if (expandedSection.subSections && expandedSubSectionId) {
          // Znajdź nazwę rozwiniętej podsekcji
          const expandedSubSection = expandedSection.subSections.find(subSection => subSection.id === expandedSubSectionId);
          if (expandedSubSection) {
            expandedSubSectionName = expandedSubSection.name;

            // Znajdź nazwę rozwiniętej grupy w podsekcji
            const expandedGroup = expandedSubSection.groups.find(group => group.id === expandedGroupId);
            if (expandedGroup) {
              expandedGroupName = expandedGroup.name;
            }
          }
        } else {
          // Znajdź nazwę rozwiniętej grupy bezpośrednio w sekcji
          const expandedGroup = expandedSection.groups.find(group => group.id === expandedGroupId);
          if (expandedGroup) {
            expandedGroupName = expandedGroup.name;
          }
        }
      }

      return {
        sectionName: expandedSectionName,
        subSectionName: expandedSubSectionName,
        groupName: expandedGroupName
      };
    };

    // Przykład użycia
    const expandedNames = getExpandedNames(sections, expandedSection, expandedSubSection, expandedGroup);
    let promptSystemMessage, userPrompt;


    // if (questionAnswer === "yes") {
    //   promptSystemMessage = {
    //     role: "system",
    //     content: `Tworzę raport z audytu w hotelu. W obszarze, który badaliśmy, nie wykryto żadnych problemów. Jeśli masz dodatkowe sugestie dotyczące poprawy lub wdrożenia nowych rozwiązań, napisz je zgodnie z poniższym schematem:
    //         1. Zaczynam od sugestii, zaczynając od słowa 'Sugestia:', po czym krótko przedstawiam, co można dodać lub ulepszyć.
    //         Każda sugestia powinna być zwięzła, konkretna i napisana w stylu, który wypracowaliśmy podczas testów — np. 'Warto rozważyć...', 'Proponujemy wprowadzić...', 'Sugerujemy dodanie...' itd. Używaj tych sformułowań na zmianę. Wymyśl całkowicie nowe sugestie na bazie treści pytania, w którym nie wykryto problemów`
    //   };

    //   userPrompt = `(Dodatkowa informacja) Audyt dotyczy obszaru: ${expandedNames.sectionName}, ${expandedNames.subSectionName}, ${expandedNames.groupName}. Pytanie: '${questionText}' Odpowiedź: 'yes' — nie wykryto żadnych problemów. Możesz zasugerować dodatkowe ulepszenia lub zmiany.
    // Dodatkowe uwagi: ${comment}`;
    // } 


    promptSystemMessage = {
      role: "system",
      content: `Tworzę raport z audytu w hotelu. Przedstawię tutaj problemy, które się pojawiły, i napisz mi sugestie rozwiązań problemu według poniższego schematu:
            1. Zaczynam od opisu problemu, zaczynając od słowa 'Problem:', po czym krótko przedstawiam, co jest nie tak. 
            2. Następnie formułuję 'Sugestia rozwiązania:' i podaję, co można zrobić, aby rozwiązać problem.
            Każda odpowiedź powinna być zgodna z ustalonym wzorem. Problemy i sugestie rozwiązania muszą być zwięzłe, konkretne i napisane w stylu, który wypracowaliśmy podczas testów — np. 'Sugerujemy', 'Warto...', 'Można rozważyć...', 'Proponujemy rozważyć' (używaj tych zformułowań na zmianę) itd. Odpowiedzi muszą być spójne z oczekiwanym formatem, który podałeś podczas testowania. 
            Unika zwrotów 'należy' coś zrobić.
            (Dodatkowa informacja) Problem dotyczy obszaru: ${expandedNames.sectionName}, ${expandedNames.subSectionName}, ${expandedNames.groupName}. To treść pytania '${questionText}' oraz odpowiedź na to pytanie brzmi "nie"
            Przy opisie napisz na co wpływa ten problem. Nie pisz jak wpływa na gościa a na co wpływa, np. 'na sprzedaż, na bezpieczeństwo, na atmosferę itp.'
            `
    };
    userPrompt = `${comment}`;



    const chatCompletion = await openai.chat.completions.create({
      messages: [
        promptSystemMessage,
        { role: 'user', content: userPrompt }
      ],
      // model: 'ft:gpt-4o-mini-2024-07-18:personal:guestify-new2:AFjiNFLc:ckpt-step-90',
      model: 'ft:gpt-4o-mini-2024-07-18:personal:guestify-new-3:AHUxd3zq',
    });

    let aiSolution = chatCompletion.choices[0].message.content;

    // Usunięcie nowej linii między akapitami
    aiSolution = aiSolution
      .replace(/Problem: /g, '')
      .replace(/Sugestia rozwiązania: /g, '\n\n')
      .replace(/\n+/g, '\n')
      .trim();

    // Zapisanie rozwiązania AI
    const aiData = {
      audit: auditId,
      question: getServerId(questionId),
      ai_solution: aiSolution,
    };

    try {
      const res = await fetch(`${backendUrl}/audit-page/audit-response/`, {
        method: 'PATCH',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${authTokens?.access || ''}`,
        },
        body: JSON.stringify(aiData),
      });

      if (res.ok) {
        console.log('Rozwiązanie AI zapisane pomyślnie');
        setAiSolutions((prevAiSolutions) => ({
          ...prevAiSolutions,
          [questionId]: { ai_solution: aiSolution },
        }));
      } else {
        console.error('Wystąpił błąd podczas zapisywania rozwiązania AI');
      }
    } catch (error) {
      console.error('Wystąpił błąd podczas zapisywania rozwiązania AI', error);
    } finally {
      setGeneratingQuestionId(null); // Ustaw stan na null po zakończeniu generowania
    }
  };



  const saveCommentAndResponse = () => {
    const data = {
      audit: auditId,
      question: currentQuestionNumber,
      // note: currentComment, // Aktualny komentarz
      ai_solution: currentAiSolution, // Aktualne rozwiązanie AI
    };

    // Wywołanie API w celu zapisania komentarza i rozwiązania AI
    fetch(`${backendUrl}/audit-page/audit-response/`, {
      method: 'PATCH',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${authTokens?.access || ''}`,
      },
      body: JSON.stringify(data),
    })
      .then((res) => {
        if (res.ok) {
          console.log('Komentarz i rozwiązanie AI zapisane pomyślnie');
          // setComments((prevComments) => ({
          //   ...prevComments,
          //   [currentQuestionNumber]: { note: currentComment },
          // }));
          setAiSolutions((prevAiSolutions) => ({
            ...prevAiSolutions,
            [currentQuestionNumber]: { ai_solution: currentAiSolution },
          }));
          closeCommentPopup();
          notification.success({
            message: 'Sukces',
            description: 'Poprawnie Zapisano komentarz i rozwiązanie AI',
          });
        } else {
          console.error('Wystąpił błąd podczas zapisywania komentarza i rozwiązania AI');
          const updatedComments = { ...comments, [currentQuestionNumber]: { note: currentComment } };
          localStorage.setItem('comments', JSON.stringify(updatedComments));
          setComments(updatedComments);
          closeCommentPopup();
          notification.error({
            message: 'Wystąpił błąd',
            description: 'Nie udało się zapisać komentarza i rozwiązania AI',
          });
        }
      })
      .catch((error) => {
        console.error('Wystąpił błąd podczas zapisywania komentarza i rozwiązania AI', error);
        const updatedComments = { ...comments, [currentQuestionNumber]: { note: currentComment } };
        localStorage.setItem('comments', JSON.stringify(updatedComments));
        setComments(updatedComments);
        closeCommentPopup();

        notification.error({
          message: 'Wystąpił błąd',
          description: 'Wystąpił błąd przy zapisnie komentarza',
        });
      });
  };


  // Funkcja do zapisywania komentarza w localStorage
  useEffect(() => {
    localStorage.setItem('currentComment', currentComment);
  }, [currentComment]);

  const saveAiSolution = async (questionId) => {
    // Sprawdź, czy AI Solution się zmieniło
    if (currentAiSolution !== aiSolutions[questionId]?.ai_solution) {
      const data = {
        audit: auditId,
        question: getServerId(questionId), // Pobieramy rzeczywiste ID z serwera
        ai_solution: currentAiSolution, // Przesyłamy aktualne rozwiązanie AI
      };

      const handleResponse = (res) => {
        if (res.ok) {
          console.log('Rozwiązanie AI zapisane pomyślnie');
          setAiSolutions((prevAiSolutions) => ({
            ...prevAiSolutions,
            [questionId]: { ai_solution: currentAiSolution },
          }));
          setEditingAiSolutionId(null); // Zamknij edycję po zapisaniu rozwiązania AI
          notification.success({
            message: 'Zapisano',
            description: 'Rozwiązanie AI zapisane pomyślnie',
          });
        } else {
          console.error('Błąd podczas zapisywania rozwiązania AI na serwerze');
          const updatedAiSolutions = {
            ...aiSolutions,
            [questionId]: { ai_solution: currentAiSolution },
          }
          setAiSolutions(updatedAiSolutions); // Aktualizuj lokalny stan AI Solutions
          setEditingAiSolutionId(null); // Zamknij edycję po zapisaniu
        }
      };

      // Sprawdź stan połączenia internetowego
      if (navigator.onLine) {
        try {
          const res = await fetch(`${backendUrl}/audit-page/audit-response/`, {
            method: 'PATCH',
            headers: {
              'Content-Type': 'application/json',
              Authorization: `Bearer ${authTokens?.access || ''}`,
            },
            body: JSON.stringify(data),
          });
          handleResponse(res);
        } catch (error) {
          console.error('Wystąpił błąd podczas zapisywania rozwiązania AI', error);
          setEditingAiSolutionId(null); // Zamknij edycję
        }
      } else {
        notification.info({
          message: 'Zapisano offline',
          description: 'Rozwiązanie AI zostało zapisane lokalnie',
        });

        const updatedAiSolutions = {
          ...aiSolutions,
          [questionId]: { ai_solution: currentAiSolution },
        };
        const existingAiSolutions = JSON.parse(localStorage.getItem('aiSolutions')) || {};
        existingAiSolutions[questionId] = { ai_solution: currentAiSolution };
        localStorage.setItem('aiSolutions', JSON.stringify(existingAiSolutions))
        console.log('Zapisano do localStorage:', existingAiSolutions);
        setAiSolutions(updatedAiSolutions); // Aktualizuj lokalny stan AI Solutions
        setEditingAiSolutionId(null); // Zamknij edycję
      }
    }
  };


  // Funkcja synchronizacji z serwerem
  const syncAiSolutions = async () => {
    const solutionsToSync = JSON.parse(localStorage.getItem('aiSolutions')) || {};
    if (navigator.onLine && Object.keys(solutionsToSync).length > 0) {
      for (const questionId in solutionsToSync) {
        const { ai_solution } = solutionsToSync[questionId];
        console.log(ai_solution)
        const data = {
          audit: auditId,
          question: getServerId(questionId),
          ai_solution,
        };

        try {
          const res = await fetch(`${backendUrl}/audit-page/audit-response/`, {
            method: 'PATCH',
            headers: {
              'Content-Type': 'application/json',
              Authorization: `Bearer ${authTokens?.access || ''}`,
            },
            body: JSON.stringify(data),
          });

          if (res.ok) {
            // Usunięcie zsynchronizowanego pytania z localStorage po pomyślnym zapisie na serwer
            const existingSolutions = JSON.parse(localStorage.getItem('aiSolutions')) || {};
            delete existingSolutions[questionId]; // Usunięcie elementu z localStorage
            localStorage.setItem('aiSolutions', JSON.stringify(existingSolutions)); // Zaktualizowanie localStorage

            // Zaktualizuj stan lokalny
            setAiSolutions((prevAiSolutions) => ({
              ...prevAiSolutions,
              [questionId]: { ai_solution },
            }));

          } else {
            console.error(`Błąd podczas synchronizacji dla pytania ${questionId}`, res);
          }
        } catch (error) {
          console.error(`Wystąpił błąd podczas synchronizacji rozwiązania AI dla pytania ${questionId}`, error);
        }
      }

      notification.success({
        message: 'Synchronizacja udana',
        description: 'Rozwiązania AI zostały zsynchronizowane pomyślnie.',
      });
    }
  };





  const convertFileToBase64 = (file) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    });
  };
  const convertBase64ToFile = (base64, filename) => {
    if (!base64 || typeof base64 !== 'string' || !base64.includes(',')) {
      console.error('Nieprawidłowy format base64:', base64);
      return null; // Zwracamy null lub możesz zdecydować, jak chcesz obsłużyć ten błąd
    }

    const arr = base64.split(',');
    const mime = arr[0].match(/:(.*?);/)[1];
    const bstr = atob(arr[1]);
    let n = bstr.length;
    const u8arr = new Uint8Array(n);

    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }

    return new File([u8arr], filename, { type: mime });
  };

  // Funkcja do zapisywania zdjęć offline
  const savePhotoLocally = async (photosToUpload) => {
    let offlinePhotos = JSON.parse(localStorage.getItem('offlinePhotos')) || [];

    // Zapisanie zdjęć offline w localStorage i w stanie aplikacji
    const newPhotosForState = [];

    for (const file of photosToUpload) {
      const base64File = await convertFileToBase64(file);
      const photoEntry = {
        base64: base64File,
        questionId: currentQuestionNumber,
        auditId,
        timestamp: Date.now(), // Dodanie timestampu
      };

      offlinePhotos.push(photoEntry);
      newPhotosForState.push(photoEntry); // Przechowujemy pełen obiekt
    }

    localStorage.setItem('offlinePhotos', JSON.stringify(offlinePhotos));
    notification.info({
      message: 'Zdjęcia zapisane lokalnie',
      description: 'Zdjęcia zostały zapisane i są widoczne offline',
    });

    // Zaktualizowanie stanu dla wielu zdjęć
    setPhotos((prevPhotos) => {
      const updatedPhotos = new Map(prevPhotos); // Tworzymy nową mapę na bazie poprzedniego stanu

      // Iterujemy po nowo dodanych zdjęciach
      newPhotosForState.forEach((photo) => {
        const existingPhotos = updatedPhotos.get(photo.questionId) || [];

        // Dodajemy nowe zdjęcie do istniejącej listy dla danego pytania
        updatedPhotos.set(photo.questionId, [
          ...existingPhotos,
          {
            id: null,  // Tymczasowy ID
            url: `${photo.base64}`, // URL z base64
          },
        ]);
      });

      return updatedPhotos; // Zwracamy nową zaktualizowaną mapę
    });
  };




  // Funkcja do przesyłania zdjęć offline
  const uploadOfflinePhotos = async () => {
    const offlinePhotos = JSON.parse(localStorage.getItem('offlinePhotos')) || [];

    for (let entry of offlinePhotos) {
      const file = convertBase64ToFile(entry.base64, `photo-${entry.questionId}.jpg`);

      if (!file) {
        console.error('Nie udało się przekonwertować base64 na plik dla pytania:', entry.questionId);
        continue; // Pomijamy to zdjęcie i przechodzimy do następnego
      }
      const serverId = getServerId(entry.questionId)

      const formData = new FormData();
      formData.append('files', file);
      formData.append('question', serverId);
      formData.append('audit', entry.auditId);

      try {
        const response = await fetch(`${backendUrl}/audit-page/audit-response/`, {
          method: 'PUT',
          headers: {
            Authorization: `Bearer ${authTokens?.access || ''}`,
          },
          body: formData,
        });

        if (!response.ok) {
          throw new Error('Network response was not ok');
        }

        const data = await response.json();
        console.log('Zdjęcia zostały przesłane:', data);
        // if (data.newPhotos) {
        //   // Aktualizuj stan aplikacji po pomyślnym przesłaniu zdjęcia
        //   setPhotos((prevPhotos) => {
        //     const updatedPhotos = new Map(prevPhotos);
        //     const existingPhotos = updatedPhotos.get(entry.questionId) || [];

        //     // Dodaj nowe zdjęcia do istniejącej listy
        //     updatedPhotos.set(entry.questionId, [...existingPhotos, ...data.newPhotos]);

        //     return updatedPhotos;
        //   });
        // }
        // Usuń tylko przesłane zdjęcie z localStorage
        const updatedOfflinePhotos = offlinePhotos.filter(
          (item) => item.questionId !== entry.questionId || item.auditId !== entry.auditId
        );
        localStorage.setItem('offlinePhotos', JSON.stringify(updatedOfflinePhotos));

      } catch (error) {
        console.error('Błąd przesyłania zdjęć:', error);
      }
    }
  };



  // Używanie useEffect do przesyłania zdjęć, gdy użytkownik odzyskuje połączenie


  useEffect(() => {
    const storedPhotos = JSON.parse(localStorage.getItem('photos')) || {};
    const photosMap = new Map(Object.entries(storedPhotos));
    setPhotos(photosMap);
  }, []);



  const handleUploadPhotos = async (photosToUpload) => {
    if (photosToUpload.length > 0) {
      try {
        // Pobierz istniejące zdjęcia dla pytania
        const existingPhotos = photos.get(currentQuestionNumber) || [];
        // Aktualizacja stanu zdjęć przed wysłaniem
        const updatedPhotos = [...existingPhotos, ...photosToUpload];
        if (navigator.onLine) {
          const formData = new FormData();

          // Dodaj zdjęcia do formData
          photosToUpload.forEach((file) => {
            formData.append('files', file);
          });
          formData.append('question', currentQuestionNumber);
          formData.append('audit', auditId);

          const response = await fetch(`${backendUrl}/audit-page/audit-response/`, {
            method: 'PUT',
            headers: {
              Authorization: `Bearer ${authTokens?.access}`,
            },
            body: formData,
          });

          if (!response.ok) {
            throw new Error('Network response was not ok');
          }

          const data = await response.json();
          console.log('Zdjęcia zostały przesłane:', data);

          if (data.newPhotos) {
            setPhotos((prevPhotos) => {
              const updatedPhotos = new Map(prevPhotos);
              const existingPhotos = updatedPhotos.get(currentQuestionNumber) || [];
              const newPhotos = data.newPhotos || [];

              // Dodaj nowe zdjęcia do istniejącej listy i zaktualizuj stan
              updatedPhotos.set(currentQuestionNumber, [...existingPhotos, ...newPhotos]);
              return updatedPhotos;
            });
          }
        } else {
          // Obsługa offline
          savePhotoLocally(photosToUpload);
        }
      } catch (error) {
        console.error('Błąd przesyłania zdjęć:', error);
        notification.error({
          message: 'Wystąpił błąd',
          description: 'Nie udało się przesłać zdjęć',
        });
      }
    }
  };

  useEffect(() => {
    const handleOnline = async () => {
      try {
        // Zawsze najpierw wywołujemy uploadOfflineQuestions
        console.log('Uploading offline questions...');
        await uploadOfflineQuestions();

        // Dopiero teraz wywołujemy pozostałe funkcje
        console.log('Connection restored, uploading offline responses...');
        await uploadOfflineResponses();  // Synchronizacja offline responses

        console.log('Uploading offline photos...');
        await uploadOfflinePhotos();

        console.log('Uploading offline edited questions...');
        await uploadOfflineEditedQuestions();

        console.log('Uploading offline deleted questions...');
        await uploadOfflineDeletedQuestions(); // Dodane await, aby upewnić się, że to również poczeka na zakończenie

        const fetchData = async () => {
          setIsLoading(true);
          try {
            const response = await fetch(`${backendUrl}/audit-page/audits/${auditId}/`, {
              headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${authTokens?.access || ''}`,
              },
            });

            if (!response.ok) throw new Error('Network response was not ok.');

            const data = await response.json();
            console.log('DATA FROM API: ', data);

            const processedSections = processSections(data.sections, filterSections);

            // Update states with processed data
            setSections(processedSections);
            setCoveragePhoto(data.cover_photo || '');
            setAuditTitle(data.audit_title || '');
            setAuditCity(data.audit_city || '');
            setAuditStreet(data.audit_street || '');
            setStartDate(data.start_date || '');
            setEndDate(data.end_date || '');
            handleOfflineResponses();
            handleOfflineComments();
            updateUIStates(processedSections);

            // Load saved answers from localStorage
            let savedAnswers = JSON.parse(localStorage.getItem(`selectedAnswers_${auditId}`)) || {};

            // If there are no saved answers in localStorage, load from API data
            const answersFromAPI = {};
            data.sections.forEach(section => {
              section.groups.forEach(group => {
                group.questions.forEach(question => {
                  if (question.answer) {
                    answersFromAPI[question.id] = { answer: question.answer };
                  }
                });
              });
              if (section.subSections) {
                section.subSections.forEach(subSection => {
                  subSection.groups.forEach(group => {
                    group.questions.forEach(question => {
                      if (question.answer) {
                        answersFromAPI[question.id] = { answer: question.answer };
                      }
                    });
                  });
                });
              }
            });
            // Merge answers from localStorage with answers from API
            const mergedAnswers = { ...answersFromAPI, ...savedAnswers };

            // Save merged answers to localStorage and update state
            localStorage.setItem(`selectedAnswers_${auditId}`, JSON.stringify(mergedAnswers));
            setSelectedAnswers(mergedAnswers);
          } catch (error) {
            console.error('Error:', error);
          } finally {
            setIsLoading(false);
          }
        };

        fetchData();
      } catch (error) {
        console.error('Wystąpił błąd podczas synchronizacji:', error);
      }
    };

    if (navigator.onLine) {
      handleOnline();
    }

    const syncAiSolutions = () => {
      const solutionsToSync = JSON.parse(localStorage.getItem('aiSolutions')) || {};
      if (Object.keys(solutionsToSync).length > 0) {
        syncAiSolutions();
      }
    };

    window.addEventListener('online', handleOnline);
    syncAiSolutions();

    return () => {
      window.removeEventListener('online', handleOnline);
    };
  }, [authTokens]);




  // Funkcja obsługująca usuwanie zdjęć
  const deletePhoto = (photoId, questionId) => {
    axios
      .delete(`${backendUrl}/audit-page/delete-photo/`, {
        data: {
          photo_id: photoId,
          audit: auditId,
        },
        headers: {
          Authorization: `Bearer ${authTokens?.access || ''}`, // Dodajemy token dostępu do nagłówka
        },
      })
      .then((response) => {
        console.log('Usunięto zdjęcie:', photoId)



        setPhotos((prevPhotos) => {
          const updatedPhotos = new Map(prevPhotos)
          const questionPhotos = updatedPhotos.get(questionId)

          if (questionPhotos) {
            const newPhotos = questionPhotos.filter(
              (photo) => photo.id !== photoId
            )
            updatedPhotos.set(questionId, newPhotos)
          }

          return updatedPhotos
        })
      })
      .catch((error) => {
        console.error('Błąd usuwania zdjęcia:', error)
      })
  }

  // Funkcja obsługująca tworzenie pytań
  const handleCreateResponse = (isSubsection) => {
    if (!currentQuestionGroup || !currentQuestionSection) {
      console.error('Group or Section is not set');
      return;
    }

    const localId = `local-${Date.now()}`;
    const question = {
      id: localId,
      question: "Dodatkowe uwagi",  // Stała treść pytania
      groupId: currentQuestionGroup.id,
      sectionId: currentQuestionSection.id,
      auditId,
    };
    console.log("CHUJ INTERNET!!!!!!!!!!!!!!!")

    const updateUI = (updatedQuestion) => {
      if (!updatedQuestion) {
        console.error('updatedQuestion is null or undefined');
        return;
      }

      const updatedSections = sections.map((section) => {
        if (section.id === currentQuestionSection?.id) {
          if (isSubsection) {
            return {
              ...section,
              subSections: section.subSections.map((subSection) => {
                if (subSection.id === currentSubsection?.id) {
                  return {
                    ...subSection,
                    groups: subSection.groups.map((group) => {
                      if (group.id === currentQuestionGroup?.id) {
                        return {
                          ...group,
                          questions: [...group.questions.filter(q => q.id !== localId), updatedQuestion],
                        };
                      }
                      return group;
                    }),
                  };
                }
                return subSection;
              }),
            };
          } else {
            return {
              ...section,
              groups: section.groups.map((group) => {
                if (group.id === currentQuestionGroup?.id) {
                  return {
                    ...group,
                    questions: [...group.questions.filter(q => q.id !== localId), updatedQuestion],
                  };
                }
                return group;
              }),
            };
          }
        }

        return {
          ...section,
          subSections: section.subSections?.map((subSection) => {
            return {
              ...subSection,
              groups: subSection.groups?.map((group) => {
                if (group.id === currentQuestionGroup?.id) {
                  return {
                    ...group,
                    questions: group.questions.map((q) =>
                      q.id === updatedQuestion.id ? { ...q, question: updatedQuestion.question } : q
                    ),
                  };
                }
                return group;
              }),
            };
          }),
        };
      });
      setSections(updatedSections);
    };


    if (navigator.onLine) {
      axios.post(`${backendUrl}/audit-page/create-response/`, {
        group: currentQuestionGroup.id,
        audit: auditId,
      }, {
        headers: {
          Authorization: `Bearer ${authTokens?.access || ''}`,
        },
      })
        .then((response) => {
          const serverQuestion = response.data.question_data;
          console.log(serverQuestion)
          console.log(response.data)
          mapLocalToServerId(localId, serverQuestion.id);
          updateUI({ ...question, id: serverQuestion.id }); // Update question with server ID
        })
        .catch((error) => {
          console.error(error);
          notification.error({
            message: 'Wystąpił błąd',
            description: 'Nie udało się stworzyć pytania',
          });
          saveNewQuestionLocally(question);
          updateUI(question);
        });
    } else {
      saveNewQuestionLocally(question);
      updateUI(question);
    }
  };





  const mapLocalToServerId = (localId, serverId) => {
    let idMapping = JSON.parse(localStorage.getItem('idMapping')) || {};
    idMapping[localId] = serverId;
    localStorage.setItem('idMapping', JSON.stringify(idMapping));
  };

  const saveNewQuestionLocally = (question) => {
    let offlineNewQuestions = JSON.parse(localStorage.getItem('offlineNewQuestions')) || [];
    offlineNewQuestions.push(question);
    localStorage.setItem('offlineNewQuestions', JSON.stringify(offlineNewQuestions));

    notification.info({
      message: 'Sukces',
      description: 'Nowe pytanie zapisane lokalnie',
    });
  };


  const uploadOfflineQuestions = async () => {
    const offlineNewQuestions = JSON.parse(localStorage.getItem('offlineNewQuestions')) || [];

    for (const question of offlineNewQuestions) {
      try {
        const response = await axios.post(`${backendUrl}/audit-page/create-response/`, {
          question: question.question,
          group: question.groupId,
          audit: question.auditId,
        }, {
          headers: {
            Authorization: `Bearer ${authTokens?.access || ''}`,
          },
        });

        if (response.status === 200 || response.status === 201) {
          const serverQuestion = response.data.question_data;
          mapLocalToServerId(question.id, serverQuestion.id);
          console.log(serverQuestion)
          // Aktualizacja UI, jeśli to konieczne
        } else {
          console.error('Network response was not ok:', response.status, response.statusText);
          throw new Error('Network response was not ok');
        }
      } catch (error) {
        console.error('Błąd przesyłania nowych pytań:', error);
        notification.error({
          message: 'Wystąpił błąd',
          description: 'Nie udało dodać się nowego pytania',
        });
      }
    }

    localStorage.removeItem('offlineNewQuestions');
  };



  const uploadOfflineResponsesAndComments = async () => {
    const offlineResponses = JSON.parse(localStorage.getItem('offlineResponses')) || [];
    const offlineComments = JSON.parse(localStorage.getItem('comments')) || {};

    // Przesyłanie odpowiedzi
    for (const response of offlineResponses) {
      try {
        const serverQuestionId = getServerId(response.question);
        const responseWithServerId = { ...response, question: serverQuestionId };
        console.log(serverQuestionId)
        const res = await axios.post(`${backendUrl}/audit-page/audit-response/`, responseWithServerId, {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${authTokens?.access || ''}`,
          }
        });

        console.log('Odpowiedzi zostały przesłane:', res.data);
      } catch (error) {
        console.error('Błąd przesyłania odpowiedzi:', error);

        notification.error({
          message: 'Wystąpił błąd',
          description: 'Błąd przesyłania odpowiedzi',
        });
      }
    }

    // Przesyłanie komentarzy
    for (const questionId in offlineComments) {
      try {
        const serverQuestionId = getServerId(questionId);
        const comment = offlineComments[questionId];
        const commentWithServerId = {
          audit: auditId,
          question: serverQuestionId,
          note: comment.note
        };
        console.log(commentWithServerId)

        const res = await axios.patch(`${backendUrl}/audit-page/audit-response/`, commentWithServerId, {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${authTokens?.access || ''}`,
          }
        });

        console.log('Komentarze zostały przesłane:', res.data);
      } catch (error) {
        console.error('Błąd przesyłania komentarzy:', error);
        notification.error({
          message: 'Wystąpił błąd',
          description: 'Błąd przesyłania komentarzy',
        });
      }
    }

    localStorage.removeItem('offlineResponses');
    localStorage.removeItem('comments');
  };




  // Ogólna funkcja do zapisywania danych offline
  const saveDataOffline = (key, data) => {
    let offlineData = JSON.parse(localStorage.getItem(key)) || [];
    offlineData.push(data);
    localStorage.setItem(key, JSON.stringify(offlineData));
  };



  // Funkcja do aktualizacji mapowania lokalnych identyfikatorów na identyfikatory serwera
  const updateLocalIdMapping = (localId, serverId) => {
    let idMapping = JSON.parse(localStorage.getItem('idMapping')) || {};
    idMapping[localId] = serverId;
    localStorage.setItem('idMapping', JSON.stringify(idMapping));
  };




  // Funkcja do zapisywania edytowanego pytania
  const saveEditedQuestion = (questionId) => {
    const serverQuestionId = getServerId(questionId);  // Pobierz prawidłowy identyfikator serwera
    console.log(serverQuestionId)
    console.log(questionId)
    const data = {
      question: currentQuestionText,
      id: serverQuestionId != '' ? serverQuestionId : questionId, // Użyj identyfikatora serwera
    };

    const updateUI = () => {
      const updatedSections = sections.map((section) => ({
        ...section,
        groups: section.groups.map((group) => ({
          ...group,
          questions: group.questions.map((q) =>
            q.id === questionId ? { ...q, question: currentQuestionText } : q
          ),
        })),
        subSections: section.subSections.map((subSection) => ({
          ...subSection,
          groups: subSection.groups.map((group) => ({
            ...group,
            questions: group.questions.map((q) =>
              q.id === questionId ? { ...q, question: currentQuestionText } : q
            ),
          })),
        })),
      }));
      setSections(updatedSections);
      setEditingQuestionId(null);
    };

    if (navigator.onLine) {
      axios.put(`${backendUrl}/audit-page/edit-response/`, data, {
        headers: {
          Authorization: `Bearer ${authTokens?.access || ''}`,
        },
      })
        .then((response) => {
          updateUI();
          localStorage.removeItem('editableQuestion');
        })
        .catch((error) => {
          console.error('Error editing question:', error);
          notification.error({
            message: 'Wystąpił błąd',
            description: 'Nie udało się zedytować pytania',
          });
          saveDataOffline('offlineEditedQuestions', { question_id: questionId, question_text: currentQuestionText });
          updateUI();
        });
    } else {
      saveDataOffline('offlineEditedQuestions', { question_id: questionId, question_text: currentQuestionText });
      updateUI();
    }
  };

  // Przesyłanie edytowanych pytań na serwer
  const uploadOfflineEditedQuestions = async () => {
    const offlineEditedQuestions = JSON.parse(localStorage.getItem('offlineEditedQuestions')) || [];

    for (const question of offlineEditedQuestions) {
      try {
        const realQuestionId = getServerId(question.question_id);
        console.log("realQuestionId", realQuestionId)
        const response = await axios.put(`${backendUrl}/audit-page/edit-response/`, {
          id: realQuestionId,  // Używamy realnego ID
          question: question.question_text,
        }, {
          headers: {
            Authorization: `Bearer ${authTokens?.access || ''}`,
          },
        });

        if (response.status >= 200 && response.status < 300) {
          console.log(`Pytanie zostało zaktualizowane na serwerze: ${response.data}`);
        } else {
          console.error('Network response was not ok:', response.status, response.statusText);
          throw new Error('Network response was not ok');
        }
      } catch (error) {
        console.error('Błąd przesyłania offlineEditedQuestions:', error);
        notification.error({
          message: 'Wystąpił błąd',
          description: 'Bład podczas edycji pytań',
        });
      }
    }

    localStorage.removeItem('offlineEditedQuestions');
  };




  // Funkcja obsługująca usunięcie pytania
  // Function to handle question deletion
  const deleteResponse = (questionId) => {
    const updateUI = () => {
      const updatedSections = sections.map((section) => ({
        ...section,
        groups: section.groups.map((group) => ({
          ...group,
          questions: group.questions.filter(
            (question) => question.id !== questionId
          ),
        })),
        subSections: section.subSections.map((subSection) => ({
          ...subSection,
          groups: subSection.groups.map((group) => ({
            ...group,
            questions: group.questions.filter(
              (question) => question.id !== questionId
            ),
          })),
        })),
      }));
      setSections(updatedSections);
    };

    const saveDeletedQuestionLocally = (questionId) => {
      let offlineDeletedQuestions = JSON.parse(localStorage.getItem('offlineDeletedQuestions')) || [];
      offlineDeletedQuestions.push(questionId);
      localStorage.setItem('offlineDeletedQuestions', JSON.stringify(offlineDeletedQuestions));

      notification.info({
        message: 'Zapisano offline',
        description: 'Usunięcie pytania zapisane lokalnie',
      });
    };

    if (navigator.onLine) {
      axios
        .delete(`${backendUrl}/audit-page/delete-response/`, {
          data: {
            question: questionId,
          },
          headers: {
            Authorization: `Bearer ${authTokens?.access || ''}`,
          },
        })
        .then((response) => {
          updateUI();
          console.log(`audit: ${auditId}, question: ${questionId}`);
        })
        .catch((error) => {
          console.error('Error deleting question:', error);
          console.log(questionId);
          notification.error({
            message: 'Wystąpił błąd',
            description: 'Nie udało się usunąć pytania',
          });
          saveDeletedQuestionLocally(questionId);
          updateUI();
        });
    } else {
      saveDeletedQuestionLocally(questionId);
      updateUI();
    }
  };






  const uploadOfflineDeletedQuestions = async () => {
    const offlineDeletedQuestions = JSON.parse(localStorage.getItem('offlineDeletedQuestions')) || [];

    for (const questionId of offlineDeletedQuestions) {
      try {
        const response = await axios.delete(`${backendUrl}/audit-page/delete-response/`, {
          data: {
            question: questionId,
          },
          headers: {
            Authorization: `Bearer ${authTokens?.access || ''}`,
          },
        });

        if (response.status === 200 || response.status === 204) {
          console.log('Pytanie zostało usunięte:', questionId);
        } else {
          console.error('Network response was not ok:', response.status, response.statusText);
          throw new Error('Network response was not ok');
        }
      } catch (error) {
        console.error('Błąd przesyłania usuniętych pytań:', error);
        notification.error({
          message: 'Wystąpił błąd',
          description: 'Błąd przesyłania usuniętych pytań',
        });
      }
    }

    localStorage.removeItem('offlineDeletedQuestions');
  };



  const handleCreateGroupInSubsection = () => {
    axios
      .post(
        `${backendUrl}/audit-page/create-subsection-group/`,
        {
          subsection: currentSubsection.id,
          group_name: newGroupName,
        },
        {
          headers: {
            Authorization: `Bearer ${authTokens?.access || ''}`,
          },
        }
      )
      .then((response) => {
        const updatedSections = sections.map((section) => {
          section.subSections = section.subSections.map((subSection) => {
            if (subSection.id === currentSubsection.id) {
              const newGroup = {
                ...response.data.group_data,
                questions: [],
                num_questions: 0,
              };
              return {
                ...subSection,
                groups: [...subSection.groups, newGroup],
              }
            }
            return subSection;
          });
          return section;
        });
        setSections(updatedSections);
        setIsCreateGroupPopupVisible(false);
      })
      .catch((error) => {
        console.error(error);
        notification.error({
          message: 'Wystąpił błąd',
          description: 'Nie udało się stworzyć grupy',
        });
      });
  };
  const handleCopySubsection = (subSectionId) => {
    const newSubsectionName = prompt("Enter the name for the new subsection:");
    if (!newSubsectionName) return;

    axios
      .post(
        `${backendUrl}/audit-page/copy-subsection/`,
        {
          subsection_id: subSectionId,
          new_subsection_name: newSubsectionName,
        },
        {
          headers: {
            Authorization: `Bearer ${authTokens?.access || ''}`,
          },
        }
      )
      .then((response) => {
        const copiedSubsection = response.data.subsection_data;
        const updatedSections = sections.map((section) => {
          if (section.id === copiedSubsection.section) {
            return {
              ...section,
              subSections: [...section.subSections, copiedSubsection],
            };
          }
          return section;
        });
        setSections(updatedSections);
        notification.success({
          message: 'Subsection copied successfully',
          description: 'Subsection copied successfully',
        });
      })
      .catch((error) => {
        console.error(error);
        notification.error({
          message: 'Failed to copy subsection',
          description: 'Failed to copy subsection',
        });
      });
  };


  // Funkcja obsługująca tworzenie grupy
  const handleCreateGroup = () => {
    axios
      .post(
        `${backendUrl}/audit-page/create-group/`,
        {
          section: currentQuestionSection.id,
          group_name: newGroupName,
        },
        {
          headers: {
            Authorization: `Bearer ${authTokens?.access || ''}`,
          },
        }
      )
      .then((response) => {
        const updatedSections = sections.map((section) => {
          if (section.id === currentQuestionSection.id) {
            const newGroup = {
              ...response.data.group_data,
              questions: [],
              num_questions: 0,
            };
            return {
              ...section,
              groups: [...section.groups, newGroup],
            }
          }
          return section
        })
        setSections(updatedSections)
        setIsCreateGroupPopupVisible(false)
      })
      .catch((error) => {
        console.error(error)
        notification.error({
          message: 'Wystąpił błąd',
          description: 'Nie udało się stworzyć grupy',
        });
      })
  }
  const handleCreateSubSection = () => {
    axios
      .post(
        `${backendUrl}/audit-page/create-subsection/`,
        {
          section_id: currentSection.id,
          subsection_name: newSubSectionName,
        },
        {
          headers: {
            Authorization: `Bearer ${authTokens?.access || ''}`,
          },
        }
      )
      .then((response) => {
        const updatedSections = sections.map((section) => {
          if (section.id === currentSection.id) {
            const newSubSection = {
              ...response.data.subsection_data,
              groups: [],
            };
            return {
              ...section,
              subSections: [...section.subSections, newSubSection],
            };
          }
          return section;
        });
        setSections(updatedSections);
        setIsCreateSubSectionPopupVisible(false);
      })
      .catch((error) => {
        console.error(error);
        notification.error({
          message: 'Wystąpił błąd',
          description: 'Nie udało się stworzyć podsekcji',
        });
      });
  };
  // Funkcja obsługująca edycję nazwy grupy
  const handleUpdateGroupName = () => {
    axios
      .put(
        `${backendUrl}/audit-page/update-group/`,
        {
          audit: auditId,
          group_id: editingGroupId,
          group_name: editingGroupName,
        },
        {
          headers: {
            Authorization: `Bearer ${authTokens?.access || ''}`,
          },
        }
      )
      .then((response) => {

        const updatedSections = sections.map((section) => {
          return {
            ...section,
            groups: section.groups.map((group) =>
              group.id === editingGroupId ? { ...group, name: editingGroupName } : group
            )
          };
        });
        setSections(updatedSections);
        setIsEditingGroup(false);
      })
      .catch((error) => {
        console.error(error)
        notification.error({
          message: 'Wystąpił błąd',
          description: 'Nie udało się edytować nazwy grupy',
        });
      });
  };

  // Funkcja obsługująca usunięcie grupy
  const handleDeleteGroup = (groupId) => {

    axios.delete(`${backendUrl}/audit-page/delete-group/`, {
      data: {
        audit: auditId,
        group_id: groupId,
      },
      headers: {
        Authorization: `Bearer ${authTokens?.access || ''}`,
      },
    })
      .then((response) => {
        const updatedSections = sections.map((section) => ({
          ...section,
          groups: section.groups.filter(
            (group) => group.id !== groupId
          ),
        }))
        setSections(updatedSections)
        console.log(`Poprawnie usunięto groupę: ${groupId}`)
      })
  }
  // Funkcja obsługująca tworzenie sekcji
  const handleCreateSection = () => {
    axios
      .post(
        `${backendUrl}/audit-page/create-section/`,
        {
          audit: auditId,
          section_name: newSectionName,
        },
        {
          headers: {
            Authorization: `Bearer ${authTokens?.access || ''}`,
          },
        }
      )
      .then((response) => {
        const newSection = {
          id: response.data.section_id,
          name: newSectionName,
          groups: [],
          num_groups: 0,
        };
        const updatedSections = [...sections, newSection];
        setSections(updatedSections)
        setIsCreateSectionPopupVisible(false)
      })
      .catch((error) => {
        console.error(error)
      })
  }
  // Funkcja obsługująca edycję nazwy sekcji
  const handleUpdateSectionName = () => {
    axios
      .put(
        `${backendUrl}/audit-page/update-section/`,
        {
          audit: auditId,
          section_id: editingSectionId,
          section_name: editingSectionName,
        },
        {
          headers: {
            Authorization: `Bearer ${authTokens?.access || ''}`,
          },
        }
      )
      .then((response) => {
        // Aktualizacja stanu, na przykład aktualizując nazwę sekcji w stanie audytu
        const updatedSections = sections.map((section) =>
          section.id === editingSectionId ? { ...section, name: editingSectionName } : section
        );
        setSections(updatedSections);
        setIsEditingSection(false);
      })
      .catch((error) => {
        console.error(error)
        notification.error({
          message: 'Wystąpił błąd',
          description: 'Nie udało się edytować nazwy sekcji',
        });
      });
  };
  const handleUpdateSubSectionName = () => {
    axios
      .put(
        `${backendUrl}/audit-page/update-subsection/`,
        {
          audit: auditId,
          subsection_id: editingSubSectionId,
          subsection_name: editingSubSectionName,
        },
        {
          headers: {
            Authorization: `Bearer ${authTokens?.access || ''}`,
          },
        }
      )
      .then((response) => {
        const updatedSections = sections.map((section) => {
          return {
            ...section,
            subSections: section.subSections.map((subSection) =>
              subSection.id === editingSubSectionId ? { ...subSection, name: editingSubSectionName } : subSection
            ),
          };
        });
        setSections(updatedSections);
        setIsEditingSubSection(false);
      })
      .catch((error) => {
        console.error(error);
        notification.error({
          message: 'Wystąpił błąd',
          description: 'Nie udało się edytować nazwy podsekcji',
        });
      });
  };
  const handleDeleteSubSection = (subsectionId) => {
    axios
      .delete(
        `${backendUrl}/audit-page/delete-subsection/${subsectionId}/`,
        {
          headers: {
            Authorization: `Bearer ${authTokens?.access || ''}`,
          },
        }
      )
      .then((response) => {
        const updatedSections = sections.map((section) => {
          return {
            ...section,
            subSections: section.subSections.filter((subSection) => subSection.id !== subsectionId),
          };
        });
        setSections(updatedSections);
      })
      .catch((error) => {
        console.error(error);
        notification.error({
          message: 'Wystąpił błąd',
          description: 'Nie udało się usunąć podsekcji',
        });
      });
  };

  // Funkcja obsługująca usunięcie sekcji
  const handleDeleteSection = (sectionId) => {

    axios.delete(`${backendUrl}/audit-page/delete-section/`, {
      data: {
        audit: auditId,
        section_id: sectionId,
      },
      headers: {
        Authorization: `Bearer ${authTokens?.access || ''}`,
      },
    })
      .then((response) => {
        const updatedSections = sections.filter(
          (section) => section.id !== sectionId

        )
        setSections(updatedSections)
        console.log(`Poprawnie usunięto sekcję: ${sectionId}`)
      })
  }

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (commentPopupRef.current && !commentPopupRef.current.contains(event.target)) {
        const existingComment = comments[currentQuestionNumber]?.note || '';
        if (currentComment !== existingComment) {
          saveComment(currentQuestionNumber, currentComment);
        }
        setCurrentComment(''); // Wyczyść currentComment
        closeCommentPopup();
      }
      if (commentResponsePopupRef.current && !commentResponsePopupRef.current.contains(event.target)) {
        const existingComment = comments[currentQuestionNumber]?.note || '';
        const existingAiSolution = aiSolutions[currentQuestionNumber]?.ai_solution || '';
        if (currentComment !== existingComment || currentAiSolution !== existingAiSolution) {
          saveCommentAndResponse();
        }
        setCurrentComment(''); // Wyczyść currentComment
        setCurrentAiSolution(''); // Wyczyść currentAiSolution
        closeCommentResponsePopup();
      }
      if (aiSolutionPopupRef.current && !aiSolutionPopupRef.current.contains(event.target)) {
        saveAiSolution();
        setCurrentComment(''); // Wyczyść currentComment
      }
      if (
        fullSizePhotoRef.current &&
        !fullSizePhotoRef.current.contains(event.target) // Sprawdzenie czy kliknięcie nastąpiło poza obszarem pełnowymiarowego zdjęcia
      ) {
        setFullSizePhoto(null) // Zamknięcie pełnowymiarowego zdjęcia
      }
      if (
        createQuestionPopupRef.current &&
        !createQuestionPopupRef.current.contains(event.target)
      ) {
        handleCreateResponse()
      }
      if (
        createGroupPopupRef.current &&
        !createGroupPopupRef.current.contains(event.target)
      ) {
        setIsCreateGroupPopupVisible(false)
      }
      if (
        createSectionPopupRef.current &&
        !createSectionPopupRef.current.contains(event.target)
      ) {
        setIsCreateSectionPopupVisible(false)
      }
      if (
        editQuestionPopupRef.current &&
        !editQuestionPopupRef.current.contains(event.target)
      ) {
        saveEditedQuestion();
        setEditableQuestion({
          id: null,
          question: '',
        });
        setIsEditPopupVisible(false)
      }
    }
    document.addEventListener('mousedown', handleClickOutside)
    return () => {
      document.removeEventListener('mousedown', handleClickOutside)
    }
  }, [saveComment])

  const getButtonClass = (questionId, answer) => {
    const isAnswerSelected = selectedAnswers[questionId]?.answer === answer
    switch (answer) {
      case 'yes':
        return isAnswerSelected
          ? 'border-green-400 text-green-400 border'
          : 'border border-gray-700'
      case 'no':
        return isAnswerSelected
          ? 'border-red-400 text-red-400 border'
          : 'border border-gray-700'
      case 'na':
        return isAnswerSelected
          ? 'border-yellow-400 text-yellow-400 border'
          : 'border border-gray-700'
      default:
        return 'border-2 border-gray-700'
    }
  }
  const getTextClass = (questionId, answer) => {
    const isAnswerSelected = selectedAnswers[questionId]?.answer === answer
    switch (answer) {
      case 'yes':
        return isAnswerSelected ? 'text-green-400' : ''
      case 'no':
        return isAnswerSelected ? 'text-red-400' : ''
      case 'na':
        return isAnswerSelected ? 'text-yellow-400' : ''
      default:
        return ''
    }
  }

  // Funkcja obsługująca wybór odpowiedzi
  const handleSelectAnswer = (questionId, answer) => {
    setSelectedAnswers((prev) => {
      let newAnswers;

      if (prev[questionId] && prev[questionId].answer === answer) {
        // Usuń odpowiedź, jeśli jest taka sama jak poprzednio wybrana
        newAnswers = { ...prev };
        delete newAnswers[questionId];
        saveResponse(questionId, ''); // Zapisz pustą odpowiedź na serwerze
      } else {
        // Przechowaj wybraną odpowiedź dla pytania
        const newAnswer = { [questionId]: { answer } };
        newAnswers = { ...prev, ...newAnswer };
        saveResponse(questionId, answer); // Zapisz odpowiedź na serwerze
      }

      // Save the updated answers to localStorage
      localStorage.setItem('selectedAnswers', JSON.stringify(newAnswers));
      return newAnswers;
    });
  };



  const openFullSizePhoto = (photoUrl) => setFullSizePhoto(photoUrl)

  const openCommentPopup = (questionNumber) => {
    setCurrentQuestionNumber(questionNumber)
    const existingComment = comments[questionNumber]?.note || ''
    setCurrentComment(existingComment)
    setIsCommentPopupVisible(true)
  }

  const closeCommentPopup = () => setIsCommentPopupVisible(false)
  const closeCommentResponsePopup = () => setIsCommentResponsePopupVisible(false)
  const openCreateQuestionPopup = (sectionOrSubsection, group, isSubsection) => {
    if (isSubsection) {
      setCurrentSubsection(sectionOrSubsection);
      setIsSubsection(true);
    } else {
      setcurrentQuestionSection(sectionOrSubsection);
      setIsSubsection(false);
    }
    setcurrentQuestionGroup(group);
  };
  useEffect(() => {
    if (currentQuestionGroup && currentQuestionSection) {
      handleCreateResponse(isSubsection);
    }
  }, [currentQuestionGroup, currentQuestionSection, isSubsection]);




  const openCreateGroupPopup = (sectionOrSubsection, type) => {
    console.log(sectionOrSubsection)
    if (type === 'section') {
      setcurrentQuestionSection(sectionOrSubsection);
      setIsSubsection(false);
    } else {
      console.log(sectionOrSubsection)
      setCurrentSubsection(sectionOrSubsection);
      setIsSubsection(true);
    }
    setIsCreateGroupPopupVisible(true);
  };

  const openCreateSectionPopup = () => {
    setIsCreateSectionPopupVisible(true)
  }

  const openCreateSubSectionPopup = (section) => {
    setCurrentSection(section);
    setIsCreateSubSectionPopupVisible(true);
  };

  const handleSubSectionTextChange = (event) => {
    setNewSubSectionName(event.target.value);
  };

  const openEditPopup = (question) => {
    setEditableQuestion(question) // Zakładając, że `question` zawiera `id`
    setIsEditPopupVisible(true)
  }


  const handleGroupTextChange = (event) => {
    setNewGroupName(event.target.value)
  }
  const handleSectionTextChange = (event) => {
    setNewSectionName(event.target.value)
  }
  const handleEditQuestionTextChange = (event) => {
    // Aktualizacja stanu tekstu pytania do edycji
    setEditableQuestion({
      ...editableQuestion,
      question: event.target.value,
    })
  }
  // Funkcje formatujące datę
  const formatDate = (dateString) => {
    const date = new Date(dateString)
    return `${date.getDate().toString().padStart(2, '0')}.${(
      date.getMonth() + 1
    )
      .toString()
      .padStart(2, '0')}.${date.getFullYear()}`
  }


  const handlePhotoChange = (e, questionId) => {
    if (e.target.files) {
      const filesArray = Array.from(e.target.files);
      setSelectedPhotos(filesArray);
      handleUploadPhotos(filesArray);
    }
  };
  const toggleEditMode = () => {
    setIsEditMode(!isEditMode);
  };
  const [totalQuestions, setTotalQuestions] = useState(0);
  const [missedQuestions, setMissedQuestions] = useState(0);
  const [haScore, setHaScore] = useState(0);

  useEffect(() => {
    let total = 0;
    let answered = 0;
    let yes = 0;
    let no = 0;
    let na = 0;

    sections.forEach(section => {
      section.groups.forEach(group => {
        group.questions.forEach(question => {
          total += 1;
          const answer = selectedAnswers[question.id]?.answer || '';
          if (answer === 'yes') {
            yes += 1;
          } else if (answer === 'no') {
            no += 1;
          } else if (answer === 'na') {
            na += 1;
          }
          if (answer !== '') {
            answered += 1;
          }
        });
      });
      if (section.subSections) {
        section.subSections.forEach(subSection => {
          subSection.groups.forEach(group => {
            group.questions.forEach(question => {
              total += 1;
              const answer = selectedAnswers[question.id]?.answer || '';
              if (answer === 'yes') {
                yes += 1;
              } else if (answer === 'no') {
                no += 1;
              } else if (answer === 'na') {
                na += 1;
              }
              if (answer !== '') {
                answered += 1;
              }
            });
          });
        });
      }
    });

    // Obliczenie brakujących pytań
    console.log(answered)
    console.log(total)
    let missedQs = total - answered;
    // Obliczenie HA score
    let haScore = (answered - na > 0 ? ((yes / (answered - na)) * 100).toFixed(2) : 0);

    // Aktualizacja stanów
    setHaScore(haScore);
    setTotalQuestions(total);
    setMissedQuestions(missedQs);
  }, [sections, selectedAnswers]);

  const openActionPopup = (section) => {
    setCurrentSection(section)
    setIsSectionActionPopupVisible(true);
  };

  const closeActionPopup = () => {
    setIsSectionActionPopupVisible(false);
  };



  // Oblsuga Searchbara audytów
  const [searchTerm, setSearchTerm] = useState('');
  const [searchResults, setSearchResults] = useState([]);
  const [isFocused, setIsFocused] = useState(false); // Dodajemy stan śledzący, czy searchbar jest zafocusowany

  const handleSelectClick = (event, audit) => {
    // Sprawdź, czy kliknięcie nastąpiło na elemencie select
    event.stopPropagation();

    if (event.target.tagName.toLowerCase() === 'select') {
      // Jeśli tak, przerwij funkcję i nie otwieraj popupa
      return;
    }

    // Otwórz podgląd popupa
    openPreviewPopup(audit);
  };
  const openPreviewPopup = (audit) => {
    setCurrentAudit(audit.audit_id);
    setAudit(audit)
    setIsPreviewPopupVisible(true);
  };
  useEffect(() => {
    if (searchTerm === '') {
      setSearchResults([]);
    } else {
      const results = allAudits.filter(audit =>
        audit.audit_title.toLowerCase().includes(searchTerm.toLowerCase()) ||
        formatDate(audit.start_date).includes(searchTerm.toLowerCase()) // Check if start_date contains the search term
      );
      setSearchResults(results);
    }
  }, [searchTerm, allAudits]);


  const [selectedQuestion, setSelectedQuestion] = useState(null);
  console.log(currentSection)

  // Funkcja obsługująca wybór pytania
  const handleQuestionSelect = (question, sectionId, groupId) => {
    setSelectedQuestion(question);
    setExpandedSection(sectionId);
    setExpandedGroup(groupId);
    setSelectedQuestion(question.id); // Ustawiamy id wybranego pytania
    setTimeout(() => setSelectedQuestion(null), 1000);
    // Tutaj możesz wykonać dodatkowe operacje po wybraniu pytania
    console.log("Selected question:", question);
  };
  const toggleExpandedInfo = (groupId) => {
    if (expandedInfo) {
      setExpandedInfo(null)
    } else {
      setExpandedInfo(groupId);

    }
  }


  useEffect(() => {
    saveToLocalStorage(`${auditId}_expandedSubSection`, expandedSubSection);
  }, [expandedSubSection, auditId]);

  useEffect(() => {
    saveToLocalStorage(`${auditId}_expandedSection`, expandedSection);
  }, [expandedSection, auditId]);

  useEffect(() => {
    saveToLocalStorage(`${auditId}_expandedGroup`, expandedGroup);
  }, [expandedGroup, auditId]);


  // const generateCSV = (sections) => {
  //   const csvData = [];

  //   // Sort sections by id in ascending order
  //   const sortedSections = [...sections].sort((a, b) => a.id - b.id);

  //   sortedSections.forEach((section) => {
  //     // Sort groups within each section by id in ascending order
  //     const sortedGroups = [...section.groups].sort((a, b) => a.id - b.id);

  //     sortedGroups.forEach((group) => {
  //       // Sort questions within each group by id in ascending order
  //       const sortedQuestions = [...group.questions].sort((a, b) => a.id - b.id);

  //       sortedQuestions.forEach((question) => {
  //         csvData.push({
  //           section: section.name,
  //           subSection: '', // Empty as per your requirement
  //           question: question.question,
  //           group: group.name,
  //           emptyColumn: '', // Empty as per your requirement
  //           type: 'Theater' // Assuming 'Theater' is the type, change if needed
  //         });
  //       });
  //     });

  //     // Sort subSections by id in ascending order
  //     const sortedSubSections = [...section.subSections].sort((a, b) => a.id - b.id);

  //     sortedSubSections.forEach((subSection) => {
  //       // Sort groups within each subSection by id in ascending order
  //       const sortedSubSectionGroups = [...subSection.groups].sort((a, b) => a.id - b.id);

  //       sortedSubSectionGroups.forEach((group) => {
  //         // Sort questions within each group by id in ascending order
  //         const sortedSubSectionQuestions = [...group.questions].sort((a, b) => a.id - b.id);

  //         sortedSubSectionQuestions.forEach((question) => {
  //           csvData.push({
  //             section: section.name,
  //             subSection: subSection.name, // Include subSection name
  //             question: question.question,
  //             group: group.name,
  //             emptyColumn: '', // Empty as per your requirement
  //             type: 'Theater' // Assuming 'Theater' is the type, change if needed
  //           });
  //         });
  //       });
  //     });
  //   });

  //   // Convert JSON to CSV string
  //   const csvString = Papa.unparse(csvData);

  //   // Create a Blob from the CSV string
  //   const blob = new Blob([csvString], { type: 'text/csv;charset=utf-8;' });

  //   // Create a link element
  //   const link = document.createElement('a');
  //   const url = URL.createObjectURL(blob);
  //   link.setAttribute('href', url);
  //   link.setAttribute('download', 'audit_data.csv');
  //   link.style.visibility = 'hidden';
  //   document.body.appendChild(link);
  //   link.click();
  //   document.body.removeChild(link);
  // };

  // const handleDownloadClick = () => {
  //   generateCSV(sections);
  // };


  // Renderowanie pojedynczej sekcji
  return (
    <div className=''>
      <div className='w-full flex items-center justify-between'>
        {/* Sekcja tytułu i powitania */}
        <div className='flex flex-col'>
          <h1 className='text-3xl font-semibold text-black'>Dashboard</h1>
          <p className='text-lg font-semibold text-gray-400 mt-1'>
            Hi {userInfo ? userInfo.first_name : 'NAZWA UZYTKOWNIKA'}!
            {currentPage !== 4 && ' You currently have:'}
          </p>
        </div>

        {/* Sekcja wyszukiwania */}
        <div className='flex mr-3'>
          <div className='hidden lg:block w-80 mx-12 relative'>
            <input
              className='bg-gray-100 p-2 h-10 rounded font-semibold text-base focus:outline-none focus:shadow border-gray-300 w-full'
              placeholder='Search here...'
              value={searchTerm}
              onChange={e => setSearchTerm(e.target.value)}
              onFocus={() => setIsFocused(true)}
              onBlur={() => {
                setTimeout(() => setIsFocused(false), 100);
              }}
            />
            {isFocused && (
              <div className='absolute top-full left-0 w-full bg-white shadow-lg border border-gray-300 rounded-b-md'>
                {searchResults.length > 0 && searchResults.map(audit => (
                  <div
                    className='cursor-pointer py-2 px-4 text-black hover:bg-gray-200 border-b'
                    key={audit.id}
                    onClick={(e) => handleSelectClick(e, audit)}
                  >
                    {audit.audit_title} - {formatDate(audit.start_date)}
                  </div>
                ))}
                {searchResults.length === 0 && (
                  <div className='py-2 px-4 text-gray-500'>No results found</div>
                )}
              </div>
            )}
          </div>

          {/* Sekcja użytkownika */}
          <UserElement />
        </div>
      </div>




      <div className='flex flex-col lg:flex-row flex-wrap gap-4 rounded-lg px-2 mt-6'>
        <div className='coverage-photo-container w-full lg:w-[660px] 3xl:w-[393px] h-[240px]'>
          <img src={CoveragePhoto} className='rounded-2xl w-full h-full object-cover' alt="Coverage" />
        </div>
        <div className='info-container w-full lg:w-[660px] h-auto lg:h-[240px] rounded-xl bg-gray-100 px-6 py-3'>
          <h1 className='text-2xl font-semibold text-black'>{auditTitle}</h1>
          <p className='text-md font-semibold text-gray-400 mt-2'>{auditCity}, {auditStreet}</p>
          {/* <button onClick={handleDownloadClick}>Download CSV</button> */}
          <div className={`w-full hidden sm:flex flex-wrap gap-4 mt-4 ${expandedSection === null ? 'block' : 'hidden'}`}>
            <div className='w-full sm:w-44 lg:w-48 bg-zinc-800 rounded-md px-2'>
              <div className='p-4 text-white font-semibold'>
                <div className='flex'>
                  <h2 className='text-4xl'>{missedQuestions}</h2>
                  <ChartHistogramIcon fill="#fff" className='w-6 h-6 ml-auto mt-2' />
                </div>
                <p className='text-base mt-1'>open questions</p>
              </div>
            </div>
            <div className='w-full sm:w-44 lg:w-48 bg-zinc-800 rounded-md px-2'>
              <div className='p-4 text-white font-semibold'>
                <div className='flex'>
                  <h2 className='text-4xl'>{totalQuestions}</h2>
                  <ChartHistogramIcon fill="#fff" className='w-6 h-6 ml-auto mt-2' />
                </div>
                <p className='text-base mt-1'>all questions</p>
              </div>
            </div>
            <div className='w-full sm:w-44 lg:w-48 bg-zinc-800 rounded-md px-2'>
              <div className='p-4 text-white font-semibold'>
                <div className='flex'>
                  <h2 className='text-4xl'>{Math.round(haScore)}%</h2>
                  <ChartPieIcon fill="#fff" className='w-6 h-6 ml-auto mt-2' />
                </div>
                <p className='text-base mt-1'>HA score</p>
              </div>
            </div>
          </div>
        </div>
      </div>



      <div className='flex z-5 pt-6'>
        {/* Edit Mode Toggle */}

        {(userInfo.role === "Admin" || userInfo.role === "Menager") && (
          <label className="inline-flex items-center cursor-pointer">
            <input type="checkbox" checked={isEditMode} onChange={toggleEditMode} className="sr-only peer" />
            <div className="relative w-11 h-6 ml-2 bg-gray-200 rounded-full peer dark:bg-gray-700 peer-checked:after:translate-x-full rtl:peer-checked:after:-translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:start-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-gray-600 peer-checked:bg-green-600"></div>
            <span className="ms-3 text-sm font-medium text-gray-900 dark:text-black">Edit Mode</span>
          </label>
        )}


        <QuestionSearchBar sections={sections} onSelect={handleQuestionSelect} expandedSection={expandedSection} />


      </div>

      {/* Section Header */}
      <div className="w-full px-2">
        {/* <h3 className="font-semibold text-xl">Section</h3> */}
        <div className="flex justify-between">
          <p className="mt-1 text-base font-semibold text-gray-500 w-2/6">Section name</p>
          <p className="mt-1 text-base font-semibold text-center text-gray-500 w-1/3 lg:w-1/6">Open questions</p>
          <p className="hidden lg:block mt-1 text-base font-semibold text-center text-gray-500 w-1/6">Progress</p>
          <p className="hidden lg:block mt-1 text-base font-semibold text-center text-gray-500 w-36">Section score</p>
        </div>
      </div>

      {/* Loading Spinner */}
      {isLoading ? (
        <div className="w-full   h-32 flex justify-center items-center mt-72">
          <Spinner height={40} width={40} />
        </div>
      ) : (
        <div className="">
          {/* Popups */}
          {isCommentPopupVisible && <CommentPopup currentComment={currentComment} setCurrentComment={setCurrentComment} closeCommentPopup={closeCommentPopup} saveComment={saveComment} commentPopupRef={commentPopupRef} />}
          {isCommentResponsePopupVisible && <CommentResponsePopup currentComment={currentComment} setCurrentComment={setCurrentComment} currentAiSolution={currentAiSolution} setCurrentAiSolution={setCurrentAiSolution} saveComment={saveComment} saveAiSolution={saveAiSolution} closeCommentResponsePopup={closeCommentResponsePopup} commentResponsePopupRef={commentResponsePopupRef} currentQuestionNumber={currentQuestionNumber} />}
          {isCreateGroupPopupVisible && (
            <CreateGroupPopup
              handleGroupTextChange={handleGroupTextChange}
              handleCreateGroup={handleCreateGroup}
              handleCreateGroupInSubsection={handleCreateGroupInSubsection}
              setIsCreateGroupPopupVisible={setIsCreateGroupPopupVisible}
              createGroupPopupRef={createGroupPopupRef}
              isSubsection={isSubsection}
            />
          )}
          {isSectionActionPopupVisible && (
            <SectionActionPopup
              handleDeleteSection={handleDeleteSection}
              openCreateGroupPopup={openCreateGroupPopup}
              openCreateSubSectionPopup={openCreateSubSectionPopup}
              section={currentSection}
              closePopup={closeActionPopup}
            />
          )}
          {isCreateSubSectionPopupVisible && (
            <CreateSubSectionPopup
              handleSubSectionTextChange={handleSubSectionTextChange}
              handleCreateSubSection={handleCreateSubSection}
              setIsCreateSubSectionPopupVisible={setIsCreateSubSectionPopupVisible}
            />
          )}

          {isCreateSectionPopupVisible && <CreateSectionPopup handleSectionTextChange={handleSectionTextChange} handleCreateSection={handleCreateSection} setIsCreateSectionPopupVisible={setIsCreateSectionPopupVisible} createSectionPopupRef={createSectionPopupRef} />}
          {fullSizePhoto && (
            <div className="fixed inset-0 bg-black bg-opacity-75 flex justify-center items-center z-50">
              <img src={fullSizePhoto} alt="Pełnowymiarowe zdjęcie" className="max-w-full max-h-full" onClick={() => setFullSizePhoto(null)} ref={fullSizePhotoRef} />
            </div>
          )}




          {sections
            .slice() // Stworzenie kopii tablicy, aby nie zmieniać oryginału
            .sort((a, b) => a.id - b.id) // Sortowanie elementów wg. rosnącego ID
            .map((section) => {
              // Obliczanie liczby pytań w sekcji
              const totalQuestionsInSection = section.groups.reduce(
                (total, group) => total + group.questions.length, 0
              ) + (section.subSections ? section.subSections.reduce(
                (total, subSection) => total + subSection.groups.reduce(
                  (subTotal, group) => subTotal + group.questions.length, 0
                ), 0
              ) : 0);

              const answeredQuestionsInSection = section.groups.reduce(
                (total, group) => total + group.questions.filter(
                  (question) => selectedAnswers[question.id]
                ).length, 0
              ) + (section.subSections ? section.subSections.reduce(
                (total, subSection) => total + subSection.groups.reduce(
                  (subTotal, group) => subTotal + group.questions.filter(
                    (question) => selectedAnswers[question.id]
                  ).length, 0
                ), 0
              ) : 0);

              const yesQuestionsInSection = section.groups.reduce(
                (total, group) => total + group.questions.filter(
                  (question) => selectedAnswers[question.id]?.answer === 'yes'
                ).length, 0
              ) + (section.subSections ? section.subSections.reduce(
                (total, subSection) => total + subSection.groups.reduce(
                  (subTotal, group) => subTotal + group.questions.filter(
                    (question) => selectedAnswers[question.id]?.answer === 'yes'
                  ).length, 0
                ), 0
              ) : 0);

              const naQuestionsInSection = section.groups.reduce(
                (total, group) => total + group.questions.filter(
                  (question) => selectedAnswers[question.id]?.answer === 'na'
                ).length, 0
              ) + (section.subSections ? section.subSections.reduce(
                (total, subSection) => total + subSection.groups.reduce(
                  (subTotal, group) => subTotal + group.questions.filter(
                    (question) => selectedAnswers[question.id]?.answer === 'na'
                  ).length, 0
                ), 0
              ) : 0);

              const totalQuestionsInSectionIncludingSubSections = totalQuestionsInSection;
              const answeredQuestionsInSectionIncludingSubSections = answeredQuestionsInSection;
              const yesQuestionsInSectionIncludingSubSections = yesQuestionsInSection;
              const naQuestionsInSectionIncludingSubSections = naQuestionsInSection;

              const openQuestionsInSection = totalQuestionsInSectionIncludingSubSections - answeredQuestionsInSectionIncludingSubSections;

              const progressPercentage = totalQuestionsInSectionIncludingSubSections > 0 ?
                ((answeredQuestionsInSectionIncludingSubSections / totalQuestionsInSectionIncludingSubSections) * 100).toFixed(2) :
                0;

              const scorePercentage = (answeredQuestionsInSectionIncludingSubSections - naQuestionsInSectionIncludingSubSections) > 0 ?
                ((yesQuestionsInSectionIncludingSubSections / (answeredQuestionsInSectionIncludingSubSections - naQuestionsInSectionIncludingSubSections)) * 100).toFixed(2) :
                0;


              if (expandedSection !== null && section.id !== expandedSection) {
                return null;
              }
              let questionIndex = 0;

              return (
                <div key={section.id} className="bg-white mx-2">
                  <div
                    onClick={() => {
                      if (expandedSection === section.id) {
                        setExpandedSection(null);
                        setExpandedGroup(null); // Dodaj tę linię, aby zresetować rozwiniętą grupę
                        setExpandedSubSection(null);
                      } else {
                        setExpandedSection(section.id);
                        setcurrentQuestionSection(section)
                      }
                    }}
                    className={`relative flex items-center justify-between cursor-pointer rounded-md my-2 text-center font-bold text-base  ${answeredQuestionsInSectionIncludingSubSections === totalQuestionsInSectionIncludingSubSections
                      ? 'border-2 border-green-500'
                      : 'border-0'
                      }`}
                  >
                    <div className="flex justify-between font-semibold text-sm w-full bg-gray-100 rounded-md py-3 ">
                      <div className='flex w-4/5 sm:w-2/6 pl-4 text-base '>
                        {isEditingSection && editingSectionId === section.id ? (
                          <input
                            type="text"
                            value={editingSectionName}
                            onChange={(e) => setEditingSectionName(e.target.value)}
                            onClick={(e) => {
                              e.stopPropagation();
                            }}
                            onBlur={handleUpdateSectionName}
                            autoFocus
                          />
                        ) : (
                          <div className='flex items-start align-top truncate w-4/5'>
                            {isEditMode ? (
                              <p className='truncate' onClick={(e) => {
                                e.stopPropagation();
                                setEditingSectionId(section.id);
                                setEditingSectionName(section.name);
                                setIsEditingSection(true);
                              }}>{section.name}</p>
                            ) : (
                              <p className='truncate '>{section.name}</p>
                            )}


                            {isEditMode &&
                              <>
                                {isEditMode && (
                                  <button
                                    className="ml-4"
                                    onClick={(e) => {
                                      e.stopPropagation();
                                      openActionPopup(section);
                                    }}
                                  >
                                    <PlusCircleIcon className="text-zinc-800 w-4 h-4 mt-1" />
                                  </button>
                                )}
                              </>
                            }

                          </div>
                        )}
                      </div>
                      <p className=' sm:w-1/5 w-1/5 lg:pr-0 lg:w-1/6 text-center text-base'>{openQuestionsInSection}</p>
                      <p className='hidden lg:block w-1/6 text-center text-base'>{progressPercentage}%</p>
                      <p className='hidden lg:block w-36  text-center text-base'>{yesQuestionsInSectionIncludingSubSections}/{totalQuestionsInSectionIncludingSubSections - naQuestionsInSectionIncludingSubSections} ({scorePercentage}%)</p>
                    </div>
                  </div>

                  {/* Renderowanie grup */}
                  {expandedSection === section.id && !expandedSubSection && section.groups

                    .slice() // Stworzenie kopii tablicy, aby nie zmieniać oryginału
                    .sort((a, b) => a.id - b.id) // Sortowanie elementów wg. rosnącego ID
                    .map((group) => {
                      const totalQuestionsInGroup = group.questions.filter(
                        (question) => currentPage === 4 ? selectedAnswers[question.id]?.answer === 'no' : group.questions.length
                      ).length;
                      const answeredQuestionsInGroup = group.questions.filter(
                        (question) => currentPage === 4 ? selectedAnswers[question.id]?.answer === 'no' : selectedAnswers[question.id]
                      ).length;

                      const yesQuestionsInGroup = group.questions.filter(
                        (question) => selectedAnswers[question.id]?.answer === 'yes'
                      ).length;

                      const naQuestionsInGroup = group.questions.filter(
                        (question) => selectedAnswers[question.id]?.answer === 'na'
                      ).length;

                      const groupProgressPercentage = (answeredQuestionsInGroup / totalQuestionsInGroup * 100).toFixed(2);
                      const groupScorePercentage = (yesQuestionsInGroup / (answeredQuestionsInGroup - naQuestionsInGroup) * 100).toFixed(2);

                      if (expandedGroup !== null && group.id !== expandedGroup) {
                        return null;
                      }

                      return (
                        <div key={group.id}>
                          <div
                            onClick={() => setExpandedGroup(expandedGroup === group.id ? null : group.id)}
                            className={`relative flex cursor-pointer my-3  lg:ml-4 text-center font-semibold  text-base rounded-md ${answeredQuestionsInGroup === totalQuestionsInGroup ? 'border-green-400 border-2' : 'border-gray-400 border'}`}
                          >
                            <div className="flex justify-between font-semibold text-sm w-full rounded-md py-2">
                              <div className='flex w-4/5 lg:w-2/6 pl-4 text-base'>
                                {isEditingGroup && editingGroupId === group.id ? (
                                  <input
                                    type="text"
                                    value={editingGroupName}
                                    onChange={(e) => setEditingGroupName(e.target.value)}
                                    onClick={(e) => {
                                      e.stopPropagation();
                                    }}
                                    onBlur={handleUpdateGroupName}
                                    autoFocus
                                  />
                                ) : (
                                  <div className='truncate'>
                                    {isEditMode ? (
                                      <div className='flex '>
                                        <p onClick={(e) => {
                                          e.stopPropagation();
                                          setEditingGroupId(group.id);
                                          setEditingGroupName(group.name);
                                          setIsEditingGroup(true);
                                        }}>{group.name}</p>
                                        <button
                                          className="ml-3"
                                          onClick={(e) => {
                                            e.stopPropagation();
                                            const isConfirmed = window.confirm(
                                              'Czy na pewno chcesz usunąć tą grupę?'
                                            )
                                            if (isConfirmed) {
                                              handleDeleteGroup(group.id);
                                            }
                                          }}
                                        >
                                          <TrashIcon className="text-zinc-800 w-4 h-4" />
                                        </button>
                                      </div>
                                    ) : (
                                      <div className='flex'>
                                        <p className='truncate'>
                                          {group.name}
                                        </p>
                                      </div>
                                    )}
                                  </div>
                                )}
                              </div>
                              <p className='w-1/5  lg:pr-4 lg:w-1/6 text-center text-base'>{totalQuestionsInGroup - answeredQuestionsInGroup}</p>
                              <p className='hidden lg:block lg:pr-1 w-1/6 text-center text-base '>{groupProgressPercentage}%</p>
                              <p className='hidden lg:block w-36 text-center text-base '>{yesQuestionsInGroup}/{totalQuestionsInGroup - naQuestionsInGroup} ({groupScorePercentage}%)</p>
                            </div>
                          </div>


                          {expandedGroup === group.id && group.questions
                            .slice() // Stworzenie kopii tablicy, aby nie zmieniać oryginału
                            .sort((a, b) => a.id - b.id) // Sortowanie elementów wg. rosnącego ID
                            .map((question) => (
                              <div
                                key={question.id}
                                className="ml-2 lg:ml-8 rounded-xl "
                                id={`question-${question.id}`} // Dodajemy id dla pytania
                              >
                                <div className='lg:flex w-full items-start'>
                                  <div className="flex w-full justify-between mb-4 lg:mb-0">
                                    <div className={`w-full text-base pr-4 font-semibold text-justify ${getTextClass(question.id, selectedAnswers[question.id]?.answer)} ${question.id === selectedQuestion ? 'highlight' : ''}`}>
                                      {editingQuestionId === question.id ? (
                                        <input
                                          value={currentQuestionText}
                                          onChange={(e) => setCurrentQuestionText(e.target.value)}
                                          onBlur={() => {
                                            if (currentQuestionText.trim() === '') {
                                              // Jeśli tekst jest pusty, usuwamy pytanie
                                              const isConfirmed = window.confirm('Czy na pewno chcesz usunąć to pytanie?');
                                              if (isConfirmed) {
                                                deleteResponse(question.id);
                                              }
                                            } else {
                                              // W przeciwnym razie zapisujemy edytowane pytanie
                                              saveEditedQuestion(question.id);
                                            }
                                          }}
                                          onKeyDown={(e) => {
                                            if (e.key === 'Enter') {
                                              if (currentQuestionText.trim() === '') {
                                                const isConfirmed = window.confirm('Czy na pewno chcesz usunąć to pytanie?');
                                                if (isConfirmed) {
                                                  setEditingQuestionId(null);
                                                  deleteResponse(question.id);
                                                  // Zamykanie okna potwierdzenia po usunięciu
                                                  return; // Zatrzymujemy dalsze przetwarzanie
                                                }
                                              } else {
                                                saveEditedQuestion(question.id);
                                              }
                                            }
                                          }}
                                          autoFocus
                                          className="p-2 w-full border-b-2 focus:outline-none font-semibold text-base"
                                        />
                                      ) : (
                                        <p
                                          className={`text-gray-700 text-base font-bold cursor-pointer ${getTextClass(
                                            question.id,
                                            selectedAnswers[question.id]?.answer
                                          )} ${question.id === selectedQuestion ? 'highlight' : ''}`}
                                          onClick={() => {
                                            setEditingQuestionId(question.id);
                                            setCurrentQuestionText(question.question);
                                          }}
                                        >
                                          {++questionIndex}. {question.question}

                                          {isEditMode && (
                                            <button
                                              onClick={(e) => {
                                                e.stopPropagation();
                                                const isConfirmed = window.confirm('Czy na pewno chcesz usunąć to pytanie?');
                                                if (isConfirmed) {
                                                  deleteResponse(question.id);
                                                }
                                              }}
                                            >
                                              <TrashIcon className="text-zinc-800  w-4 h-4 mr-4 ml-2 mt-1" />
                                            </button>
                                          )}
                                        </p>
                                      )}


                                    </div>
                                    <div className="sm:flex hidden lg:block">
                                      <div className="flex items-start mr-1">
                                        {generatingQuestionId === question.id ? (
                                          <div className="justify-center items-center w-6 h-6 my-2 md:mx-1 text-center">
                                            <Spinner width={20} height={20} /> {/* Dodaj komponent Spinner lub inną animację ładowania */}
                                          </div>
                                        ) : (
                                          <div
                                            onClick={() => generateAndSaveAiSolution(comments[question.id]?.note, question.id, question.question)}
                                            className='justify-center items-center w-6 h-6 cursor-pointer my-2 md:mx-1 text-center hover:scale-110'
                                          >
                                            <img src={aiSolutionSvg} alt='Solution' className='w-6 h-6' />
                                          </div>
                                        )}
                                        <div
                                          onClick={() =>
                                            openCommentPopup(question.id)
                                          }
                                          className="justify-center items-center w-6 h-6 cursor-pointer my-2 md:mx-1 text-center hover:scale-110"
                                        >
                                          <img
                                            src={commentSvg}
                                            alt="Comment"
                                            className='w-6 h-6'
                                          />
                                        </div>
                                        <div className='justify-center items-center w-6 h-6 cursor-pointer my-2 md:mx-1 text-center hover:scale-110'>
                                          <label htmlFor={`photoUpload${question.id}`}>
                                            <img src={photoSvg} className="w-6 h-6 cursor-pointer" />
                                          </label>
                                          <input
                                            type="file"
                                            onClick={() => setCurrentQuestionNumber(question.id)}
                                            onChange={(e) => handlePhotoChange(e, question.id)}
                                            id={`photoUpload${question.id}`}
                                            multiple
                                            className="hidden"
                                          />
                                        </div>
                                      </div>
                                    </div>
                                  </div>

                                  <div className="flex justify-between ">
                                    <div className="flex items-center space-x-2 mt-1 w-52 h-8">
                                      <button
                                        className={`${getButtonClass(question.id, 'yes')} rounded-lg w-16 h-7 font-semibold min-w-0  hover:scale-110`}
                                        onClick={() => handleSelectAnswer(question.id, 'yes')}
                                      >
                                        <span className="flex justify-center items-center text-sm">YES</span>
                                      </button>
                                      <button
                                        className={`${getButtonClass(question.id, 'no')} rounded-lg  w-16  h-7 font-semibold min-w-0   hover:scale-110`}
                                        onClick={() => handleSelectAnswer(question.id, 'no')}
                                      >
                                        <span className="flex justify-center items-center text-sm">NO</span>
                                      </button>
                                      <button
                                        className={`${getButtonClass(question.id, 'na')} rounded-lg  w-16  h-7 font-semibold min-w-0 hover:scale-110`}
                                        onClick={() => handleSelectAnswer(question.id, 'na')}
                                      >
                                        <span className="flex justify-center items-center text-sm">N/A</span>
                                      </button>
                                    </div>

                                    <div className="sm:hidden flex w-max ">
                                      <div className="flex items-start mr-1">
                                        {generatingQuestionId === question.id ? (
                                          <div className="justify-center items-center w-6 h-6 my-2 md:mx-1 text-center">
                                            <Spinner width={20} height={20} /> {/* Dodaj komponent Spinner lub inną animację ładowania */}
                                          </div>
                                        ) : (
                                          <div
                                            onClick={() => generateAndSaveAiSolution(comments[question.id]?.note, question.id, question.question)}
                                            className='justify-center items-center w-6 h-6 cursor-pointer my-2 md:mx-1 text-center hover:scale-110'
                                          >
                                            <img src={aiSolutionSvg} alt='Solution' className='w-6 h-6' />
                                          </div>
                                        )}
                                        <div
                                          onClick={() =>
                                            openCommentPopup(question.id)
                                          }
                                          className="justify-center items-center w-6 h-6 mx-2 cursor-pointer my-2 md:mx-1 text-center hover:scale-110"
                                        >
                                          <img
                                            src={commentSvg}
                                            alt="Comment"
                                            className='w-6 h-6'
                                          />
                                        </div>
                                        <div className='justify-center items-center w-6 h-6 cursor-pointer my-2 md:mx-1 text-center hover:scale-110'>
                                          <label htmlFor={`photoUpload${question.id}`}>
                                            <img src={photoSvg} className="w-6 h-6 cursor-pointer" />
                                          </label>
                                          <input
                                            type="file"
                                            onClick={() => setCurrentQuestionNumber(question.id)}
                                            onChange={(e) => handlePhotoChange(e, question.id)}
                                            id={`photoUpload${question.id}`}
                                            multiple
                                            className="hidden"
                                          />
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>

                                <div className='w-full pr-0 xl:pr-52'>
                                  {comments[question.id] && comments[question.id].note.trim() !== '' && (
                                    <div className="pb-2 flex text-justify mt-3">
                                      {editingCommentId === question.id ? (
                                        <textarea
                                          value={currentComment}
                                          onChange={(e) => {
                                            const cursorPosition = e.target.selectionStart;
                                            setCurrentComment(e.target.value);
                                            e.target.style.height = 'auto';
                                            e.target.style.height = e.target.scrollHeight + 'px';
                                            // Ustawienie pozycji kursora na poprzednią pozycję po zmianie tekstu
                                            setTimeout(() => {
                                              e.target.selectionStart = cursorPosition;
                                              e.target.selectionEnd = cursorPosition;
                                            }, 0);
                                          }}
                                          onBlur={() => {
                                            saveComment(question.id, currentComment);
                                            setEditingCommentId(null);
                                          }}
                                          onKeyDown={(e) => {
                                            if (e.key === 'Enter' && !e.shiftKey) {
                                              e.preventDefault();
                                              saveComment(question.id, currentComment);
                                              setEditingCommentId(null);
                                            }
                                          }}
                                          autoFocus
                                          className="text-xs text-gray-600 w-full resize-none overflow-hidden"
                                          style={{ height: '1.5em' }}
                                          ref={(textarea) => {
                                            if (textarea) {
                                              // Ustawienie kursora na końcu tekstu
                                              textarea.setSelectionRange(textarea.value.length, textarea.value.length);
                                            }
                                          }}
                                        />
                                      ) : (
                                        <p
                                          className="text-xs text-gray-600 cursor-pointer"
                                          onClick={() => {
                                            setEditingCommentId(question.id);
                                            setCurrentQuestionNumber(question.id);
                                            setCurrentComment(comments[question.id].note);
                                            setTimeout(() => {
                                              const parentNode = document.activeElement?.parentNode;
                                              if (parentNode) {
                                                const textarea = parentNode.querySelector('textarea');
                                                if (textarea) {
                                                  textarea.style.height = 'auto';
                                                  textarea.style.height = `${textarea.scrollHeight}px`;
                                                  // Ustawienie kursora na końcu tekstu
                                                  textarea.setSelectionRange(textarea.value.length, textarea.value.length);
                                                  textarea.focus();
                                                }
                                              }
                                            }, 0);
                                          }}
                                        >
                                          <span className='font-semibold mr-1'>COMMENTS:</span>
                                          <span>{comments[question.id].note}</span>
                                        </p>
                                      )}
                                    </div>
                                  )}


                                  {aiSolutions[question.id] && aiSolutions[question.id].ai_solution.trim() !== '' && (
                                    <div className="pb-2 flex text-justify mt-1">
                                      {editingAiSolutionId === question.id ? (
                                        <textarea
                                          value={currentAiSolution}
                                          onChange={(e) => {
                                            const cursorPosition = e.target.selectionStart;
                                            setCurrentAiSolution(e.target.value);

                                            // Automatyczne dopasowanie wysokości textarea do zawartości
                                            e.target.style.height = 'auto';
                                            e.target.style.height = `${e.target.scrollHeight}px`;

                                            // Ustawienie pozycji kursora na poprzednią pozycję po zmianie tekstu
                                            setTimeout(() => {
                                              e.target.selectionStart = cursorPosition;
                                              e.target.selectionEnd = cursorPosition;
                                            }, 0);
                                          }}
                                          onBlur={() => {
                                            saveAiSolution(question.id); // Zapisz zmiany
                                            setEditingAiSolutionId(null); // Wyłącz tryb edycji
                                          }}
                                          onKeyDown={(e) => {
                                            if (e.key === 'Enter' && !e.shiftKey) {
                                              e.preventDefault();
                                              saveAiSolution(question.id); // Zapisz zmiany po naciśnięciu Enter
                                              setEditingAiSolutionId(null); // Wyłącz tryb edycji po Enterze
                                            }
                                          }}
                                          autoFocus
                                          className="text-xs text-gray-600 w-full resize-none p-1 min-h-[100px] border-1"
                                          style={{ whiteSpace: 'pre-wrap', overflowWrap: 'break-word', overflow: 'hidden' }}
                                        />
                                      ) : (
                                        <p
                                          className="text-xs text-gray-600 cursor-pointer whitespace-pre-wrap"
                                          onClick={(e) => {
                                            setEditingAiSolutionId(question.id);
                                            setCurrentAiSolution(aiSolutions[question.id].ai_solution);
                                            setTimeout(() => {
                                              const parentNode = e.target.parentNode;
                                              if (parentNode) {
                                                const textarea = parentNode.querySelector('textarea');
                                                if (textarea) {
                                                  textarea.style.height = 'auto';
                                                  textarea.style.height = `${textarea.scrollHeight}px`;

                                                  // Ustawienie kursora na końcu tekstu przy rozpoczęciu edycji
                                                  textarea.focus();
                                                }
                                              }
                                            }, 0);
                                          }}
                                        >
                                          <span className='font-semibold mr-1'>Odpowiedź AI:</span>
                                          <span>{aiSolutions[question.id].ai_solution}</span>
                                        </p>
                                      )}
                                    </div>
                                  )}



                                </div>
                                <div className="flex mt-2 pb-2">
                                  {photos.get(question.id) &&
                                    photos.get(question.id).map((photo) => (
                                      <div key={photo.id} className="relative inline-block mt-2 mr-4">
                                        <img
                                          src={photo.url}
                                          alt={`Miniatura dla pytania ${question.id}`}
                                          className="sm:w-36 sm:h-36 w-24 h-24 rounded"
                                          onClick={() => openFullSizePhoto(photo.url)}
                                          loading='lazy'
                                        />
                                        <button
                                          onClick={() => deletePhoto(photo.id, question.id)}
                                          className="absolute -top-2 -right-2 bg-red-400 text-white h-6 w-6 p-1 rounded-full"
                                        >
                                          <TrashIcon className="text-white sm:w-4 sm:h-4" />
                                        </button>
                                      </div>
                                    ))}
                                </div>

                                <div className="flex mt-2">
                                  <div className='ml-auto'>
                                  </div>
                                </div>
                              </div>
                            ))}
                          {expandedGroup === group.id &&
                            <div className="w-full text-right">
                              <button
                                className="inline-flex mr-4 mb-2 items-center justify-center text-black font-bold text-3xl pb-1"
                                onClick={() => openCreateQuestionPopup(section, group, false)}  // false oznacza, że nie jest to podsekcja
                              >
                                +
                              </button>
                              <button
                                className="inline-flex mr-2 mb-2 items-center justify-center text-black font-bold text-2xl pb-1"
                                onClick={() =>
                                  setExpandedGroup(expandedGroup === group.id ? null : group.id)
                                }
                              >
                                ↑↓
                              </button>
                            </div>
                          }


                        </div>
                      );
                    })}

                  {/* Renderowanie subsekcji */}
                  {expandedSection === section.id && section.subSections && section.subSections
                    .slice() // Stworzenie kopii tablicy, aby nie zmieniać oryginału
                    .sort((a, b) => a.id - b.id) // Sortowanie elementów wg. rosnącego ID
                    .map((subSection) => {
                      const totalQuestionsInSubSection = subSection.groups.reduce(
                        (total, group) => total + group.questions.length, 0
                      );

                      const answeredQuestionsInSubSection = subSection.groups.reduce(
                        (total, group) => total + group.questions.filter(
                          (question) => selectedAnswers[question.id]
                        ).length, 0
                      );

                      const yesQuestionsInSubSection = subSection.groups.reduce(
                        (total, group) => total + group.questions.filter(
                          (question) => selectedAnswers[question.id]?.answer === 'yes'
                        ).length, 0
                      );

                      const naQuestionsInSubSection = subSection.groups.reduce(
                        (total, group) => total + group.questions.filter(
                          (question) => selectedAnswers[question.id]?.answer === 'na'
                        ).length, 0
                      );

                      const subSectionProgressPercentage = totalQuestionsInSubSection > 0 ?
                        ((answeredQuestionsInSubSection / totalQuestionsInSubSection) * 100).toFixed(2) :
                        0;

                      const subSectionScorePercentage = (answeredQuestionsInSubSection - naQuestionsInSubSection) > 0 ?
                        ((yesQuestionsInSubSection / (answeredQuestionsInSubSection - naQuestionsInSubSection)) * 100).toFixed(2) :
                        0;


                      if (expandedSubSection !== null && subSection.id !== expandedSubSection) {
                        return null;
                      }

                      return (
                        <div key={subSection.id} className="bg-white">
                          <div
                            onClick={() => {
                              if (expandedSubSection === subSection.id) {
                                setExpandedSubSection(null);
                                setExpandedGroup(null); // Zresetowanie rozwiniętej grupy
                              } else {
                                setExpandedGroup(null)
                                setExpandedSubSection(subSection.id);
                              }
                            }}
                            className={`relative flex items-center justify-between cursor-pointer rounded-md lg:ml-4 my-2 text-center font-bold text-base  ${answeredQuestionsInSubSection === totalQuestionsInSubSection
                              ? 'border-2 border-green-500 border-dashed'
                              : 'border border-gray-400 border-dashed'
                              }`}
                          >
                            <div className="flex justify-between font-semibold text-sm w-full  rounded-md py-2 ">
                              <div className='flex w-4/5 sm:w-2/6 pl-4 text-base '>
                                {isEditingSubSection && editingSubSectionId === subSection.id ? (
                                  <input
                                    type="text"
                                    value={editingSubSectionName}
                                    onChange={(e) => setEditingSubSectionName(e.target.value)}
                                    onClick={(e) => {
                                      e.stopPropagation();
                                    }}
                                    onBlur={handleUpdateSubSectionName}
                                    autoFocus
                                  />
                                ) : (
                                  <div className='flex items-start align-top truncate w-4/5'>
                                    {isEditMode ? (
                                      <p className='truncate' onClick={(e) => {
                                        e.stopPropagation();
                                        setEditingSubSectionId(subSection.id);
                                        setEditingSubSectionName(subSection.name);
                                        setIsEditingSubSection(true);
                                      }}>{subSection.name}</p>
                                    ) : (
                                      <p className='truncate '>{subSection.name}</p>
                                    )}
                                    {isEditMode &&
                                      <>
                                        <button
                                          className="ml-4"
                                          onClick={(e) => {
                                            e.stopPropagation();
                                            const isConfirmed = window.confirm(
                                              'Czy na pewno chcesz usunąć tę podsekcję?'
                                            );
                                            if (isConfirmed) {
                                              if (isConfirmed) {
                                                handleDeleteSubSection(subSection.id);
                                              }
                                            }
                                          }}
                                        >
                                          <TrashIcon className="text-zinc-800 w-4 h-4 mt-1" />
                                        </button>
                                        <button
                                          className="rounded-full inline-flex items-center ml-2 justify-center text-zinc-800 font-bold text-2xl h-6 w-6 md:w-6 md:h-6 pb-1"
                                          onClick={(e) => {
                                            e.stopPropagation();
                                            openCreateGroupPopup(subSection, "subsection");
                                          }}
                                        >
                                          +
                                        </button>
                                        <button
                                          className="rounded-full inline-flex items-center ml-2 justify-center text-zinc-800 font-bold text-sm h-6 w-6 md:w-6 md:h-6 pb-1"
                                          onClick={(e) => {
                                            e.stopPropagation();
                                            handleCopySubsection(subSection.id);
                                          }}
                                        >
                                          <CopyIcon className="w-4 h-4 mt-1" />
                                        </button>

                                      </>
                                    }
                                  </div>
                                )}
                              </div>
                              <p className=' sm:w-1/5 w-1/5 lg:pr-4 lg:w-1/6 text-center text-base'>{totalQuestionsInSubSection - answeredQuestionsInSubSection}</p>
                              <p className='hidden lg:block w-1/6 text-center text-base'>{subSectionProgressPercentage}%</p>
                              <p className='hidden lg:block w-36 text-center text-base'>{yesQuestionsInSubSection}/{totalQuestionsInSubSection - naQuestionsInSubSection} ({subSectionScorePercentage}%)</p>
                            </div>
                          </div>

                          {/* Renderowanie grup w podsekcjach */}
                          {expandedSubSection === subSection.id && subSection.groups
                            .slice() // Stworzenie kopii tablicy, aby nie zmieniać oryginału
                            .sort((a, b) => a.id - b.id) // Sortowanie elementów wg. rosnącego ID
                            .map((group) => {
                              const totalQuestionsInGroup = group.questions.filter(
                                (question) => currentPage === 4 ? selectedAnswers[question.id]?.answer === 'no' : group.questions.length
                              ).length;
                              const answeredQuestionsInGroup = group.questions.filter(
                                (question) => currentPage === 4 ? selectedAnswers[question.id]?.answer === 'no' : selectedAnswers[question.id]
                              ).length;

                              const yesQuestionsInGroup = group.questions.filter(
                                (question) => selectedAnswers[question.id]?.answer === 'yes'
                              ).length;

                              const naQuestionsInGroup = group.questions.filter(
                                (question) => selectedAnswers[question.id]?.answer === 'na'
                              ).length;

                              const groupProgressPercentage = (answeredQuestionsInGroup / totalQuestionsInGroup * 100).toFixed(2);
                              const groupScorePercentage = (yesQuestionsInGroup / (answeredQuestionsInGroup - naQuestionsInGroup) * 100).toFixed(2);

                              if (expandedGroup !== null && group.id !== expandedGroup) {
                                return null;
                              }

                              return (
                                <div key={group.id}>
                                  <div
                                    onClick={() => {
                                      setExpandedGroup(null);
                                      setTimeout(() => {
                                        setExpandedGroup(expandedGroup === group.id ? null : group.id);
                                      }, 0);
                                    }}
                                    className={`relative flex cursor-pointer my-3 lg:ml-8 text-center font-semibold text-base rounded-md ${answeredQuestionsInGroup === totalQuestionsInGroup ? 'border-green-400 border-2' : 'border-gray-400 border'}`}
                                  >
                                    <div className="flex justify-between font-semibold text-sm w-full rounded-md py-2">
                                      <div className='flex w-4/5 lg:w-2/6 pl-4 text-base'>
                                        {isEditingGroup && editingGroupId === group.id ? (
                                          <input
                                            type="text"
                                            value={editingGroupName}
                                            onChange={(e) => setEditingGroupName(e.target.value)}
                                            onClick={(e) => {
                                              e.stopPropagation();
                                            }}
                                            onBlur={handleUpdateGroupName}
                                            autoFocus
                                          />
                                        ) : (
                                          <div className='truncate'>
                                            {isEditMode ? (
                                              <div className='flex '>
                                                <p onClick={(e) => {
                                                  e.stopPropagation();
                                                  setEditingGroupId(group.id);
                                                  setEditingGroupName(group.name);
                                                  setIsEditingGroup(true);
                                                }}>{group.name}</p>
                                                <button
                                                  className="ml-3"
                                                  onClick={(e) => {
                                                    e.stopPropagation();
                                                    const isConfirmed = window.confirm(
                                                      'Czy na pewno chcesz usunąć tę grupę?'
                                                    );
                                                    if (isConfirmed) {
                                                      handleDeleteGroup(group.id);
                                                    }
                                                  }}
                                                >
                                                  <TrashIcon className="text-zinc-800 w-4 h-4" />
                                                </button>
                                              </div>
                                            ) : (
                                              <div className='flex'>
                                                <p className='truncate'>
                                                  {group.name}
                                                </p>
                                              </div>
                                            )}
                                          </div>
                                        )}
                                      </div>
                                      <p className='sm:w-1/5 w-1/5 lg:pr-8 lg:w-1/6 text-center text-base'>{totalQuestionsInGroup - answeredQuestionsInGroup}</p>
                                      <p className='hidden lg:block w-1/6 text-center text-base '>{groupProgressPercentage}%</p>
                                      <p className='hidden lg:block w-36 text-center text-base '>{yesQuestionsInGroup}/{totalQuestionsInGroup - naQuestionsInGroup} ({groupScorePercentage}%)</p>
                                    </div>
                                  </div>

                                  {expandedGroup === group.id && group.questions
                                    .slice() // Stworzenie kopii tablicy, aby nie zmieniać oryginału
                                    .sort((a, b) => a.id - b.id) // Sortowanie elementów wg. rosnącego ID
                                    .map((question) => (
                                      <div
                                        key={question.id}
                                        className="ml-2 lg:ml-8 rounded-xl "
                                        id={`question-${question.id}`} // Dodajemy id dla pytania
                                      >
                                        <div className='lg:flex w-full items-start'>
                                          <div className="flex w-full justify-between mb-4 lg:mb-0">
                                            <div className={`w-full text-base pr-4 font-semibold text-justify ${getTextClass(question.id, selectedAnswers[question.id]?.answer)} ${question.id === selectedQuestion ? 'highlight' : ''}`}>
                                              {editingQuestionId === question.id ? (
                                                <input
                                                  value={currentQuestionText}
                                                  onChange={(e) => setCurrentQuestionText(e.target.value)}
                                                  onBlur={() => {
                                                    if (currentQuestionText.trim() === '') {
                                                      // Jeśli tekst jest pusty, usuwamy pytanie
                                                      const isConfirmed = window.confirm('Czy na pewno chcesz usunąć to pytanie?');
                                                      if (isConfirmed) {
                                                        deleteResponse(question.id);
                                                      }
                                                    } else {
                                                      // W przeciwnym razie zapisujemy edytowane pytanie
                                                      saveEditedQuestion(question.id);
                                                    }
                                                  }}
                                                  onKeyDown={(e) => {
                                                    if (e.key === 'Enter') {
                                                      if (currentQuestionText.trim() === '') {
                                                        const isConfirmed = window.confirm('Czy na pewno chcesz usunąć to pytanie?');
                                                        if (isConfirmed) {
                                                          setEditingQuestionId(null);
                                                          deleteResponse(question.id);
                                                        }
                                                      } else {
                                                        saveEditedQuestion(question.id);
                                                      }
                                                    }
                                                  }}
                                                  autoFocus
                                                  className="p-2 w-full border-b-2 focus:outline-none font-semibold text-base"
                                                />
                                              ) : (
                                                <p
                                                  className={`text-gray-700 text-base font-bold cursor-pointer ${getTextClass(
                                                    question.id,
                                                    selectedAnswers[question.id]?.answer
                                                  )} ${question.id === selectedQuestion ? 'highlight' : ''}`}
                                                  onClick={() => {
                                                    setEditingQuestionId(question.id);
                                                    setCurrentQuestionText(question.question);
                                                  }}
                                                >
                                                  {++questionIndex}. {question.question}
                                                  {isEditMode && (
                                                    <button
                                                      onClick={(e) => {
                                                        e.stopPropagation();
                                                        const isConfirmed = window.confirm('Czy na pewno chcesz usunąć to pytanie?');
                                                        if (isConfirmed) {
                                                          deleteResponse(question.id);
                                                        }
                                                      }}
                                                    >
                                                      <TrashIcon className="text-zinc-800  w-4 h-4 mr-4 ml-2 mt-1" />
                                                    </button>
                                                  )}
                                                </p>
                                              )}

                                            </div>
                                            <div className="sm:flex hidden lg:block">
                                              <div className="flex items-start mr-1">
                                                {generatingQuestionId === question.id ? (
                                                  <div className="justify-center items-center w-6 h-6 my-2 md:mx-1 text-center">
                                                    <Spinner width={20} height={20} /> {/* Dodaj komponent Spinner lub inną animację ładowania */}
                                                  </div>
                                                ) : (
                                                  <div
                                                    onClick={() => generateAndSaveAiSolution(comments[question.id]?.note, question.id, question.question)}
                                                    className='justify-center items-center w-6 h-6 cursor-pointer my-2 md:mx-1 text-center hover:scale-110'
                                                  >
                                                    <img src={aiSolutionSvg} alt='Solution' className='w-6 h-6' />
                                                  </div>
                                                )}
                                                <div
                                                  onClick={() =>
                                                    openCommentPopup(question.id)
                                                  }
                                                  className="justify-center items-center w-6 h-6 cursor-pointer my-2 md:mx-1 text-center hover:scale-110"
                                                >
                                                  <img
                                                    src={commentSvg}
                                                    alt="Comment"
                                                    className='w-6 h-6'
                                                  />
                                                </div>
                                                <div className='justify-center items-center w-6 h-6 cursor-pointer my-2 md:mx-1 text-center hover:scale-110'>
                                                  <label htmlFor={`photoUpload${question.id}`}>
                                                    <img src={photoSvg} className="w-6 h-6 cursor-pointer" />
                                                  </label>
                                                  <input
                                                    type="file"
                                                    onClick={() => setCurrentQuestionNumber(question.id)}
                                                    onChange={(e) => handlePhotoChange(e, question.id)}
                                                    id={`photoUpload${question.id}`}
                                                    multiple
                                                    className="hidden"
                                                  />
                                                </div>
                                              </div>
                                            </div>
                                          </div>

                                          <div className="flex  justify-between">
                                            <div className="flex items-center space-x-2 mt-1 w-52 h-8">
                                              <button
                                                className={`${getButtonClass(question.id, 'yes')} rounded-lg w-16 h-7 font-semibold min-w-0  hover:scale-110`}
                                                onClick={() => handleSelectAnswer(question.id, 'yes')}
                                              >
                                                <span className="flex justify-center items-center text-sm">YES</span>
                                              </button>
                                              <button
                                                className={`${getButtonClass(question.id, 'no')} rounded-lg  w-16  h-7 font-semibold min-w-0   hover:scale-110`}
                                                onClick={() => handleSelectAnswer(question.id, 'no')}
                                              >
                                                <span className="flex justify-center items-center text-sm">NO</span>
                                              </button>
                                              <button
                                                className={`${getButtonClass(question.id, 'na')} rounded-lg  w-16  h-7 font-semibold min-w-0 hover:scale-110`}
                                                onClick={() => handleSelectAnswer(question.id, 'na')}
                                              >
                                                <span className="flex justify-center items-center text-sm">N/A</span>
                                              </button>
                                            </div>

                                            <div className="sm:hidden flex w-max ">
                                              <div className="flex items-start mr-1">
                                                {generatingQuestionId === question.id ? (
                                                  <div className="justify-center items-center w-6 h-6 my-2 md:mx-1 text-center">
                                                    <Spinner width={20} height={20} /> {/* Dodaj komponent Spinner lub inną animację ładowania */}
                                                  </div>
                                                ) : (
                                                  <div
                                                    onClick={() => generateAndSaveAiSolution(comments[question.id]?.note, question.id, question.question)}
                                                    className='justify-center items-center w-6 h-6 cursor-pointer my-2 md:mx-1 text-center hover:scale-110'
                                                  >
                                                    <img src={aiSolutionSvg} alt='Solution' className='w-6 h-6' />
                                                  </div>
                                                )}
                                                <div
                                                  onClick={() =>
                                                    openCommentPopup(question.id)
                                                  }
                                                  className="justify-center items-center w-6 h-6 mx-2 cursor-pointer my-2 md:mx-1 text-center hover:scale-110"
                                                >
                                                  <img
                                                    src={commentSvg}
                                                    alt="Comment"
                                                    className='w-6 h-6'
                                                  />
                                                </div>
                                                <div className='justify-center items-center w-6 h-6 cursor-pointer my-2 md:mx-1 text-center hover:scale-110'>
                                                  <label htmlFor={`photoUpload${question.id}`}>
                                                    <img src={photoSvg} className="w-6 h-6 cursor-pointer" />
                                                  </label>
                                                  <input
                                                    type="file"
                                                    onClick={() => setCurrentQuestionNumber(question.id)}
                                                    onChange={(e) => handlePhotoChange(e, question.id)}
                                                    id={`photoUpload${question.id}`}
                                                    multiple
                                                    className="hidden"
                                                  />
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                        </div>

                                        <div className='w-full pr-0 xl:pr-52'>
                                          {comments[question.id] && comments[question.id].note.trim() !== '' && (
                                            <div className="pb-2 flex text-justify mt-3">
                                              {editingCommentId === question.id ? (
                                                <textarea
                                                  value={currentComment}
                                                  onChange={(e) => {
                                                    const cursorPosition = e.target.selectionStart;
                                                    setCurrentComment(e.target.value);
                                                    e.target.style.height = 'auto';
                                                    e.target.style.height = e.target.scrollHeight + 'px';
                                                    // Ustawienie pozycji kursora na poprzednią pozycję po zmianie tekstu
                                                    setTimeout(() => {
                                                      e.target.selectionStart = cursorPosition;
                                                      e.target.selectionEnd = cursorPosition;
                                                    }, 0);
                                                  }}
                                                  onBlur={() => {
                                                    saveComment(question.id, currentComment);
                                                    setEditingCommentId(null);
                                                  }}
                                                  onKeyDown={(e) => {
                                                    if (e.key === 'Enter' && !e.shiftKey) {
                                                      e.preventDefault();
                                                      saveComment(question.id, currentComment);
                                                      setEditingCommentId(null);
                                                    }
                                                  }}
                                                  autoFocus
                                                  className="text-xs text-gray-600 w-full resize-none overflow-hidden"
                                                  style={{ height: '1.5em' }}
                                                  ref={(textarea) => {
                                                    if (textarea) {
                                                      // Ustawienie kursora na końcu tekstu
                                                      textarea.setSelectionRange(textarea.value.length, textarea.value.length);
                                                    }
                                                  }}
                                                />
                                              ) : (
                                                <p
                                                  className="text-xs text-gray-600 cursor-pointer"
                                                  onClick={() => {
                                                    setEditingCommentId(question.id);
                                                    setCurrentQuestionNumber(question.id);
                                                    setCurrentComment(comments[question.id].note);
                                                    setTimeout(() => {
                                                      const parentNode = document.activeElement?.parentNode;
                                                      if (parentNode) {
                                                        const textarea = parentNode.querySelector('textarea');
                                                        if (textarea) {
                                                          textarea.style.height = 'auto';
                                                          textarea.style.height = `${textarea.scrollHeight}px`;
                                                          // Ustawienie kursora na końcu tekstu
                                                          textarea.setSelectionRange(textarea.value.length, textarea.value.length);
                                                          textarea.focus();
                                                        }
                                                      }
                                                    }, 0);
                                                  }}
                                                >
                                                  <span className='font-semibold mr-1'>COMMENTS:</span>
                                                  <span>{comments[question.id].note}</span>
                                                </p>
                                              )}
                                            </div>
                                          )}



                                          {aiSolutions[question.id] && aiSolutions[question.id].ai_solution.trim() !== '' && (
                                            <div className="pb-2 flex text-justify mt-1">
                                              {editingAiSolutionId === question.id ? (
                                                <textarea
                                                  value={currentAiSolution}
                                                  onChange={(e) => {
                                                    const cursorPosition = e.target.selectionStart;
                                                    setCurrentAiSolution(e.target.value);

                                                    // Automatyczne dopasowanie wysokości textarea do zawartości
                                                    e.target.style.height = 'auto';
                                                    e.target.style.height = `${e.target.scrollHeight}px`;

                                                    // Ustawienie pozycji kursora na poprzednią pozycję po zmianie tekstu
                                                    setTimeout(() => {
                                                      e.target.selectionStart = cursorPosition;
                                                      e.target.selectionEnd = cursorPosition;
                                                    }, 0);
                                                  }}
                                                  onBlur={() => {
                                                    saveAiSolution(question.id); // Zapisz zmiany
                                                    setEditingAiSolutionId(null); // Wyłącz tryb edycji
                                                  }}
                                                  onKeyDown={(e) => {
                                                    if (e.key === 'Enter' && !e.shiftKey) {
                                                      e.preventDefault();
                                                      saveAiSolution(question.id); // Zapisz zmiany po naciśnięciu Enter
                                                      setEditingAiSolutionId(null); // Wyłącz tryb edycji po Enterze
                                                    }
                                                  }}
                                                  autoFocus
                                                  className="text-xs text-gray-600 w-full resize-none p-1 min-h-[100px] border-1"
                                                  style={{ whiteSpace: 'pre-wrap', overflowWrap: 'break-word', overflow: 'hidden' }}
                                                />
                                              ) : (
                                                <p
                                                  className="text-xs text-gray-600 cursor-pointer whitespace-pre-wrap"
                                                  onClick={(e) => {
                                                    setEditingAiSolutionId(question.id);
                                                    setCurrentAiSolution(aiSolutions[question.id].ai_solution);
                                                    setTimeout(() => {
                                                      const parentNode = e.target.parentNode;
                                                      if (parentNode) {
                                                        const textarea = parentNode.querySelector('textarea');
                                                        if (textarea) {
                                                          textarea.style.height = 'auto';
                                                          textarea.style.height = `${textarea.scrollHeight}px`;

                                                          // Ustawienie kursora na końcu tekstu przy rozpoczęciu edycji
                                                          textarea.focus();
                                                        }
                                                      }
                                                    }, 0);
                                                  }}
                                                >
                                                  <span className='font-semibold mr-1'>Odpowiedź AI:</span>
                                                  <span>{aiSolutions[question.id].ai_solution}</span>
                                                </p>
                                              )}
                                            </div>
                                          )}





                                        </div>
                                        <div className="flex mt-2 pb-2">
                                          {photos.get(question.id) &&
                                            photos.get(question.id).map((photo) => (
                                              <div key={photo.id} className="relative inline-block mt-2 mr-4">
                                                <img
                                                  src={photo.url}
                                                  alt={`Miniatura dla pytania ${question.id}`}
                                                  className="sm:w-36 sm:h-36 w-24 h-24 rounded"
                                                  onClick={() => openFullSizePhoto(photo.url)}
                                                  loading='lazy'
                                                />
                                                <button
                                                  onClick={() => deletePhoto(photo.id, question.id)}
                                                  className="absolute -top-2 -right-2 bg-red-400 text-white h-6 w-6 p-1 rounded-full"
                                                >
                                                  <TrashIcon className="text-white w-4 h-4" />
                                                </button>
                                              </div>
                                            ))}
                                        </div>

                                        <div className="flex mt-2">
                                          <div className='ml-auto'>
                                          </div>
                                        </div>
                                      </div>
                                    ))}
                                  {expandedGroup === group.id &&
                                    <div className="w-full text-right">
                                      <button
                                        className="inline-flex mr-4 mb-2 items-center justify-center text-black font-bold text-3xl pb-1"
                                        onClick={() => openCreateQuestionPopup(subSection, group, true)}  // true oznacza, że to podsekcja
                                      >
                                        +
                                      </button>
                                      <button
                                        className="inline-flex mr-2 mb-2 items-center justify-center text-black font-bold text-2xl pb-1"
                                        onClick={() =>
                                          setExpandedGroup(expandedGroup === group.id ? null : group.id)
                                        }
                                      >
                                        ↑↓
                                      </button>
                                    </div>
                                  }

                                </div>
                              );
                            })}
                        </div>
                      );
                    })}

                </div>
              );
            })}
          {isEditMode && !expandedGroup && !expandedSubSection && !expandedSection &&
            // <button onClick={openCreateSectionPopup}> Create Section</button>
            <button
              onClick={openCreateSectionPopup}
              className="bg-gray-500 text-white px-4 py-2 rounded-lg hover:bg-gray-600 transition shadow-lg ml-2 mt-4"
            >
              Create new Section
            </button>
          }
        </div>
      )}
    </div>
  );

};

export default SectionList;
