import React, { useRef, useEffect, useState } from 'react';

const CreateSubSectionPopup = ({ handleSubSectionTextChange, handleCreateSubSection, setIsCreateSubSectionPopupVisible }) => {
    const [newSubSectionName, setNewSubSectionName] = useState('');
    const inputRef = useRef(null);

    useEffect(() => {
        const handleKeyDown = (event) => {
            if (event.key === 'Enter' && !event.shiftKey) {
                event.preventDefault();
                handleCreateSubSection();
            }
        };

        const inputElement = inputRef.current;
        inputElement.addEventListener('keydown', handleKeyDown);

        return () => {
            inputElement.removeEventListener('keydown', handleKeyDown);
        };
    }, [handleCreateSubSection]);

    const handleInputChange = (e) => {
        setNewSubSectionName(e.target.value);
        handleSubSectionTextChange(e);
    };

    return (
        <div className="fixed inset-0 flex items-center justify-center z-50 bg-black bg-opacity-50">
            <div className="bg-white rounded-lg p-8 w-full max-w-3xl mx-4">
                <h2 className="text-2xl font-bold mb-6">Create a New Subsection</h2>
                <div className="mb-4">
                    <label htmlFor="subSectionName" className="block text-gray-700 text-lg font-bold mb-2">
                        Subsection Name:
                    </label>
                    <input
                        type="text"
                        id="subSectionName"
                        placeholder="Enter subsection name..."
                        value={newSubSectionName}
                        onChange={handleInputChange}
                        ref={inputRef}
                        className="border border-gray-300 p-3 w-full rounded-lg focus:outline-none focus:border-gray-500 font-semibold"
                    />
                </div>
                <div className="flex justify-between items-center mt-6">
                    <button
                        onClick={() => setIsCreateSubSectionPopupVisible(false)}
                        className="bg-gray-500 text-white px-4 py-2 rounded-lg hover:bg-gray-600 transition"
                    >
                        Cancel
                    </button>
                    <button
                        onClick={handleCreateSubSection}
                        className="bg-green-500 text-white px-4 py-2 rounded-lg hover:bg-green-600 transition"
                    >
                        Create
                    </button>
                </div>
            </div>
        </div>

    );
};

export default CreateSubSectionPopup;
