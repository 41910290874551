import React, { useState, useEffect, useRef } from 'react';
import { useAuth } from '../../context/AuthContext';
import axios from 'axios';
import { notification } from 'antd';

// Components
import PreviewPopup from '../MainPage/PreviewPopup';
import UserElement from '.././UserElement'
import MainSearchbar from '.././MainSearchbar'
// Icons
import { ReactComponent as ChartHistogramIcon } from '../../utils/dashboard/chart-histogram.svg';

const CopyAudit = ({
    auditTitle, setAuditTitle,
    startDate, setStartDate,
    endDate, setEndDate,
    coverPhoto, setCoverPhoto,
    questionGroups, selectedCategory, setSelectedCategory,
    questions, selectedGroups, setSelectedGroups,
    setSelectedUsers, handleSubmit,
    collapsedSections, setCollapsedSections,
    users, selectedUsers,
    sectionCounts, setSectionCounts,
    questionGroupsUnique, setCreateObjectType,
    setQuestiongroupId, setCurrentAudit,
    setIsSectionsView, allAudits, setCreatePageNumber
}) => {
    const { authTokens, sentAudits } = useAuth();
    const [audit, setAudit] = useState(null);
    const [isPreviewPopupVisible, setIsPreviewPopupVisible] = useState(false);
    const [showScrollbar, setShowScrollbar] = useState(false);
    const lastScrollPos = useRef(null);
    const [selectAll, setSelectAll] = useState(false);
    const [isUserListVisible, setIsUserListVisible] = useState(false);
    const [expandedSection, setExpandedSection] = useState(null);
    const [selectedObjectType, setSelectedObjectType] = useState(null);
    const [selectedAudit, setSelectedAudit] = useState(null);
    const PreviewPopupRef = useRef();
    const backendUrl = process.env.REACT_APP_BACKEND_URL;

    const formatDate = (dateString) => {
        const date = new Date(dateString);
        const day = String(date.getDate()).padStart(2, '0');
        const month = String(date.getMonth() + 1).padStart(2, '0');
        const year = date.getFullYear().toString().slice(-2);
        return `${day}/${month}/${year}`;
    };
    console.log(sentAudits)
    useEffect(() => {
        let scrollTimeout;

        function hideScrollbarFunc() {
            setShowScrollbar(false);
        }

        function showScrollbarFunc() {
            setShowScrollbar(true);
            clearTimeout(scrollTimeout);
            scrollTimeout = setTimeout(hideScrollbarFunc, 3000);
        }

        function handleScroll() {
            if (lastScrollPos.current !== window.scrollY) {
                showScrollbarFunc();
                lastScrollPos.current = window.scrollY;
            }
        }

        function handleMouseMove() {
            showScrollbarFunc();
        }

        function handleTouchStart() {
            showScrollbarFunc();
        }

        window.addEventListener('scroll', handleScroll);
        window.addEventListener('mousemove', handleMouseMove);
        window.addEventListener('touchstart', handleTouchStart);

        return () => {
            window.removeEventListener('scroll', handleScroll);
            window.removeEventListener('mousemove', handleMouseMove);
            window.removeEventListener('touchstart', handleTouchStart);
        };
    }, []);

    const groupQuestionsBySectionAndGroup = (questions, selectedCategory) => {
        if (!Array.isArray(questions)) return {};

        const filteredQuestions = questions.filter(question => question.question_group === selectedCategory);

        return filteredQuestions.reduce((acc, question) => {
            const section = question.section || 'Brak sekcji';
            const subSection = question.sub_section || '';
            const group = question.group || 'Brak grupy';

            acc[section] = acc[section] || {};
            acc[section][subSection] = acc[section][subSection] || {};
            acc[section][subSection][group] = acc[section][subSection][group] || [];
            acc[section][subSection][group].push(question);

            return acc;
        }, {});
    };

    const groupedQuestions = groupQuestionsBySectionAndGroup(questions, selectedCategory);



    const getFill = (objectType) => {
        if (objectType === selectedObjectType) {
            return '#fff';
        }
        return '#000';
    }

    const handleUserSelection = (userId) => {
        setSelectedUsers((prevSelectedUsers) => {
            const userIndex = prevSelectedUsers.indexOf(userId);
            if (userIndex !== -1) {
                return [...prevSelectedUsers.slice(0, userIndex), ...prevSelectedUsers.slice(userIndex + 1)];
            } else {
                return [...prevSelectedUsers, userId];
            }
        });
    };

    const uniqueObjectTypes = new Set();

    Object.values(questionGroupsUnique).forEach((questions) => {
        questions.forEach((question) => {
            if (question.audit_object_type && question.object_type_name) {
                uniqueObjectTypes.add(question.object_type_name);
            }
        });
    });

    let categories = Object.keys(questionGroupsUnique)
        .filter(groupName => {
            return questionGroupsUnique[groupName].some(question => {
                return question.object_type_name === selectedObjectType;
            });
        });

    const objectTypeButtons = Array.from(new Set(sentAudits.map(audit => audit.object_type))).map(objectType => {
        const objectCount = sentAudits.filter(audit => audit.object_type === objectType).length;

        return (
            <div
                key={objectType}
                onClick={() => {
                    setSelectedObjectType(objectType);
                    setCreateObjectType(objectType);

                    const auditsForSelectedObjectType = sentAudits.filter(audit => audit.object_type === objectType);

                    if (auditsForSelectedObjectType.length > 0) {
                        const firstAudit = auditsForSelectedObjectType[0];
                        setSelectedAudit(firstAudit.audit_id);
                        setQuestiongroupId(firstAudit.audit_id);
                        setSelectedCategory(firstAudit.audit_title);
                    } else {
                        setSelectedAudit('');
                        setQuestiongroupId(null);
                        setSelectedCategory('');
                    }
                }}
                className={`w-full sm:w-64 rounded-md py-2 px-4 cursor-pointer font-semibold ${objectType === selectedObjectType ? 'bg-zinc-800 text-white' : 'bg-gray-200 text-black'}`}
            >
                <h2 className="text-4xl flex items-center">{objectType} <ChartHistogramIcon fill={getFill(objectType)} className="w-6 h-6 ml-auto mr-3 mt-2" /></h2>
                <p className="text-lg mt-2">{objectCount} objects</p>
            </div>
        );
    });



    useEffect(() => {
        if (users.length > 0 && !Array.isArray(selectedUsers)) {
            setSelectedUsers([users[0].id]);
        }
    }, [users]);

    const handleSelectAllSections = () => {
        if (selectAll) {
            setSelectedGroups({});
            setSelectAll(false);
        } else {
            const selectedAll = {};
            Object.keys(groupedQuestions).forEach(section => {
                Object.keys(groupedQuestions[section]).forEach(subSection => {
                    Object.keys(groupedQuestions[section][subSection]).forEach(group => {
                        selectedAll[`${section}__${subSection}__${group}`] = true;
                    });
                });
            });
            setSelectedGroups(selectedAll);
            setSelectAll(true);
        }
    };

    const [searchTerm, setSearchTerm] = useState('');
    const [searchResults, setSearchResults] = useState([]);
    const [isFocused, setIsFocused] = useState(false);

    useEffect(() => {
        if (searchTerm === '') {
            setSearchResults([]);
        } else {
            const results = allAudits.filter(audit =>
                audit.audit_title.toLowerCase().includes(searchTerm.toLowerCase()) ||
                formatDate(audit.start_date).includes(searchTerm.toLowerCase())
            );
            setSearchResults(results);
        }
    }, [searchTerm, allAudits]);

    const openPreviewPopup = (audit) => {
        setCurrentAudit(audit.audit_id);
        setAudit(audit)
        setIsPreviewPopupVisible(true);
    };

    const handleSelectClick = (event, audit) => {
        event.stopPropagation();

        if (event.target.tagName.toLowerCase() === 'select') {
            return;
        }

        openPreviewPopup(audit);
    };
    console.log(selectedAudit)

    const handleCopySubmit = async (event) => {
        event.preventDefault();

        const formData = new FormData();
        formData.append('title', auditTitle);
        formData.append('start_date', startDate);
        formData.append('end_date', endDate);
        formData.append('user_ids', JSON.stringify(selectedUsers));
        formData.append('is_unique', true);
        formData.append('copy_audit_id', selectedAudit);

        try {
            const response = await axios.post(`${backendUrl}/audit-page/copy-audit/`, formData, {
                headers: {
                    'Authorization': `Bearer ${authTokens.access}`,
                    'Content-Type': 'multipart/form-data',
                },
            });

            console.log('Audit copied successfully:', response.data);
            notification.success({
                message: 'Audit copied successfully',
                description: 'Audit został poprawnie skopiowany.',
            });
        } catch (error) {
            console.error('Error copying audit:', error);
        }
    };

    const auditsForSelectedObjectType = sentAudits.filter(audit => audit.object_type === selectedObjectType);


    const [isOpen, setIsOpen] = useState(false);
    const [isFocusedSelect, setIsFocusedSelect] = useState(false);


    const handleToggle = () => {
        setIsOpen(!isOpen);
    };

    const handleOptionClick = (value) => {
        setSelectedAudit(value);
        setIsOpen(false);
    };

    const selectedOption = auditsForSelectedObjectType.find(audit => audit.audit_id === selectedAudit);
    return (
        <>
            <div className={`lg:w-full w-full px-4 lg:ml-6 pt-8 sm:overflow-auto lg:max-h-screen ${showScrollbar ? '' : 'hide-scrollbar'}`} id="scrollContainer">
                {isPreviewPopupVisible && (
                    <PreviewPopup
                        audit={audit}
                        PreviewPopupRef={PreviewPopupRef}
                        setIsSectionsView={setIsSectionsView}
                        setIsPreviewPopupVisible={setIsPreviewPopupVisible}
                    />
                )}
                <div className="w-full flex items-center justify-between mt-1">
                    <h1 className="text-4xl font-bold text-gray-800 mr-8">Copy Audit</h1>
                    <div className='flex mr-3'>
                        <div className='hidden lg:block w-80 mx-12 relative'>
                           <MainSearchbar allAudits={allAudits}/>
                        </div>
                        <UserElement />
                    </div>
                </div>

                <p className="text-2xl font-semibold mt-6 mb-4 text-gray-700">Choose Place Type:</p>

                <div className="flex flex-wrap gap-4 mt-2">
                    {objectTypeButtons}
                </div>

                <div className='max-w-4xl'>
                    <h3 className="mt-12 text-2xl font-semibold text-gray-700">Add General Info:</h3>
                    <div className="flex flex-wrap gap-4 mt-2">
                        <div className="relative w-72">
                            {/* Button that acts as the "select" */}
                            <button
                                type="button"
                                onClick={handleToggle}
                                disabled={!selectedObjectType}
                                className={`w-full text-start p-2 border border-gray-300 bg-white ${isOpen ? 'rounded-t-lg' : 'rounded-lg'} shadow-sm focus:outline-none focus:border-gray-400 transition duration-150 ${selectedAudit ? 'text-black' : 'text-gray-500'} `}
                            >
                                {selectedOption ? selectedOption.audit_title : 'Choose Audit'}
                                <svg
                                    className={`w-5 h-5 text-gray-500 transform transition-transform duration-200 ${isOpen ? 'rotate-180' : ''} absolute right-2 top-1/2 -translate-y-1/2`}
                                    xmlns="http://www.w3.org/2000/svg"
                                    viewBox="0 0 20 20"
                                    fill="currentColor"
                                >
                                    <path
                                        fillRule="evenodd"
                                        d="M6.293 9.293a1 1 0 011.414 0L10 11.586l2.293-2.293a1 1 0 111.414 1.414l-3 3a1 1 0 01-1.414 0l-3-3a1 1 0 010-1.414z"
                                        clipRule="evenodd"
                                    />
                                </svg>
                            </button>
                            {/* Dropdown list */}
                            {isOpen && (
                                <ul className="absolute left-0 w-full bg-white border border-t-0 border-gray-400 rounded-b-lg shadow-lg max-h-60 overflow-y-auto z-10">
                                    {auditsForSelectedObjectType.map((audit) => (
                                        <li
                                            key={audit.audit_id}
                                            onClick={() => handleOptionClick(audit.audit_id)}
                                            className="cursor-pointer px-4 py-2 text-gray-700 hover:bg-gray-100 transition-colors duration-150"
                                        >
                                            {audit.audit_title}
                                        </li>
                                    ))}
                                </ul>
                            )}
                        </div>
                    </div>


                    <div className="flex flex-col gap-4 mt-4 sm:flex-row">
                        <input
                            className="w-full sm:w-1/3 p-2 h-10 font-medium border border-gray-300 bg-white rounded-lg shadow-sm focus:outline-none focus:border-gray-400  placeholder-gray-500 hover:border-gray-400 transition duration-200"
                            type="text"
                            name="startDate"
                            id="startDate"
                            value={startDate}
                            onFocus={(e) => (e.target.type = 'date')}
                            onBlur={(e) => (e.target.type = 'text')}
                            onChange={(e) => setStartDate(e.target.value)}
                            placeholder="Start Date"
                        />
                        <input
                            className="w-full sm:w-1/3 p-2 h-10 font-medium border border-gray-300 bg-white rounded-lg shadow-sm focus:outline-none focus:border-gray-400 hover:border-gray-400 transition duration-200 placeholder-gray-500"
                            type="text"
                            name="endDate"
                            id="endDate"
                            value={endDate}
                            onFocus={(e) => (e.target.type = 'date')}
                            onBlur={(e) => (e.target.type = 'text')}
                            onChange={(e) => setEndDate(e.target.value)}
                            placeholder="End Date"
                        />

                        <div className="w-full sm:w-1/3 relative">
                            <div
                                className="w-full p-2 font-medium border border-gray-300 bg-white text-gray-500 rounded-lg shadow-sm cursor-pointer hover:border-gray-400 transition duration-150"
                                onClick={() => setIsUserListVisible(!isUserListVisible)}
                            >
                                Select Users
                            </div>
                            {isUserListVisible && (
                                <div className="absolute z-10 w-full bg-white border border-gray-300 rounded-lg mt-1 shadow-lg max-h-40 overflow-y-auto">
                                    {users.map(user => (
                                        <div key={user.id} className="flex items-center p-2 hover:bg-blue-50">
                                            <input
                                                type="checkbox"
                                                id={`user-${user.id}`}
                                                checked={selectedUsers.includes(user.id)}
                                                onChange={() => handleUserSelection(user.id)}
                                                className="w-4 h-4 text-blue-600 rounded border-gray-300 focus:ring-blue-500"
                                            />
                                            <label htmlFor={`user-${user.id}`} className="ml-2 text-sm text-gray-600">
                                                {user.username}
                                            </label>
                                        </div>
                                    ))}
                                </div>
                            )}
                        </div>
                    </div>
                </div>

                <button
                    onClick={handleCopySubmit}
                    type='button'
                    className='w-36 mb-24 mt-32 sm:mt-6 sm:mb-6 mx-auto py-2 px-4 bg-zinc-800 text-white text-lg font-semibold rounded-lg hover:bg-zinc-600 focus:outline-none focus:ring-2 focus:ring-blue-300 transition duration-150'
                >
                    Copy Audit
                </button>
            </div>

        </>
    );
}

export default CopyAudit;
