import React from 'react';

const Spinner = ({ height, width }) => {
  return (
    <div className="spinner-container m-auto" style={{ height: height ? `${height}px` : 'auto' }}>
      <div className="spinner"  style={{ height: `${height}px`, width: `${width}px` }}></div>
    </div>
  );
};

export default Spinner;
