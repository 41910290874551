import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useAuth } from '../../context/AuthContext';
import UserForm from './UserForm';
import AssignAudit from './AssignAudit';
import defaultAvatar from '../../utils/svgs/default-avatar.svg';
import { notification } from 'antd';
import { FaUserEdit, FaTasks } from 'react-icons/fa';

const UserManagement = () => {
    const [userProfiles, setUserProfiles] = useState([]);
    const [isAssigningAudit, setIsAssigningAudit] = useState(false);
    const [isAddingUser, setIsAddingUser] = useState(false);
    const [selectedUser, setSelectedUser] = useState(null);
    const backendUrl = process.env.REACT_APP_BACKEND_URL;
    const { authTokens } = useAuth();

    useEffect(() => {
        fetchUserProfiles();
    }, []);

    const fetchUserProfiles = async () => {
        try {
            const response = await axios.get(`${backendUrl}/audit-page/user-profiles/`, {
                headers: {
                    Authorization: `Bearer ${authTokens?.access || ''}`,
                },
            });
            setUserProfiles(response.data);
        } catch (error) {
            console.error('Error fetching user profiles:', error);
            notification.error({
                message: 'Wystąpił błąd',
                description: 'Nie udało się pobrać danych użytkowników.',
            });
        }
    };

    const handleRoleChange = async (userId, newRole) => {
        try {
            await axios.post(
                `${backendUrl}/audit-page/manage-role/`,
                {
                    user_id: userId,
                    role: newRole,
                },
                {
                    headers: {
                        Authorization: `Bearer ${authTokens?.access || ''}`,
                    },
                }
            );
            fetchUserProfiles(); // Odświeżenie listy użytkowników po zmianie roli
        } catch (error) {
            console.error('Error updating role:', error);
            notification.error({
                message: 'Wystąpił błąd',
                description: 'Nie udało się zaktualizować roli.',
            });
        }
    };

    return (
        <div className="p-4 sm:p-8">
            <h2 className="text-2xl font-bold mb-4">User Management</h2>

            {!isAssigningAudit && !isAddingUser && (
                <>
                    <button
                        onClick={() => setIsAddingUser(true)}
                        className="mb-4 px-4 py-2 bg-green-500 text-white rounded hover:bg-green-600 transition duration-200"
                    >
                        Add New User
                    </button>

                    {/* Responsywna lista użytkowników */}
                    <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
                        {userProfiles.map((profile) => (
                            <div
                                key={profile.id}
                                className="bg-white p-4 rounded-lg shadow flex flex-col"
                            >
                                <div className="flex items-center mb-4">
                                    <img
                                        src={profile.profile_picture ? `${profile.profile_picture}` : defaultAvatar}
                                        alt={profile.username}
                                        className="h-12 w-12 rounded-full mr-4"
                                    />
                                    <div>
                                        <h3 className="text-lg font-semibold">{profile.username}</h3>
                                        <p className="text-gray-600 text-sm">{profile.email}</p>
                                    </div>
                                </div>

                                <div className="flex items-center mb-4">
                                    <label htmlFor={`role-${profile.id}`} className="mr-2 font-medium">
                                        Role:
                                    </label>
                                    <select
                                        id={`role-${profile.id}`}
                                        value={profile.role}
                                        onChange={(e) => handleRoleChange(profile.id, e.target.value)}
                                        className="flex-1 border rounded px-2 py-1"
                                    >
                                        <option value="Admin">Admin</option>
                                        <option value="Manager">Manager</option>
                                        <option value="Auditor">Auditor</option>
                                    </select>
                                </div>

                                <div className="flex justify-between mt-auto">
                                    <button
                                        onClick={() => {
                                            setSelectedUser(profile.id);
                                            setIsAssigningAudit(true);
                                        }}
                                        className="flex items-center px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-600 transition duration-200"
                                    >
                                        <FaTasks className="mr-2" />
                                        Assign Audit
                                    </button>
                                    {/* Możesz dodać inne akcje tutaj */}
                                </div>
                            </div>
                        ))}
                    </div>
                </>
            )}

            {isAssigningAudit && (
                <AssignAudit selectedUser={selectedUser} onCancel={() => setIsAssigningAudit(false)} />
            )}

            {isAddingUser && <UserForm onCancel={() => setIsAddingUser(false)} />}
        </div>
    );
};

export default UserManagement;
