import React, { useState, useRef, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../context/AuthContext';

const UserElement = () => {
    const { userInfo, logout, setCurrentPage } = useAuth();
    const [isMenuOpen, setIsMenuOpen] = useState(false);
    const menuRef = useRef(null); // Referencja do rozwijanego menu
    const navigate = useNavigate();
    const [isMobile, setIsMobile] = useState(window.innerWidth < 640);

    const handleMenuToggle = () => {
        setIsMenuOpen(!isMenuOpen);
    };

    const handleSettingsClick = () => {
        navigate('/home');
        setCurrentPage(6);
        setIsMenuOpen(false);
    };

    const handleLogoutClick = async () => {
        await logout();
        navigate('/login');
        setIsMenuOpen(false);
    };

    // Event listener zamykający menu po kliknięciu poza nim
    useEffect(() => {
        const handleClickOutside = (event) => {
            if (menuRef.current && !menuRef.current.contains(event.target)) {
                setIsMenuOpen(false);
            }
        };

        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    // Sprawdzanie rozdzielczości ekranu w czasie rzeczywistym
    useEffect(() => {
        const handleResize = () => {
            setIsMobile(window.innerWidth < 640);
        };

        window.addEventListener('resize', handleResize);
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    // Ukrycie komponentu na ekranach mobilnych
    if (isMobile) {
        return null;
    }

    return (
        <div
            ref={menuRef} // Przypisanie referencji do kontenera menu
            className={`relative hidden sm:flex items-center bg-white p-2 h-10 w-52 border border-gray-300 hover:border-gray-400 cursor-pointer transition duration-200 ${isMenuOpen ? 'rounded-t-lg border-gray-400' : 'rounded-lg'}`}
            onClick={handleMenuToggle}
        >
            <img
                src={userInfo.profile_picture}
                className='rounded-full bg-gray-200 w-8 h-8'
                alt='Zdjęcie profilowe'
            />
            <h3 className='text-sm font-medium text-gray-700 mx-auto'>
                {userInfo
                    ? `${userInfo.first_name} ${userInfo.last_name ? userInfo.last_name[0] + '.' : ''}`
                    : 'NAZWA UŻYTKOWNIKA'}
            </h3>
            <svg
                className={`w-5 h-5 text-gray-500 transform ${isMenuOpen ? 'rotate-180' : ''} transition-transform duration-200`}
                xmlns='http://www.w3.org/2000/svg'
                viewBox='0 0 20 20'
                fill='currentColor'
            >
                <path
                    fillRule='evenodd'
                    d='M6.293 9.293a1 1 0 011.414 0L10 11.586l2.293-2.293a1 1 0 111.414 1.414l-3 3a1 1 0 01-1.414 0l-3-3a1 1 0 010-1.414z'
                    clipRule='evenodd'
                />
            </svg>
            {isMenuOpen && (
                <div className='absolute left-[-1px] top-full mt-0 w-52 border border-gray-400 bg-white shadow-lg rounded-b-lg z-20'>
                    <div
                        role='menu'
                        aria-orientation='vertical'
                        aria-labelledby='options-menu'
                    >
                        <button
                            onClick={handleSettingsClick}
                            className='block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 w-full text-left transition-colors duration-150'
                            role='menuitem'
                        >
                            Ustawienia
                        </button>
                        <button
                            onClick={handleLogoutClick}
                            className='block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 w-full text-left transition-colors duration-150 rounded-b-lg'
                            role='menuitem'
                        >
                            Wyloguj
                        </button>
                    </div>
                </div>
            )}
        </div>
    );
};

export default UserElement;
