import React, { useState, useEffect, useRef } from 'react';
import { Calendar, momentLocalizer } from 'react-big-calendar';
import moment from 'moment';
import 'moment/locale/pl';
import 'react-big-calendar/lib/css/react-big-calendar.css';
import { useAuth } from '../context/AuthContext';
import Nav from '../components/MainPage/Nav';
import Event from '../components/Event';
import { useNavigate } from 'react-router-dom';
import { FaGoogle, FaSyncAlt } from 'react-icons/fa';
import PreviewPopup from '../components/MainPage/PreviewPopup'; // Importuj komponent PreviewPopup
import UserElement from '../components/UserElement';
import MainSearchbar from '../components/MainSearchbar'
moment.locale('pl');

const CalendarPage = () => {
    const backendUrl = process.env.REACT_APP_BACKEND_URL;

    const {
        scheduledAudits = [],
        inProgressAudits = [],
        toCheckAudits = [],
        toSendAudits = [],
        sentAudits = [],
        currentPage,
        setCurrentPage,
        createPageNumber,
        setCreatePageNumber,
        setToCheckAudits,
        setScheduledAudits,
        setInProgressAudits,
        setToSendAudits,
        setSentAudits,
        setAllAudits,
        allAudits = [],
        authTokens,
    } = useAuth() || {};

    const localizer = momentLocalizer(moment);
    const navigate = useNavigate();

    const handleGoogleSync = () => {
        fetch(`${backendUrl}/audit-page/get_google_auth_url/`, {
            method: 'GET',
            headers: {
                'Authorization': 'Bearer ' + authTokens?.access,
                'Content-Type': 'application/json',
            },
        })
            .then(response => response.json())
            .then(data => {
                const authorizationUrl = data.authorization_url;
                window.location.href = authorizationUrl;
            })
            .catch(error => {
                console.error('Error fetching authorization URL:', error);
            });
    };

    const handleRefreshAudits = () => {
        fetch(`${backendUrl}/audit-page/sync_audits_to_calendar/`, {
            method: 'POST',
            headers: {
                'Authorization': 'Bearer ' + authTokens?.access,
                'Content-Type': 'application/json',
            },
        })
            .then(response => response.json())
            .then(data => {
                if (data.error) {
                    console.error('Błąd podczas synchronizacji audytów:', data.error);
                    alert(`Błąd: ${data.error}`);
                } else {
                    alert(data.message);
                }
            })
            .catch(error => {
                console.error('Błąd podczas synchronizacji audytów:', error);
            });
    };

    useEffect(() => {
        if (
            !scheduledAudits.length &&
            !inProgressAudits.length &&
            !toCheckAudits.length &&
            !toSendAudits.length &&
            !sentAudits.length
        ) {
            fetch(`${backendUrl}/audit-page/audits/`, {
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: 'Bearer ' + (authTokens?.access || ''),
                },
            })
                .then((response) => response.json())
                .then((data) => {
                    if (Array.isArray(data)) {
                        const scheduledAudits = data.filter((audit) => audit.status === 'Scheduled');
                        const inProgressAudits = data.filter((audit) => audit.status === 'In Progress');
                        const toCheckAudits = data.filter((audit) => audit.status === 'To Check');
                        const toSendAudits = data.filter((audit) => audit.status === 'To Send');
                        const sentAudits = data.filter((audit) => audit.status === 'Sent');
                        setScheduledAudits(scheduledAudits);
                        setInProgressAudits(inProgressAudits);
                        setToCheckAudits(toCheckAudits);
                        setToSendAudits(toSendAudits);
                        setSentAudits(sentAudits);
                        setAllAudits(data);
                    } else {
                        console.error('Oczekiwano tablicy, ale otrzymano:', data);
                    }
                })
                .catch((error) => {
                    console.error('Błąd:', error);
                    setScheduledAudits([]);
                    setInProgressAudits([]);
                    setToCheckAudits([]);
                    setToSendAudits([]);
                    setSentAudits([]);
                    setAllAudits([]);
                });
        }
    }, [
        authTokens?.access,
        backendUrl,
        inProgressAudits.length,
        scheduledAudits.length,
        toCheckAudits.length,
        toSendAudits.length,
        sentAudits.length,
        setAllAudits,
        setInProgressAudits,
    ]);

    // Łączymy wszystkie audyty w jedną tablicę
    const allAuditsArray = allAudits.length
        ? allAudits
        : [
            ...scheduledAudits,
            ...inProgressAudits,
            ...toCheckAudits,
            ...toSendAudits,
            ...sentAudits,
        ];

    // Modyfikujemy wydarzenia do formatu akceptowanego przez kalendarz
    const events = allAuditsArray.map((audit) => ({
        title: audit.audit_title,
        start: new Date(audit.start_date),
        end: new Date(audit.end_date),
        auditId: audit.audit_id,
        audit_title: audit.audit_title,
        status: audit.status,
        ...audit, // Dodatkowe dane audytu
    }));

    // Stan dla PreviewPopup
    const [isPreviewPopupVisible, setIsPreviewPopupVisible] = useState(false);
    const [audit, setAudit] = useState(null);
    const PreviewPopupRef = useRef();

    const openPreviewPopup = (audit) => {
        setAudit(audit);
        setIsPreviewPopupVisible(true);
    };

    const handleSelectEvent = (event) => {
        // Otwórz PreviewPopup z danymi audytu
        openPreviewPopup(event);
    };

    const calculateOverlaps = (events) => {
        return events.map((event, index, arr) => {
            const overlappingCount = arr.filter((e) =>
                e.start < event.end && e.end > event.start && e !== event
            ).length;
            return { ...event, overlappingCount };
        });
    };
    const eventsWithOverlaps = calculateOverlaps(events);

    return (
        <div className="flex flex-col lg:flex-row max-h-screen overflow-hidden">
            <Nav
                currentPage={currentPage}
                setCurrentPage={setCurrentPage}
                createPageNumber={createPageNumber}
                setCreatePageNumber={setCreatePageNumber}
            />

            <div className="w-full p-4 sm:p-6 lg:p-8 sm:!pt-0 flex flex-col min-h-screen overflow-auto pb-12 mt-7">

                <div className='w-full flex items-center justify-between mb-4'>
                    {/* Sekcja tytułu i powitania */}
                    <div className='flex flex-col'>
                        <h1 className='text-3xl sm:text-4xl font-bold text-gray-800 mb-2'>Kalendarz Audytów</h1>
                    </div>

                    {/* Sekcja wyszukiwania */}
                    <div className='flex mr-3'>
                        <div className='hidden lg:block w-80 mx-12 relative'>
                            <MainSearchbar allAudits={allAudits} />
                        </div>
                        <UserElement />
                    </div>

                </div>



                {/* Kalendarz */}
                <div style={{ height: 'calc(100vh - 200px)' }}>
                    <Calendar
                        localizer={localizer}
                        events={eventsWithOverlaps}
                        startAccessor="start"
                        endAccessor="end"
                        style={{ height: '100%' }}
                        onSelectEvent={handleSelectEvent}
                        views={['month']}
                        popup // Wyłącza wyświetlanie przycisku "+x more"
                        components={{
                            event: (props) => <Event {...props} events={eventsWithOverlaps} />,
                        }}
                    />
                </div>

                {/* Kontener przycisków */}
                <div className="flex flex-col sm:flex-row items-center justify-center mt-6 space-y-4 sm:space-y-0 sm:space-x-4">
                    <button
                        onClick={handleGoogleSync}
                        className="flex items-center bg-blue-600 hover:bg-blue-700 text-white px-4 py-2 rounded shadow"
                    >
                        <FaGoogle className="mr-2" />
                        Połącz z Kalendarzem Google
                    </button>

                    <button
                        onClick={handleRefreshAudits}
                        className="flex items-center bg-green-600 hover:bg-green-700 text-white px-4 py-2 rounded shadow"
                    >
                        <FaSyncAlt className="mr-2" />
                        Synchronizuj Audyty
                    </button>
                </div>

                {/* PreviewPopup z informacjami o audycie */}
                {isPreviewPopupVisible && (
                    <PreviewPopup
                        audit={audit}
                        setAudit={setAudit}
                        PreviewPopupRef={PreviewPopupRef}
                        setIsPreviewPopupVisible={setIsPreviewPopupVisible}
                    />
                )}
            </div>
        </div>
    );
};

export default CalendarPage;
