import React, { useState, useEffect } from 'react';
import { Table, Button, Input, Select, Modal, Form, Card, Statistic, notification, Tag } from 'antd';
import { PlusOutlined, EditOutlined, DeleteOutlined } from '@ant-design/icons';
import { format, isValid } from 'date-fns';
import { pl } from 'date-fns/locale';
import { useAuth } from '../../context/AuthContext';
import axios from 'axios';
const { Option } = Select;

const Clients = () => {
    const [clients, setClients] = useState([]);
    const [filteredClients, setFilteredClients] = useState([]);
    const [selectedClient, setSelectedClient] = useState(null);
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [isDeleteModalVisible, setIsDeleteModalVisible] = useState(false);
    const [clientToDelete, setClientToDelete] = useState(null);
    const [form] = Form.useForm();
    const { authTokens } = useAuth();
    const backendUrl = process.env.REACT_APP_BACKEND_URL;
    const [expandedRowKeys, setExpandedRowKeys] = useState([]);
    const [allAudits, setAllAudits] = useState([]); // Dodaj stan do przechowywania wszystkich audytów

    useEffect(() => {
        fetchClients();
        fetchAllAudits(); // Pobierz wszystkie audyty
    }, []);

    const fetchClients = async () => {
        try {
            const response = await axios.get(`${backendUrl}/audit-page/clients/`, {
                headers: {
                    'Authorization': `Bearer ${authTokens?.access}`
                }
            });
            const clientsData = response.data.map(client => {
                const audits = client.audits || [];
                const sortedAudits = audits.sort((a, b) => new Date(b.end_date) - new Date(a.end_date));
                const lastAudit = sortedAudits[0] || {};
                const uniqueLocations = [...new Set(audits.map(audit => audit.location))];
                return {
                    ...client,
                    auditsCount: audits.length,
                    type: lastAudit.object_type || 'Brak',
                    locationsCount: uniqueLocations.length,
                    status: lastAudit.status || 'Brak',
                    lastAuditDate: lastAudit.end_date || null
                };
            });
            setClients(clientsData);
            setFilteredClients(clientsData);
        } catch (error) {
            console.error('Error fetching clients:', error);
            notification.error({
                message: 'Błąd',
                description: 'Nie udało się pobrać danych klientów',
            });
        }
    };

    const fetchAllAudits = async () => {
        try {
            const response = await axios.get(`${backendUrl}/audit-page/audits/`, {
                headers: {
                    'Authorization': `Bearer ${authTokens?.access}`
                }
            });
            setAllAudits(response.data);
        } catch (error) {
            console.error('Error fetching audits:', error);
            notification.error({
                message: 'Błąd',
                description: 'Nie udało się pobrać danych audytów',
            });
        }
    };

    const handleStatusChange = async (auditId, newStatus) => {
        try {
            await axios.put(`${backendUrl}/audit-page/change-audit-status/`, {
                audit_id: auditId,
                new_status: newStatus
            }, {
                headers: {
                    Authorization: `Bearer ${authTokens?.access || ''}`,
                },
            });
            notification.success({ message: 'Audit status updated' });

            // Update the status in the local state
            const updatedClients = clients.map(client => {
                const updatedAudits = client.audits.map(audit =>
                    audit.audit_id === auditId ? { ...audit, status: newStatus } : audit
                );
                const sortedAudits = updatedAudits.sort((a, b) => new Date(b.end_date) - new Date(a.end_date));
                const lastAudit = sortedAudits[0] || {};
                return {
                    ...client,
                    audits: updatedAudits,
                    status: lastAudit.status || 'Brak',
                    lastAuditDate: lastAudit.end_date || null
                };
            });
            setClients(updatedClients);
            setFilteredClients(updatedClients);
        } catch (error) {
            console.error('Error updating audit status:', error);
            notification.error({
                message: 'Error',
                description: 'Failed to update audit status',
            });
        }
    };

    const handleAddClient = async (values) => {
        try {
            const response = await axios.post(`${backendUrl}/audit-page/clients/add/`, values, {
                headers: {
                    'Authorization': `Bearer ${authTokens?.access}`
                }
            });
            const newClient = response.data;
            fetchClients()
            setClients([...clients, newClient]);
            setFilteredClients([...clients, newClient]);

            notification.success({ message: 'New client added' });
        } catch (error) {
            console.error('Error adding client:', error);
            notification.error({
                message: 'Error',
                description: 'Failed to add new client',
            });
        }
    };

    const handleEditClient = async (clientId, values) => {
        try {
            const response = await axios.put(`${backendUrl}/audit-page/edit-client/${clientId}/`, values, {
                headers: {
                    'Authorization': `Bearer ${authTokens?.access}`
                }
            });
            const updatedClient = response.data;
            const updatedClients = clients.map(client =>
                client.id === clientId ? { ...client, ...updatedClient, audits: updatedClient.audits || client.audits } : client
            );
            fetchClients()
            setClients(updatedClients);
            setFilteredClients(updatedClients);
            notification.success({ message: 'Client updated' });
        } catch (error) {
            console.error('Error updating client:', error);
            notification.error({
                message: 'Error',
                description: 'Failed to update client',
            });
        }
    };

    const handleDelete = async (clientId) => {
        try {
            await axios.delete(`${backendUrl}/audit-page/clients/delete/${clientId}/`, {
                headers: {
                    'Authorization': `Bearer ${authTokens?.access}`
                }
            });
            const updatedClients = clients.filter(client => client.id !== clientId);
            setClients(updatedClients);
            setFilteredClients(updatedClients);
            notification.success({ message: 'Client deleted' });
        } catch (error) {
            console.error('Error deleting client:', error);
            notification.error({
                message: 'Error',
                description: 'Failed to delete client',
            });
        }
    };

    const confirmDelete = (clientId) => {
        setClientToDelete(clientId);
        setIsDeleteModalVisible(true);
    };

    const handleDeleteModalOk = () => {
        handleDelete(clientToDelete);
        setIsDeleteModalVisible(false);
        setClientToDelete(null);
    };

    const handleDeleteModalCancel = () => {
        setIsDeleteModalVisible(false);
        setClientToDelete(null);
    };

    const columns = [
        { title: 'Client Name', dataIndex: 'name', key: 'name' },
        { title: 'Object Type', dataIndex: 'type', key: 'type' },
        { title: 'Locations', dataIndex: 'locationsCount', key: 'locationsCount' },
        { title: 'Audit Count', dataIndex: 'auditsCount', key: 'auditsCount' },
        {
            title: 'Last Audit',
            dataIndex: 'lastAuditDate',
            key: 'lastAuditDate',
            render: (date) => isValid(new Date(date)) ? format(new Date(date), 'dd.MM.yyyy', { locale: pl }) : 'N/A'
        },
        {
            title: 'Status',
            dataIndex: 'status',
            key: 'status',
            render: (status) => {
                let color = 'default';
                if (status === 'Completed') color = 'green';
                else if (status === 'In Progress') color = 'blue';
                else if (status === 'Scheduled') color = 'orange';
                else if (status === 'To Check') color = 'purple';
                else if (status === 'Sent') color = 'cyan';
                return <Tag color={color}>{status}</Tag>;
            }
        },
        {
            title: 'Actions',
            key: 'actions',
            render: (text, record) => (
                <span>
                    <Button icon={<EditOutlined />} onClick={() => handleEdit(record)} />
                    <Button icon={<DeleteOutlined />} onClick={() => confirmDelete(record.id)} />
                </span>
            ),
        },
    ];

    const handleEdit = (client) => {
        setSelectedClient(client);
        form.setFieldsValue({
            ...client,
            audit_ids: client.audits.map(audit => audit.audit_id) // Ustawienie wybranych audytów
        });
        setIsModalVisible(true);
    };
    const handleModalOk = () => {
        form.validateFields().then(values => {
            if (selectedClient) {
                handleEditClient(selectedClient.id, values);
            } else {
                handleAddClient(values);
            }
            setIsModalVisible(false);
            form.resetFields();
        });
    };

    const handleModalCancel = () => {
        setIsModalVisible(false);
        form.resetFields();
    };

    const handleSearch = (value) => {
        const filtered = clients.filter(client =>
            client.name.toLowerCase().includes(value.toLowerCase()) ||
            client.location.toLowerCase().includes(value.toLowerCase())
        );
        setFilteredClients(filtered);
    };

    const handleFilter = (type) => {
        if (type === 'all') {
            setFilteredClients(clients);
        } else {
            const filtered = clients.filter(client => client.type === type);
            setFilteredClients(filtered);
        }
    };

    return (
        <div className="p-6">
            <h1 className="text-3xl font-bold mb-4">Clients</h1>
            <div className="mb-4 flex justify-between items-center">
                <div>
                    <Input.Search
                        placeholder="Search client"
                        onSearch={handleSearch}
                        style={{ width: 200 }}
                        className="mr-2"
                    />
                    <Select defaultValue="all" style={{ width: 120 }} onChange={handleFilter}>
                        <Option value="all">All</Option>
                        <Option value="Hotel">Hotel</Option>
                        <Option value="Museum">Museum</Option>
                        <Option value="Theater">Theater</Option>
                    </Select>
                </div>
                <Statistic title="Client Count" value={filteredClients.length} />
                <Button type="primary" icon={<PlusOutlined />} onClick={() => {
                    setSelectedClient(null); // Ustawienie wybranego klienta na null, aby modal był do dodawania
                    setIsModalVisible(true);
                }} style={{ backgroundColor: '#1890ff', borderColor: '#1890ff', color: '#fff' }}>
                    Add New Client
                </Button>
            </div>
            <Table
                columns={columns}
                dataSource={filteredClients}
                rowKey="id"
                expandable={{
                    expandedRowRender: (record) => (
                        <div style={{ margin: 0 }}>
                            <p><strong>Manager:</strong> {record.manager_first_name} {record.manager_last_name}</p>
                            <p><strong>Contact Email:</strong> {record.contact_email}</p>
                            <p><strong>LinkedIn:</strong> <a href={record.linkedin_profile} target="_blank" rel="noopener noreferrer">{record.linkedin_profile}</a></p>
                            <h4>Audit History</h4>
                            <Table
                                columns={[
                                    {
                                        title: 'Audit Date',
                                        dataIndex: 'end_date',
                                        key: 'end_date',
                                        render: (date) => isValid(new Date(date)) ? format(new Date(date), 'dd.MM.yyyy', { locale: pl }) : 'N/A'
                                    },
                                    {
                                        title: 'Status',
                                        dataIndex: 'status',
                                        key: 'status',
                                        render: (status, record) => (
                                            <Select
                                                defaultValue={status}
                                                style={{ width: 120 }}
                                                onChange={(value) => handleStatusChange(record.audit_id, value)}
                                            >
                                                <Option key="Scheduled" value="Scheduled">Scheduled</Option>
                                                <Option key="In Progress" value="In Progress">In Progress</Option>
                                                <Option key="To Check" value="To Check">To Check</Option>
                                                <Option key="To Send" value="To Send">To Send</Option>
                                                <Option key="Sent" value="Sent">Sent</Option>
                                            </Select>
                                        )
                                    },
                                    {
                                        title: 'Yes Answers',
                                        key: 'yes_answers',
                                        render: (text, record) => `${record.yes_count || 0} / ${record.all_qs - record.na_count || 0}`
                                    },
                                    {
                                        title: 'Yes Answer Percentage',
                                        key: 'yes_percentage',
                                        render: (text, record) => {
                                            const totalAnswered = (record.all_qs || 0) - (record.na_count || 0);
                                            const percentage = totalAnswered > 0
                                                ? ((record.yes_count / totalAnswered) * 100).toFixed(2)
                                                : '0.00';
                                            return `${percentage}%`;
                                        }
                                    },
                                ]}
                                dataSource={record.audits}
                                pagination={false}
                                rowKey="audit_id"
                            />
                        </div>
                    ),
                    expandedRowKeys: expandedRowKeys,
                    onExpand: (expanded, record) => {
                        const keys = expanded
                            ? [...expandedRowKeys, record.id]
                            : expandedRowKeys.filter(key => key !== record.id);
                        setExpandedRowKeys(keys);
                    },
                }}
            />
            <Modal
                title={selectedClient ? 'Edit Client' : 'Add New Client'}
                open={isModalVisible}
                onOk={handleModalOk}
                onCancel={handleModalCancel}
                okButtonProps={{ style: { backgroundColor: '#1890ff', borderColor: '#1890ff', color: '#fff' } }}
            >
                <Form form={form} layout="vertical">
                    <Form.Item name="name" label="Client Name" rules={[{ required: true }]}>
                        <Input />
                    </Form.Item>
                    <Form.Item name="contact_email" label="Contact Email" rules={[{ required: true, type: 'email' }]}>
                        <Input />
                    </Form.Item>
                    <Form.Item name="manager_first_name" label="Manager First Name" rules={[{ required: true }]}>
                        <Input />
                    </Form.Item>
                    <Form.Item name="manager_last_name" label="Manager Last Name" rules={[{ required: true }]}>
                        <Input />
                    </Form.Item>
                    <Form.Item name="linkedin_profile" label="LinkedIn Profile">
                        <Input />
                    </Form.Item>
                    <Form.Item name="audit_ids" label="Audits">
                        <Select mode="multiple" allowClear>
                            {allAudits.map(audit => (
                                <Option key={audit.audit_id} value={audit.audit_id}>
                                    {audit.audit_title}
                                </Option>
                            ))}
                        </Select>
                    </Form.Item>
                </Form>
            </Modal>
            <Modal
                title="Confirm Deletion"
                open={isDeleteModalVisible}
                onOk={handleDeleteModalOk}
                onCancel={handleDeleteModalCancel}
                okButtonProps={{ style: { backgroundColor: '#ff4d4f', borderColor: '#ff4d4f', color: '#fff' } }}
            >
                <p>Are you sure you want to delete this client?</p>
            </Modal>
        </div>
    );
};

export default Clients;