import React, { useState, useEffect } from 'react';
import { useAuth } from '../../context/AuthContext';
import { notification, Select, Input, Button, Switch } from 'antd';
import { Bell, Phone, Moon, User, Mail, Globe } from 'lucide-react';
import { UploadOutlined } from '@ant-design/icons';
import axios from 'axios';

const { Option } = Select;

const UserSettings = () => {
    const { authTokens, updateUserProfile } = useAuth();
    const [formData, setFormData] = useState({
        first_name: '',
        last_name: '',
        email: '',
        timezone: '',
        language: '',
        email_notifications: false,
        sms_notifications: false,
        dark_mode: false,
        phone_number: ''
    });
    const [passwordData, setPasswordData] = useState({
        oldPassword: '',
        newPassword: '',
        confirmNewPassword: ''
    });
    const [isChangingPassword, setIsChangingPassword] = useState(false);
    const [profileImage, setProfileImage] = useState(null);
    const [previewImage, setPreviewImage] = useState(null);
    const [csrfToken, setCsrfToken] = useState('');
    const backendUrl = process.env.REACT_APP_BACKEND_URL;

    useEffect(() => {
        fetchUserProfile();
    }, []);

    useEffect(() => {
        const fetchCsrfToken = async () => {
            try {
                const response = await axios.get(`${backendUrl}/audit-page/get-csrf-token/`, {
                    withCredentials: true,
                });
                const csrfTokenFromResponse = response.headers['x-csrftoken'];
                setCsrfToken(csrfTokenFromResponse);
                // Ustawienie domyślnego nagłówka w axios
                axios.defaults.headers.common['X-CSRFToken'] = csrfTokenFromResponse;
            } catch (error) {
                console.error('Error fetching CSRF token:', error);
            }
        };
        fetchCsrfToken();
    }, [backendUrl]);




    const fetchUserProfile = async () => {
        try {
            const response = await axios.get(
                `${backendUrl}/audit-page/user-info/`,
                {
                    headers: {
                        'Authorization': `Bearer ${authTokens.access}`
                    }
                }
            );
            setFormData(response.data);
            if (response.data.profile_picture) {
                setPreviewImage(`${response.data.profile_picture}`);
            }
        } catch (error) {
            console.error('Błąd podczas pobierania profilu użytkownika:', error);
            notification.error({
                message: 'Błąd',
                description: 'Wystąpił błąd podczas pobierania danych użytkownika.',
            });
        }
    };

    const handleChange = (e) => {
        const { name, value, type, checked } = e.target;
        setFormData(prevState => ({
            ...prevState,
            [name]: type === 'checkbox' ? checked : value
        }));
    };

    const handleSelectChange = (name, value) => {
        setFormData(prevState => ({
            ...prevState,
            [name]: value
        }));
    };

    const handlePasswordChange = (e) => {
        const { name, value } = e.target;
        setPasswordData(prevState => ({
            ...prevState,
            [name]: value
        }));
    };

    const handleImageChange = (e) => {
        const file = e.target.files[0];
        if (file) {
            setProfileImage(file);
            setPreviewImage(URL.createObjectURL(file));
        }
    };



    const handleSubmit = async (e) => {
        e.preventDefault();
        const formDataToSend = new FormData();
        const fieldsToSend = [
            'first_name', 'last_name', 'timezone', 'language',
            'email_notifications', 'sms_notifications', 'dark_mode'
        ];

        fieldsToSend.forEach(key => {
            if (formData[key] !== undefined) {
                formDataToSend.append(key, formData[key]);
            }
        });

        if (profileImage) {
            formDataToSend.append('profile_picture', profileImage);
        }

        try {
            const response = await axios.put(
                `${backendUrl}/audit-page/user-profile-edit/`,
                formDataToSend,
                {
                    headers: {
                        'Authorization': `Bearer ${authTokens.access}`,
                        'Content-Type': 'multipart/form-data'
                    }
                }
            );
            updateUserProfile({
                first_name: formData.first_name,
                last_name: formData.last_name,
                profile_picture: response.data.profile_picture
            });

            notification.success({
                message: 'Sukces',
                description: 'Ustawienia zostały zaktualizowane pomyślnie.',
            });
        } catch (error) {
            notification.error({
                message: 'Błąd',
                description: 'Wystąpił błąd podczas aktualizacji ustawień.',
            });
            console.error('Błąd aktualizacji ustawień użytkownika:', error);
        }
    };

    const handlePasswordSubmit = async (e) => {
        e.preventDefault();
        if (passwordData.newPassword !== passwordData.confirmNewPassword) {
            notification.error({
                message: 'Błąd',
                description: 'Nowe hasła się nie zgadzają.',
            });
            return;
        }

        try {
            await axios.post(
                `${backendUrl}/audit-page/change-password/`,
                {
                    old_password: passwordData.oldPassword,
                    new_password: passwordData.newPassword,
                    confirm_password: passwordData.confirmNewPassword,
                },
                {
                    headers: {
                        'Authorization': `Bearer ${authTokens.access}`,
                        'X-CSRFToken': csrfToken,
                    },
                    withCredentials: true, // Ważne dla przesyłania ciasteczek sesji
                }
            );
            notification.success({
                message: 'Sukces',
                description: 'Hasło zostało zmienione pomyślnie.',
            });
            setIsChangingPassword(false);
            // Usuwanie wartości z formularzy po poprawnym zresetowaniu hasła
            setPasswordData({
                oldPassword: '',
                newPassword: '',
                confirmNewPassword: '',
            });
        } catch (error) {
            notification.error({
                message: 'Błąd',
                description: 'Wystąpił błąd podczas zmiany hasła.',
            });
            console.error('Błąd zmiany hasła:', error);
        }
    };



    return (
        <div className="min-h-screen py-8 bg-white">
            <div className="w-full max-w-8xl mx-auto py-4">
                <h1 className="text-3xl font-semibold mb-6">User Settings</h1>



                {/* Password Change Form */}
                {isChangingPassword ? (
                    <form onSubmit={handlePasswordSubmit} className="mt-8 space-y-6 max-w-lg mx-auto">
                        <h2 className="text-2xl font-semibold mb-4">Change Password</h2>
                        <div>
                            <label htmlFor="oldPassword" className="block text-sm font-medium text-gray-700">Old Password</label>
                            <Input.Password name="oldPassword" value={passwordData.oldPassword} onChange={handlePasswordChange} />
                        </div>
                        <div>
                            <label htmlFor="newPassword" className="block text-sm font-medium text-gray-700">New Password</label>
                            <Input.Password name="newPassword" value={passwordData.newPassword} onChange={handlePasswordChange} />
                        </div>
                        <div>
                            <label htmlFor="confirmNewPassword" className="block text-sm font-medium text-gray-700">Confirm New Password</label>
                            <Input.Password name="confirmNewPassword" value={passwordData.confirmNewPassword} onChange={handlePasswordChange} />
                        </div>
                        <Button type="primary" htmlType="submit" style={{ backgroundColor: '#000', borderColor: '#000' }}>
                            Change Password
                        </Button>
                    </form>
                ) : (
                    <div className="grid grid-cols-1 lg:grid-cols-2 gap-8">
                        {/* Personal Information */}
                        <div className="border border-gray-200 rounded-lg p-6">
                            <div className="flex items-center mb-4">
                                <User className="h-6 w-6 mr-2 text-gray-700" />
                                <h2 className="text-2xl font-semibold">Personal Information</h2>
                            </div>
                            <div className="flex items-center space-x-6 mb-6">
                                <div className="relative group w-24 h-24">
                                    <img
                                        src={previewImage || 'path/to/default/image'}
                                        alt="Profile"
                                        className="w-full h-full rounded-full object-cover cursor-pointer"
                                    />
                                    <div className="absolute inset-0 bg-black opacity-0 group-hover:opacity-50 rounded-full transition-opacity duration-300"></div>
                                    <div className="absolute inset-0 flex items-center justify-center opacity-0 group-hover:opacity-100 transition-opacity duration-300">
                                        <span className="text-white text-xs text-center px-2">Change photo</span>
                                    </div>
                                    <input
                                        type="file"
                                        id="profile_picture"
                                        name="profile_picture"
                                        accept="image/*"
                                        onChange={handleImageChange}
                                        className="hidden"
                                    />
                                    <label htmlFor="profile_picture" className="absolute inset-0 cursor-pointer"></label>
                                </div>
                            </div>
                            <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                                <div>
                                    <label className="block text-sm font-medium text-gray-700">First Name</label>
                                    <Input name="first_name" value={formData.first_name} onChange={handleChange} />
                                </div>
                                <div>
                                    <label className="block text-sm font-medium text-gray-700">Surname</label>
                                    <Input name="last_name" value={formData.last_name} onChange={handleChange} />
                                </div>
                            </div>
                        </div>

                        {/* Contact Information */}
                        <div className="border border-gray-200 rounded-lg p-6">
                            <div className="flex items-center mb-4">
                                <Mail className="h-6 w-6 mr-2 text-gray-700" />
                                <h2 className="text-2xl font-semibold">Contact Information</h2>
                            </div>
                            <div className="grid grid-cols-1 gap-4">
                                <div>
                                    <label className="block text-sm font-medium text-gray-700">Email</label>
                                    <Input name="email" value={formData.email} disabled onChange={handleChange} />
                                </div>
                                <div>
                                    <label className="block text-sm font-medium  text-gray-700">Phone Number</label>
                                    <Input name="phone_number" value={formData.phone_number} disabled onChange={handleChange} />
                                </div>
                            </div>
                        </div>

                        {/* Regional Settings */}
                        <div className="border border-gray-200 rounded-lg p-6">
                            <div className="flex items-center mb-4">
                                <Globe className="h-6 w-6 mr-2 text-gray-700" />
                                <h2 className="text-2xl font-semibold">Regional Settings</h2>
                            </div>
                            <div className="grid grid-cols-1 gap-4">
                                <div>
                                    <label className="block text-sm font-medium text-gray-700">Timezone</label>
                                    <Select
                                        value={formData.timezone}
                                        onChange={(value) => handleSelectChange('timezone', value)}
                                        placeholder="Select Timezone"
                                        className="w-full"
                                    >
                                        <Option value="Europe/Warsaw">Europe/Warsaw</Option>
                                        <Option value="Europe/London">Europe/London</Option>
                                        <Option value="America/New_York">America/New_York</Option>
                                        <Option value="Asia/Tokyo">Asia/Tokyo</Option>
                                        <Option value="Australia/Sydney">Australia/Sydney</Option>
                                    </Select>
                                </div>
                                <div>
                                    <label className="block text-sm font-medium text-gray-700">Language</label>
                                    <Select
                                        value={formData.language}
                                        onChange={(value) => handleSelectChange('language', value)}
                                        placeholder="Select Language"
                                        className="w-full"
                                    >
                                        <Option value="pl">Polski</Option>
                                        <Option value="en">English</Option>
                                        <Option value="de">Deutsch</Option>
                                        <Option value="fr">Français</Option>
                                        <Option value="es">Español</Option>
                                    </Select>
                                </div>
                            </div>
                        </div>

                        {/* Notifications */}
                        <div className="border border-gray-200 rounded-lg p-6">
                            <div className="flex items-center mb-4">
                                <Bell className="h-6 w-6 mr-2 text-gray-700" />
                                <h2 className="text-2xl font-semibold">Notifications</h2>
                            </div>
                            <div className="space-y-4">
                                <div className="flex items-center justify-between">
                                    <label className="flex items-center space-x-2">
                                        <Mail className="h-5 w-5" />
                                        <span>Email Notifications</span>
                                    </label>
                                    <Switch checked={formData.email_notifications} onChange={(checked) => handleSelectChange('email_notifications', checked)} />
                                </div>
                                <div className="flex items-center justify-between">
                                    <label className="flex items-center space-x-2">
                                        <Phone className="h-5 w-5" />
                                        <span>SMS Notifications</span>
                                    </label>
                                    <Switch checked={formData.sms_notifications} onChange={(checked) => handleSelectChange('sms_notifications', checked)} />
                                </div>
                                <div className="flex items-center justify-between">
                                    <label className="flex items-center space-x-2">
                                        <Moon className="h-5 w-5" />
                                        <span>Dark Mode</span>
                                    </label>
                                    <Switch checked={formData.dark_mode} onChange={(checked) => handleSelectChange('dark_mode', checked)} />
                                </div>
                            </div>
                        </div>
                    </div>
                )}
                {/* Buttons */}
                <div className="mt-8 flex justify-end space-x-4">
                    <Button
                        type="default"
                        onClick={() => setIsChangingPassword(!isChangingPassword)}
                        style={{ backgroundColor: '#fff', borderColor: '#a0a0a0', color: "#000" }}
                    >
                        {isChangingPassword ? 'Cancel Password Change' : 'Change Password'}
                    </Button>
                    <Button
                        type="primary"
                        onClick={handleSubmit}
                        style={{ backgroundColor: '#000', borderColor: '#000' }}
                    >
                        Save Changes
                    </Button>

                </div>


            </div>
        </div>
    );
};

export default UserSettings;
