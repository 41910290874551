import React, { useState, useEffect, useRef } from 'react'
import { useAuth } from '../../context/AuthContext';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';

// Components
import AuditsList from './AuditsList'
import PreviewPopup from './PreviewPopup';
import UserElement from '../UserElement';
import MainSearchbar from '../MainSearchbar';

// Icons
import { ReactComponent as ChartHistogramIcon } from '../../utils/dashboard/chart-histogram.svg';
import { ReactComponent as CalendarClockIcon } from '../../utils/dashboard/calendar-clock.svg';
import { ReactComponent as AssessmentIcon } from '../../utils/dashboard/assessment.svg';
import UserSettings from './UserSettings';
import { FaMobileAlt, FaDesktop } from 'react-icons/fa';
import { FaArrowUp } from 'react-icons/fa';



const Dashboard = ({ scheduledAudits, setScheduledAudits, inProgressAudits, setInProgressAudits, toCheckAudits, setToCheckAudits, toSendAudits, setToSendAudits, sentAudits, setSentAudits, currentPage, currentAudit, setCurrentAudit, allAudits, setCurrentPage }) => {
    const { authTokens, userInfo } = useAuth();


    const [showScrollbar, setShowScrollbar] = useState(false);
    const [isPreviewPopupVisible, setIsPreviewPopupVisible] = useState(false);
    const [audit, setAudit] = useState(null);
    const [sortOrder, setSortOrder] = useState({
        inProgress: true,
        scheduled: true,
        toCheck: true,
        toSend: true,
        sent: true
    });
    const PreviewPopupRef = useRef();
    console.log(scheduledAudits)
    const lastScrollPos = useRef(null);

    const [isMobileView, setIsMobileView] = useState(() => {
        const savedState = localStorage.getItem('isMobileView');
        return savedState ? JSON.parse(savedState) : false; // Domyślnie ustaw false, jeśli nie ma zapisanego stanu
    });
    const toggleView = () => {
        const newIsMobileView = !isMobileView;
        setIsMobileView(newIsMobileView);
        localStorage.setItem('isMobileView', JSON.stringify(newIsMobileView)); // Zapisz stan
    };

    useEffect(() => {
        let scrollTimeout;

        function hideScrollbarFunc() {
            setShowScrollbar(false);
        }

        function showScrollbarFunc() {
            setShowScrollbar(true);
            clearTimeout(scrollTimeout);
            scrollTimeout = setTimeout(hideScrollbarFunc, 3000); // Ukrycie suwaka przewijania po 3 sekundach nieaktywności
        }

        function handleScroll() {
            if (lastScrollPos.current !== window.scrollY) {
                showScrollbarFunc();
                lastScrollPos.current = window.scrollY;
            }
        }

        function handleMouseMove() {
            showScrollbarFunc();
        }

        function handleTouchStart() {
            showScrollbarFunc();
        }

        window.addEventListener('scroll', handleScroll);
        window.addEventListener('mousemove', handleMouseMove);
        window.addEventListener('touchstart', handleTouchStart);

        return () => {
            window.removeEventListener('scroll', handleScroll);
            window.removeEventListener('mousemove', handleMouseMove);
            window.removeEventListener('touchstart', handleTouchStart);
        };
    }, []);
    const backendUrl = process.env.REACT_APP_BACKEND_URL;
    const [status, setStatus] = useState({});

    const handleChange = (event, auditId) => {
        setStatus(prevStatus => ({ ...prevStatus, [auditId]: event.target.value }));
    }

    const handleSubmit = async (auditId, new_status) => {
        try {
            const response = await axios.put(`${backendUrl}/audit-page/change-audit-status/`, {
                audit_id: auditId,
                new_status: new_status
            }, {
                headers: {
                    Authorization: `Bearer ${authTokens?.access || ''}`,
                },
            });
            console.log(response.data.message);

            // Po udanym zapisie zaktualizuj stan listy audytów
            const removeAuditFromList = (list) => list.filter((audit) => audit.audit_id !== auditId);

            setInProgressAudits(removeAuditFromList(inProgressAudits));
            setScheduledAudits(removeAuditFromList(scheduledAudits));
            setToCheckAudits(removeAuditFromList(toCheckAudits));
            setToSendAudits(removeAuditFromList(toSendAudits));
            setSentAudits(removeAuditFromList(sentAudits));

            let updatedAudit = allAudits.find((audit) => audit.audit_id === auditId);
            if (updatedAudit) {
                switch (new_status) {
                    case 'In Progress':
                        setInProgressAudits((prev) => [...prev, updatedAudit]);
                        break;
                    case 'Scheduled':
                        setScheduledAudits((prev) => [...prev, updatedAudit]);
                        break;
                    case 'To Check':
                        setToCheckAudits((prev) => [...prev, updatedAudit]);
                        break;
                    case 'To Send':
                        setToSendAudits((prev) => [...prev, updatedAudit]);
                        break;
                    case 'Sent':
                        setSentAudits((prev) => [...prev, updatedAudit]);
                        break;
                    default:
                        console.error('Nieznany status: ' + new_status);
                }
            }
        } catch (error) {
            console.error(error);
        }
    };


    const [searchTerm, setSearchTerm] = useState('');
    const [searchResults, setSearchResults] = useState([]);
    const [isFocused, setIsFocused] = useState(false); // Dodajemy stan śledzący, czy searchbar jest zafocusowany


    useEffect(() => {
        if (searchTerm === '') {
            setSearchResults([]);
        } else {
            const results = allAudits.filter(audit =>
                audit.audit_title.toLowerCase().includes(searchTerm.toLowerCase()) ||
                formatDate(audit.start_date).includes(searchTerm.toLowerCase()) // Check if start_date contains the search term
            );
            setSearchResults(results);
        }
    }, [searchTerm, allAudits]);


    const openPreviewPopup = (audit) => {
        setCurrentAudit(audit.audit_id);
        setAudit(audit)
        setIsPreviewPopupVisible(true);
        // console.log(audit)
    };

    const handleSelectClick = (event, audit) => {
        // Sprawdź, czy kliknięcie nastąpiło na elemencie select
        event.stopPropagation();

        if (event.target.tagName.toLowerCase() === 'select') {
            // Jeśli tak, przerwij funkcję i nie otwieraj popupa
            return;
        }

        // Otwórz podgląd popupa
        openPreviewPopup(audit);
    };

    const formatDate = (dateString) => {
        const date = new Date(dateString);
        const day = String(date.getDate()).padStart(2, '0'); // Get day and pad with leading zero if needed
        const month = String(date.getMonth() + 1).padStart(2, '0'); // Get month (Note: January is 0!)
        const year = date.getFullYear().toString().slice(-2); // Get last two digits of the year
        return `${day}/${month}/${year}`;
    };

    const getLastThreeAudits = (audits) => {
        return audits
            .sort((a, b) => new Date(b.end_date) - new Date(a.end_date))
            .slice(0, 3);
    }
    const lastThreeAudits = getLastThreeAudits(scheduledAudits);

    const toggleSortOrder = (listKey) => {
        setSortOrder(prevSortOrder => ({
            ...prevSortOrder,
            [listKey]: !prevSortOrder[listKey]
        }));
    };





    return (
        <>
            <div className={`lg:w-full  w-full px-4  lg:pl-6 sm:pt-4  sm:pb-16 pb-32 max-h-screen overflow-hidden  ${showScrollbar ? '' : 'sm:hide-scrollbar'}`} id="scrollContainer">
                {/* <div className={`lg:w-full w-full px-2  lg:pl-6 sm:pt-4 pr-2 sm:pb-16 pb-32 overflow-auto sm:max-h-screen   ${showScrollbar ? '' : 'hide-scrollbar'}`} id="scrollContainer"> */}
                {isPreviewPopupVisible && (
                    <PreviewPopup
                        audit={audit}
                        PreviewPopupRef={PreviewPopupRef}
                        setIsPreviewPopupVisible={setIsPreviewPopupVisible}
                    />
                )}


                <div className='w-full flex items-center justify-between'>
                    {/* Sekcja tytułu i powitania */}
                    <div className='flex flex-col'>
                        <h1 className='text-3xl sm:text-4xl font-bold text-gray-800 mb-2'>Dashboard</h1>
                        <p className='text-lg sm:text-xl text-gray-600'>
                            Hi {userInfo ? userInfo.first_name : 'NAZWA UZYTKOWNIKA'}!
                            {currentPage !== 6 && ' You currently have:'}
                        </p>
                    </div>

                    {/* Sekcja wyszukiwania */}
                    <div className='flex mr-3'>
                        <div className='hidden lg:block w-80 mx-12 relative'>
                
                            <MainSearchbar allAudits={allAudits} />

                        </div>


                        <UserElement />

                        {/* Sekcja użytkownika */}
                    </div>

                </div>



                {currentPage !== 6 && (
                    <div className='w-full hidden md:flex flex-wrap gap-4 mt-4 '>
                        <div className='w-44 lg:w-60  bg-zinc-800 rounded-md'>
                            <div className='p-4 lg:ml-3 text-white font-semibold'>
                                <div className='flex '>
                                    <h2 className='text-4xl'>{inProgressAudits && inProgressAudits.length}</h2>
                                    <ChartHistogramIcon fill="#fff" className='lg:w-8 lg:h-8 h-6 w-6 ml-auto lg:mr-3 mt-2' />
                                </div>

                                <p className='text-base mt-1'>audits in progress</p>
                            </div>
                        </div>
                        <div className='w-44 lg:w-60 bg-zinc-800 rounded-md'>
                            <div className='p-4 lg:ml-3 font-semibold text-white'>
                                <div className='flex'>
                                    <h2 className='text-4xl'>{scheduledAudits && scheduledAudits.length}</h2>
                                    <CalendarClockIcon fill="#fff" className='lg:w-8 lg:h-8 h-6 w-6 ml-auto lg:mr-3 mt-2' />
                                </div>
                                <p className='text-base mt-1'>audits scheduled</p>
                            </div>
                        </div>
                        <div className='w-44 lg:w-60 bg-zinc-800 rounded-md'>
                            <div className='p-4 lg:ml-3 font-semibold text-white'>
                                <div className='flex'>
                                    <h2 className='text-4xl '>{sentAudits && sentAudits.length}</h2>
                                    <AssessmentIcon fill="#fff" className='lg:w-8 lg:h-8 h-6 w-6 ml-auto lg:mr-3 mt-2' />
                                </div>
                                <p className='text-base mt-1'>audits completed</p>
                            </div>
                        </div>
                        <button
                            onClick={toggleView}
                            className="h-12 mt-auto px-2 py-2 bg-gray-500 text-white rounded hidden sm:block"
                            aria-label={isMobileView ? 'Przełącz na widok desktopowy' : 'Przełącz na widok mobilny'}
                        >
                            {isMobileView ? (
                                <FaMobileAlt size={24} />
                            ) : (
                                <FaDesktop size={24} />
                            )}
                        </button>
                    </div>

                )}





                {currentPage === 1 && (
                    <>
                        <div className='w-full mt-6'>

                            <h3 className='font-bold text-xl mb-2'>In progress</h3>
                            {!isMobileView && (
                                <div className='hidden sm:flex justify-between'>
                                    <p className='mt-1 text-base font-semibold text-gray-500 w-1/3 lg:w-2/6'>Place name</p>
                                    <div className='mt-1 lg:text-base font-semibold text-center text-gray-500 w-1/3 lg:w-1/6 flex justify-center'>
                                        <p>Start date</p>
                                        <button onClick={() => toggleSortOrder('inProgress')} className="flex items-center text-gray-600 hover:text-gray-800 ml-2">
                                            <FaArrowUp className={`w-5 h-5 transform ${sortOrder.inProgress ? '' : 'rotate-180'}`} />
                                        </button>
                                    </div>
                                    <p className='hidden xl:block mt-1 text-base font-semibold text-center text-gray-500 w-1/6'>Progress</p>
                                    <p className='hidden xl:block mt-1 text-base font-semibold text-center text-gray-500 w-1/6'>HA Score</p>
                                    <p className='mt-1 text-base font-semibold text-center text-gray-500 w-1/6 xl:w-1/12'>Preview</p>
                                    <p className='mt-1 text-base font-semibold text-center text-gray-500 w-1/6 xl:w-1/6'>Status</p>
                                </div>

                            )}

                            <AuditsList audits={inProgressAudits} setCurrentAudit={setCurrentAudit} handleChange={handleChange} handleSubmit={handleSubmit} status={status} auditType="InProgress" isMobileView={isMobileView} sortDescending={sortOrder.inProgress} />
                        </div>


                        <div className='w-full mt-6 mb-2'>
                            <h3 className='font-semibold text-xl mb-2'>Upcoming audits</h3>
                            {!isMobileView && (
                                <div className='hidden sm:flex justify-between'>
                                    <p className='mt-1 text-base font-semibold text-gray-500 w-1/3 lg:w-2/6'>Place name</p>
                                    <div className='mt-1 lg:text-base font-semibold text-center text-gray-500 w-1/3 lg:w-1/6 flex justify-center'>
                                        <p>Start date</p>
                                        <button onClick={() => toggleSortOrder('scheduled')} className="flex items-center text-gray-600 hover:text-gray-800 ml-2">
                                            <FaArrowUp className={`w-5 h-5 transform ${sortOrder.scheduled ? '' : 'rotate-180'}`} />
                                        </button>
                                    </div>
                                    <p className='hidden xl:block mt-1 text-base font-semibold text-center text-gray-500 w-1/6'>Progress</p>
                                    <p className='hidden xl:block mt-1 text-base font-semibold text-center text-gray-500 w-1/6'>HA Score</p>
                                    <p className='mt-1 text-base font-semibold text-center text-gray-500 w-1/6 xl:w-1/12'>Preview</p>
                                    <p className='mt-1 text-base font-semibold text-center text-gray-500 w-1/6 xl:w-1/6'>Status</p>
                                </div>

                            )}
                            <AuditsList audits={lastThreeAudits} setCurrentAudit={setCurrentAudit} handleChange={handleChange} handleSubmit={handleSubmit} status={status} auditType="Scheduled" isMobileView={isMobileView} sortDescending={sortOrder.scheduled} />

                        </div>
                    </>
                )}

                {currentPage === 2 && (
                    <>
                        <div className='w-full mt-12 lg:mt-6'>
                            <h3 className='font-bold text-xl mb-2'>Scheduled</h3>
                            {!isMobileView && (
                                <div className='hidden sm:flex justify-between'>
                                    <p className='mt-1 text-base font-semibold text-gray-500 w-1/3 lg:w-2/6'>Place name</p>
                                    <div className='mt-1 lg:text-base font-semibold text-center text-gray-500 w-1/3 lg:w-1/6 flex justify-center'>
                                        <p>Start date</p>
                                        <button onClick={() => toggleSortOrder('scheduled')} className="flex items-center text-gray-600 hover:text-gray-800 ml-2">
                                            <FaArrowUp className={`w-5 h-5 transform ${sortOrder.scheduled ? '' : 'rotate-180'}`} />
                                        </button>
                                    </div>
                                    <p className='hidden xl:block mt-1 text-base font-semibold text-center text-gray-500 w-1/6'>Progress</p>
                                    <p className='hidden xl:block mt-1 text-base font-semibold text-center text-gray-500 w-1/6'>HA Score</p>
                                    <p className='mt-1 text-base font-semibold text-center text-gray-500 w-1/6 xl:w-1/12'>Preview</p>
                                    <p className='mt-1 text-base font-semibold text-center text-gray-500 w-1/6 xl:w-1/6'>Status</p>
                                </div>
                            )}
                            <AuditsList audits={scheduledAudits} setCurrentAudit={setCurrentAudit} handleChange={handleChange} handleSubmit={handleSubmit} status={status} auditType="Scheduled" isMobileView={isMobileView} sortDescending={sortOrder.scheduled} />

                        </div>
                    </>
                )}
                {currentPage === 5 && (
                    <>
                        <div className='w-full mt-12 lg:mt-6'>
                            <h3 className='font-bold text-xl mb-2'>Sent</h3>
                            {!isMobileView && (
                                <div className='hidden sm:flex justify-between'>
                                    <p className='mt-1 text-base font-semibold text-gray-500 w-1/3 lg:w-2/6'>Place name</p>
                                    <div className='mt-1 lg:text-base font-semibold text-center text-gray-500 w-1/3 lg:w-1/6 flex justify-center'>
                                        <p>Start date</p>
                                        <button onClick={() => toggleSortOrder('sent')} className="flex items-center text-gray-600 hover:text-gray-800 ml-2">
                                            <FaArrowUp className={`w-5 h-5 transform ${sortOrder.sent ? '' : 'rotate-180'}`} />
                                        </button>
                                    </div>
                                    <p className='hidden xl:block mt-1 text-base font-semibold text-center text-gray-500 w-1/6'>Progress</p>
                                    <p className='hidden xl:block mt-1 text-base font-semibold text-center text-gray-500 w-1/6'>HA Score</p>
                                    <p className='mt-1 text-base font-semibold text-center text-gray-500 w-1/6 xl:w-1/12'>Preview</p>
                                    <p className='mt-1 text-base font-semibold text-center text-gray-500 w-1/6 xl:w-1/6'>Status</p>
                                </div>
                            )}
                            <AuditsList audits={sentAudits} setCurrentAudit={setCurrentAudit} handleChange={handleChange} handleSubmit={handleSubmit} status={status} auditType="Sent" isMobileView={isMobileView} sortDescending={sortOrder.sent} />

                        </div>
                    </>
                )}
                {currentPage === 4 && (
                    <>
                        <div className='w-full mt-6'>
                            <h3 className='font-bold text-xl mb-2'>To Check</h3>
                            {!isMobileView && (
                                <div className='hidden sm:flex justify-between'>
                                    <p className='mt-1 text-base font-semibold text-gray-500 w-1/3 lg:w-2/6'>Place name</p>
                                    <div className='mt-1 lg:text-base font-semibold text-center text-gray-500 w-1/3 lg:w-1/6 flex justify-center'>
                                        <p>Start date</p>
                                        <button onClick={() => toggleSortOrder('toCheck')} className="flex items-center text-gray-600 hover:text-gray-800 ml-2">
                                            <FaArrowUp className={`w-5 h-5 transform ${sortOrder.toCheck ? '' : 'rotate-180'}`} />
                                        </button>
                                    </div>
                                    <p className='hidden xl:block mt-1 text-base font-semibold text-center text-gray-500 w-1/6'>Progress</p>
                                    <p className='hidden xl:block mt-1 text-base font-semibold text-center text-gray-500 w-1/6'>HA Score</p>
                                    <p className='mt-1 text-base font-semibold text-center text-gray-500 w-1/6 xl:w-1/12'>Preview</p>
                                    <p className='mt-1 text-base font-semibold text-center text-gray-500 w-1/6 xl:w-1/6'>Status</p>
                                </div>
                            )}
                            <AuditsList audits={toCheckAudits} setCurrentAudit={setCurrentAudit} handleChange={handleChange} handleSubmit={handleSubmit} status={status} auditType="ToCheck" isMobileView={isMobileView} sortDescending={sortOrder.toCheck} />
                        </div>

                        <div className='w-full mt-6'>
                            <h3 className='font-semibold text-xl mb-2'>To Send</h3>
                            {!isMobileView && (
                                <div className='hidden sm:flex justify-between'>
                                    <p className='mt-1 text-base font-semibold text-gray-500 w-1/3 lg:w-2/6'>Place name</p>
                                    <div className='mt-1 lg:text-base font-semibold text-center text-gray-500 w-1/3 lg:w-1/6 flex justify-center'>
                                        <p>Start date</p>
                                        <button onClick={() => toggleSortOrder('toSend')} className="flex items-center text-gray-600 hover:text-gray-800 ml-2">
                                            <FaArrowUp className={`w-5 h-5 transform ${sortOrder.toSend ? '' : 'rotate-180'}`} />
                                        </button>
                                    </div>
                                    <p className='hidden xl:block mt-1 text-base font-semibold text-center text-gray-500 w-1/6'>Progress</p>
                                    <p className='hidden xl:block mt-1 text-base font-semibold text-center text-gray-500 w-1/6'>HA Score</p>
                                    <p className='mt-1 text-base font-semibold text-center text-gray-500 w-1/6 xl:w-1/12'>Preview</p>
                                    <p className='mt-1 text-base font-semibold text-center text-gray-500 w-1/6 xl:w-1/6'>Status</p>
                                </div>
                            )}
                            <AuditsList audits={toSendAudits} setCurrentAudit={setCurrentAudit} handleChange={handleChange} handleSubmit={handleSubmit} status={status} auditType="ToSent" isMobileView={isMobileView} sortDescending={sortOrder.toSend} />
                        </div>
                    </>
                )}
                {currentPage === 6 && (
                    <>
                        <UserSettings />
                    </>
                )}

            </div >
        </>



    )
}

export default Dashboard